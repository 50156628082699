import React, { Dispatch, SetStateAction } from 'react';
import { CUSTOMER_DOCUMENTS, useGetCustomerAllDocuments } from 'hooks/customer/documents.hooks';

// material-ui
import { Autocomplete, Box, Button, ButtonGroup, Checkbox, Dialog, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';

// third-party
import { FormattedMessage } from 'react-intl';
import { IconCalendarTime, IconTable } from '@tabler/icons';

// locale
import { addDays, endOfMonth, parseJSON, startOfMonth, subDays } from 'date-fns';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import DocumentsTable from './Table';
import DocumentPreview from './DocumentPreview';
import { DocumentStatus, documentStatuses, ExtendedDocument } from 'types/DocumentsTypes/Document';

// assets
import Loader from 'ui-component/Loader';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import MyRangePicker from '../../../../ui-component/MyRangePicker';
import CalendarContainer from 'ui-component/calendar-container/calendar-container';
import { getMasterFilterState, setMasterFilterState } from "../../../../ui-component/master-filter-bar";
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from 'config';
import { enUS, he } from 'date-fns/locale';

export const DocumentsFilterBar = ({ status, setStatus, range, setRange, isLoading, tableName, singleDate, setSingleDate, byOrder, setByOrder,byBase,setByBase ,savePriceInClient,setSavePriceInClient,setInventory,inventory}: {
    status: DocumentStatus[] | null | undefined;
    setStatus: Dispatch<SetStateAction<DocumentStatus[]>> | undefined;
    range: RangeInput<null>;
    setRange: (range: RangeInput<null>) => void;
    isLoading: boolean;
    tableName: string;
    singleDate?: Date | null;
    setSingleDate?: (date: Date | null) => void;
    byOrder?: boolean;
    setByOrder?: (byOrder: boolean) => void;
    setInventory?: (inventory: boolean) => void;
    inventory?: boolean;
    byBase?: boolean;
    setByBase?: (byBase: boolean) => void;
    savePriceInClient?: boolean;
    setSavePriceInClient?: (savePriceInClient: boolean) => void;
}) => {
    const data = getMasterFilterState(tableName)
    const [radio_value, setRadioValue] = React.useState(data?.radio_value || 'from');
    return (
        <Grid spacing={1} style={{
            display: 'flex',
            justifyContent: 'start',
            width: '100%',
            gap: '10px',
        }}
            container
        >
            <Grid item m={[2, 1]} md={6} xs={12}>
                {
                    radio_value === "unique" && (
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                            <DatePicker
                                mask=''
                                renderInput={(props) => <TextField {...props} />}
                                label={"עד תאריך הפקדה"}
                                value={singleDate || null}
                                onChange={(newValue) => {
                                    if (newValue && !isNaN(newValue.getDate()) && setSingleDate) setSingleDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    )
                }
                {(radio_value === "from" && range) && <MyRangePicker range={range} setRange={setRange} isLoading={isLoading} />}
            </Grid>
            {

                (setSingleDate) &&
                <Grid item xs={5}>
                    <RadioGroup
                        row
                        value={radio_value}
                        aria-labelledby='demo-form-control-label-placement'
                        name='position'
                        onChange={(e) => {
                            setRadioValue(e.target.value);
                            setMasterFilterState(tableName, { radio_value: e.target.value })
                        }}
                    >
                        <FormControlLabel
                            value='from'
                            control={<Radio />}
                            label="בין תאריכים"
                            labelPlacement='top'
                        />
                        <FormControlLabel
                            value='unique'
                            control={<Radio />}
                            label="עד תאריך הפקדה"
                            labelPlacement='top'
                        />
                    </RadioGroup>
                </Grid>
            }
            <Grid item m={[2, 1]} md={4} xs={12}>
                {(status && status?.length > 0) && <Autocomplete
                    value={status}
                    onChange={(e: any, v: DocumentStatus[] | null) => {
                        if (v) setStatus(v);
                        setMasterFilterState(tableName, v)
                    }}
                    multiple
                    options={Object.keys(documentStatuses)}
                    getOptionLabel={(o) => documentStatuses[o]}
                    size='small'
                    fullWidth
                    autoHighlight
                    renderInput={(params) => <TextField {...params} label={<FormattedMessage id='Choose Status' />}
                        fullWidth size='small' />}
                />}
            </Grid>

            {(byOrder || setByOrder) &&
                <Grid item m={[2, 1]} md={2} xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox checked={byOrder} onChange={(e) => {
                                    if (setByOrder) setByOrder(e.target.checked);
                                }} />
                            }
                            label="רק לפי הזמנות"
                        />
                    </FormGroup>
                </Grid>
            }
            {(inventory || setInventory) &&<Grid item m={[2, 1]} md={2} xs={12}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox checked={inventory} onChange={(e) => {
                            if (setInventory) setInventory(e.target.checked);
                        }} />
                    }
                    label="עם מלאי"
                />
            </FormGroup>
        </Grid> }
            {(byBase || setByBase) &&<Grid item m={[2, 1]} md={2} xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox checked={byBase} onChange={(e) => {
                                if (setByBase) setByBase(e.target.checked);
                            }} />
                        }
                        label="רק בסיס"
                    />
                </FormGroup>
            </Grid> }

            {savePriceInClient && setSavePriceInClient &&
                <Grid item m={[2, 1]} md={2} xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox checked={savePriceInClient} onChange={(e) => {
                                    if (setSavePriceInClient) setSavePriceInClient(e.target.checked);

                                }
                                } />
                            }
                            label="שמור מחיר בלקוח"
                        />
                    </FormGroup>
                </Grid>

            }
        </Grid>
    )

}

const Calendar = ({ cid, name }: { cid: string; name: string }) => {
    const [status, setStatus] = React.useState<DocumentStatus[]>(['open', 'approved', 'pending_approval', 'closed']);
    const [range, setRange] = React.useState<RangeInput<null>>([(subDays(startOfMonth(new Date()), 7)), addDays(endOfMonth(new Date()), 7)]);
    const dispatch = useDispatch();
    const cache = useQueryClient();
    const {
        data: docs,
        isLoading,
        refetch
    } = useGetCustomerAllDocuments(parseJSON(range[0]!), parseJSON(range[1]!), true, cid, true, false, undefined, true,"CUSTOMER_DOCUMENTS_SPECIFIC");
    const [documents, setDocs] = React.useState<ExtendedDocument[]>([]);
    const pool = "CUSTOMER_DOCUMENTS_SPECIFIC"
    // fetch events data
    // eslint-disable-next-line prefer-const
    React.useEffect(() => {

        if (!isLoading && range && range[0] != null && range[1] != null) {
            cache.removeQueries(pool);
            refetch();
        }
    }, [range, dispatch]);

    React.useEffect(() => {
        if (docs)
            setDocs(docs?.filter((d) => (d.type != 'debt' || d?.nkObject?.state == 0) && (status.length === 0 || status.indexOf(d.status) > -1)));
        else
            setDocs([]);

    }, [status, docs]);


    return (
        <>
            {isLoading && <Loader />}
            <MainCard>
                <Box sx={{ pb: 3 }}>
                    <DocumentsFilterBar status={status} setStatus={setStatus} range={range} setRange={setRange}
                        isLoading={isLoading} />
                </Box>
                <DocumentsTable data={documents ?? []} pool={pool}/>
            </MainCard>
        </>
    );
};

export default Calendar;

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import styledTag from '../styles';

// assets
import CloseIcon from '@mui/icons-material/Close';

// types
import { TagProps } from '../types';

function TextTag(props: TagProps) {
    const { filter, onApplyFilter, onRemoveFilter, onDelete, ...other } = props;
    const [search, setSearch] = React.useState('');
    const [focused, setFocused] = React.useState(true);

    return (
        <Box {...other} sx={{ cursor: 'pointer' }} onClick={() => setFocused((p) => !p)}>
            <Typography variant="h5" fontWeight="bold">
                {filter.label ?? filter.id}:
            </Typography>
            {focused && (
                <TextField
                    autoFocus
                    focused={focused}
                    value={search}
                    onChange={(v) => {
                        if (v.target?.value) {
                            setSearch(v.target?.value ?? '')
                        } else if (onRemoveFilter) {
                            setSearch('')
                            onRemoveFilter({property: filter.id, contains: ''})
                        }
                    }}
                    onBlur={() => {
                        setFocused(false)
                        if (search && onApplyFilter)
                            onApplyFilter({property: filter.id, contains: search})
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setFocused(false);
                            if (onApplyFilter)
                                onApplyFilter({property: filter.id, contains: search})
                        }
                    }}
                />
            )}
            {!focused && (
                <>
                    <Typography variant="h5" fontWeight="bold">
                        {search}
                    </Typography>
                    <CloseIcon onClick={() => {
                        onDelete();
                        if (onRemoveFilter)
                            onRemoveFilter({property: filter.id, contains: ''})
                    }} />
                </>
            )}
        </Box>
    );
}

const StyledTextTag = styledTag(TextTag);

export default StyledTextTag;

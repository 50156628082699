import { Grid, IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from '../../config';
import { enUS, he } from 'date-fns/locale';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import {memo} from "react";

function CancelNoteDialog({curDate,cancelFunc,text}:{curDate?:Date,cancelFunc:any,text?:string}) {
    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState(curDate);

    const handleOk = async () => {
        cancelFunc(date)
        handleClose();
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    // React.useEffect(() => {
    //     console.log(updateCustomerAsyncState);
    //     if (updateCustomerAsyncState === 'succeeded') {
    //         dispatch({
    //             type: SNACKBAR_OPEN,
    //             open: true,
    //             message: 'המוצרים עודכנו בהצלחה!',
    //             variant: 'alert',
    //             alertSeverity: 'success'
    //         });
    //
    //         dispatch(initMassCustomersProductAsyncState());
    //
    //     } else if (updateCustomerAsyncState === 'failed') {
    //         Swal.fire({
    //             title: 'שגיאה בעדכון מוצרים',
    //             target: '#custom-target',
    //             text: '',
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'המשך'
    //         });
    //         dispatch(initMassCustomersProductAsyncState());
    //     }
    // }, [updateCustomerAsyncState, dispatch]);

    const handleClose = () => {
        setOpen(false);

    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue((event.target as HTMLInputElement).value);
    // };

    const onApprove =
        () => {
            handleOk();
        };

    return (
        <div>
            <Tooltip title={'האם תרצה לבטל מסמך'}>
                <IconButton style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '25px',
                    marginRight: '40px',
                    marginLeft: '-10px',
                    padding: '5px',
                }} size='medium' onClick={handleClickOpen}>
                    <DoNotDisturbOnIcon color='error' />
                    <span style={{
                        fontSize: '15px',
                        margin: '0 5px',
                    }}>{text ?? 'ביטול מסמך'}</span>
                </IconButton>
            </Tooltip>
            {open ? <Dialog id='doc_search_dialog'  maxWidth={'xs'} fullWidth={true}
                            open={open} onClose={handleClose}>
                <DialogTitle> {text ?? 'ביטול מסמך'}

                </DialogTitle>
                <DialogContent>
                    &nbsp;
                    <Grid container>
                        {curDate?            <Grid item xs={12} sm={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} type={'date'} />}
                                        label={'תאריך מסמך מבוטל'}
                                        value={date ?? new Date()}
                                        onChange={(newValue) => {
                                            if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            :undefined}

                    </Grid>
                    {/*<MasterTable*/}
                    {/*    headCells={columns}*/}
                    {/*    data={data ?? []}*/}
                    {/*    keyExtractor={(p) => p.id.toString()}*/}
                    {/*    withPagination*/}
                    {/*    paginationOptions={{*/}
                    {/*        rowsPerPage: 1000*/}
                    {/*    }}*/}
                    {/*    withSort*/}
                    {/*    sortOptions={{*/}
                    {/*        sortBy: {*/}
                    {/*            order: 'desc',*/}
                    {/*            orderBy: 'position'*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    withFilter*/}
                    {/*    TableItem={(row) => <ChangeItem row={row} />}*/}
                    {/*/>*/}

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'red', color: 'white' }}> בטל מסמך
                    </Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'blue', color: 'white' }}>חזור</Button>
                </DialogActions>
            </Dialog> : undefined}

        </div>
    );
}
export default memo(CancelNoteDialog);

import {useSelector} from 'react-redux';

import {createTheme, ThemeProvider, Theme} from '@mui/material/styles';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import * as locales from '@mui/material/locale';

// routing
import Routes from 'routes';

// store
import {DefaultRootStateProps} from 'types';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import config from 'config';

// auth provider
import {NKProvider} from './contexts/NKContext';
import {LicenseManager} from "ag-grid-enterprise";
import React from "react";


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {
    }
}

// ==============================|| APP ||============================== //


const App = () => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    React.useEffect(() => {
        LicenseManager.setLicenseKey(process.env.REACT_APP_GRID!)

    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createTheme(themes(customization), locales[config.locale])}>
                <CssBaseline/>
                <RTLLayout>
                    <Locales>
                        <NavigationScroll>
                            <NKProvider>
                                <>
                                    <Routes/>
                                    <Snackbar/>
                                </>
                            </NKProvider>
                        </NavigationScroll>
                    </Locales>
                </RTLLayout>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

import * as React from 'react';
import {memo, useCallback, useEffect, useRef} from 'react';
import {ContextMenuComponentProps} from 'react-datasheet-grid';
import {Avatar, Badge, Box, Button, Grid, ListItemIcon} from '@mui/material';
import {DataSheetGridContext} from './index';
import {getFile} from '../../../api/customer/files.api';
import {
    all_dvs_printed,
    buildQuerySum, changeAllOrderNoteDate, dailyBuildQuerySum,
    getMassDocumentPdf,
    getOrderDocumentPdf
} from '../../../api/customer/documents.api';
import {pdf} from '../../../assets/images';

import FormDialog from './custom-components/distributeDialog';
import Swal from 'sweetalert2';
import {useTheme} from '@mui/material/styles';
import IconsRow from './custom-cells/icons_row';
import ChangeOrderDateDialog from "../../../ui-component/dialogs/changeOrderDateDialog";

export const useDocumentEventListener = (type: string, listener: (...args: any[]) => void) => {
    useEffect(() => {
        document.addEventListener(type, listener);

        return () => {
            document.removeEventListener(type, listener);
        };
    }, [listener, type]);
};
const ContextMenu = ({clientX, clientY, items, close}: ContextMenuComponentProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [items2, setItems2] = React.useState([]);
    const {
        current,
        data,
        setData,
        updatedRowIds,
        saveOrder,
        cancelDV,
        columns,
        activeState,
        onApplyFilter,
        addCustomerFilter,
        removeAllFilters,
        date,
        dvs_flag
    } = React.useContext(DataSheetGridContext);
    const theme = useTheme();
    React.useEffect(() => {
        if (items[0] != null) {

            const colMin = items[0].colMin;
            const colMax = items[0].colMax;
            const rowMin = items[0].rowMin;
            const rowMax = items[0].rowMax;
            let notes = true;
            for (let i = rowMin; i <= rowMax; i += 1) {
                if (data) notes = notes && ((data[i]?.readonly));
            }
            const curData = [];
            for (let i = rowMin; i <= rowMax; i += 1) {
                if (data) curData.push(data[i]);
            }
            const it = [];
            it.push({
                key: 14, type: '', action: () => {

                }, icon:
                    <Grid container>

                        <Grid item xs={3} sm={3}>
                            <Badge color='secondary' max={2000} badgeContent={curData.length}/>
                        </Grid>
                        <Grid item xs={2} sm={2}>

                        </Grid>
                        <Grid item xs={4} sm={4}>
                            לקוחות
                        </Grid>
                    </Grid>

            });
            it.push({
                key: 1, type: '', action: () => {
                    const names = [];
                    for (let i = rowMin; i <= rowMax; i += 1) {
                        if (data) names.push(data[i]?.customer);
                    }
                    removeAllFilters();
                    onApplyFilter({property: 'customer', contains: '', values: Array.from(names)});
                    addCustomerFilter(Array.from(names));
                    if (current.selection)
                        current?.setSelection({
                            min: {col: current.selection.min.col, row: 0},
                            max: {col: current.selection.max.col, row: names.length - 1}
                        });
                    close();

                }, icon: <Button variant='outlined'>
                    הוסף לקוחות לפלטור
                </Button>
            });

            if (colMin === colMax && columns.length - 1 - colMin > 4) {
                const col = columns[colMin];
                let border = localStorage.getItem(`${col.id}_column_border`) ? Number(localStorage.getItem(`${col.id}_column_border`)) : 0;
                it.push({
                    key: 2,
                    type: '',
                    action: () => {
                        if (border) {
                            localStorage.setItem(`${col.id}_column_border`, '0');
                        } else {
                            localStorage.setItem(`${col.id}_column_border`, '1');
                        }
                        close();
                    },
                    icon: <Button style={{
                        backgroundColor: 'whitesmoke',
                        color: 'black'
                    }}>{(border) ? 'מחק הפרדה מימין' : 'הפרד עמודה מימין'}</Button>
                });
                it.push({
                    key: 3, devider: true, type: '', action: () => {
                    }, icon: <FormDialog items={items}/>

                });
                it.push({
                    key: 10, devider: true, type: '', action: () => {
                    }

                });
            }

            if (!notes && activeState.day == -1) {
                it.push({
                    key: 4, type: '', action: () => {
                        const orders = [];
                        for (let i = rowMin; i <= rowMax; i += 1) {
                            if (data && !(data[i]?.dvs && data[i]?.dvs.length > 0) && (dvs_flag || !updatedRowIds.has(data[i].id))) orders.push(data[i]);
                        }
                        const dvs = orders.map((p) => null);
                        if (dvs.length > 0)
                            Swal.fire({
                                title: `האם תרצה להנפיק ${dvs.length} תעודות `,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: theme.palette.primary.main,
                                cancelButtonColor: theme.palette.error.main,
                                confirmButtonText: 'הנפק',
                                cancelButtonText: 'בטל'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    /* Perform insert, update, and delete to the database here */

                                    saveOrder(orders.map((p) => p.id), dvs);
                                }
                            });

                        close();
                    }, icon: <Button style={{backgroundColor: 'red', color: 'white'}}>הנפק תעודות משלוח</Button>
                });
            }
            if (notes && activeState.day == -1) {
                it.push({
                    key: 4, type: '', action: () => {
                        const dvs = [];
                        for (let i = rowMin; i <= rowMax; i += 1) {
                            if (data && (data[i]?.dvs && data[i]?.dvs.length > 0))
                                data[i].dvs.forEach((dd) => (dd.status !== 'closed' && dd.type=='delivery_certificate') ? dvs.push(dd.id) : undefined);
                        }

                        if (dvs.length > 0)
                            Swal.fire({
                                title: `האם תרצה לבטל את תעודות המשלוח? ${dvs.length} תעודות משלוח`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: theme.palette.primary.main,
                                cancelButtonColor: theme.palette.error.main,
                                confirmButtonText: 'ביטול תעודות',
                                cancelButtonText: 'חזור'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    /* Perform insert, update, and delete to the database here */

                                    cancelDV(dvs);
                                }
                            });

                        close();
                    }, icon: <Button style={{backgroundColor: 'orange', color: 'white'}}>בטל תעודות משלוח</Button>
                });
            }
            it.push({
                key: 5, type: 'ייצא הזמנות', action: () => {
                    const ids = [];
                    const update_printed = new Set();
                    for (let i = rowMin; i <= rowMax; i += 1) {
                        if (data && data[i]?.orderId) ids.push(data[i]?.orderId);
                        if (data && data[i]?.orderId && !data[i]?.readonly) {
                            update_printed.add(data[i]?.orderId);
                        }

                    }
                    const curPdf = ids.map((u) => Number(u));

                    if (curPdf.length > 0) {
                        getFile(false, () => getOrderDocumentPdf(undefined, curPdf), 'הזמנות');
                        setData((prev) =>
                            prev.map((d, i) => {
                                    if (update_printed.has(d.orderId)) {
                                        updatedRowIds.add(d.id);
                                        return {...d, status: 'הודפס'};
                                    } else {
                                        return d;
                                    }

                                }
                            )
                        );

                    }

                    close();
                }, icon: <Avatar variant='square'
                                 sx={{
                                     marginRight: '10px',
                                     cursor: 'pointer',
                                     bgcolor: 'white',
                                     width: '18px',
                                     height: '18px'
                                 }}
                                 src={pdf}/>
            });
            it.push({
                key: 6, type: 'ייצא תעודות', action: () => {
                    const ids = [];
                    for (let i = rowMin; i <= rowMax; i += 1) {
                        if (data && data[i]?.dvs && data[i]?.dvs.length > 0) ids.push(...data[i]?.dvs);
                    }
                    const curPdf = ids.map((u) => Number(u));
                    if (curPdf.length > 0)
                        getFile(false, () => getMassDocumentPdf(ids, undefined, undefined), 'תעודות', undefined, async () => {
                            try {
                                const x = await all_dvs_printed(ids);
                                const set = new Set(x.map((dv) => dv.entId));
                                setData((prev) =>
                                    prev.map((d, i) => {
                                            if (set.has((d.id))) {
                                                return {...d, all_dvs_printed: true};
                                            } else {
                                                return d;
                                            }

                                        }
                                    )
                                );
                            } catch (e) {

                            }


                        });
                    close();
                }, icon: <Avatar variant='square'
                                 sx={{
                                     marginRight: '10px',
                                     cursor: 'pointer',
                                     bgcolor: 'white',
                                     width: '18px',
                                     height: '18px'
                                 }}
                                 src={pdf}/>
            });
            it.push({
                key: 11, type: 'ייצא השוואת כמויות', action: () => {
                    const ids = [];
                    const title_holder = new Set();
                    for (let i = rowMin; i <= rowMax; i += 1) {
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) ids.push(data[i]?.id);
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) title_holder.add((data[i]?.driverName != '') ? data[i]?.driverName : data[i]?.agent);
                    }
                    const curPdf = ids.map((u) => Number(u));
                    let title = Array.from(title_holder).join(',');

                    if (curPdf.length > 0)
                        getFile(false, () => buildQuerySum(curPdf, date, date, title, true, true), 'השוואת כמויות');
                    close();
                }, icon: <Avatar variant='square'
                                 sx={{
                                     marginRight: '10px',
                                     cursor: 'pointer',
                                     bgcolor: 'white',
                                     width: '18px',
                                     height: '18px'
                                 }}
                                 src={pdf}/>
            });
            it.push({
                key: 12, type: 'ייצא סיכום הזמנות', action: () => {
                    const ids = [];
                    const title_holder = new Set();
                    for (let i = rowMin; i <= rowMax; i += 1) {
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) ids.push(data[i]?.id);
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) title_holder.add((data[i]?.driverName != '') ? data[i]?.driverName : data[i]?.agent);
                    }
                    const curPdf = ids.map((u) => Number(u));
                    let title = Array.from(title_holder).join(',');

                    if (curPdf.length > 0)
                        getFile(false, () => buildQuerySum(curPdf, date, date, title, false, true), 'סיכום הזמנות');
                    close();
                }, icon: <Avatar variant='square'
                                 sx={{
                                     marginRight: '10px',
                                     cursor: 'pointer',
                                     bgcolor: 'white',
                                     width: '18px',
                                     height: '18px'
                                 }}
                                 src={pdf}/>
            });
            it.push({
                key: 13, type: 'ייצא סיכום נהגים', action: () => {
                    const ids = [];
                    const title_holder = new Set();
                    for (let i = rowMin; i <= rowMax; i += 1) {
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) ids.push(data[i]?.id);
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) title_holder.add((data[i]?.driverName != '') ? data[i]?.driverName : data[i]?.agent);
                    }
                    const curPdf = ids.map((u) => Number(u));

                    if (curPdf.length > 0)
                        getFile(false, () => dailyBuildQuerySum(curPdf, date, 1, undefined, 1), 'סיכום נהגים');
                    close();
                }, icon: <Avatar variant='square'
                                 sx={{
                                     marginRight: '10px',
                                     cursor: 'pointer',
                                     bgcolor: 'white',
                                     width: '18px',
                                     height: '18px'
                                 }}
                                 src={pdf}/>
            });
            it.push({
                key: 14, type: 'ייצא סיכום נהגים - מפורט', action: () => {
                    const ids = [];
                    const title_holder = new Set();
                    for (let i = rowMin; i <= rowMax; i += 1) {
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) ids.push(data[i]?.id);
                        if (data && ((data[i]?.dvs && data[i]?.dvs.length > 0) || (data[i].orderId != ''))) title_holder.add((data[i]?.driverName != '') ? data[i]?.driverName : data[i]?.agent);
                    }
                    const curPdf = ids.map((u) => Number(u));

                    if (curPdf.length > 0)
                        getFile(false, () => dailyBuildQuerySum(curPdf, date, 1, undefined, 2), 'סיכום נהגים מפורט');
                    close();
                }, icon: <Avatar variant='square'
                                 sx={{
                                     marginRight: '10px',
                                     cursor: 'pointer',
                                     bgcolor: 'white',
                                     width: '18px',
                                     height: '18px'
                                 }}
                                 src={pdf}/>
            });
            // const orders = [];
            // for (let i = rowMin; i <= rowMax; i += 1) {
            //     if (data && !(data[i]?.dvs && data[i]?.dvs.length > 0) && (dvs_flag || !updatedRowIds.has(data[i].id))) orders.push(data[i]);
            // }
            //
            // it.push({
            //     key: 7, type: '', action: () => {
            //
            //
            //     }, icon: <ChangeOrderDateDialog     boxid={'helper_dialog_order'}
            //         func={(date) => {
            //
            //             changeAllOrderNoteDate(orders.map((o)=>Number(o.orderId)), date);
            //         }
            //         } />
            // });
            it.push({
                key: 8, type: '', action: () => {

                }, icon: <IconsRow rowData={curData}/>
            });

            setItems2(it);
        }

    }, [items]);

    const onClickOutside = useCallback(
        (event: MouseEvent) => {
            const clickInside = containerRef.current?.contains(event.target as Node);
            const x = document.getElementById('helper_dialog');
            const y = document.getElementById('helper_dialog_order');
            const z = document.getElementById('helper_dialog_daily');
            if (!clickInside && (!x?.contains(event.target as Node) && !y?.contains(event.target as Node) && !z?.contains(event.target as Node)) && !y && !z) {
                close();
            }
        },
        [close]
    );

    useDocumentEventListener('mousedown', onClickOutside);

    return (
        <div className='dsg-context-menu' style={{left: `${clientX + 30}px`, top: `${clientY - 200}px`, zIndex: 0}}
             ref={containerRef}>
            {items2.map((item) => (
                <Box key={item.key} onClick={
                    (v) => {
                        item.action();
                    }
                } className='dsg-context-menu-item'>
                    <ListItemIcon>
                        {item.icon}
                        {(item.type)}
                    </ListItemIcon>
                </Box>

            ))}
        </div>
    );
};

export default memo(ContextMenu);

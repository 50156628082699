import React, {memo} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getSpecificCustomer, selectCustomerById} from 'store/customers/customersSlice';
import {useCreateCustomerDocument} from 'hooks/customer/documents.hooks';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Box, Grid, TextField, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MainCard from 'ui-component/cards/MainCard';

// locale
import {FormattedMessage} from 'react-intl';

// project imports
import CustomerProductsList from 'views/customer/profile/products/CustomerProductsList';
import DocumentCustomerDetails from '../DocumentCustomerDetails';

// types
import {createDocument} from 'types/DocumentsTypes/utils';
import IItem from 'types/product_delivery';

import Swal from 'sweetalert2';
import {collect, getClientsDaily, updateDriver} from '../../../../../api/customer/documents.api';
import getCustomerProductsPrices from '../../../../../api/customer/products_prices.api';
import {dateObjectToString} from '../../../../../fromKotlin/nkutils';
import JSDB, {NKTypeConvertor} from '../../../../../fromKotlin/nk';
import {DocumentType, ProductNote} from '../../../../../types/DocumentsTypes/Document';
import DocumentPreview from '../DocumentPreview';
import NoNegative from '../../../../../ui-component/textFieldNumber';
import {SNACKBAR_OPEN} from "../../../../../store/actions";
import {selectDrivers} from "../../../../../store/agents/agentsSlice";
import Agent from "../../../../../types/agent";

const NewDeliveryCertificate = ({
                                    upid,
                                    dtype,
                                    narrow,
                                    productsFromAbove,
                                    refId,fromOrder
                                }: { upid: string, dtype: documentType, narrow?: boolean, productsFromAbove?: IItem[], refId?: number[],fromOrder?:any }) => {
    const theme = useTheme();
    const {id} = useParams();
    const dispatch = useDispatch();

    const documentType: DocumentType = dtype ? dtype : location.pathname.endsWith('DeliveryCertificate') ? 'delivery_certificate' : 'order';
    // TODO: Change to single customer fetching
    const customer = useSelector((state: any) => selectCustomerById(state, id ?? upid));
    React.useEffect(() => {
        if (!customer) dispatch(getSpecificCustomer(Number(id)));
    }, [dispatch]);
    const [date, setDate] = React.useState<Date>(fromOrder ?? new Date());
    const [products, setProducts] = React.useState<IItem[]>([]);
    const [bul, setBul] = React.useState<number>();
    const [autoNum, setAutoNum] = React.useState<number | undefined>(undefined);
    const drivers = useSelector(selectDrivers);
    const [driver, setDriver] = React.useState<Agent | undefined>(undefined);
    const [mainValue, setMainValue] = React.useState<number>(0);
    const [tax, setTaxValue] = React.useState<number>(0);
    const [getAllProducts, setGetAllProducts] = React.useState<boolean>(false);
    const [commentControl, setCommentControl] = React.useState<any>({
        notes: '',
        notes2: '',
        notes3: '',
        notes4: '',
        notes5: '',
    });
    React.useEffect(() => {
        if (productsFromAbove) {
            setProducts(productsFromAbove);
        }
    }, [productsFromAbove]);

    React.useEffect(() => {
        if (productsFromAbove) return;
        const genProds = async () => {
            const ps = await getCustomerProductsPrices(id ?? upid, dateObjectToString(date), getAllProducts, undefined, undefined, true);
            products.forEach((p) => {
                const found = ps.find((pr) => pr.id === p.id);
                if (found) {
                    found.quantity = p.quantity;
                    found.quantityReturns = p.quantityReturns;
                    found.quantitySecond = p.quantitySecond;
                    found.notePlacement = p.notePlacement;
                }
            })
            setProducts(ps);
            if (drivers.length > 0) {
                const daily = await getClientsDaily((date), (date), Number(id ?? upid), undefined, undefined, undefined, undefined);
                if (daily?.length ?? 0 > 0) {
                    setDriver(drivers.find((s) => s.id == daily[0].driver_id))
                } else {
                    setDriver(undefined)
                }
            }
        };
        genProds();

    }, [date, getAllProducts]);
    let counter = 0;
    const onProductChange = React.useCallback((product: IItem) => {
        setProducts((prevProducts) => {
                let seen = false;
                const x = prevProducts.map((p) => {

                        if (product.id === p.id) {
                            seen = true;
                            return product;
                        }
                        return p;
                    }
                );
                if (!seen) {

                    x.push(product);
                }

                if (product.notePlacement == undefined) {
                    product.notePlacement = counter;
                    counter += 1;
                }
                return x;

            }
        );
    }, []);

    const clearProducts = React.useCallback(() => {
        setProducts((prevProducts) => prevProducts.map((p) => ({...p, quantity: 0, quantityReturns: 0})));
    }, []);
    React.useEffect(() => {
        const pr = products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && (p.conversion_ratio > 0 || documentType == "order")));

        if (pr.length === 0) {
            setMainValue(0);
            setTaxValue(0);
        }

        const result = JSDB().productDeliveryToAmount(NKTypeConvertor().toKotlinList(collect(pr, Number(id ?? upid), true, false)), Number(id ?? upid), dateObjectToString(date));
        setMainValue(JSDB().round(result.third, 2));
        setTaxValue(JSDB().round(result.second, 2));

    }, [products]);

    const createCustomerDocument = useCreateCustomerDocument(id ?? upid);
    const onProductChangeReal = (createCustomerDocument.data) ? undefined : onProductChange;
    React.useEffect(() => {
        if(fromOrder && createCustomerDocument.data){
            // fromOrder(createCustomerDocument.data)
        }
    }, [createCustomerDocument.data]);

    if (!customer || (!id && !upid)) return <Box/>;

    return (
        <MainCard
            title={
                <Typography variant='h3'>
                    {documentType == 'delivery_certificate' ?
                        <FormattedMessage id='Create new delivery certificate'/> : 'הזמנה חדשה'}
                </Typography>
            }
        >
            <Grid container spacing={3}>
                {createCustomerDocument.data === undefined && !narrow ?
                    <Grid item xs={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <DocumentCustomerDetails customer={customer} date={date} setDate={setDate}
                                                         driver={driver} drivers={drivers} setDriver={setDriver}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant='h4'>
                                    פרטים נוספים
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    fullWidth
                                    id='comment'
                                    name='comment'
                                    label='הערות'
                                    value={commentControl.notes}
                                    onChange={(e) => {
                                        setCommentControl({...commentControl, notes: e.target.value});
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <TextField
                                    fullWidth
                                    id='bul'
                                    name='bul'
                                    label='מספר בול'
                                    type='number'
                                    onChange={(v) => {
                                        setBul(Math.floor(Number(v.target.value)));
                                    }}
                                    value={bul}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 38 || e.keyCode === 40) {
                                            e.preventDefault();
                                        }
                                    }
                                    }
                                    onWheel={(e) => e.target.blur()}
                                />
                            </Grid>

                            <Grid item xs={3} sm={3}>
                                <NoNegative
                                    fullWidth
                                    id='autoNum'
                                    name='autoNum'
                                    label='מספור ידני'
                                    changer={(v) => {
                                        if (v != 0)
                                            setAutoNum((v));
                                        else
                                            setAutoNum(undefined);
                                    }
                                    }
                                    value={autoNum}


                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} alignItems='center'>
                                <CustomerProductsList products={products} onProductChange={onProductChange}
                                                      clearFunc={clearProducts}
                                                      withReturns={documentType == 'delivery_certificate'}
                                                      getAllProducts={getAllProducts}
                                                      setGetAllProducts={setGetAllProducts}

                                />
                            </Grid>
                        </Grid>
                    </Grid> : undefined
                }
                <Grid item xs={narrow ? 12 : 5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <DocumentPreview
                                outerDoc={createCustomerDocument.data ? undefined : setCommentControl}
                                document={
                                    {
                                        entId: customer.id,
                                        date: date ?? new Date(),
                                        type: documentType,
                                        entName: customer.name,
                                        products:
                                            ((createCustomerDocument.data) ?
                                                ((documentType == 'delivery_certificate' || documentType == "tax_invoice" || documentType == "invoice" || documentType == "refund") ?
                                                    (createCustomerDocument.data as ProductNote[])[0].products : (createCustomerDocument.data as ProductNote).products) : products)
                                                .filter((p) => p.quantity > 0 || p.quantityReturns > 0 || p.forceShow || (p.quantitySecond > 0 && (p.conversion_ratio > 0 || documentType == "order"))),
                                        status: ((createCustomerDocument.data) ?
                                            ((documentType == 'delivery_certificate' || documentType == "tax_invoice" || documentType == "invoice" || documentType == "refund") ?
                                                (createCustomerDocument.data as ProductNote[])[0].status : (createCustomerDocument.data as ProductNote).status) : 'pending_approval'),
                                        id: ((createCustomerDocument.data) ?
                                            ((documentType == 'delivery_certificate' || documentType == "tax_invoice" || documentType == "invoice" || documentType == "refund") ?
                                                (createCustomerDocument.data as ProductNote[])[0].id : (createCustomerDocument.data as ProductNote).id) : autoNum ?? 0),
                                        end: new Date(),
                                        color: '',
                                        textColor: '',
                                        baseColor: '',
                                        description: '',
                                        title: ((createCustomerDocument.data) ?
                                            ((documentType == 'delivery_certificate' || documentType == "tax_invoice" || documentType == "invoice" || documentType == "refund") ?
                                                (createCustomerDocument.data as ProductNote[])[0].title : (createCustomerDocument.data as ProductNote).title) : 'תעודה חדשה'),
                                        allDay: false,
                                        nkObject: ((createCustomerDocument.data) ?
                                            ((documentType == 'delivery_certificate' || documentType == "tax_invoice" || documentType == "invoice" || documentType == "refund") ?
                                                (createCustomerDocument.data as ProductNote[])[0].nkObject : (createCustomerDocument.data as ProductNote).nkObject) : undefined),
                                        onProductChange: onProductChangeReal,
                                        onDateChange: (createCustomerDocument.data) ? undefined : setDate,
                                        onIdChange: (createCustomerDocument.data) ? undefined : setAutoNum,
                                        agent: JSDB().currentAgent,
                                        issueDate: new Date(),
                                        name: '',
                                        mainValue: mainValue,
                                        taxValue: tax,
                                        comment: commentControl.notes,
                                        notes2: commentControl.notes2,
                                        notes3: commentControl.notes3,

                                        bul: bul
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Box
                                sx={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                <LoadingButton
                                    onClick={() => {
                                        const newDoc = createDocument({
                                            mainValue: 0,
                                            date: date ?? new Date(),
                                            type: documentType,
                                            products: products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && (p.conversion_ratio > 0 || documentType == "order"))),
                                            entId: id,
                                            id: autoNum,
                                            status: 'approved',
                                            comment: commentControl.notes,
                                            notes2: commentControl.notes2,
                                            notes3: commentControl.notes3,
                                            notes4: commentControl.notes4,
                                            notes5: commentControl.notes5,
                                            bul
                                        })
                                        if (refId && refId.length > 0 && productsFromAbove)
                                            newDoc.refId = refId[0]
                                        if(fromOrder)
                                            newDoc.fromOrder = true
                                        narrow ? createCustomerDocument.mutate(
                                                newDoc
                                            ) :
                                            Swal.fire({
                                                title: 'האם תרצה להנפיק מסמך?',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: theme.palette.primary.dark,
                                                cancelButtonColor: theme.palette.error.light,
                                                confirmButtonText: 'הנפק',
                                                cancelButtonText: 'בטל',
                                                allowEnterKey: true,
                                                allowEscapeKey: true,
                                                keydownListenerCapture: true,
                                                focusConfirm: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {

                                                    createCustomerDocument.mutate(
                                                        newDoc
                                                    );
                                                }
                                            })
                                    }

                                    }
                                    loading={createCustomerDocument.isLoading}
                                    disabled={createCustomerDocument.isSuccess || products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && (p.conversion_ratio > 0 || documentType == "order"))).length === 0}
                                    variant='contained'
                                    type='submit'
                                    fullWidth
                                    sx={{m: 2}}
                                >
                                    הנפק
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};
export default memo(NewDeliveryCertificate);

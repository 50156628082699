/* eslint-disable no-bitwise */
import Customer, {deserializeCustomer} from 'types/customer';
import {createSlice, createSelector, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {AsyncStatus} from 'store/types/asyncState';
import {ArrangementOrder} from 'types';
import stableSort, {getComparator, applyFilters, distinct, distinctByField} from 'utils/sort';
import {Filter} from 'types';
import {
    getCustomersAsync,
    addCustomerAsync,
    updateCustomerAsync,
    deleteCustomerAsync,
    getSpecificCustomersAsync, fillCustomersAsync, getCustomersCreationAsync, changeAllCustomers
} from 'api/customer';
import JSDB from '../../fromKotlin/nk';

const customersAdapter = createEntityAdapter<Customer>({
    sortComparer: (c1, c2) => c1.name.localeCompare(c2.name)
});

// THUNKS
export const getCustomers = createAsyncThunk('customers/getCustomers', getCustomersAsync);
export const getCustomersCreation = createAsyncThunk('customers/creation', getCustomersCreationAsync);
export const fillCustomers = createAsyncThunk('customers/fillCustomers', fillCustomersAsync);
export const getSpecificCustomer = createAsyncThunk('customers/getSpecificCustomers', getSpecificCustomersAsync);
export const addCustomer = createAsyncThunk('customers/addCustomer', addCustomerAsync);
export const updateCustomer = createAsyncThunk('customers/updateCustomer', updateCustomerAsync);
export const deleteCustomer = createAsyncThunk('customers/activeCustomer', deleteCustomerAsync);
export const updateAllCustomer = createAsyncThunk('customers/updateAllCustomer', changeAllCustomers);
export const removeAllFilters = createAsyncThunk('customers/removeFilters', async () => {
    return true;
});

const initialState: {
    getCustomersAsyncState: AsyncStatus;
    getSpecificAsyncState: AsyncStatus;
    addCustomerAsyncState: AsyncStatus;
    updateCustomerAsyncState: AsyncStatus;
    deleteCustomerAsyncState: AsyncStatus;
    updateAllCustomerAsyncState: AsyncStatus;
    sortBy: { orderBy: string; order: ArrangementOrder };
    filterBy: Filter[];
} = {
    getCustomersAsyncState: 'idle',
    getSpecificAsyncState: 'idle',
    addCustomerAsyncState: 'idle',
    updateCustomerAsyncState: 'idle',
    deleteCustomerAsyncState: 'idle',
    updateAllCustomerAsyncState: 'idle',
    sortBy: {orderBy: 'position', order: 'asc'},
    filterBy: []
};

export const customersSlice = createSlice({
    name: 'customers',
    initialState: customersAdapter.getInitialState({
        ...initialState
    }),
    reducers: {
        initGetCustomersAsyncState: (state) => {
            state.getCustomersAsyncState = 'idle';
        },
        initAddCustomerAsyncState: (state) => {
            state.addCustomerAsyncState = 'idle';
        },
        initUpdateCustomerAsyncState: (state) => {
            state.updateCustomerAsyncState = 'idle';
        },
        initDeleteCustomerAsyncState: (state) => {
            state.deleteCustomerAsyncState = 'idle';
        },
        updateAllCustomerAsyncState: (state) => {
            state.updateAllCustomerAsyncState = 'idle';
        },

        applySort: (state, action) => {
            state.sortBy = action.payload;
        },
        applyFilter: (state, action) => {
            state.filterBy = state.filterBy.reduce((acc, f: Filter) => (f.property === action.payload.property ? acc : acc.concat(f)), [
                action.payload
            ]);
        },
        removeFilter: (state, action) => {
            state.filterBy = state.filterBy.filter((f) => f.property !== action.payload.property);
        },
        removeAllFilter: (state, action) => {
            state.filterBy = [];
        }
    },
    extraReducers: (builder) => {
        builder
            /* GET_CUSTOMERS STATE */
            .addCase(getCustomers.pending, (state) => {
                if (state.getCustomersAsyncState === 'idle') {
                    state.getCustomersAsyncState = 'loading';
                }
            })
            .addCase(getCustomers.fulfilled, (state, action) => {
                state.getCustomersAsyncState = 'succeeded';
                if (action.payload.first.size > 0)
                    customersAdapter.setAll(
                        state,
                        action.payload.first
                            .toArray()
                            .map((c) => deserializeCustomer(c))
                    ); // for now active later create selector

            })
            .addCase(getCustomersCreation.fulfilled, (state, action) => {
                if( JSDB().getAllClient().first.size>0)
                customersAdapter.setAll(
                    state,
                    JSDB().getAllClient().first
                        .toArray()
                        .map((c) => deserializeCustomer(c))
                ); // for now active later create selector
            })
            .addCase(getCustomers.rejected, (state, action) => {
                if (state.getCustomersAsyncState === 'loading') {
                    state.getCustomersAsyncState = 'failed';
                }
            })


            .addCase(fillCustomers.pending, (state) => {

                    state.getCustomersAsyncState = 'loading';

            })
            .addCase(fillCustomers.fulfilled, (state, action) => {

                    state.getCustomersAsyncState = 'succeeded';
                    customersAdapter.setAll(
                        state,
                        action.payload.first
                            .toArray()
                            .map((c) => deserializeCustomer(c))
                    ); // for now active later create selector

            })
            .addCase(fillCustomers.rejected, (state, action) => {

                    state.getCustomersAsyncState = 'failed';

            })

            /* ADD_CUSTOMER STATE */
            .addCase(addCustomer.pending, (state) => {
                if (state.addCustomerAsyncState === 'idle') {
                    state.addCustomerAsyncState = 'loading';
                }
            })
            .addCase(addCustomer.fulfilled, (state, action) => {
                if (state.addCustomerAsyncState === 'loading') {
                    state.addCustomerAsyncState = 'succeeded';
                    customersAdapter.addOne(state, deserializeCustomer(action.payload.first));
                }
            })
            .addCase(addCustomer.rejected, (state, action) => {
                if (state.addCustomerAsyncState === 'loading') {
                    state.addCustomerAsyncState = 'failed';
                }
            })
            /* UPDATE_CUSTOMER STATE */
            .addCase(updateCustomer.pending, (state) => {

                    state.updateCustomerAsyncState = 'loading';

            })
            .addCase(updateCustomer.fulfilled, (state, action) => {

                    state.updateCustomerAsyncState = 'succeeded';
                    const client = action.payload.first;
                    state.entities[client.id] = deserializeCustomer(client);
                    // This is not working for some reason
                    // customersAdapter.updateOne(state, { id: customer.id, changes: customer });

            })
            .addCase(updateCustomer.rejected, (state, action) => {

                    state.updateCustomerAsyncState = 'failed';

            })

            /* UPDATE_CUSTOMER STATE */
            .addCase(updateAllCustomer.pending, (state) => {

                state.updateAllCustomerAsyncState = 'loading';

            })
            .addCase(updateAllCustomer.fulfilled, (state, action) => {

                state.updateAllCustomerAsyncState = 'succeeded';
                customersAdapter.upsertMany(
                    state,
                    action.payload.first
                        .toArray()
                        .map((c) => deserializeCustomer(c))
                );

            })
            .addCase(updateAllCustomer.rejected, (state, action) => {
                state.updateAllCustomerAsyncState = 'failed';
            })
            /* DELETE_CUSTOMER STATE */
            .addCase(deleteCustomer.pending, (state) => {
                state.deleteCustomerAsyncState = 'loading';
            })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                state.deleteCustomerAsyncState = 'succeeded';
                const client = action.payload.first;
                state.entities[client.id] = deserializeCustomer(client);

            })
            .addCase(deleteCustomer.rejected, (state, action) => {
                state.deleteCustomerAsyncState = 'failed';

            })
            .addCase(getSpecificCustomer.fulfilled, (state, action) => {
                const client = JSDB().getClient(action.payload).first;
                state.entities[client.id] = deserializeCustomer(client);
            })
            .addCase(removeAllFilters.fulfilled, (state, action) => {
                state.filterBy = [];
            });
    }
});

// actions
export const {
    initGetCustomersAsyncState,
    initAddCustomerAsyncState,
    initUpdateCustomerAsyncState,
    initDeleteCustomerAsyncState,
    applySort,
    applyFilter,
    removeFilter
} = customersSlice.actions;

// Selectors
export const selectGetCustomersAsyncState = (state: RootState) => state.customers.getCustomersAsyncState;
export const selectAddCustomerAsyncState = (state: RootState) => state.customers.addCustomerAsyncState;
export const selectUpdateCustomerAsyncState = (state: RootState) => state.customers.updateCustomerAsyncState;
export const selectDeleteCustomerAsyncState = (state: RootState) => state.customers.deleteCustomerAsyncState;
export const selectCustomersSortBy = (state: RootState) => state.customers.sortBy;
export const selectCustomersFilterBy = (state: RootState) => state.customers.filterBy;

export const {selectAll: selectCustomers, selectById: selectCustomerById} = customersAdapter.getSelectors(
    (state: RootState) => state.customers
);
export const selectActiveCustomers = createSelector(selectCustomers, (customers) => customers.filter((c) => c.active));
export const selectActiveNetworks = createSelector(selectCustomers, (customers) => distinctByField(customers.filter((c) => c.nkObject.branch>=0), (c)=>c.nkObject.branch));
export const selectNotActiveCustomers = createSelector(selectCustomers, (customers) => customers.filter((c) => !c.active));

const applyCustomersSort = (customers, sortBy) => stableSort(customers, getComparator(sortBy.order, sortBy.orderBy));

// Memoized Selectors
export const selectCustomersIds = createSelector(selectActiveCustomers, (customers) => customers.map((c) => c.id));
export const selectCustomersNames = createSelector(selectActiveCustomers, (customers) => customers.map((c) => c.name));
export const selectCustomersCity = createSelector(selectActiveCustomers, (customers) => Array.from(new Set(customers.map((p) => p.city))));
export const selectCustomersBNames = createSelector(selectActiveCustomers, (customers) => Array.from(new Set(customers.map((p) => p.businessName))));

export const selectCustomersCategories = createSelector(selectActiveCustomers, (customers) => Array.from(new Set(customers.map((p) => p.category))));
export const selectCustomersNotes2 = createSelector(selectActiveCustomers, (customers) => Array.from(new Set(customers.map((p) => p.notes2))));
export const selectCustomersNotes3 = createSelector(selectActiveCustomers, (customers) => Array.from(new Set(customers.map((p) => p.notes3))));
export const selectCustomersNotes4 = createSelector(selectActiveCustomers, (customers) => Array.from(new Set(customers.map((p) => p.notes4))));
export const selectCustomersGroups = createSelector(selectActiveCustomers, (customers) => Array.from(new Set(customers.map((p) => p.group ?? ''))));


export const selectCustomersidname = createSelector(selectActiveCustomers, (customers) => customers.filter((c)=>c.active).map((c) => {
    return {name: c.name, id: c.id, externalId: c.externalId,phone:c.phone,email:c.email};
}));
export const selectCustomersIdsWithSort = createSelector(selectActiveCustomers, selectCustomersSortBy, (customers, sortBy) =>
    applyCustomersSort(customers, sortBy).map((c) => c.id)
);
export const selectCustomersIdsWithFilter = createSelector(selectActiveCustomers, selectCustomersFilterBy, (customers, filterBy) =>
    applyFilters(customers, filterBy).map((c) => c.id)
);
export const selectCustomersIdsWithFilterSort = createSelector(
    selectActiveCustomers,
    selectCustomersFilterBy,
    selectCustomersSortBy,
    (customers, filterBy, sortBy) => applyCustomersSort(applyFilters(customers, filterBy), sortBy).map((c) => c.id)
);
export const selectCustomersObjs = createSelector(
    selectCustomers,
    selectCustomersFilterBy,
    selectCustomersSortBy,
    (_, booleanParam) => booleanParam,
    (customers, filterBy, sortBy, booleanParam) => applyCustomersSort(applyFilters(customers.filter((s) => (Number(s.id)>-10) && (booleanParam && s.active) || (!booleanParam && !s.active)), filterBy), sortBy)
);

export const selectPriceMenus = createSelector(
    selectCustomers,
    selectCustomersFilterBy,
    selectCustomersSortBy,
    (customers, filterBy, sortBy) => applyCustomersSort(applyFilters(customers.filter((s) => Number(s.id) && Number(s.id)<=-10), filterBy), sortBy)
);


export const selectCustomersWithFilter = createSelector(selectActiveCustomers, selectCustomersFilterBy, (customers, filterBy) =>
    applyFilters(customers, filterBy)
);

// Derived selectors
export const selectDistinctValues = createSelector(selectActiveCustomers, distinct);
export const selectDistinctValuesNetwork = createSelector(selectActiveNetworks, distinct);

export default customersSlice.reducer;

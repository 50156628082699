import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material"
import {optimizeRouteClients} from "api/customer/documents.api"
import JSDB from "fromKotlin/nk"
import {Map} from "tabler-icons-react"
import React from "react";

export const ReorderDialogOrders = ({ reorderDialog, setReorderDialog,setData,setRefreshRowIds,updatedDriverRowIds ,updatedRowIds}) => {
    const [start, setStart] = React.useState<string>(reorderDialog?.start ||'')
    const [end, setEnd] = React.useState<string>( reorderDialog?.end ||'')
    const [startPosition, setStartPosition] = React.useState<number>( 1)
    const [jumps, setJumps] = React.useState<number>( 1)
    React.useEffect(() => {
        console.log(reorderDialog)
        setStart(reorderDialog?.start ||'')
        setEnd(reorderDialog?.end ||'')
    }, [reorderDialog])
  return (
    <>
      <Dialog
        open={reorderDialog}
        onClose={() => setReorderDialog(undefined)}
        aria-labelledby="reorder-dialog-title"
        aria-describedby="reorder-dialog-description"
        sx={{
          ".MuiDialog-paper": {
            overflow: "visible",
          }
        }
        }
      >
          <DialogTitle id="reorder-dialog-title">{`שינוי סדר לקוחות(${reorderDialog?.data?.length}) עבור ${reorderDialog?.ag?.user_name}`}</DialogTitle>
          <DialogContent>
              <DialogContentText id="reorder-dialog-description">
                  בחר לקוח מתוך הרשימה או הכנס <b>נקודות ציון</b>
              </DialogContentText>
              <div style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                  gap: "10px",
              }}>
                  <Autocomplete
                      style={{ width: 200 }}
                      disablePortal
                      id="customers-search-plan-route-start"
                      options={[]}
                      freeSolo
                      // getOptionLabel={(option: any) => option?.customer}
                      onChange={(e, value) => {
                          console.log(value)

                      }}
                      onInputChange={(e, value) => {
                          if (value) {
                              setStart(value)
                          }
                      }}
                      // isOptionEqualToValue={(option: any, value: any) => {
                      //     return option?.id === value?.id;
                      // }}
                      value={start}
                   renderInput={(params) => <TextField {...params} label="נקודת התחלה" />}

                  />
                  <Autocomplete
                      style={{ width: 200 }}
                      disablePortal
                      id="customers-search-plan-route-end"
                      options={[]}
                      freeSolo
                      value={end}
                      // getOptionLabel={(option: any) => option?.customer}
                      // renderOption={(props, option) => {
                      //     return (
                      //         <li {...props}>
                      //             <div style={{
                      //                 display: "flex",
                      //                 flexDirection: "column",
                      //             }}>
                      //                 <span>{option?.customer}</span>
                      //                 <span>{option?.city}</span>
                      //             </div>
                      //         </li>
                      //     )
                      // }}
                      onInputChange={(e, value) => {
                          if (value) {
                              setEnd(value)
                          }
                      }}
                      onChange={(e, value) => {
                          if (value) {
                                setEnd(value)
                          }
                      }}
                      renderInput={(params) => <TextField {...params} label="נקודת סיום" />}

                  />
              </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => setReorderDialog(undefined)}>ביטול</Button>
              <Button onClick={async () => {
                  const rows = (reorderDialog as any)?.data;
                  const customersIds = rows.map((row) => Number(row?.id));
                  const r = await optimizeRouteClients(customersIds, undefined, true, start, end);
                    const s= {}
                    r.forEach((id,index) =>  s[id] = startPosition+index*jumps)
                  const x= new Set()
                  setData((prev) =>
                      prev.map((d, i) => {
                              if (d.id! in s) {
                                  updatedDriverRowIds.add(d.id);

                                  x.add(d.id)
                                  return {
                                      ...d,
                                      driver_position: s[d.id],
                                  };
                              }
                              return d;
                          }
                      )
                  );
                  setRefreshRowIds(x)




                  setReorderDialog(undefined)
              }} autoFocus>
                  הפעל אופטימיזציה
              </Button>
          </DialogActions>
      </Dialog >
    </>
  )
}


const ReorderDialog = ({ reorderDialog, setReorderDialog, reorderPosition, setReorderPosition }) => {
  return (
      <>
        <Dialog
            open={(reorderDialog as {
              driver: string,
              data: any[],
              api: any,
            })?.driver ? true : false}
            onClose={() => setReorderDialog(false)}
            aria-labelledby="reorder-dialog-title"
            aria-describedby="reorder-dialog-description"
            sx={{
              ".MuiDialog-paper": {
                overflow: "visible",
              }
            }
            }
        >
          <DialogTitle id="reorder-dialog-title">{"שינוי סדר לקוחות"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="reorder-dialog-description">
              בחר לקוח מתוך הרשימה או הכנס <b>נקודות ציון</b>
            </DialogContentText>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              marginTop: "10px",
              gap: "10px",
            }}>
              <Autocomplete
                  style={{ width: 200 }}
                  disablePortal
                  id="customers-search-plan-route-start"
                  options={(reorderDialog as {
                    driver: string,
                    data: any[],
                    api: any,
                  })?.data}
                  freeSolo
                  getOptionLabel={(option: any) => option?.customer}
                  onChange={(e, value) => {
                    if (value?.id) {
                      setReorderPosition({
                        ...reorderPosition,
                        start: value.id,
                      });
                    }
                  }}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option?.id === value?.id;
                  }}
                  defaultValue={() => {
                    return {
                      customer: reorderPosition?.start || "",
                    }
                  }}
                  renderInput={(params) => <TextField {...params}
                                                      label={
                                                        <span style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                          justifyContent: "space-between",
                                                          width: 170,
                                                        }}>
                    נקודת התחלה {(params?.inputProps?.['aria-controls'] || params?.inputProps?.value?.length > 0) ? undefined : <Map />}
                  </span>
                                                      } onChange={(e) => {
                    setReorderPosition({
                      ...reorderPosition,
                      start: e?.target?.value.replace(/\s/g, ''),
                    });
                  }} />}
              />
              <Autocomplete
                  style={{ width: 200 }}
                  disablePortal
                  id="customers-search-plan-route-end"
                  options={(reorderDialog as {
                    driver: string,
                    data: any[],
                    api: any,
                  })?.data}
                  freeSolo
                  getOptionLabel={(option: any) => option?.customer}
                  renderOption={(props, option) => {
                    return (
                        <li {...props}>
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                            <span>{option?.customer}</span>
                            <span>{option?.city}</span>
                          </div>
                        </li>
                    )
                  }}
                  defaultValue={() => {
                    return {
                      customer: reorderPosition?.end || "",
                    }
                  }}
                  onChange={(e, value) => {
                    if (value?.id) {
                      setReorderPosition({
                        ...reorderPosition,
                        end: value.id,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} onChange={(e) => {
                    setReorderPosition({
                      ...reorderPosition,
                      end: e.target.value.replace(/\s/g, ''),
                    });
                  }}
                                                      value={reorderPosition?.end || ""}
                                                      label={
                                                        <span style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                          justifyContent: "space-between",
                                                          width: 170,
                                                        }}>
                    נקודת סיום {(params?.inputProps?.['aria-controls'] || params?.inputProps?.value?.length > 0) ? undefined : <Map />}
                  </span>
                                                      } />}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setReorderDialog(false)}>ביטול</Button>
            <Button onClick={async () => {
              const tableApi = (reorderDialog as {
                driver: string,
                data: any[],
                api: any,
              })?.api;
              const rows = (reorderDialog as any)?.data;
              const customersIds = rows.map((row) => Number(row?.id)).filter((id) => id);
              const startLocation = reorderPosition.start.includes(",") ? reorderPosition.start : JSDB().getClient(Number(reorderPosition.start))?.first?.location;
              const endLocation = reorderPosition.end.includes(",") ? reorderPosition.end : JSDB().getClient(Number(reorderPosition.end))?.first?.location;
              startLocation && endLocation && localStorage.setItem(`driver_${(reorderDialog as any)?.driver}_position`, JSON.stringify({
                start_position: startLocation,
                end_position: endLocation,
              }));
              const r = await optimizeRouteClients(customersIds, undefined, true, startLocation, endLocation);
              const newRows = r.map((id) => {
                const row = rows.find((row) => Number(row?.id) === id);
                return row;
              }).filter((row) => row);
              const driver = tableApi.rowModel.rowsToDisplay.find((row) => row?.data?.id === reorderDialog?.driver);
              const data = driver?.data?.isDriver ? driver?.childrenAfterGroup : driver?.parent?.data?.isDriver ? driver?.parent?.childrenAfterGroup : undefined;
              tableApi.applyTransaction({
                remove: data,
              });
              tableApi.applyTransaction({
                add: newRows
              });
              setReorderDialog(false)
            }} autoFocus>
              שמור
            </Button>
          </DialogActions>
        </Dialog >
      </>
  )
}

export default ReorderDialog;

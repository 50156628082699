/* eslint-disable no-nested-ternary */
import React from 'react';
import {useSelector} from 'react-redux';

// material-ui
import {Box, Grid, Typography} from '@mui/material';


import {selectUser} from '../../../../../store/user/userSlice';


const DocumentHeaderPreview = () => {
    // TODO: Change to single customer fetching
    const user = useSelector(selectUser);
    if (!user) return <Box />;
    return (
        <Grid item xs={12}>
            {/* TODO: Separate to component, use the current logged in user data */}
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' fontWeight='bold'>
                        {user.companyName}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body2'>{user.companyId}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body2'>{user.address}</Typography>
                </Grid>
                {(user.email && user.email !== '') ?
                    <Grid item xs={12}>
                        <Typography variant='body2' color='primary'>
                            {user.email}
                        </Typography>
                    </Grid> : undefined}

                <Grid item xs={12}>
                    <Typography variant='body2'>{user.phone}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DocumentHeaderPreview;

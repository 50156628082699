import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, InputLabel, MenuItem, DialogActions, Button, Select } from "@mui/material"
import { useEffect, useState } from "react";
import {deleteCustomer, updateCustomer} from "../../store/customers/customersSlice";
import {useDispatch} from "react-redux";

const CreateBranchDialog = ({ chooseBranch, setChooseBranch, branches, params}) => {
  const dispatch = useDispatch();
  const [selectedBranch, setSelectedBranch] = useState<any>("");
  const [isThereBranch, setIsThereBranch] = useState<boolean>(false);

  useEffect(() => {
    const focusedCell = params?.api?.getFocusedCell()
    const node = params?.api?.getDisplayedRowAtIndex(focusedCell?.rowIndex)
    if (node?.data?.nkObject?.branch >= 0) {
      setIsThereBranch(true)
    }
    // if (node?.data?.businessName && node?.data?.nkObject?.branch >= 0) {
    //   setSelectedBranch({
    //     name: branches?.find((branch) => branch.branch === node?.data?.nkObject?.branch)?.name,
    //     branch: node?.data?.nkObject?.branch,
    //     master: node?.data?.nkObject?.master,
    //   })
    // }
    if (!chooseBranch) {
      setSelectedBranch("")
      setIsThereBranch(false)
    }
  }, [chooseBranch])

  return (
    <Dialog
      open={chooseBranch}
      onClose={() => {
        setChooseBranch(false)
        setSelectedBranch("")
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {
          isThereBranch ?
            "ללקוח זה כבר קיימת רשת" :
            "בחר/ צור רשת"
        }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <>
            <InputLabel style={{
              color: isThereBranch ? "red" : "black",
            }} id="demo-simple-select-label">
              {
                isThereBranch ?
                  "לא ניתן לשנות רשת" :
                  "בחר רשת"
              }
            </InputLabel>
            <Select
              disabled={isThereBranch}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedBranch.name}
              label={"בחר רשת"}
              onChange={(e) => {
                setSelectedBranch({
                  name: e.target.value,
                  branch: branches?.find((branch) => branch.name === e.target.value)?.branch,
                  master: branches?.find((branch) => branch.name === e.target.value)?.master,
                })
              }}
              style={{ width: "100%" }}
            >
              {branches?.map((branch) => {
                return <MenuItem value={branch.name}>{branch.name}</MenuItem>
              })
              }
            </Select>
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={() => setChooseBranch(false)}>בטל</Button>
          <Button disabled={isThereBranch} onClick={() => {
            const focusedRow = params?.api?.getFocusedCell()
            const rowNode = params?.api?.getDisplayedRowAtIndex(focusedRow?.rowIndex);
            const rowNodeCopy = params?.api?.getDisplayedRowAtIndex(focusedRow?.rowIndex);

          console.log(params)
            dispatch(updateCustomer({
              ...rowNode.data,branch:selectedBranch.branch,master:selectedBranch.master
            }
          ))
            // CALLBACK HERE

            // rowNode.data.nkObject.branch = selectedBranch.branch;
            // rowNode.data.nkObject.master = selectedBranch.master;
            // params.api.refreshCells({ force: true });
            setChooseBranch(false)
          }}>שמור</Button>
          <Button disabled={isThereBranch} onClick={() => {
            const focusedRow = params?.api?.getFocusedCell()
            const rowNode = params?.api?.getDisplayedRowAtIndex(focusedRow?.rowIndex)
            const rowNodeCopy = params?.api?.getDisplayedRowAtIndex(focusedRow?.rowIndex)

            dispatch(updateCustomer({
                  ...rowNode.data,branch:branches.reduce((max, branch) => branch.branch > max ? branch.branch : max, 0) + 1,master:-1
                }
            ))
            // CALLBACK HERE

            // rowNode.data.nkObject.branch = branches.reduce((max, branch) => branch.branch > max ? branch.branch : max, 0) + 1;
            // rowNode.data.nkObject.master = -1;
            // params.api.refreshCells({ force: true });
            setChooseBranch(false)
          }}>צור רשת חדשה</Button>
        </>
      </DialogActions>
    </Dialog>
  )
}

export default CreateBranchDialog

/* eslint-disable no-bitwise */
import {createSlice, createSelector, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {AsyncStatus} from 'store/types/asyncState';
import {ArrangementOrder} from 'types';
import stableSort, {getComparator, applyFilters, distinct} from 'utils/sort';
import {Filter} from 'types';

import JSDB from '../../fromKotlin/nk';
import Supplier, {deserializeSupplier} from '../../types/supplier';
import {
    addSupplierAsync,
    deleteSupplierAsync, fillSuppliersAsync,
    getSpecificSuppliersAsync,
    getSuppliersAsync, updateSupplierAsync
} from '../../api/supplier';
import {fillCustomersAsync} from "../../api/customer";
import {selectActiveCustomers} from "../customers/customersSlice";

const suppliersAdapter = createEntityAdapter<Supplier>({
    sortComparer: (c1, c2) => c1.name.localeCompare(c2.name)
});

// THUNKS
export const getSuppliers = createAsyncThunk('suppliers/getSuppliers', getSuppliersAsync);
export const getSpecificSupplier = createAsyncThunk('suppliers/getSpecificSuppliers', getSpecificSuppliersAsync);
export const fillSuppliers = createAsyncThunk('customers/fillSuppliers', fillSuppliersAsync);
export const addSupplier = createAsyncThunk('suppliers/addSupplier', addSupplierAsync);
export const updateSupplier = createAsyncThunk('suppliers/updateSupplier', updateSupplierAsync);
export const deleteSupplier = createAsyncThunk('suppliers/activeSupplier', deleteSupplierAsync);
export const removeAllFilters = createAsyncThunk('suppliers/removeFilters', async () => {
    return true;
});

const initialState: {
    getSuppliersAsyncState: AsyncStatus;
    getSpecificAsyncState: AsyncStatus;
    addSupplierAsyncState: AsyncStatus;
    updateSupplierAsyncState: AsyncStatus;
    deleteSupplierAsyncState: AsyncStatus;
    fillSuppliersAsync: AsyncStatus;
    sortBy: { orderBy: string; order: ArrangementOrder };
    filterBy: Filter[];
} = {
    getSuppliersAsyncState: 'idle',
    getSpecificAsyncState: 'idle',
    addSupplierAsyncState: 'idle',
    updateSupplierAsyncState: 'idle',
    deleteSupplierAsyncState: 'idle',
    fillSuppliersAsync: 'idle',
    sortBy: {orderBy: 'position', order: 'asc'},
    filterBy: []
};

export const suppliersSlice = createSlice({
    name: 'suppliers',
    initialState: suppliersAdapter.getInitialState({
        ...initialState
    }),
    reducers: {
        initGetSuppliersAsyncState: (state) => {
            state.getSuppliersAsyncState = 'idle';
        },
        initAddSupplierAsyncState: (state) => {
            state.addSupplierAsyncState = 'idle';
        },
        initUpdateSupplierAsyncState: (state) => {
            state.updateSupplierAsyncState = 'idle';
        },
        initDeleteSupplierAsyncState: (state) => {
            state.deleteSupplierAsyncState = 'idle';
        },
        initFillSuppliersAsync: (state) => {
            state.fillSuppliersAsync = 'idle';
        },

        applySort: (state, action) => {
            state.sortBy = action.payload;
        },
        applyFilter: (state, action) => {
            state.filterBy = state.filterBy.reduce((acc, f: Filter) => (f.property === action.payload.property ? acc : acc.concat(f)), [
                action.payload
            ]);
        },
        removeFilter: (state, action) => {
            state.filterBy = state.filterBy.filter((f) => f.property !== action.payload.property);
        },
        removeAllFilter: (state, action) => {
            state.filterBy = [];
        }
    },
    extraReducers: (builder) => {
        builder
            /* GET_SUPPLIERS STATE */
            .addCase(getSuppliers.pending, (state) => {
                if (state.getSuppliersAsyncState === 'idle') {
                    state.getSuppliersAsyncState = 'loading';
                }
            })
            .addCase(getSuppliers.fulfilled, (state, action) => {
                if (state.getSuppliersAsyncState === 'loading' || state.getSuppliersAsyncState === 'succeeded') {
                    state.getSuppliersAsyncState = 'succeeded';
                    if (action.payload.first.size > 0)
                        suppliersAdapter.setAll(
                            state,
                            action.payload.first
                                .toArray()
                                .map((c) => deserializeSupplier(c))
                        ); // for now active later create selector
                }
            })
            .addCase(getSuppliers.rejected, (state, action) => {
                if (state.getSuppliersAsyncState === 'loading') {
                    state.getSuppliersAsyncState = 'failed';
                }
            })
            /* ADD_SUPPLIER STATE */
            .addCase(addSupplier.pending, (state) => {

                state.addSupplierAsyncState = 'loading';

            })
            .addCase(addSupplier.fulfilled, (state, action) => {
                state.addSupplierAsyncState = 'succeeded';
                suppliersAdapter.addOne(state, deserializeSupplier(action.payload.first));
            })
            .addCase(addSupplier.rejected, (state, action) => {

                state.addSupplierAsyncState = 'failed';

            })
            /* UPDATE_SUPPLIER STATE */
            .addCase(updateSupplier.pending, (state) => {

                state.updateSupplierAsyncState = 'loading';

            })
            .addCase(updateSupplier.fulfilled, (state, action) => {

                state.updateSupplierAsyncState = 'succeeded';
                const client = action.payload.first;
                state.entities[client.id] = deserializeSupplier(client);
                // This is not working for some reason
                // suppliersAdapter.updateOne(state, { id: supplier.id, changes: supplier });

            })
            .addCase(updateSupplier.rejected, (state, action) => {
                state.updateSupplierAsyncState = 'failed';

            })
            /* DELETE_SUPPLIER STATE */
            .addCase(deleteSupplier.pending, (state) => {

                state.deleteSupplierAsyncState = 'loading';

            })
            .addCase(deleteSupplier.fulfilled, (state, action) => {
                state.deleteSupplierAsyncState = 'succeeded';
                suppliersAdapter.removeOne(state, action.payload.first.id);

            })
            .addCase(deleteSupplier.rejected, (state, action) => {
                state.deleteSupplierAsyncState = 'failed';

            })
            .addCase(getSpecificSupplier.fulfilled, (state, action) => {
                const client = JSDB().getSupplier(action.payload).first;
                state.entities[client.id] = deserializeSupplier(client);
            })
            .addCase(removeAllFilters.fulfilled, (state, action) => {
                state.filterBy = [];
            })
            .addCase(fillSuppliers.fulfilled, (state, action) => {

                state.getSuppliersAsyncState = 'succeeded';
                if (action.payload.first.size > 0)
                    suppliersAdapter.setAll(
                        state,
                        action.payload.first
                            .toArray()
                            .map((c) => deserializeSupplier(c))
                    ); // for now active later create selector

            });
    }
});

// actions
export const {
    initGetSuppliersAsyncState,
    initAddSupplierAsyncState,
    initUpdateSupplierAsyncState,
    initDeleteSupplierAsyncState,
    applySort,
    applyFilter,
    removeFilter
} = suppliersSlice.actions;

// Selectors
export const selectGetSuppliersAsyncState = (state: RootState) => state.suppliers.getSuppliersAsyncState;
export const selectAddSupplierAsyncState = (state: RootState) => state.suppliers.addSupplierAsyncState;
export const selectUpdateSupplierAsyncState = (state: RootState) => state.suppliers.updateSupplierAsyncState;
export const selectDeleteSupplierAsyncState = (state: RootState) => state.suppliers.deleteSupplierAsyncState;
export const selectSuppliersSortBy = (state: RootState) => state.suppliers.sortBy;
export const selectSuppliersFilterBy = (state: RootState) => state.suppliers.filterBy;

export const {selectAll: selectSuppliers, selectById: selectSupplierById} = suppliersAdapter.getSelectors(
    (state: RootState) => state.suppliers
);
export const selectActiveSuppliers = createSelector(selectSuppliers, (suppliers) => suppliers.filter((c) => c.active));

const applySuppliersSort = (suppliers, sortBy) => stableSort(suppliers, getComparator(sortBy.order, sortBy.orderBy));

// Memoized Selectors
export const selectSuppliersIds = createSelector(selectActiveSuppliers, (suppliers) => suppliers.map((c) => c.id));
export const selectSuppliersNames = createSelector(selectActiveSuppliers, (suppliers) => suppliers.map((c) => c.name));
export const selectSuppliersCity = createSelector(selectActiveSuppliers, (suppliers) => Array.from(new Set(suppliers.map((p) => p.city))));
export const selectSuppliersidname = createSelector(selectActiveSuppliers, (suppliers) => suppliers.map((c) => {
    return {name: c.name, id: c.id};
}));
export const selectSupplierCategories = createSelector(selectActiveCustomers, (suppliers) => Array.from(new Set(suppliers.map((p) => p.category))));
export const selectSupplierGroups = createSelector(selectActiveCustomers, (suppliers) => Array.from(new Set(suppliers.map((p) => p.group ?? ''))));

export const selectSuppliersIdsWithSort = createSelector(selectActiveSuppliers, selectSuppliersSortBy, (suppliers, sortBy) =>
    applySuppliersSort(suppliers, sortBy).map((c) => c.id)
);
export const selectSuppliersIdsWithFilter = createSelector(selectActiveSuppliers, selectSuppliersFilterBy, (suppliers, filterBy) =>
    applyFilters(suppliers, filterBy).map((c) => c.id)
);
export const selectSuppliersIdsWithFilterSort = createSelector(
    selectActiveSuppliers,
    selectSuppliersFilterBy,
    selectSuppliersSortBy,
    (suppliers, filterBy, sortBy) => applySuppliersSort(applyFilters(suppliers, filterBy), sortBy).map((c) => c.id)
);

export const selectSuppliersObj = createSelector(
    selectSuppliers,
    selectSuppliersFilterBy,
    selectSuppliersSortBy,
    (_, booleanParam) => booleanParam,
    (suppliers, filterBy, sortBy, booleanParam) => applySuppliersSort(applyFilters(suppliers.filter((s) => (booleanParam && s.active) || (!booleanParam && !s.active)), filterBy), sortBy)
);

export const selectSuppliersWithFilter = createSelector(selectActiveSuppliers, selectSuppliersFilterBy, (suppliers, filterBy) =>
    applyFilters(suppliers, filterBy)
);

// Derived selectors
export const selectDistinctValues = createSelector(selectActiveSuppliers, distinct);

export default suppliersSlice.reducer;

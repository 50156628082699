import { Autocomplete, Box, Chip, Grid, IconButton, InputAdornment, Tooltip } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Search } from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from '../../store/actions';
import { ExtendedDocument } from '../../types/DocumentsTypes/Document';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { documentTypes } from '../../types/DocumentsTypes/utils';
import NoNegative from '../textFieldNumber';
import DocumentPreview from '../../views/customer/profile/documents/DocumentPreview';
import { getSpecificDocument } from '../../api/customer/documents.api';
import { useDocumentEventListener } from '../../datasheetreact/src/hooks/useDocumentEventListener';
import {memo} from "react";

export interface DialogChooserClass {
    id: number;
    name: string;
}

function DialogSearchDoc() {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [newValue, setNewValue] = React.useState<number | string>('');
    const [docType, setDocType] = React.useState<any>();
    const [doc, setDoc] = React.useState<ExtendedDocument | undefined>(undefined);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [options,] = React.useState<any[]>(Object.keys(documentTypes).map((i) => {
        return { ...documentTypes[i], type: i };
    }
    ));
    const onKeyDown = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.keyCode === 68 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                setOpen(true);
            }

            if (e.keyCode === 13 && open) {
                e.preventDefault();
                handleOk();
            }

        }, [newValue, open, docType, doc]);
    useDocumentEventListener('keydown', onKeyDown);


    const handleOk = async () => {
        try {
            if (!isNaN(Number(newValue)) && Number(newValue) != undefined && Number(newValue) != 0 && docType != undefined) {

                const d = await getSpecificDocument(docType.type, undefined, undefined, undefined, undefined, Number(newValue), true,false,undefined,undefined,true);
                if (d.length > 0) {
                    setDoc(d[0]);
                    setPreviewOpen(true);
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        },
                        message: `לא קיים מסמך ${docType.name} ${newValue}`,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                }

            } else {
                return
            }
        } catch (e) {

        }

        handleClose();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewValue('');

    };
    const onApprove =
        () => {
            handleOk();
        };

    return (
        <div>
        <Chip onClick={handleClickOpen} icon={<PlagiarismIcon fontSize={'medium'} />} sx={{ml: 1, mr: 0.5, '& .MuiChip-icon': {
      color: '#0000FF',
    }, }} label="חפש מסמך" variant="outlined" />
            {/* <Tooltip title={'חפש מסמך'}>
                <IconButton size='large' onClick={handleClickOpen} style={{ color: '#0000FF' }} >
                    <PlagiarismIcon fontSize={'medium'} />
                </IconButton>
            </Tooltip> */}
            {open ? <Dialog id='doc_search_dialog' maxWidth={'sm'} fullWidth={true}
                open={open} onClose={handleClose}>
                <DialogTitle> {'חפש מסמך..'}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={5} sm={5}>

                            <Autocomplete
                                id='document'
                                value={docType}
                                onChange={(e, value) => {
                                    if (value)
                                        setDocType(value);
                                    else
                                        setDocType(undefined);
                                }}

                                getOptionLabel={(option) => option.name}
                                options={options ?? []}
                                fullWidth
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='standard'
                                        label='בחר סוג מסמך.. '
                                        autoFocus

                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Search size={16} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box />
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <NoNegative


                                label={'מספר מסמך'}

                                value={newValue}
                                sx={{ minWidth: 10 }}
                                changer={(v) => {
                                    setNewValue(v);
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='standard'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'green', color: 'white' }}> חפש
                    </Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>ביטול</Button>
                </DialogActions>
            </Dialog> : undefined}
            {previewOpen && doc ? <Dialog fullWidth open={previewOpen} onBackdropClick={() => setPreviewOpen(false)} maxWidth={'md'}
                sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                <DocumentPreview document={doc} />
            </Dialog> : undefined}
        </div>
    );
}
export default memo(DialogSearchDoc);

import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

export const AutoCompleteInput = (params) => {
    const theme = useTheme();

    const textFieldStyles = useMemo(
        () => ({
            '& .MuiInputLabel-root': { color: '#616161' },
            '& .MuiOutlinedInput-root': {
                '& > fieldset': {
                    borderColor: theme?.palette?.mode === 'dark' ? theme?.palette?.dark?.main : theme?.palette?.primary?.light
                }
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                    borderColor: theme?.palette?.mode === 'dark' ? theme?.palette?.dark?.main : theme?.palette?.primary?.light
                }
            }
        }),
        [theme]
    );

    return (
        <TextField
            sx={textFieldStyles}
            variant="outlined"
            {...params}
            label="חפש לקוח.."
            autoFocus
            style={{ width: 150, marginLeft: '4px', marginRight: '4px' }}
            fullWidth
            size="small"
        />
    );
};

import {useDispatch} from 'react-redux';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import getCustomerProducts, {updateCustomerProduct} from 'api/customer/products.api';
import {SNACKBAR_OPEN} from 'store/actions';
import IItem from 'types/product_delivery';
import getCustomerProductsPrices, {
    getAllCpData,
    getAllCustomerProductAvailable,
    getAllCustomerProductPrices,
    getSpecialPrice,
    getSupplierProductsPrices,
    newAllCpData,
    updateCustomerProductAvailable,
    updateCustomerProductPrice,
    updateSupplierProductPrice
} from '../../api/customer/products_prices.api';
import {getInventory, getInventoryNote} from "../../api/products/products.api";

export const CUSTOMER_PRODUCTS_PRICES = 'customerProductsPrices';
export const CUSTOMER_PRODUCTS_AVAILABLE = 'customerAvailableProducts';
export const CUSTOMER_PRODUCTS_DATA = 'customerProductsData';
export const CUSTOMER_PRODUCTS = 'customerProducts';
export const CUSTOMER_INVENTORY = 'customerInventory';
export const CUSTOMER_INVENTORY_NOTE = 'customerInventoryNote';
export const SUPPLIER_PRODUCTS = 'supplierProducts';

export const useGetInventory = (product_id?: number[], agent_id?: number[],fromDate?: Date, toDate?: Date,last?:boolean) => useQuery(CUSTOMER_INVENTORY, () => getInventory(product_id, agent_id,fromDate,toDate,last).catch(reason => {
        console.log(reason)
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));
export const useGetInventoryNote = (fromDate: Date, toDate: Date, full?: boolean,agent_id?:number[],zero?:boolean,type?:number) => useQuery(CUSTOMER_INVENTORY_NOTE, () => getInventoryNote(fromDate, toDate, full,agent_id,zero,type).catch(reason => {
        console.log(reason)
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));

const useGetCustomerProducts = (cid: string) => useQuery(CUSTOMER_PRODUCTS, () => getCustomerProducts(cid).catch(reason => {
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));
export const useGetSpecialPrices = (cid: string, date: string) => useQuery(CUSTOMER_PRODUCTS_PRICES, () => getSpecialPrice(cid, date).catch(reason => {
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));
export const useGetCustomerProductsPrices = (cid: string, date: string, all: boolean = false,with_prices?:boolean,available?:boolean) => useQuery(CUSTOMER_PRODUCTS, () => getCustomerProductsPrices(cid, date, all,undefined,with_prices,available).catch(reason => {
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
),{
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,  // Important to set this to false
});
export const useGetSupplierProductsPrices = (cid: string, date: string, all: boolean = false) => useQuery(SUPPLIER_PRODUCTS, () => getSupplierProductsPrices(cid, date, all).catch(reason => {
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));
export const useUpdateAllSupplierProductPrice = (cid: number[], pid: number[], date: string, keyExtractor, toDate?: string, taxMutate?: boolean, unique?: boolean) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation([SUPPLIER_PRODUCTS, {
        cid,
        pid
    }], ({
             total_price,
             discount,
             price_percent,
             byPrice,
                                    alreadyPrice

         }: { total_price?: number, discount?: number, price_percent?: number, byPrice?: number,alreadyPrice?:boolean }) => updateSupplierProductPrice((cid), pid, date, total_price, discount, price_percent, toDate, taxMutate, byPrice, unique), {
        onSuccess: (data) => {
            cache.setQueryData(SUPPLIER_PRODUCTS, (oldData: IItem[]) => oldData?.map((d) => {
                const x = data.find((p) => keyExtractor(p) === keyExtractor(d));
                if (x) {
                    return x;
                }

                return d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המחירים עודכנו בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המחירים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const useUpdateSupplierProductPrice = (cid: string, pid: number, date: string, keyExtractor, toDate?: string, unique?: boolean) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation([SUPPLIER_PRODUCTS, {
        cid,
        pid
    }], (product: IItem) => updateSupplierProductPrice([Number(cid)], [product.id], date, product.totalPrice, product.discount, undefined, toDate, undefined, undefined, unique), {
        onSuccess: (data) => {
            cache.setQueryData(SUPPLIER_PRODUCTS, (oldData: IItem[]) => oldData?.map((d) => {
                const x = data.find((p) => keyExtractor(p) === keyExtractor(d));
                if (x) {
                    return x;
                }

                return d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המוצר עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: (error, variables, context) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: error,
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const useUpdateCustomerProductPrice = (cid: string, pid: number, date: string, keyExtractor, toDate?: string, unique?: boolean, special?: boolean) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation([special ? CUSTOMER_PRODUCTS_PRICES : CUSTOMER_PRODUCTS, {
        cid,
        pid
    }], (product: IItem) => updateCustomerProductPrice([Number(cid)], [product.id], date, product.totalPrice, product.discount, undefined, toDate, undefined, undefined, unique, special), {
        onSuccess: (data) => {
            cache.setQueryData(special ? CUSTOMER_PRODUCTS_PRICES : CUSTOMER_PRODUCTS, (oldData: IItem[]) => oldData?.map((d) => {
                const x = data.find((p) => keyExtractor(p) === keyExtractor(d));
                if (x)
                    return x;
                return d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המוצר עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: (error, variables, context) => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: error,
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};

export const useGetAllCustomerProductPrices = (pid: number, date: string) => useQuery(CUSTOMER_PRODUCTS, () => getAllCustomerProductPrices(pid, date).catch(reason => {
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));
export const useGetAllCustomerProductAvailable = (ids?: number[], product_ids?: number[]) => useQuery(CUSTOMER_PRODUCTS_AVAILABLE, () => getAllCustomerProductAvailable(ids, product_ids).catch(reason => {
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));
export const useGetAllCPData = () => useQuery(CUSTOMER_PRODUCTS_DATA, () => getAllCpData().catch(reason => {
        const dispatch = useDispatch();
        dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: 'שגיאה בגישה לנתונים',
            variant: 'alert',
            alertSeverity: 'error'
        });
    }
));
export const useUpdateAllCPDATA = () => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation([CUSTOMER_PRODUCTS_DATA, {

    }], ({
             data,
         }: {data:any}) => newAllCpData(data), {
        onSuccess: (data) => {




            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'נתונים עודכנו בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: (x) => {
            console.log(x)
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המחירים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const useUpdateAllCustomerAvailable = (cid: number[], pid: number[], keyExtractor) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation([CUSTOMER_PRODUCTS_AVAILABLE, {
        cid,
        pid
    }], ({
             available,
         }: {available:boolean}) => updateCustomerProductAvailable((cid), pid,undefined,available), {
        onSuccess: (data) => {
            cache.setQueryData(CUSTOMER_PRODUCTS_AVAILABLE, (oldData: IItem[]) => oldData?.map((d) => {
                const x = data.find((p) => keyExtractor?  keyExtractor(p) === keyExtractor(d):p.id===d.id);
                if (x)
                    return x;
                return d;
            }));
            cache.invalidateQueries(CUSTOMER_PRODUCTS)


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'מגוונים עודכנו בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: (x) => {
            console.log(x)
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המחירים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const useUpdateAllCustomerProductPrice = (cid: number[], pid: number[], date: string, keyExtractor, toDate?: string, taxMutate?: boolean, special?: boolean, unique?: boolean) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation([special ? CUSTOMER_PRODUCTS_PRICES : CUSTOMER_PRODUCTS, {
        cid,
        pid
    }], ({
             total_price,
             discount,
             price_percent,
             byPrice,
            baseChange,
                                    alreadyPrice
         }: { total_price?: number, discount?: number, price_percent?: number, byPrice?: number ,baseChange?:boolean,alreadyPrice?:boolean}) => updateCustomerProductPrice((cid), pid, date, total_price, discount, price_percent, toDate, taxMutate, byPrice, unique, special,baseChange,alreadyPrice), {
        onSuccess: (data) => {
            cache.setQueryData(special ? CUSTOMER_PRODUCTS_PRICES : CUSTOMER_PRODUCTS, (oldData: IItem[]) => oldData?.map((d) => {
                const x = data.find((p) => keyExtractor?  keyExtractor(p) === keyExtractor(d):p.id===d.id);
                if (x)
                    return x;
                return d;
            }));


            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המחירים עודכנו בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: (x) => {
            console.log(x)
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המחירים',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};
export const useUpdateCustomerProduct = (cid: string, pid: number) => {
    const cache = useQueryClient();
    const dispatch = useDispatch();

    return useMutation([CUSTOMER_PRODUCTS, {cid, pid}], (product: IItem) => updateCustomerProduct(cid, product), {
        onSuccess: (data) => {
            cache.setQueryData(CUSTOMER_PRODUCTS, (oldData: IItem[]) => oldData?.map((d) => (d.id === data.id ? data : d)));
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'המוצר עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        },
        onError: () => {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'אירעה שגיאה בעדכון המוצר',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    });
};

export default useGetCustomerProducts;

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HeadCell } from 'types';
import { openColumnMenuOnRightClick } from "ui-component/ag-grid/ag-grid-functions";
import { AG_GRID_LOCALE_HE } from 'ui-component/ag-grid/ag-table-localization';
import './Ag-Timeline.scss';
import CircleIcon from '@mui/icons-material/Circle';

interface IAgTimeLineControls {

}

const AgTimeLine = ({
  columns,
  data,
  tableControls,
  setPreviewOpen,
  setPreviewData,
  documents,
  setDocuments,
}: {
  columns: HeadCell[],
  data: any,
  tableControls: IAgTimeLineControls,
  setPreviewOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  setPreviewData?: React.Dispatch<any>,
  documents?: any[],
  setDocuments?: React.Dispatch<React.SetStateAction<any[]>>
}) => {

  const [rowData, setRowData] = useState<any[]>([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      setRowData(data);
    }
  }, [data]);

  useEffect(() => {
    if (columns) {
      const colDefs = columns.map((col) => {
        return {
          headerName: col.label,
          field: col.id,
          id: col.id,
          hide: col.hide,
          valueGetter: (params) => col.valueGetter ? col.valueGetter(params) : params.data[col.id],
          cellRenderer: (params) => {
            const displayColumns = params?.columnApi?.getAllDisplayedColumns();
            const midColIndex = Math.floor(displayColumns?.length / 2);
            const isFirstRow = params?.node?.rowIndex === 0;
            const isLastRow = params?.node?.rowIndex === rowData?.length - 1;
            if (params?.colDef?.id === "cname") {
              return (
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  color: "blue",
                  cursor: "pointer",
                }} onClick={() => {
                  if (setPreviewOpen && setPreviewData) {
                    if (params?.node?.data?.docs?.length > 1) {
                      documents && documents?.length > 0 ? setDocuments && setDocuments([]) : setDocuments && setDocuments(params?.node?.data?.docs);
                    } else {
                      setPreviewData(params?.node?.data?.docs?.[0]);
                      setPreviewOpen(true);
                    }
                  }
                }}
                >
                  {params?.value || ""}
                </div>
              )
            }
            if (params?.colDef?.id === "date") {
              return (
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  color: "blue",
                  cursor: "pointer",
                }}
                  onClick={() => {
                    if (setPreviewOpen && setPreviewData) {
                      if (params?.node?.data?.docs?.length > 1) {
                        documents && documents?.length > 0 ? setDocuments && setDocuments([]) : setDocuments && setDocuments(params?.node?.data?.docs);
                      } else {
                        setPreviewData(params?.node?.data?.docs?.[0]);
                        setPreviewOpen(true);
                      }
                    }
                  }}
                >
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginLeft: "10px",
                    position: "relative"
                  }}>
                    {
                      isFirstRow ? null : (
                        <div style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          borderLeft: "2px solid #000",
                          height: "0.7rem",
                        }} />
                      )
                    }
                    <CircleIcon style={{
                      fontSize: "0.7rem",
                      margin: "0.2rem 0",
                      color: params.data.cname !== params.data.planName ?  params.data.cname !='' ? "#ff8f32":"#ff0000" : "green",
                      zIndex: 2,
                      marginTop: isFirstRow ? "0.7rem" : "0",
                      marginBottom: isLastRow ? "0.7rem" : "0",
                    }} />
                    {
                      isLastRow ? null : (
                        <div style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          borderLeft: "2px solid #000",
                          height: "0.7rem",
                        }} />
                      )}
                  </div>
                  <span>
                    <b>{params?.value}</b>
                  </span>
                </div>
              )
            }
            return params?.value;
          }
        }
      });
      setColumnDefs(colDefs);
    }
  }, [columns, rowData]);

  const tableRef = useRef<any>(null);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      flex: 1,
    }
  }, []);

  return (

    <div className="ag-theme-alpine ag-timeline-table" style={{ height: 500, maxHeight: 500, width: "100%" }}
      onContextMenu={(e) => {
        openColumnMenuOnRightClick(e, tableRef);
      }}>
      <AgGridReact
        //---- basic settings ----//
        ref={tableRef}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        enableRtl={true}
        localeText={AG_GRID_LOCALE_HE}
        // rowClassRules={{
        //   "not-in-plan": (params) => {
        //     return params.data.cname !== params.data.planName;
        //   },
        //   "in-plan": (params) => {
        //     return params.data.cname === params.data.planName;
        //   }
        // }}
        rowStyle={{
          border: "none",
          backgroundColor: "white",
        }}
      />
    </div>
  )
}

export default AgTimeLine;

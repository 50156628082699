import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Tooltip, Select, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { memo, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { addDragToTable, removeDragFromTable } from "../ag-grid-functions";

export interface IAgChip {
  action: (params, setLoader?) => any;
  icon: any;
  tooltip: string;
  label: (params) => string;
  active?: any;
  isLoading?: boolean;
  authButton?: any;

}

export interface IChipForAgTable extends IAgChip {
  params: any;
}

const ChipForAgTable = ({
  action,
  icon,
  tooltip,
  label,
  params,
  active,
  isLoading,
  authButton
}: IChipForAgTable) => {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const isActive = active && active(params);
  return (
    authButton ? authButton : (
      <Tooltip title={tooltip} onClick={(a) => {
        action(params, setLoading);
        setClicked(!clicked);
      }}
        style={{ backgroundColor: isActive !== undefined ? (isActive ? 'rgb(249 255 187)' : '#f5f5f5') : (clicked ? 'rgb(249 255 187)' : '#f5f5f5') }}
      >
        <Chip
          icon={loading ? <CircularProgress size={20} /> : icon} sx={{
            ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
              color: '#000080',
            },
          }} label={label(params)} variant="outlined" />
      </Tooltip>
    )
  )
}

const ChipForReorder = ({ reOrderMode, setReOrderMode, params, tableControls, rowData, setRowData, setReOrderDialog }) => {
  return (
    !reOrderMode ? (
      <Tooltip title={"סדר מחדש את השורות בטבלה"} onClick={() => {
        setReOrderMode(true)
        setReOrderDialog(true)
      }}
        style={{ backgroundColor: '#f5f5f5' }}
      >
        <Chip icon={<SyncAltIcon />} sx={{
          ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
            color: '#000080',
          },
        }} label={"סדר שורות"} variant="outlined" />
      </Tooltip>
    ) : (
      <>
        <Tooltip title={"סדר מחדש את השורות בטבלה"} onClick={() => {
          setReOrderMode(false);
          const allRowNodes: any[] = [];
          params?.api?.setFilterModel(null);
          params?.api?.forEachNodeAfterFilterAndSort
            ((node: any) => {
              const rowNodeData = { ...node?.data };
              allRowNodes.push(rowNodeData);
            });
          tableControls?.allowReorder(allRowNodes.map((row) => row.id))
          params?.api?.setRowData(allRowNodes);
          setRowData(allRowNodes);
          removeDragFromTable(params);
        }}
          style={{ backgroundColor: '#f5f5f5' }}
        >
          <Chip icon={<SaveIcon />} sx={{
            ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
              color: 'green',
            },
          }} label={"שמור סדר"} variant="outlined" />
        </Tooltip>
        <Tooltip title={"סדר מחדש את השורות בטבלה"} onClick={() => {
          setReOrderMode(false);
          params?.api?.setRowData(rowData);
          removeDragFromTable(params);
        }}
          style={{ backgroundColor: '#f5f5f5' }}
        >
          <Chip icon={<CancelIcon />} sx={{
            ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
              color: 'red',
            },
          }} label={"בטל"} variant="outlined" />
        </Tooltip>
      </>
    )
  )

}


export const MemoChip = memo(ChipForReorder)


const AgChips = ({ chipList, reOrderMode, setReOrderMode, params, tableControls, rowData, setRowData, chipDisplayAmount }: {
  chipList: IAgChip[] | undefined,
  reOrderMode: boolean,
  setReOrderMode: (reOrderMode: boolean) => void,
  params: any,
  tableControls: any,
  rowData: any,
  setRowData: (rowData: any) => void,
  withLoader?: boolean,
  chipDisplayAmount?: number
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reOrderOption, setReOrderOption] = useState<any>(0);
  const [reOrderDialog, setReOrderDialog] = useState(false);

  const reOrderOptionsForSelect = [
    { value: 0, label: 'סדר נוכחי' },
    { value: 1, label: 'סדר לפי קטגוריות' },
    { value: 2, label: 'סדר לפי קבוצות' },
    { value: 3, label: 'סדר לפי מוצר' },
    // { value: 4, label: 'סדר לפי מוצר חזק' },
  ];

  const isPhone = window.innerWidth < 768;

  const chipsToDisplay = chipDisplayAmount ? chipDisplayAmount : 4

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        padding: "0",
        alignItems: "center",
      }}
    >
      {
        !isPhone && tableControls?.allowReorder && <MemoChip reOrderMode={reOrderMode} setReOrderMode={setReOrderMode} params={params} tableControls={tableControls} rowData={rowData} setRowData={setRowData} setReOrderDialog={setReOrderDialog} />
      }

      {!isPhone && !reOrderMode && chipList?.map((chip, index) => {
        if (index < chipsToDisplay) return <ChipForAgTable key={index} action={chip.action} icon={chip.icon}
          tooltip={chip.tooltip} label={chip.label} params={params}
          authButton={chip.authButton}
          active={chip.active}
          isLoading={chip.isLoading} />
      })}
      {(isPhone || (!reOrderMode && chipList && chipList?.length > chipsToDisplay)) &&
        <>
          <Chip
            label="עוד"
            icon={<MoreVertIcon />}
            sx={{
              ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                color: '#000080',
                '&:hover': {
                  color: '#000080',
                },
              },
              backgroundColor: '#f5f5f5',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
            variant="outlined"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          />
          <Menu
            id="ag-chips-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {chipList?.map((chip, index) => {
              if (!isPhone && index >= chipsToDisplay) {
                return <MenuItem onClick={() => chip.action(params)} key={index} value={chip.label(params)}>{chip.label(params)}</MenuItem>
              }
              if (isPhone) {
                return <MenuItem onClick={() => chip.action(params)} key={index} value={chip.label(params)}>{chip.label(params)}</MenuItem>
              }
            }
            )}
          </Menu>
        </>
      }
      {
        tableControls?.allowReorder && (
          <Dialog
            open={reOrderDialog}
            onClose={() => setReOrderDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"סדר מחדש את השורות בטבלה"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                בחר כיצד תרצה להתחיל את סידור הטבלה
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{
              display: 'flex',
              flexDirection: 'column',
              padding: "0 24px",
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%", padding: "0", alignItems: "center", justifyContent: "space-between", marginBottom: 2 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reOrderOption}
                  label="סדר לפי"
                  onChange={(e) => setReOrderOption(e.target.value)}
                  style={{ width: "100%" }}
                >
                  {reOrderOptionsForSelect.map((option, index) => {
                    return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                  })}
                </Select>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%", padding: "0", alignItems: "center", justifyContent: "space-between" }}>
                <Button
                  style={{ color: "green", fontWeight: "bold" }}
                  onClick={() => {
                    const returnSorted = (a, b, key) => {
                      if (a[key] > b[key]) {
                        return 1;
                      }
                      if (a[key] < b[key]) {
                        return -1;
                      }
                      return 0;
                    }
                    switch (reOrderOption) {
                      case 1: {
                        const sortedRowData = [...rowData].sort((a: any, b: any) => returnSorted(a, b, "category"))
                        params.api.setRowData(sortedRowData);
                      }
                        break;
                      case 2: {
                        const sortedRowData = [...rowData].sort((a: any, b: any) => returnSorted(a, b, "category2"))
                        params.api.setRowData(sortedRowData);
                      }
                        break;
                      case 3: {
                        const sortedRowData = [...rowData].sort((a: any, b: any) => returnSorted(a, b, "name"))
                        params.api.setRowData(sortedRowData);
                      }
                      //   break;
                      // case 4: {
                      //   const sortedRowData = [...rowData].sort((a: any, b: any) => returnSorted(a, b, "product2"))
                      //   params.api.setRowData(sortedRowData);
                      // }
                    }
                    const displayedColumns = params.columnApi.getAllDisplayedColumns();
                    displayedColumns.forEach((column) => {
                      column.setColDef({
                        ...column.getColDef(),
                        rowDrag: false,
                        hide: column.getColDef().hide,
                      });
                    });
                    addDragToTable(params)
                    params.api.refreshCells();
                    setReOrderDialog(false)
                  }
                  } autoFocus>
                  סדר מחדש
                </Button>
                <Button style={{ color: "red", fontWeight: "bold" }} onClick={() => {
                  setReOrderMode(false)
                  setReOrderDialog(false)
                }}>בטל</Button>
              </Box>
            </DialogActions>
          </Dialog>
        )
      }
    </Box>
  )
}

export default memo(AgChips);

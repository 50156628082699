/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

// material-ui
import { Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { LoadingButton } from '@mui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { getSpecificCustomer, selectCustomerById } from 'store/customers/customersSlice';
import { RootState } from 'store';
import {
  getCustomerProduct,
  selectActiveProducts,
  selectCustomersOrderProductsWithSort
} from 'store/customersProducts/customersProductsSlice';

// project imports
import config from 'config';
import SubCard from 'ui-component/cards/SubCard';
import Logo from 'ui-component/Logo';
import MyChip from 'ui-component/extended/Chip';

// Locale
import { format } from 'date-fns';
import { enUS, he } from 'date-fns/locale';
import { FormattedMessage } from 'react-intl';

// types
import DocumentHeaderPreview from '../document_header_preview/DocumentHeaderPreview';
import DocumentClientDetails from '../document_header_preview/DocumentClientDetails';
import { ExtendedDocument } from '../../../../../types/DocumentsTypes/Document';
import {useCreateCustomerDocument} from "../../../../../hooks/customer/documents.hooks";
import Swal from "sweetalert2";
import {createDocument, visitStates} from "../../../../../types/DocumentsTypes/utils";
import JSDB from "../../../../../fromKotlin/nk";
import getCustomerProductsPrices from "../../../../../api/customer/products_prices.api";
import {dateObjectToString} from "../../../../../fromKotlin/nkutils";
import {getClientsDaily} from "../../../../../api/customer/documents.api";
import IItem from "../../../../../types/product_delivery";



export const documentStatesArr: visitStates[] = [
  { label: "ביקור כללי", value: 0 },
  { label: "ביצעתי הזמנה", value: 1 },
  { label: "ביצעתי מכירה", value: 2 },
  { label: "לא צריך סחורה", value: 3 },
  { label: "לקחתי החזרות", value: 4 },
  { label: "לקחתי תשלום", value: 5 },
  { label: "הוספתי לקוח", value: 6 }
];

interface VisitProduct {
  id: number;
  name: string;
  amount: number;
  price: number;
  total: number;
}

interface VisitDocument extends ExtendedDocument {
  states: string[];
  product: VisitProduct;
  visitDetails: string;
  payment: number;
}

export const NewVisitActions = ({upid, internalEdit,setInternalEdit }: {upid?:any, internalEdit?:any,setInternalEdit?:any}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [products, setProducts] = React.useState<IItem[]>([]);
  React.useEffect(() => {

    const genProds = async () => {
      const ps = await getCustomerProductsPrices(upid, dateObjectToString(internalEdit?.date ?? new Date()), false);
      setProducts(ps);
    };
    genProds();

  }, [dispatch]);
  return (
    <Grid item xs={12}>
      <SubCard
        sx={{
          bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
        }}
      >
        <Grid container sx={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}>
          <Autocomplete
            id="visit-note-prodcut"
            sx={{
              width: 150,
            }}
            value={internalEdit?.product?.object  }
            options={products}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => {
              if (value) {
                setInternalEdit && setInternalEdit({ ...internalEdit, product: { object:value,id: value.id, name: value.name, amount: internalEdit.amount, price: value.totalPrice, total: internalEdit.amount*(internalEdit.totalPrice) } })
              }
            }
            }
            renderInput={(params) => <TextField {...params} label="מוצר" />}
          />
          <TextField
            sx={{
              width: 150,
            }}
            label="כמות"
            type="number"
            value={internalEdit?.product?.amount  ?? 0}
            onChange={(e) => {
              if (document) {
                setInternalEdit && setInternalEdit({ ...internalEdit, product: { ...internalEdit?.product, amount: Number(e.target.value), total: Number(e.target.value) * internalEdit?.product?.price ??0 } })
              }
            }}
          />
          <TextField
            sx={{
              width: 150,
            }}
            label="מחיר"
            type="number"
            disabled
            value={internalEdit?.product?.price ?? 0}
            onChange={(e) => {
              if (internalEdit) {
                setInternalEdit && setInternalEdit({ ...internalEdit, product: { ...internalEdit?.product, price: Number(e.target.value), total: Number(e.target.value) * internalEdit?.product?.amount ??0 } })
              }
            }}
          />
          <TextField
            sx={{
              width: 150,
            }}
            label="סה״כ"
            type="number"
            disabled
            value={internalEdit?.product?.amount && internalEdit?.product?.price ? internalEdit.product.amount * internalEdit.product.price : 0}
          />
        </Grid>
        <Grid container sx={{
          width: "100%",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}>
          <Grid sx={{
            width: "100%",
          }}>
            <FormGroup sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "1fr 1fr",
            }}>
              {documentStatesArr.map((s) => {
                return <FormControlLabel
                  control={<Checkbox
                    checked={internalEdit?.states?.includes(s.value) || internalEdit?.states?.includes(s.label) || false}
                    onChange={(e) => {
                      if (internalEdit) {
                        if (e.target.checked) {
                          setInternalEdit && setInternalEdit({ ...internalEdit, states: internalEdit?.states?.length ? [...internalEdit.states, s.value] : [s.value] })
                        } else {
                          setInternalEdit && setInternalEdit({ ...internalEdit, states: internalEdit?.states?.length ? internalEdit.states.filter((state) => state !== s.value) : [] })
                        }
                      }
                    }}
                    name={s.value}
                    color="primary"
                  />}
                  label={s.label}
                />
              }
              )}
            </FormGroup>
          </Grid>
          <TextField
            sx={{
              width: "100%",
            }}
            label="פרט ביקור"
            multiline
            rows={4}
            value={internalEdit?.comment ?? ''}
            onChange={(e) => {
              if (internalEdit) {
                setInternalEdit && setInternalEdit({ ...internalEdit, comment: e.target.value })
              }
            }}
          />
          <TextField
            sx={{
              width: 150,
            }}
            label="סכום תשלום"
            type="number"
            value={internalEdit?.payment ?? 0}
            onChange={(e) => {
              if (internalEdit) {
                setInternalEdit && setInternalEdit({ ...internalEdit, payment: Number(e.target.value) })
              }
            }}
          />
        </Grid>
      </SubCard>
    </Grid>
  )
}

const NewVisit = ({ upid }: { upid: string }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state: RootState) => selectCustomerById(state, upid));
  const [document, setDocument] = React.useState<VisitDocument | undefined>({});
  const createCustomerDocument = useCreateCustomerDocument(upid);
  const theme = useTheme();
  React.useEffect(() => {
    if (!customer) {
      dispatch(getSpecificCustomer(Number(upid)));
      return;
    }
  }, [dispatch]);
  return (
    <SubCard
      darkTitle
      title={
        <Typography fontWeight='bold'>
          תעודת ביקור
        </Typography>
      }
      secondary={<Logo width={50} />}
    >
      <Grid container spacing={2}>
        <DocumentHeaderPreview />
        <Grid item xs={12}>
          <Grid container>
            {customer &&
              <DocumentClientDetails address={`${customer?.street} ${customer?.streetNumber}, ${customer?.city}`} name={customer?.name}
                businessName={customer?.businessName} businessId={customer?.businessId}
                phone={customer?.phone} email={customer?.email} id={customer?.id} external_id={customer?.externalId} />
            }
            <Grid item sm={6}>
              <Grid container>
                <Typography variant='subtitle1' fontWeight='bold'>
                  פרטי ביקור
                </Typography>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant='body2'>
                        <FormattedMessage id='Date' />:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body2'>
                        {format(new Date(), 'PPP', {
                          locale: config.i18n === 'he' ? he : enUS
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2'>
                        תאריך הנפקה:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body2'>
                        {format(new Date(), 'PPPPp', {
                          locale: config.i18n === 'he' ? he : enUS
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2'>
                        <FormattedMessage id='Status' />:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <MyChip
                        label={<FormattedMessage id={'pending_approval'} />}
                        variant='outlined'
                        size='small'
                        chipcolor={'pending_approval'}
                      />
                    </Grid>
                    <>
                      <Grid item xs={4}>
                        <Typography variant='body2'>
                          סוכן:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant='body2'>
                          {customer?.agent ?? ''}
                        </Typography>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        <NewVisitActions upid={upid} internalEdit={document} setInternalEdit={setDocument} />
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}>
            <LoadingButton
              onClick={() => {
                // CALLBACK //
                Swal.fire({
                  title: 'האם תרצה להפיק ביקור',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: theme.palette.primary.dark,
                  cancelButtonColor: theme.palette.error.light,
                  confirmButtonText: 'הפק ביקור',
                  cancelButtonText: 'בטל'
                }).then((result) => {
                  if (result.isConfirmed) {
                    createCustomerDocument.mutate(
                        {
                          end: new Date(),
                          agent: "",
                          allDay: false,
                          baseColor: "",
                          color: "",
                          comment: "",
                          date: new Date(),
                          description: "",

                          entBName: "",
                          entName: "",
                          id: 0,
                          issueDate: new Date(),
                          mainValue: 0,
                          name: "",
                          nkObject: undefined,
                          status: 'pending_approval',
                          textColor: "",
                          title: "",
                          type: 'visit_note', ...document,entId:customer?.id },
                    );
                  }
                })
                console.log("mainn document", document)
              }}
              loading={createCustomerDocument.isLoading}
              disabled={createCustomerDocument.isSuccess }
              variant='contained'
              type='submit'
              fullWidth
              sx={{ m: 2 }}
            >
              הנפק
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </SubCard>
  );
};

export default NewVisit;

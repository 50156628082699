import React, {useCallback, useRef} from 'react';
import { Autocomplete, Box, IconButton, Popper, TextField, Typography } from '@mui/material';
import styledTag from '../styles';
import { TagProps } from '../types';
import { CircleX } from 'tabler-icons-react';
import {useDocumentEventListener} from "../../../views/orders/datasheet/ContextMenu";
const styles = () => ({
    popper: {
        maxWidth: 'fit-content'
    }
});

const PopperMy = function(props) {
    return <Popper {...props} style={styles.popper} placement='bottom-start' />;
};

interface call {
    callback: any;
    delay: number;
}

function AutocompleteTag(props: TagProps) {
    const { filter, onApplyFilter, onRemoveFilter, slowFilter, onDelete, ...other } = props;
    const [search, setSearch] = React.useState<string[]>(filter.values ?? []);
    const [freeSearch, setFreeSearch] = React.useState<string>('');
    const [focused, setFocused] = React.useState(filter.values ? false : true);
    const containerRef = useRef<HTMLDivElement>(null);
    const savedCallback = useRef(null);
    const ref=React.useRef()
    const onClickOutside = useCallback(
        (event: MouseEvent) => {
            const clickInside = containerRef.current?.contains(event.target as Node);
            if (clickInside || (event.target as Node)?.parentElement?.className?.toString()?.startsWith("MuiAutocomplete-listbox")==true || (event.target as Node)?.className?.toString()?.startsWith("MuiAutocomplete-listbox")==true) {

            } else {
                setFocused(false)
            }
        },
        [focused]
    );

    useDocumentEventListener('mousedown', onClickOutside);
    return (
        <Box {...other} sx={{ cursor: 'pointer' }}>
            <Typography variant='h5' fontWeight='bold' onClick={() => setFocused(prevState => !prevState)} >
                {filter.label ?? filter.id}:
            </Typography>
            {focused && (
                <div ref={containerRef}>
                    <Autocomplete
                        disableCloseOnSelect
                        value={search}
                        onInputChange={(e, v) => {
                            setFreeSearch(v);
                            if (onApplyFilter && !slowFilter) {
                                onApplyFilter({
                                    property: filter.id,
                                    contains: v,
                                    values: search,
                                    queryFunc: filter.queryFunc
                                });
                            } else {
                                if (savedCallback.current) {
                                    clearTimeout(savedCallback.current);
                                }
                                const id = setTimeout(() => {
                                    onApplyFilter!({
                                        property: filter.id,
                                        contains: v,
                                        values: search,
                                        queryFunc: filter.queryFunc
                                    });
                                    savedCallback.current = null;
                                }, 2000);
                                savedCallback.current = id;
                            }

                        }}
                        onChange={(e, v) => {
                            if (v.length > 0) {
                                if (savedCallback.current) {
                                    clearTimeout(savedCallback.current);
                                    savedCallback.current=null
                                }
                                if (onApplyFilter) {
                                    onApplyFilter({
                                        property: filter.id,
                                        contains: '',
                                        values: v.map((m)=>filter.withComplexOption ? filter.withComplexOption(m):m),
                                        queryFunc: filter.queryFunc
                                    });
                                }
                            } else if (onRemoveFilter) {
                                onRemoveFilter({ property: filter.id, contains: '', queryFunc: filter.queryFunc });
                            }
                            setSearch(v);
                            setFreeSearch('');
                        }}
                        multiple
                        options={filter.options ?? []}
                        getOptionLabel={filter.withComplexOption ? filter.withComplexOption : undefined}
                        onBlur={
                            ()=>{
                                ref.current?.focus()

                            }
                    }
                        size='medium'
                        fullWidth
                        freeSolo
                        autoHighlight
                        disableClearable
                        PopperComponent={PopperMy}
                        openOnFocus
                        open
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={ref}
                                autoFocus
                                focused={focused}
                                size='medium'
                                variant='standard'
                                onKeyDown={(event: any) => {
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }

                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true
                                }}
                                placeholder={freeSearch}
                                fullWidth
                            />
                        )}
                    />
                </div>

            )}
            {!focused && (
                <>
                    <Typography variant='h5' fontWeight='bold' onClick={() => setFocused(prevState => !prevState)}>
                        {freeSearch ? [...search, freeSearch].join(', ') : search.map((m)=>filter.withShowOption ? filter.withShowOption(m):m).join(', ')}
                    </Typography>
                    <IconButton onClick={() => {
                        onDelete();
                        if (onRemoveFilter)
                            onRemoveFilter({ property: filter.id, contains: '', queryFunc: filter.queryFunc });
                    }}>


                        <CircleX fontSize={'medium'} />
                    </IconButton>
                </>
            )}
        </Box>
    );
}

const StyledAutocompleteTag = React.memo(styledTag(AutocompleteTag));
export default StyledAutocompleteTag;

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export const SnackBarAlert = ({ open, onClose, autoHideDuration, anchorOrigin, value }) => {
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={() => onClose()} anchorOrigin={anchorOrigin}>
            <Alert
                style={{ color: 'black', fontWeight: 'bold' }}
                variant="filled"
                onClose={() => onClose()}
                severity="error"
                sx={{ width: '100%' }}
            >
                {value}
            </Alert>
        </Snackbar>
    );
};

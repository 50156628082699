import { createSlice, createSelector, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import {
    addSupplierProductAsync, deleteSupplierProductAsync,
    getSupplierProductAsync, updateSupplierProductAsync

} from 'api/products/suppliers.api';
import { RootState } from 'store';
import { AsyncStatus } from 'store/types/asyncState';
import { ArrangementOrder, Filter } from 'types';
import Product, { deserializeProduct } from 'types/product';
import stableSort, { applyFilters, getComparator, distinct } from 'utils/sort';
import JSDB from '../../fromKotlin/nk';
export const getSupplierProduct = createAsyncThunk('suppliersProducts/getSuppliersProducts', getSupplierProductAsync);
export const addSupplierProduct = createAsyncThunk('suppliersProducts/addSuppliersProduct', addSupplierProductAsync);
export const updateSupplierProduct = createAsyncThunk('suppliersProducts/updateSuppliersProduct', updateSupplierProductAsync);
export const deleteSupplierProduct = createAsyncThunk('suppliersProducts/deleteSuppliersProduct', deleteSupplierProductAsync);
export const removeAllFilters = createAsyncThunk('suppliers/removeFilters', async () => {
    return true
});
const suppliersProductsAdapter = createEntityAdapter<Product>();
const initialState: {
    getSuppliersProductsAsyncState: AsyncStatus;
    addSuppliersProductAsyncState: AsyncStatus;
    updateSuppliersProductAsyncState: AsyncStatus;
    deleteSuppliersProductAsyncState: AsyncStatus;
    sortBy: { orderBy: string; order: ArrangementOrder };
    filterBy: Filter[];
} = {
    getSuppliersProductsAsyncState: 'idle',
    addSuppliersProductAsyncState: 'idle',
    updateSuppliersProductAsyncState: 'idle',
    deleteSuppliersProductAsyncState: 'idle',
    sortBy: { orderBy: 'position', order: 'asc' },
    filterBy: []
};

export const suppliersProductsSlice = createSlice({
    name: 'suppliersProducts',
    initialState: suppliersProductsAdapter.getInitialState({
        ...initialState
    }),
    reducers: {
        initGetSuppliersProductsAsyncState: (state) => {
            state.getSuppliersProductsAsyncState = 'idle';
        },
        initAddSuppliersProductAsyncState: (state) => {
            state.addSuppliersProductAsyncState = 'idle';
        },
        initUpdateSuppliersProductAsyncState: (state) => {
            state.updateSuppliersProductAsyncState = 'idle';
        },
        initDeleteSuppliersProductAsyncState: (state) => {
            state.deleteSuppliersProductAsyncState = 'idle';
        },
        applySuppliersProductsSort: (state, action) => {
            state.sortBy = action.payload;
        },
        applySuppliersProductsFilter: (state, action) => {
            state.filterBy = state.filterBy.reduce((acc, f: Filter) => (f.property === action.payload.property ? acc : acc.concat(f)), [
                action.payload
            ]);
        },
        removeSuppliersProductsFilter: (state, action) => {
            state.filterBy = state.filterBy.filter((f) => f.property !== action.payload.property);
        }
    },
    extraReducers: (builder) => {
        builder
            /* GET_SUPPLIERS_PRODUCTS STATE */
            .addCase(getSupplierProduct.pending, (state) => {

                    state.getSuppliersProductsAsyncState = 'loading';

            })
            .addCase(getSupplierProduct.fulfilled, (state, action) => {
                if (state.getSuppliersProductsAsyncState === 'loading') {
                    state.getSuppliersProductsAsyncState = 'succeeded';
                    if(action.payload.first.size>0)
                    suppliersProductsAdapter.setAll(
                        state,
                        action.payload.first.toArray().map((p) => deserializeProduct(p))
                    );
                } else if (state.getSuppliersProductsAsyncState === 'succeeded') {
                    if(action.payload.first.size>0)
                    suppliersProductsAdapter.setAll(
                        state,
                        action.payload.first.toArray().map((p) => deserializeProduct(p))
                    );
                }
            })
            .addCase(getSupplierProduct.rejected, (state, action) => {

                    state.getSuppliersProductsAsyncState = 'failed';

            })
            /* ADD_SUPPLIER_PRODUCT STATE */
            .addCase(addSupplierProduct.pending, (state) => {

                    state.addSuppliersProductAsyncState = 'loading';

            })
            .addCase(addSupplierProduct.fulfilled, (state, action) => {

                    state.addSuppliersProductAsyncState = 'succeeded';
                    suppliersProductsAdapter.addOne(state, deserializeProduct(action.payload.first));

            })
            .addCase(addSupplierProduct.rejected, (state, action) => {

                    state.addSuppliersProductAsyncState = 'failed';

            })
            /* UPDATE_SUPPLIER_PRODUCT STATE */
            .addCase(updateSupplierProduct.pending, (state) => {

                    state.updateSuppliersProductAsyncState = 'loading';

            })
            .addCase(updateSupplierProduct.fulfilled, (state, action) => {

                    state.updateSuppliersProductAsyncState = 'succeeded';
                    const product = action.payload.first;
                    state.entities[product.id] = deserializeProduct(product);

            })
            .addCase(updateSupplierProduct.rejected, (state, action) => {

                    state.updateSuppliersProductAsyncState = 'failed';

            })
            /* DELETE_SUPPLIER_PRODUCT STATE */
            .addCase(deleteSupplierProduct.pending, (state) => {

                    state.deleteSuppliersProductAsyncState = 'loading';

            })
            .addCase(deleteSupplierProduct.fulfilled, (state, action) => {

                    state.deleteSuppliersProductAsyncState = 'succeeded';
                    const product = action.payload.first;
                    state.entities[product.id] = deserializeProduct(product);

            })
            .addCase(deleteSupplierProduct.rejected, (state, action) => {

                    state.deleteSuppliersProductAsyncState = 'failed';

            })
            .addCase(removeAllFilters.fulfilled, (state, action) => {
                state.filterBy = []
            })
            ;
    }
});

// actions
export const {
    initGetSuppliersProductsAsyncState,
    initAddSuppliersProductAsyncState,
    initUpdateSuppliersProductAsyncState,
    initDeleteSuppliersProductAsyncState,
    applySuppliersProductsSort,
    applySuppliersProductsFilter,
    removeSuppliersProductsFilter
} = suppliersProductsSlice.actions;

// Selectors
export const selectGetSuppliersProductsAsyncState = (state: RootState) => state.suppliersProducts.getSuppliersProductsAsyncState;
export const selectAddSuppliersProductAsyncState = (state: RootState) => state.suppliersProducts.addSuppliersProductAsyncState;
export const selectUpdateSuppliersProductAsyncState = (state: RootState) => state.suppliersProducts.updateSuppliersProductAsyncState;
export const selectUpdateSuppliersAllProductAsyncState = (state: RootState) => state.suppliersProducts.massSuppliersProductAsyncState;
export const selectDeleteSuppliersProductAsyncState = (state: RootState) => state.suppliersProducts.deleteSuppliersProductAsyncState;
export const selectSuppliersProductsSortBy = (state: RootState) => state.suppliersProducts.sortBy;
export const selectSuppliersProductsFilterBy = (state: RootState) => state.suppliersProducts.filterBy;

export const {
    selectAll: selectSuppliersProducts,
    selectById: selectSupplierProductById
} = suppliersProductsAdapter.getSelectors(
    (state: RootState) => state.suppliersProducts
);

const applySuppliersSort = (suppliers, sortBy) => stableSort(suppliers, getComparator(sortBy.order, sortBy.orderBy));

// Memoized Selectors
export const selectActiveProducts = createSelector(selectSuppliersProducts, (products) => products.map((p) => p.active));
export const selectSuppliersProductsIds = createSelector(selectActiveProducts, (products) => products.map((p) => p.id));
export const selectSuppliersProductsNames = createSelector(selectActiveProducts, (products) => products.map((p) => p.name));
export const selectSuppliersProductsWithSort = createSelector(
    selectActiveProducts,
    selectSuppliersProductsSortBy,
    (suppliers, sortBy) => applySuppliersSort(suppliers, { orderBy: 'position', order: 'asc' })
);
export const selectSuppliersOrderProductsWithSort = createSelector(
    selectActiveProducts,
    selectSuppliersProductsSortBy,
    (suppliers, sortBy) => applySuppliersSort(suppliers.filter((f) => f.available), { orderBy: 'position', order: 'asc' })
);
export const selectSuppliersProductsIdsWithSort = createSelector(
    selectActiveProducts,
    selectSuppliersProductsSortBy,
    (suppliers, sortBy) => applySuppliersSort(suppliers, sortBy).map((c) => c.id)
);
export const selectSuppliersProductsIdsWithFilter = createSelector(
    selectActiveProducts,
    selectSuppliersProductsFilterBy,
    (suppliers, filterBy) => applyFilters(suppliers, filterBy).map((c) => c.id)
);
export const selectSuppliersProductsIdsWithFilterSort = createSelector(
    selectActiveProducts,
    selectSuppliersProductsFilterBy,
    selectSuppliersProductsSortBy,
    (products, filterBy, sortBy) => applySuppliersSort(applyFilters(products, filterBy), sortBy).map((c) => c.id)
);
export const selectSuppliersProductsObjList = createSelector(
    selectSuppliersProducts,
    selectSuppliersProductsFilterBy,
    selectSuppliersProductsSortBy,
    (_, booleanParam) => booleanParam,
    (products, filterBy, sortBy,booleanParam) => applySuppliersSort(applyFilters(products.filter((s) => (booleanParam && s.active) || (!booleanParam && !s.active)), filterBy), sortBy)
);

// Derived selectors
export const selectSuppliersProductsDistinctValues = createSelector(selectSuppliersProducts, distinct);


export const categories = (data: Product[]) => {
    const s = new Set(data.map(item => item.category));
    s.delete('');
    return [...s];
};
export const units = () => {
    const u = JSDB().getUnits();
    if (u.size > 0)
        return u.toArray();
    return [];
};


export const selectSuppliersProductsDistinctCategoryValues = createSelector(selectSuppliersProducts, categories);
export const selectSuppliersProductsUnits = createSelector(units);

export default suppliersProductsSlice.reducer;

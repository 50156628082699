import JSDB, {ApiCall} from 'fromKotlin/nk';
import {ActiveUpdate} from 'store/utls/utils';
import Supplier, {serializeAddress, serializePrintState, serializeTax} from '../../types/supplier';


export const getSuppliersAsync = async () => {
    const req = new ApiCall('GET_SUPPLIER');
    req.putParam('date', JSDB().getDatesManipulator().dateNowNoHour());
    return req.execute();
};
export const fillSuppliersAsync = async () => {

    return  JSDB().getAllSupplier();
};
export const getSpecificSuppliersAsync = async (id: number) => {
    const req = new ApiCall('FETCH_SUPPLIER_DATA');
    req.putParam('id', id);
    const result = await req.execute();
    return id;
};

export const addSupplierAsync = async (c: Supplier) => {
    const req = new ApiCall('NEW_SUPPLIER');

    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    const phone = JSDB().preparePhone(c.phone, c.phone2, c.contactName, c.contactName2,'','');
    const branch = -1;
    const master = -1;

    // Change to -
    // Object.keys(c).forEach((k) => req.putParam(k, c[k]));
    // Need to map type key to param name

    req.putParam('date', date);
    req.putParam('name', c.name);
    if (c.id)
        req.putParam('id', c.id);

    req.putParam('include_tax', Number(serializeTax(c)));
    req.putParam('business_name', c.businessName);
    req.putParam('business_id', c.businessId);
    req.putParam('address', serializeAddress(c));
    req.putParam('print_state', serializePrintState(c));
    req.putParam('phone', phone.first);
    req.putParam('phone_contact', phone.second);
    req.putParam('branch', branch);
    req.putParam('master', master);
    req.putParam('external_id', c.externalId);
    return req.execute();
};

export const updateSupplierAsync = async (c: Supplier) => {
    const req = new ApiCall('UPDATE_SUPPLIER');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    const phone = JSDB().preparePhone(c.phone, c.phone2, c.contactName, c.contactName2,'','');
    const branch = c.nkObject.branch;
    const master = c.nkObject.master;

    // Change to -
    // Object.keys(c).forEach((k) => req.putParam(k, c[k]));
    // Need to map type key to param name

    req.putParam('id', c.id);
    req.putParam('date', date);
    req.putParam('name', c.name);
    req.putParam('include_tax', serializeTax(c));
    req.putParam('business_name', c.businessName.replace('"', '\'\''));
    req.putParam('business_id', c.businessId);
    req.putParam('address', serializeAddress(c));
    req.putParam('print_state', serializePrintState(c));
    req.putParam('phone', phone.first);
    req.putParam('phone_contact', phone.second);
    req.putParam('branch', branch);
    req.putParam('master', master);
    req.putParam('external_id', c.externalId);
    return req.execute();
};

export const deleteSupplierAsync = async (active: ActiveUpdate) => {
    const req = new ApiCall('UPDATE_SUPPLIER');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    return req.putParam('id', Number(active.id)).putParam('date', date).putParam('active', Number(active.active)).execute();
};

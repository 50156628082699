import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import { apiSlice } from './api/apiSlice';
import customersReducer from './customers/customersSlice';
import suppliersReducer from './suppliers/suppliersSlice';
import customersProductsReducer from './customersProducts/customersProductsSlice';
import suppliersProductsReducer from './SupplierProducts/supplierProductsSlice';
import agentsReducer from './agents/agentsSlice';
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';
import kanbanReducer from './kanbanReducer';
import { userSlice } from './user/userSlice';
import { apiNoteSlice } from './apiconfig/userSlice';


// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customers: customersReducer,
    suppliers: suppliersReducer,
    customersProducts: customersProductsReducer,
    suppliersProducts: suppliersProductsReducer,
    agents: agentsReducer,
    user: userSlice.reducer,
    apiNote: apiNoteSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer
});
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        const { routing } = state
        state = { routing }
    }
    return reducer(state, action)
}
export default rootReducer;

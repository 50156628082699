import { Badge, Checkbox, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputAdornment, InputLabel, Tab, Tabs, Tooltip } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Person } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import {
    useUpdateAllCustomerProductPrice,
    useUpdateAllSupplierProductPrice
} from '../../hooks/customer/products.hooks';
import { dateObjectToString } from '../../fromKotlin/nkutils';
import { CurrencyShekel, Percentage, Search } from 'tabler-icons-react';
import { memo } from "react";
import JSDB from "../../fromKotlin/nk";
import { selectCustomersObjs } from 'store/customers/customersSlice';
import { allAgents } from 'store/agents/agentsSlice';
import {useDispatch, useSelector} from 'react-redux';
import Agent from 'types/agent';
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import config from "../../config";
import {enUS, he} from "date-fns/locale";
import {FormattedMessage} from "react-intl";

function AllProductPriceChangesDialog({
    data,
    type,
    icon,
    tooltip,
    taxMutate,
    special,
    supplier = false,
}: { data: any, type, icon, tooltip, taxMutate?: boolean, supplier?: boolean, special?: boolean }) {
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [hint, setHint] = React.useState('');
    const [size, setSize] = React.useState(0);
    const [newValue, setNewValue] = React.useState<number | undefined>(undefined);
    const [newPercent, setNewPercent] = React.useState<number | undefined>(undefined);
    const [byPrice, setByPrice] = React.useState<number | undefined>(undefined);
    const [baseCheck, setBaseCheck] = React.useState(false);
    const [alreadyPrice, setAlreadyPrice] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState<"customers" | "categories" | "drivers">("customers");
    const [customersArr, setCustomersArr] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);
    const [drivers, setDrivers] = React.useState<any[]>([]);
    const [checkAll, setCheckAll] = React.useState(false);
    const [selectedCustomers, setSelectedCustomers] = React.useState<any[]>([]);
    const [selectedCategories, setSelectedCategories] = React.useState<any[]>([]);
    const [selectedDrivers, setSelectedDrivers] = React.useState<any[]>([]);
    const [date, setDate] = React.useState(data.date ?? new Date());
    const customers = useSelector(state => selectCustomersObjs(state, true));
    const agents: Agent[] = useSelector(state => allAgents(state, true));

    React.useEffect(() => {
        const categories = new Set(customers?.map((customer) => customer.category));
        const drivers = agents.filter((agent) => agent.isDriver);
        setCustomersArr(customers);
        setCategories(Array.from(categories).filter((cat) => cat !== ""));
        setDrivers(drivers);
    }, [customers, agents]);


    let updateCustomerProduct;
    if (supplier) {
        if (selectedCustomers.length) {
            updateCustomerProduct = useUpdateAllSupplierProductPrice(selectedCustomers, data.selected, dateObjectToString(date), data.extractor, (data.toDate && data.radio && data.radio != 'from') ? dateObjectToString(data.toDate) : undefined, taxMutate, data.radio == 'unique');
        } else {
            updateCustomerProduct = useUpdateAllSupplierProductPrice(data.ids, data.selected, dateObjectToString(date), data.extractor, (data.toDate && data.radio && data.radio != 'from') ? dateObjectToString(data.toDate) : undefined, taxMutate, data.radio == 'unique');
        }
    } else {
        if (selectedCustomers.length) {
            updateCustomerProduct = useUpdateAllCustomerProductPrice(selectedCustomers, data.selected, dateObjectToString(date), data.extractor, (data.toDate && data.radio && data.radio != 'from') ? dateObjectToString(data.toDate) : undefined, taxMutate, special, data.radio == 'unique');
        } else {
            updateCustomerProduct = useUpdateAllCustomerProductPrice(data.ids, data.selected, dateObjectToString(date), data.extractor, (data.toDate && data.radio && data.radio != 'from') ? dateObjectToString(data.toDate) : undefined, taxMutate, special, data.radio == 'unique');
        }
    };
    const handlePriceMutation = () => updateCustomerProduct.mutate({
        total_price: newValue,
        byPrice: byPrice,
        price_percent: newPercent,
        baseChange: baseCheck,
        alreadyPrice: alreadyPrice
    });
    const handleDiscountMutation = () => updateCustomerProduct.mutate({ discount: newValue, baseChange: baseCheck, alreadyPrice: alreadyPrice });
    React.useEffect(() => {
        setSize(data.selected.length);
        setNewValue(undefined);
        setNewPercent(undefined);
        setByPrice(undefined);
        setSelectedCategories([])
        setSelectedCustomers([])
        setSelectedDrivers([])


        setHint(data.selected.map((p) => p.name).join(','));
        if (type == 0) {
            setTitle('שינוי מחיר גורף');
        } else if (type == 1) {
            setTitle('שינוי הנחה גורף');

        }
    }, [open, data, type]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onApprove =
        () => {
            if (type == 0)
                handlePriceMutation();
            else if (type == 1)
                handleDiscountMutation();
            handleClose();
        };

    return (
        <div>
            <Tooltip title={tooltip}>
                <IconButton size='small' onClick={handleClickOpen}>
                    {icon}
                </IconButton>
            </Tooltip>
            <Dialog id='helper_dialog'
                sx={{
                    '& .MuiDialog-paper': {
                        padding: "10px"
                    }
                }}
                open={open} onClose={handleClose}>
                <DialogTitle> {title}
                    <Tooltip title={hint}>
                        <Badge color='secondary' max={2000} badgeContent={size}>
                            <IconButton size='medium'>
                                <Person />
                            </IconButton>
                        </Badge>
                    </Tooltip>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                        <DatePicker
                            mask=''
                            renderInput={(props) => <TextField {...props} />}
                            label={<FormattedMessage id='Apply Changes From Date' />}
                            value={date}
                            onChange={(newValue) => {
                                if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </DialogTitle>
                <DialogContent style={{
                    padding: '10px 5px'
                }}>

                    <Grid container>
                        <Grid item xs={(type == 0) ? 4 : 12} sm={(type == 0) ? 4 : 12}>
                            <TextField
                                type={'number'}
                                label={`שנה לכולם לערך ${taxMutate && type == 0 ? '(לפני מעמ)' : ''}`}
                                dir={'ltr'}
                                value={newValue}
                                onChange={(e) => {
                                    if (e.target.value)
                                        setNewValue(Math.max(0, Number(e.target.value)));
                                    else
                                        setNewValue(undefined);
                                }}

                            />
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            {(type == 0) ? <TextField
                                type={'number'}
                                label='העלה לכולם באחוזים'
                                dir={'ltr'}
                                value={newPercent}
                                onChange={(e) => {
                                    if (e.target.value)
                                        setNewPercent(Number(e.target.value));
                                    else
                                        setNewPercent(undefined);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Percentage size={16} />
                                        </InputAdornment>
                                    )
                                }}
                            /> : undefined}

                        </Grid>
                        <Grid item xs={4} sm={4}>
                            {(type == 0) ? <TextField
                                type={'number'}
                                label={`העלה לכולם בסכום ${taxMutate ? '(לפני מעמ)' : ''}`}
                                dir={'ltr'}
                                value={byPrice}
                                onChange={(e) => {
                                    if (e.target.value)
                                        setByPrice(Number(e.target.value));
                                    else
                                        setByPrice(undefined);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <CurrencyShekel size={16} />
                                        </InputAdornment>
                                    )
                                }}
                            /> : undefined}

                        </Grid>

                    </Grid>
                    {type == 0 && JSDB().getUser().first.savePriceInNote() ?
                        <Grid container sx={{
                            gap: '10px',
                        }}>
                            {/*<Grid item xs={4} sm={4} sx={{*/}
                            {/*    marginTop: '10px',*/}
                            {/*}}>*/}
                            {/*    <TextField*/}
                            {/*        type={'number'}*/}
                            {/*        label={"מחיר בסיס"}*/}
                            {/*        dir={'ltr'}*/}
                            {/*        value={basePrice}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            if (e.target.value)*/}
                            {/*                setBasePrice(Math.max(0, Number(e.target.value)));*/}
                            {/*            else*/}
                            {/*                setBasePrice(undefined);*/}
                            {/*        }}*/}
                            {/*        InputProps={{*/}
                            {/*            startAdornment: (*/}
                            {/*                <InputAdornment position='start'>*/}
                            {/*                    <CurrencyShekel size={16} />*/}
                            {/*                </InputAdornment>*/}
                            {/*            )*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={baseCheck}
                                        onChange={(e) => {
                                            setBaseCheck(e.target.checked);
                                        }}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="שנה מחיר בסיס"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={alreadyPrice}
                                        onChange={(e) => {
                                            setAlreadyPrice(e.target.checked);
                                        }}
                                        name="checkedalreadyPriceB"
                                        color="primary"
                                    />
                                }
                                label="שנה רק ללקוחות עם מחיר קיים למוצר"
                            />
                        </Grid>
                        : undefined
                    }
                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                        <Tab label="לקוחות" value="customers" />
                        <Tab label="קטגוריות" value="categories" />
                        <Tab label="קווים" value="drivers" />
                    </Tabs>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                    }}>
                        <FormControl variant="standard" sx={{
                            margin: "10px 0",
                            width: "180px",
                        }}>
                            <InputLabel htmlFor="select-products-to-change-dialog-input">
                                חיפוש
                            </InputLabel>
                            <Input
                                id="select-products-to-change-dialog-input"
                                onChange={(event) => {
                                    const value = event.target.value;
                                    switch (activeTab) {
                                        case "customers":
                                            if (value) {
                                                const filteredCustomers = customersArr?.filter((customer) => customer?.name.includes(value));
                                                setCustomersArr(filteredCustomers);
                                            }
                                            break;
                                        case "categories":
                                            if (value) {
                                                const filteredCategories = categories?.filter((category) => category?.includes(value));
                                                setCategories(filteredCategories);
                                            }

                                            break;
                                        case "drivers":
                                            if (value) {
                                                const filteredDrivers = drivers?.filter((driver) => driver?.name.includes(value));
                                                setDrivers(filteredDrivers);
                                            }
                                            break;
                                    }
                                    if (!value || value === "") {
                                        setCustomersArr(Array.from(customers));
                                        const categories = new Set(customers?.map((customer) => customer.category));
                                        setCategories(Array.from(categories).filter((cat) => cat !== ""));
                                        setDrivers(Array.from(agents.filter((agent) => agent.isDriver)));
                                    }
                                }}
                                startAdornment={
                                    <>
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    </>
                                }
                            />
                        </FormControl>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={checkAll} onChange={(event) => {
                                if (event.target.checked) {
                                    setCheckAll(true);
                                    setSelectedCustomers(customersArr?.map((customer) => customer?.id));
                                    setSelectedCategories(categories);
                                    setSelectedDrivers(drivers);
                                } else {
                                    setCheckAll(false);
                                    setSelectedCustomers([]);
                                    setSelectedCategories([]);
                                    setSelectedDrivers([]);
                                }
                            }} />}
                                label={"בחר הכל"}
                            />
                        </FormGroup>
                    </div>
                    <ul style={{
                        listStyleType: "none",
                        maxHeight: "400px",
                        minHeight: "400px",
                        overflowY: "auto",
                    }}>
                        {activeTab === "customers" ? customersArr.map((customer) => (
                            <li key={customer?.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedCustomers?.includes(customer?.id)}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelectedCustomers([...selectedCustomers, customer?.id]);
                                                } else {
                                                    setSelectedCustomers(selectedCustomers.filter((id) => id !== customer?.id));
                                                }
                                            }}
                                        />
                                    }
                                    label={customer?.name}
                                />
                            </li>
                        )) :
                            activeTab === "categories" ? categories.map((category) => (
                                <li key={category}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedCategories?.includes(category)}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        const customersInCategory = customersArr.filter((customer) => customer.category === category);
                                                        setSelectedCustomers([...selectedCustomers, ...customersInCategory.map((customer) => customer.id)]);
                                                        setSelectedCategories([...selectedCategories, category]);
                                                    } else {
                                                        const customersInCategory = customersArr.filter((customer) => customer.category === category);
                                                        setSelectedCustomers(selectedCustomers.filter((id) => !customersInCategory.map((customer) => customer.id).includes(id)));
                                                        setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
                                                    }
                                                }}
                                            />
                                        }
                                        label={category}
                                    />
                                </li>
                            )) :
                                activeTab === "drivers" ? drivers.map((driver) => (
                                    <li key={driver?.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedDrivers?.includes(driver?.id)}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            const driversInCustomer = customersArr.filter((customer) => customer.driver === driver?.name);
                                                            setSelectedCustomers([...selectedCustomers, ...driversInCustomer.map((customer) => customer.id)]);
                                                            setSelectedDrivers([...selectedDrivers, driver?.id]);
                                                        } else {
                                                            const driversInCustomer = customersArr.filter((customer) => customer.driver === driver?.name);
                                                            setSelectedCustomers(selectedCustomers.filter((id) => !driversInCustomer.map((customer) => customer.id).includes(id)));
                                                            setSelectedDrivers(selectedDrivers.filter((id) => id !== driver?.id));
                                                        }
                                                    }}
                                                />
                                            }
                                            label={driver?.name}
                                        />
                                    </li>
                                )) : undefined
                        }
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button disabled={newValue === undefined && newPercent === undefined && byPrice === undefined}
                        onClick={(e) => {
                            onApprove();
                        }} style={{ backgroundColor: 'green', color: 'white' }}>בצע
                        פעולה</Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>ביטול</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
export default memo(AllProductPriceChangesDialog);

import JSDB from '../fromKotlin/nk';

interface Supplier {
    active: boolean;
    id: string;
    name: string;
    phone: string;
    phone2: string;
    // Print State
    withBarcode: boolean;
    withReturns: boolean;
    withPrice: boolean;
    position: number;

    // Address
    city: string;
    street: string;
    streetNumber: string;

    category: string;
    group?: string;
    obligo: number;
    paymentComments?: string;

    // Aux
    includeTax: boolean;

    //
    contactName: string;
    contactName2: string;
    businessId: string;
    businessName: string;
    externalId: string;
    status?: string;
    nkObject: any;
}

// SERIALIZERS
export const serializePrintState = (c: Supplier): number => Number(c.withBarcode) * 1 + Number(c.withReturns) * 2 + Number(c.withPrice) * 4;
export const serializeAddress = (c: Supplier): string =>
    c.city.length === 0 && c.street.length === 0 && c.streetNumber.length === 0
        ? ''
        : `${c.city},${c.street},${c.streetNumber.toString()}`.replace('"', '');
export const serializeTax = (c: Supplier) => Number(c.includeTax);


// DE-SERIALIZERS
export const deserializePrintState = (ps: number): { withBarcode: boolean; withReturns: boolean; withPrice: boolean } => ({
    withBarcode: Boolean(ps & 1),
    withReturns: Boolean(ps & 2),
    withPrice: Boolean(ps & 4)
});


export const deserializeTax = (t: number) => Boolean(t);

export const deserializeSupplier = (c): Supplier => {
    let status: string | undefined = '';
    if (JSDB().hasBeenToday(c)) {
        status = 'חולק';
    } else if (JSDB().hasOrderToday(c)) {
        status = 'הזמנה פתוחה';
    }
    return makeSupplier({
        active: c.active === 1,
        id: c.id,
        name: c.getName(),
        phone: c.getPhone(),
        phone2: c.getSecondPhone(),

        // Print State
        ...deserializePrintState(c.print_state),
        position: c.position,
        // Address
        city: c.getCity(),
        street: c.getStreet(),
        streetNumber: c.getStreetNumber(),

        // Aux
        includeTax: deserializeTax(c.getIncludeTax()),
        //
        contactName: c.getPhoneContact(),
        contactName2: c.getSecondPhoneContact(),
        businessId: c.getBusinessId(),
        businessName: c.getBusinessName(),
        externalId: c.getExternalId(),
        status,
        category: c.category,
        obligo: c.getObligo(),
        group: c.category2,
        paymentComments: c.payment_notes,
        nkObject: c
    });
};


export const makeSupplier = ({
                                 active = false,
                                 id = '',
                                 name = '',
                                 phone = '',
                                 phone2 = '',

                                 // Print State
                                 withBarcode = false,
                                 withReturns = false,
                                 withPrice = false,
                                 position = 0,
                                 // Address
                                 city = '',
                                 street = '',
                                 streetNumber = '',

                                 // Aux
                                 includeTax = false,



                                 //
                                 contactName = '',
                                 contactName2 = '',
                                 businessId = '',
                                 businessName = '',
                                status='',
                                externalId='',
                                category='',
                                obligo=0,
                                paymentComments='',
                                 group='',
                                 nkObject = null
                             }): Supplier => ({
    active,
    id,
    name,
    phone,
    phone2,
    withBarcode,
    withReturns,
    withPrice,
    position,
    city,
    street,
    streetNumber,
    includeTax,
    contactName,
    contactName2,
    businessId,
    businessName,
    status,
    externalId,
    category,
    obligo,
    paymentComments,
    group,
    nkObject
});

export default Supplier;

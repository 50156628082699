import React, {useRef} from 'react';
import {Input, TextField} from '@mui/material';
import {getCustomers} from "../store/customers/customersSlice";
import {getCustomerProduct} from "../store/customersProducts/customersProductsSlice";
import {getAgents} from "../store/agents/agentsSlice";

export const NoNegative = (props) => {
    const quantityInputRef = useRef(null);
    const {changer, value, type, ...others} = props
    const [valueDec, setValueDec] = React.useState(value);

    React.useEffect(() => {
        setValueDec(value)
    }, [value]);
    return <TextField

        type='text'
        value={valueDec}

        onChange={(v) => {


            if (Number(v.target.value) != undefined && !isNaN(Number(v.target.value))) {
                if (v.target.value.charAt(0) == '0' && v.target.value != '0.') {
                    setValueDec(Number(v.target.value))
                } else {
                    setValueDec((v.target.value))
                }
                changer(Number(v.target.value))
            } else if (v.target.value == '') {
                changer(0)
            } else {
                setValueDec(value)
            }
        }}
        onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
                e.preventDefault();
            }
        }
        }
        onWheel={(e) => e.target.blur()}
        ref={quantityInputRef}  {...others}


    />
};
export const WithNegative = (props) => {
    const quantityInputRef = useRef(null);
    const {changer, value, type, ...others} = props
    const [valueDec, setValueDec] = React.useState(value);

    React.useEffect(() => {
        setValueDec(value)
    }, [value]);
    return <TextField

        type='text'
        value={valueDec}

        onChange={(v) => {


            if (Number(v.target.value) != undefined && !isNaN(Number(v.target.value))) {
                if (v.target.value.charAt(0) == '0' && v.target.value != '0.') {
                    setValueDec(Number(v.target.value))
                } else {
                    setValueDec((v.target.value))
                }
                changer(Number(v.target.value))
            } else if (v.target.value.charAt(0) == '-' || v.target.value.endsWith("-")) {
                setValueDec("-")


            } else if (v.target.value == '') {
                changer(0)
            } else {
                setValueDec(value)
            }
        }}
        onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
                e.preventDefault();
            }
        }
        }

        onWheel={(e) => e.target.blur()}
        ref={quantityInputRef}  {...others}


    />
};

export const NoNegativeInput = (props) => {
    const quantityInputRef = useRef(null);
    const {changer, value, type, ...others} = props
    const [valueDec, setValueDec] = React.useState(value);
    React.useEffect(() => {
        setValueDec(value)
    }, [value]);
    return <Input
        type="text"
        value={valueDec}


        onChange={(v) => {

            if (Number(v.target.value) != undefined && !isNaN(Number(v.target.value))) {
                if (v.target.value.charAt(0) == '0' && v.target.value != '0.') {
                    setValueDec(Number(v.target.value))
                } else {
                    setValueDec((v.target.value))
                }
                changer(Number(v.target.value))
            } else if (v.target.value == '') {
                changer(0)
            } else {
                setValueDec(value)
            }
        }}
        onKeyDown={(e) => {
            if (e.keyCode === 38 || e.keyCode === 40) {
                e.preventDefault();
            }
        }
        }
        onWheel={(e) => e.target.blur()}
        ref={quantityInputRef}  {...others}

    />
};

export default NoNegative

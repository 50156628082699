import JSDB, { ApiCall, NKTypeConvertor } from 'fromKotlin/nk';
import { ActiveUpdate } from 'store/utls/utils';
import Product from 'types/product';
import { base64ToArrayBuffer, dateObjectToString } from '../../fromKotlin/nkutils';
import { bool } from 'yup';

export const getSupplierProductAsync = async (attach:boolean) => {
    const req = new ApiCall('GET_SUPPLIER_PRODUCTS');
    req.putParam('fromDate', dateObjectToString(new Date()));
    req.putParam('toDate', dateObjectToString(new Date()));
    if(attach)
    req.putParam('attach', attach);

    return req.execute();
};

function putProducts(p: Product, req: ApiCall, date: string) {
    const no_tax_product = p.isNoTax ? 1 : 0;
    const defaultP = p.default_price;
    const code = p.barcode;
    req.putParam('name', p.name);
    req.putParam('date', date);
    req.putParam('barcode', String(code));
    req.putParam('category', p.category);
    req.putParam('default_price', Number(defaultP));
    req.putParam('external_id', p.external_id);
    req.putParam('no_tax_product', Number(no_tax_product));
    if (p.connected_products.length>0)
        req.putParam('connected_product', Number(p.connected_products[0].id));
}

export const addSupplierProductAsync = async (p: Product) => {
    const req = new ApiCall('NEW_SUPPLIER_PRODUCT');
    const date = JSDB()
        .getDatesManipulator()
        .getNextMonth(JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour()), -1);
    putProducts(p, req, date);
    return req.execute();
};

export const updateSupplierProductAsync = async (p: Product) => {
    const req = new ApiCall('UPDATE_SUPPLIER_PRODUCT');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    putProducts(p, req, date);
    req.putParam('id', p.id);
    return req.execute();
};
export const deleteSupplierProductAsync = async (active: ActiveUpdate) => {
    const req = new ApiCall('UPDATE_SUPPLIER_PRODUCT');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());

    return req.putParam('id', Number(active.id)).putParam('date', date).putParam('active', Number(active.active)).execute();
};


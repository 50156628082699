import {Box} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import 'sweetalert2/src/sweetalert2.scss';
import Profile1 from '../index';
import {memo} from "react";

const CustomerPreview = ({ id }: { id: string }) =>
    <Box id='custom-target'>
        <MainCard>
            <Profile1 upid={id}></Profile1>
        </MainCard>
    </Box>
export default memo(CustomerPreview);

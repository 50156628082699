import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AppRoutePlan from 'views/customer/route-plan/AppRoutePlan';
import MainPage from 'views/main-page';

// customer page routing
const HeshIsrael = Loadable(lazy(() => import('views/auth-israel/index')));
const AppCustomerList = Loadable(lazy(() => import('views/customer/CustomerList')));
const AppSupplierList = Loadable(lazy(() => import('views/suppliers/SupplierList')));
const AllDocumentsTable = Loadable(lazy(() => import('views/customer/all-documents/AllDocuments')));
const AppAllProductsSpread = Loadable(lazy(() => import('views/customer/all-documents/AppDocumentsProductSpread')));
const AppAllPaysDescriptions = Loadable(lazy(() => import('views/customer/all-pay-spread/Table')));
const AppAllCPDATA = Loadable(lazy(() => import('views/customer/all-cp-data/Table')));
const AllOpenOrders = Loadable(lazy(() => import('views/customer/all-open-orders/AllOpenOrders')));
const AllOpenInvoices = Loadable(lazy(() => import('views/customer/all-open-receipts/AllOpenReceipts')));
const AppAllDebts = Loadable(lazy(() => import('views/customer/all-debts/Table')));
const AppSupAllDebts = Loadable(lazy(() => import('views/suppliers/all-debts/Table')));
const AppAllCompare = Loadable(lazy(() => import('views/customer/all-client-compare/Table')));
const AppAllVisit = Loadable(lazy(() => import('views/customer/all-client-visitation/Table')));
const AppAmountsSupTable = Loadable(lazy(() => import('views/suppliers/all-data/Table')));
const AppAmountsTable = Loadable(lazy(() => import('views/customer/all-data/Table')));
const AppAllTaxNotes = Loadable(lazy(() => import('views/customer/all-tax-note-send/Table')));
const AppCustomerProfile = Loadable(lazy(() => import('views/customer/profile')));
const AppAllPricesCustomer = Loadable(lazy(() => import('views/products/prices-list/index')));
const AppAllPricesCost = Loadable(lazy(() => import('views/products/prices-list/speciel_prices')));
const AppCollectorManagement = Loadable(lazy(() => import('cdmanagment/src/main')));
const AppCustomerNewReceipt = Loadable(lazy(() => import('views/customer/profile/documents/receipt/NewReceipt')));
const AppCustomerNewDeliveryCertificate = Loadable(
    lazy(() => import('views/customer/profile/documents/deliveryCertificate/NewDeliveryCertificate'))
);
const AppRouteOpt = Loadable(lazy(() => import('views/customer/route-map/OrderOptimization')));
const AppCustomerNewInvoice = Loadable(lazy(() => import('views/customer/profile/documents/invoice/NewInvoice')));
const AppCustomerNewOrder = Loadable(lazy(() => import('views/customer/profile/documents/orderNote/NewOrder')));
const AppCustomerProductsList = Loadable(lazy(() => import('views/products/customer-products')));
const AppSupplierProductsList = Loadable(lazy(() => import('views/products/supplier-products')));
const AppOrders = Loadable(lazy(() => import('views/orders/ordersNew')));
const AppOrdersOld = Loadable(lazy(() => import('views/ordersold')));
const AccountProfile = Loadable(lazy(() => import('views/user')));
const AppAllCostumerReports = Loadable(lazy(() => import('views/customer/all-reports/allReportsIndex')));
const UsersTable = Loadable(lazy(() => import('views/customer/users/UsersTable')));
const AppSupplierAllDocuments = Loadable(lazy(() => import('views/suppliers/all-documents/SupplierAllDocuments')));
const AppSupplierAllProducts = Loadable(lazy(() => import('views/suppliers/all-documents/AppDocumentsProductSpread')));
const AppInventory = Loadable(lazy(() => import('views/products/inventory/AppInventory')));
const AppTaklit = Loadable(lazy(() => import('views/products/taklit/Table')));
const AppSentMessages = Loadable(lazy(() => import('views/customer/sent-messages/setMessages')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <MainPage />
        },
        {
            path: '/customer/customer-list',
            element: <AppCustomerList />
        },
        {
            path: '/customer/documents/all-documents',
            element: <AllDocumentsTable />
        },
        {
            path: '/customer/sent-messages',
            element: <AppSentMessages />
        },
        {
            path: '/customer/documents/all-products-spread',
            element: <AppAllProductsSpread />
        },
        {
            path: '/customer/documents/reciept',
            element: <AppAllPaysDescriptions />
        },
        {
            path: '/routes-plan',
            element: <AppRouteOpt />
        },
        {
            path: '/customer/cp-data',
            element: <AppAllCPDATA />
        },
        {
            path: '/customer/documents/open-orders',
            element: <AllOpenOrders />
        },
        {
            path: '/customer/documents/open-invoices',
            element: <AllOpenInvoices />
        },
        {
            path: '/reports/route-plan',
            element: <AppRoutePlan />
        },
        {
            path: '/reports/all-compare',
            element: <AppAllCompare />
        },
        {
            path: '/reports/all-reports',
            element: <AppAllCostumerReports />
        },
        {
            path: '/reports/all-visitation',
            element: <AppAllVisit />
        },
        {
            path: '/customer/all-debt',
            element: <AppAllDebts />
        },
        {
            path: '/reports/all-amount',
            element: <AppAmountsTable />
        },
        {
            path: '/supplier/all-amount',
            element: <AppAmountsSupTable />
        },
        {
            path: '/customer/all-tax-note',
            element: <AppAllTaxNotes />
        },
        {
            path: '/customer/users',
            element: <UsersTable />
        },
        {
            path: '/customer/:id',
            element: <AppCustomerProfile />
        },
        {
            path: '/products/:id',
            element: <AppAllPricesCustomer />
        },
        {
            path: '/products/cost',
            element: <AppAllPricesCost />
        },
        {
            path: '/customer/:id/documents/createReceipt',
            element: <AppCustomerNewReceipt />
        },
        {
            path: '/customer/:id/documents/createOrder',
            element: <AppCustomerNewOrder />
        },
        {
            path: '/customer/:id/documents/createDeliveryCertificate',
            element: <AppCustomerNewDeliveryCertificate />
        },
        {
            path: '/customer/:id/documents/createInvoice',
            element: <AppCustomerNewInvoice />
        },
        {
            path: '/customer/:id/documents/createRefund',
            element: <AppCustomerNewInvoice />
        },
        {
            path: '/products/customer-products',
            element: <AppCustomerProductsList />
        },
        {
            path: '/products/supplier-products',
            element: <AppSupplierProductsList />
        },
        {
            path: '/orders',
            element: <AppOrdersOld />
        },
        {
            path: '/orders-new',
            element: <AppOrders />
        },
        {
            path: '/account-profile',
            element: <AccountProfile />
        },
        {
            path: '/reports/tax-israel',
            element: <HeshIsrael />
        },
        {
            path: '/supplier/supplier-list',
            element: <AppSupplierList />
        },
        {
            path: '/supplier/all-documents',
            element: <AppSupplierAllDocuments />
        },
        {
            path: '/supplier/all-products-spread',
            element: <AppSupplierAllProducts />
        },
        {
            path: '/supplier/all-debt',
            element: <AppSupAllDebts />
        },
        {
            path: '/products/inventory',
            element: <AppInventory />
        },
        {
            path: '/products/taklit',
            element: <AppTaklit />
        }
    ]
};

export default MainRoutes;

import { Badge, Checkbox, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Input, InputAdornment, InputLabel, Tab, Tabs, Tooltip } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Person } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import {
    useUpdateAllCustomerAvailable,
    useUpdateAllCustomerProductPrice,
    useUpdateAllSupplierProductPrice
} from '../../hooks/customer/products.hooks';
import { dateObjectToString } from '../../fromKotlin/nkutils';
import { CurrencyShekel, Percentage, Search } from 'tabler-icons-react';
import { memo } from "react";
import JSDB from "../../fromKotlin/nk";
import { selectCustomersObjs } from 'store/customers/customersSlice';
import { allAgents } from 'store/agents/agentsSlice';
import {useDispatch, useSelector} from 'react-redux';
import Agent from 'types/agent';

function AllProductAvailableChangesDialog({
    data,
    type,
    icon,
    tooltip,
    taxMutate,
    special,
    supplier = false,
}: { data: any, type, icon, tooltip, taxMutate?: boolean, supplier?: boolean, special?: boolean }) {
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [hint, setHint] = React.useState('');
    const [size, setSize] = React.useState(0);
    const [available, setAvailable] = React.useState(false);

    const [activeTab, setActiveTab] = React.useState<"customers" | "categories" | "drivers">("customers");
    const [customersArr, setCustomersArr] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);
    const [drivers, setDrivers] = React.useState<any[]>([]);
    const [checkAll, setCheckAll] = React.useState(false);
    const [selectedCustomers, setSelectedCustomers] = React.useState<any[]>([]);
    const [selectedCategories, setSelectedCategories] = React.useState<any[]>([]);
    const [selectedDrivers, setSelectedDrivers] = React.useState<any[]>([]);

    const customers = useSelector(state => selectCustomersObjs(state, true));
    const agents: Agent[] = useSelector(state => allAgents(state, true));

    React.useEffect(() => {
        const categories = new Set(customers?.map((customer) => customer.category));
        const drivers = agents.filter((agent) => agent.isDriver);
        setCustomersArr(customers);
        setCategories(Array.from(categories).filter((cat) => cat !== ""));
        setDrivers(drivers);
    }, [customers, agents]);


    let updateCustomerProduct;
    if (selectedCustomers.length) {
        updateCustomerProduct = useUpdateAllCustomerAvailable(selectedCustomers, data.selected,data.extractor );
    } else {
        updateCustomerProduct = useUpdateAllCustomerAvailable(data.ids, data.selected,data.extractor);
    }
    const handleAvailableMutation = (avail) => updateCustomerProduct.mutate({
       available: avail,
    });
    React.useEffect(() => {
        setSize(data.selected.length);
        setAvailable(false);

        setSelectedCategories([])
        setSelectedCustomers([])
        setSelectedDrivers([])


        setHint(data.selected.map((p) => p.name).join(','));
        setTitle('שינוי זמינות גורף');
    }, [open, data, type]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onApprove =
        (avail) => {
            handleAvailableMutation(avail)

            handleClose();
        };

    return (
        <div>
            <Tooltip title={tooltip}>
                <IconButton size='small' onClick={handleClickOpen}>
                    {icon}
                </IconButton>
            </Tooltip>
            <Dialog id='helper_dialog'
                sx={{
                    '& .MuiDialog-paper': {
                        padding: "10px"
                    }
                }}
                open={open} onClose={handleClose}>
                <DialogTitle> {title}
                    <Tooltip title={hint}>
                        <Badge color='secondary' max={2000} badgeContent={size}>
                            <IconButton size='medium'>
                                <Person />
                            </IconButton>
                        </Badge>
                    </Tooltip>

                </DialogTitle>
                <DialogContent style={{
                    padding: '10px 5px'
                }}>

                    <Grid container>

                    </Grid>

                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                        <Tab label="לקוחות" value="customers" />
                        <Tab label="קטגוריות" value="categories" />
                        <Tab label="קווים" value="drivers" />
                    </Tabs>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                    }}>
                        <FormControl variant="standard" sx={{
                            margin: "10px 0",
                            width: "180px",
                        }}>
                            <InputLabel htmlFor="select-products-to-change-dialog-input">
                                חיפוש
                            </InputLabel>
                            <Input
                                id="select-products-to-change-dialog-input"
                                onChange={(event) => {
                                    const value = event.target.value;
                                    switch (activeTab) {
                                        case "customers":
                                            if (value) {
                                                const filteredCustomers = customersArr?.filter((customer) => customer?.name.includes(value));
                                                setCustomersArr(filteredCustomers);
                                            }
                                            break;
                                        case "categories":
                                            if (value) {
                                                const filteredCategories = categories?.filter((category) => category?.includes(value));
                                                setCategories(filteredCategories);
                                            }

                                            break;
                                        case "drivers":
                                            if (value) {
                                                const filteredDrivers = drivers?.filter((driver) => driver?.name.includes(value));
                                                setDrivers(filteredDrivers);
                                            }
                                            break;
                                    }
                                    if (!value || value === "") {
                                        setCustomersArr(Array.from(customers));
                                        const categories = new Set(customers?.map((customer) => customer.category));
                                        setCategories(Array.from(categories).filter((cat) => cat !== ""));
                                        setDrivers(Array.from(agents.filter((agent) => agent.isDriver)));
                                    }
                                }}
                                startAdornment={
                                    <>
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    </>
                                }
                            />
                        </FormControl>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={checkAll} onChange={(event) => {
                                if (event.target.checked) {
                                    setCheckAll(true);
                                    setSelectedCustomers(customersArr?.map((customer) => customer?.id));
                                    setSelectedCategories(categories);
                                    setSelectedDrivers(drivers);
                                } else {
                                    setCheckAll(false);
                                    setSelectedCustomers([]);
                                    setSelectedCategories([]);
                                    setSelectedDrivers([]);
                                }
                            }} />}
                                label={"בחר הכל"}
                            />
                        </FormGroup>
                    </div>
                    <ul style={{
                        listStyleType: "none",
                        maxHeight: "400px",
                        minHeight: "400px",
                        overflowY: "auto",
                    }}>
                        {activeTab === "customers" ? customersArr.map((customer) => (
                            <li key={customer?.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedCustomers?.includes(customer?.id)}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelectedCustomers([...selectedCustomers, customer?.id]);
                                                } else {
                                                    setSelectedCustomers(selectedCustomers.filter((id) => id !== customer?.id));
                                                }
                                            }}
                                        />
                                    }
                                    label={customer?.name}
                                />
                            </li>
                        )) :
                            activeTab === "categories" ? categories.map((category) => (
                                <li key={category}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedCategories?.includes(category)}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        const customersInCategory = customersArr.filter((customer) => customer.category === category);
                                                        setSelectedCustomers([...selectedCustomers, ...customersInCategory.map((customer) => customer.id)]);
                                                        setSelectedCategories([...selectedCategories, category]);
                                                    } else {
                                                        const customersInCategory = customersArr.filter((customer) => customer.category === category);
                                                        setSelectedCustomers(selectedCustomers.filter((id) => !customersInCategory.map((customer) => customer.id).includes(id)));
                                                        setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
                                                    }
                                                }}
                                            />
                                        }
                                        label={category}
                                    />
                                </li>
                            )) :
                                activeTab === "drivers" ? drivers.map((driver) => (
                                    <li key={driver?.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedDrivers?.includes(driver?.id)}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            const driversInCustomer = customersArr.filter((customer) => customer.driver === driver?.name);
                                                            setSelectedCustomers([...selectedCustomers, ...driversInCustomer.map((customer) => customer.id)]);
                                                            setSelectedDrivers([...selectedDrivers, driver?.id]);
                                                        } else {
                                                            const driversInCustomer = customersArr.filter((customer) => customer.driver === driver?.name);
                                                            setSelectedCustomers(selectedCustomers.filter((id) => !driversInCustomer.map((customer) => customer.id).includes(id)));
                                                            setSelectedDrivers(selectedDrivers.filter((id) => id !== driver?.id));
                                                        }
                                                    }}
                                                />
                                            }
                                            label={driver?.name}
                                        />
                                    </li>
                                )) : undefined
                        }
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(e) => {
                            onApprove(true);
                        }} style={{ backgroundColor: 'green', color: 'white' }}>זמין
                        </Button>
                    <Button
                            onClick={(e) => {
                                onApprove(false);
                            }} style={{ backgroundColor: 'purple', color: 'white' }}>לא זמין
                        </Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>ביטול פעולה</Button>
                </DialogActions>

            </Dialog>
        </div >
    );
}
export default memo(AllProductAvailableChangesDialog);

import IItem from 'types/product_delivery';
import { addHours, parseJSON } from 'date-fns';
import value from 'assets/scss/_themes-vars.module.scss';

export interface Check {
    id?: number;
    bank?: number;
    branch?: number;
    account?: number;
    dueDate?: Date;
}

export type PaymentMethod = 'cash' | 'credit' | 'check' | 'deposit' | 'bit' | 'withholding_tax' | 'barter';
export const paymentMethods = {
    cash: { name: 'מזומן' },
    credit: { name: 'אשראי' },
    check: { name: "צ'ק" },
    deposit: { name: 'העברה בנקאית' },
    bit: { name: 'ביט' },
    withholding_tax: { name: 'ניכוי מס במקור' },
    barter: { name: 'ברטר' }
};

export interface Payment {
    id: string;
    amount: number;
    method: PaymentMethod;
    check?: Check;
}

export type DocumentType = 'receipt' | 'invoice' | 'tax_invoice' | 'order' | 'delivery_certificate' | 'refund';
export type DocumentStatus = 'open' | 'approved' | 'pending_approval';
export const documentStatuses = { open: 'פתוח', approved: 'אושר', pending_approval: 'ממתין לאישור' };

// Document is a flatten interface represents any type of customer document, E.g. - receipt, tax invoice, delivery certificate, etc...
interface Document {
    id: number;
    customerId: string;
    // date: string; // yyyy-mm-dd
    start: Date;
    end: Date;
    type: DocumentType;
    color: string;
    textColor: string;
    baseColor: string;
    description: string;
    title: string;
    name: string;
    allDay: boolean;
    status: DocumentStatus;
    payments: Payment[];
    receiptsIds: string[];
    deliveryCertificatesIds: string[];
    products: IItem[];
    nkObject: any;
}

export const createDocument = ({
    id = 0,
    customerId = '',
    start = new Date(),
    type = 'invoice',
    allDay = false,
    status = 'pending_approval',
    payments = [],
    receiptsIds = [],
    deliveryCertificatesIds = [],
    products = [],
    nkObject = null
}: {
    id?: number;
    customerId?: string;
    start?: Date;
    type?: DocumentType;
    allDay?: boolean;
    status?: DocumentStatus;
    payments?: Payment[];
    receiptsIds?: string[];
    deliveryCertificatesIds?: string[];
    products?: IItem[];
    nkObject?: any;
}): Document => ({
    id,
    customerId,
    // TODO: change to date, move start & end to enrichment
    start: parseJSON(start),
    end: start ? addHours(parseJSON(start), 1) : new Date(),
    type,
    status,
    payments,
    receiptsIds,
    deliveryCertificatesIds,
    products,
    nkObject,

    // TODO: Create EnrichedDocument interface extending Document, with the below enrichments. It will be used in calendar.
    allDay,
    title: `${documentTypes[type].name} ${id.toString().substring(0, 4)}`,
    name: documentTypes[type].name,
    description: documentTypes[type].name,
    color: documentTypes[type].color,
    textColor: documentTypes[type].textColor,
    baseColor: documentTypes[type].baseColor
});

export const documentTypes = {
    invoice: { name: 'חשבונית מס', color: value.primaryLight, textColor: value.primary800, baseColor: 'error' },
    tax_invoice: { name: 'חשבונית מס קבלה', color: value.secondaryLight, textColor: value.secondaryDark, baseColor: 'secondary' },
    refund: { name: 'חשבונית זיכוי', textColor: value.grey900, color: value.warningMain, baseColor: 'warning' },
    delivery_certificate: { name: 'תעודת משלוח', color: value.successLight, textColor: value.successDark, baseColor: 'success' },
    order: { name: 'הזמנה', color: value.orangeLight, textColor: value.orangeDark, baseColor: 'orange' },
    receipt: { name: 'קבלה', color: value.grey200, textColor: value.grey600, baseColor: 'gray' }
};

export const typeStr = (doc): string => documentTypes[doc.type].name;
export default Document;

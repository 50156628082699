import React, {memo} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getSpecificCustomer, selectCustomerById} from 'store/customers/customersSlice';
import {RootState} from 'store';
import {FormattedMessage} from 'react-intl';
import FaceIcon from '@mui/icons-material/Face';
import DoneIcon from '@mui/icons-material/Done';
import {useTheme} from '@mui/material/styles';
import {Box, Grid, Tab, Tabs, Typography} from '@mui/material';
import Loader from 'ui-component/Loader';
import Profile from './Profile';
import Calendar from './documents';
import PricesList from './prices-list';
import MainCard from 'ui-component/cards/MainCard';
import {gridSpacing} from 'store/constant';
import {
    IconCurrencyShekel,
    IconFilePlus,
    IconLicense,
    IconNote,
    IconReceipt,
    IconReceiptRefund,
    IconReceiptTax,
    IconWallet
} from '@tabler/icons';

// assets
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CartesetList from './debts';
import MyChip from '../../../ui-component/extended/Chip';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import NewDeliveryCertificate from './documents/deliveryCertificate/NewDeliveryCertificate';
import NewInvoice from './documents/invoice/NewInvoice';
import NewReceipt from './documents/receipt/NewReceipt';

// tabs panel
function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}
const tabsOption = [
    {
        label: <FormattedMessage id='profile' />,
        icon: <AccountCircleOutlinedIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: <FormattedMessage id='documents' />,
        icon: <DescriptionOutlinedIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: <FormattedMessage id='prices' />,
        icon: <IconCurrencyShekel size={24} strokeWidth={1} color='black' />
    },
    {
        label: <FormattedMessage id='debts' />,
        icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    },
    {
        label: 'תעודה חדשה',
        icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    },
    {
        label: 'חשבונית חדשה',
        icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    },
    {
        label: 'חשבונית זיכוי חדשה',
        icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    },
    {
        label: 'קבלה חדשה',
        icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    },
    // {
    //     label: 'חשבונית מס/קבלה חדשה',
    //     icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    // },
    {
        label: 'הזמנה',
        icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    },
    {
        label: 'חשבונית מס קבלה',
        icon: <IconWallet size={24} strokeWidth={1} color='#000000' />
    }
];
// ==============================|| PROFILE 1 ||============================== //
const Profile1 = ({ upid }: { upid: string }) => {
    const theme = useTheme();
    const { id } = useParams();
    const dispatch = useDispatch();
    // TODO: Change to single customer fetching
    const customer = useSelector((state: RootState) => selectCustomerById(state, upid ?? id));
    React.useEffect(() => {
        dispatch(getSpecificCustomer(Number(id ?? upid)));
    }, [dispatch]);
    const [value, setValue] = React.useState<number>(0);
    const actions = [
        { icon: <IconReceiptTax />, name: 'חשבונית מס', link: (i) => `/customer/${i}/documents/createInvoice`,value:5 },
        // { icon: <IconReceipt2 />, name: 'חשבונית מס קבלה', link: (i) => `/customer/${i}/documents/createReceipt` ,value:8},
        { icon: <IconReceiptRefund />, name: 'חשבונית זיכוי', link: (i) => `/customer/${i}/documents/createRefund`,value:6 },
        {
            icon: <IconLicense />,
            name: 'תעודת משלוח',
            link: (i) => `/customer/${i}/documents/createDeliveryCertificate`
            , value: 4
        },
        { icon: <IconNote />, name: 'הזמנה', link: (i) => `/customer/${i}/documents/createOrder`, value: 8 },
        { icon: <IconReceipt />, name: 'קבלה', link: (i) => `/customer/${i}/documents/createReceipt`, value: 7 }
    ];
    if (!customer) return <Loader />;

    return (
        <MainCard>
            <Grid container spacing={2}>

                <Grid item xs={6} style={{ alignItems: 'center' }}>
                    <MyChip style={{ fontSize: '20px', fontWeight: 'bold' }} label={`${customer.name} - ${customer.id}`} color='secondary'
                        size='medium' deleteIcon={<DoneIcon />} icon={<FaceIcon />} />
                </Grid>
                <Grid item xs={1}>

                    <Box />

                </Grid>
            </Grid>
            <SpeedDial
                ariaLabel='Document actions'
                sx={{
                    bottom: '2%',
                    position: 'fixed',
                    right: '2%',
                    zIndex: 10
                }}
                icon={<IconFilePlus />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={
                            <Typography sx={{ color: 'black', fontWeight: 'bold' }} variant='subtitle2'>
                                {action.name}
                            </Typography>
                        }
                        tooltipOpen
                    />
                ))}
            </SpeedDial>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor='primary'
                        textColor='primary'
                        onClick={(e) => {
                            let a = e.target
                            if ((e.target as HTMLElement).tagName == 'svg') {
                                a = (e.target as HTMLElement).parentElement
                            }
                            const v = Number((a.id.toString()).match("[^0-9]*([0-9]*)")[1]) ?? 0
                            setValue(-1)
                            setTimeout(() => setValue(v), 50)
                        }}
                        aria-label='simple tabs example'
                        variant='scrollable'
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                        {tabsOption.map((tab, index) => (
                            <Tab key={index} component={Link} to='#' icon={tab.icon}
                                label={tab.label} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Profile customer={customer} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Calendar cid={customer?.id ?? '0'} name={customer?.name ?? ''} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <PricesList cid={customer?.id ?? '0'} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <CartesetList cid={customer?.id ?? '0'} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <NewDeliveryCertificate upid={customer?.id ?? '0'} dtype={'delivery_certificate'} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <NewInvoice upid={customer?.id ?? '0'} dtype={'invoice'} />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <NewInvoice upid={customer?.id ?? '0'} dtype={'refund'} />
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                        <NewReceipt upid={customer?.id ?? '0'} />
                    </TabPanel>
                    {/*<TabPanel value={value} index={8}>*/}

                    {/*    <NewInvoice upid={customer?.id ?? '0'} dtype={'tax_invoice'} />*/}
                    {/*</TabPanel>*/}
                    <TabPanel value={value} index={8}>
                        <NewDeliveryCertificate upid={customer?.id ?? '0'} dtype={'order'} />
                    </TabPanel>
                    <TabPanel value={value} index={9}>
                        <NewInvoice upid={customer?.id ?? '0'} dtype={'tax_invoice'} />
                    </TabPanel>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default memo(Profile1);

import {createSlice, createSelector, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import {
    getClientProductAsync,
    addClientProductAsync,
    updateClientProductAsync,
    deleteCustomerProductAsync, updateMassClientProductAsync, changeAllProducts, fillProductsImp
} from 'api/products/customers.api';
import {RootState} from 'store';
import {AsyncStatus} from 'store/types/asyncState';
import {ArrangementOrder, Filter} from 'types';
import Product, {deserializeProduct} from 'types/product';
import stableSort, {applyFilters, getComparator, distinct} from 'utils/sort';
import JSDB from '../../fromKotlin/nk';
import {changeAllCustomers} from "../../api/customer";

// THUNKS
export const getCustomerProduct = createAsyncThunk('customersProducts/getCustomersProducts', getClientProductAsync);
export const fillProducts = createAsyncThunk('customersProducts/getFillProducts', fillProductsImp);
export const addCustomerProduct = createAsyncThunk('customersProducts/addCustomersProduct', addClientProductAsync);
export const updateCustomerProduct = createAsyncThunk('customersProducts/updateCustomersProduct', updateClientProductAsync);
export const updateMassCustomerProduct = createAsyncThunk('customersProducts/updateMassCustomersProduct', updateMassClientProductAsync);
export const deleteCustomerProduct = createAsyncThunk('customersProducts/deleteCustomersProduct', deleteCustomerProductAsync);
export const updateAllProduct = createAsyncThunk('customers/updateAllCustomer', changeAllProducts);
export const removeAllFilters = createAsyncThunk('customers/removeFilters', async () => {
    return true
});

const customersProductsAdapter = createEntityAdapter<Product>();

const initialState: {
    getCustomersProductsAsyncState: AsyncStatus;
    addCustomersProductAsyncState: AsyncStatus;
    updateCustomersProductAsyncState: AsyncStatus;
    deleteCustomersProductAsyncState: AsyncStatus;
    massCustomersProductAsyncState: AsyncStatus;
    massCustomersAllProductAsyncState: AsyncStatus;
    fillProductsAsyncState: AsyncStatus;
    sortBy: { orderBy: string; order: ArrangementOrder };
    filterBy: Filter[];
} = {
    getCustomersProductsAsyncState: 'idle',
    addCustomersProductAsyncState: 'idle',
    fillProductsAsyncState: 'idle',
    updateCustomersProductAsyncState: 'idle',
    deleteCustomersProductAsyncState: 'idle',
    massCustomersProductAsyncState: 'idle',
    massCustomersAllProductAsyncState: 'idle',
    sortBy: {orderBy: 'position', order: 'asc'},
    filterBy: []
};

export const customersProductsSlice = createSlice({
    name: 'customersProducts',
    initialState: customersProductsAdapter.getInitialState({
        ...initialState
    }),
    reducers: {
        initGetCustomersProductsAsyncState: (state) => {
            state.getCustomersProductsAsyncState = 'idle';
        },
        initAddCustomersProductAsyncState: (state) => {
            state.addCustomersProductAsyncState = 'idle';
        },
        initUpdateCustomersProductAsyncState: (state) => {
            state.updateCustomersProductAsyncState = 'idle';
        },
        initDeleteCustomersProductAsyncState: (state) => {
            state.deleteCustomersProductAsyncState = 'idle';
        },
        initMassCustomersProductAsyncState: (state) => {
            state.massCustomersProductAsyncState = 'idle';
        },
        applyCustomersProductsSort: (state, action) => {
            state.sortBy = action.payload;
        },
        applyCustomersProductsFilter: (state, action) => {
            state.filterBy = state.filterBy.reduce((acc, f: Filter) => (f.property === action.payload.property ? acc : acc.concat(f)), [
                action.payload
            ]);
        },
        removeCustomersProductsFilter: (state, action) => {
            state.filterBy = state.filterBy.filter((f) => f.property !== action.payload.property);
        }
    },
    extraReducers: (builder) => {
        builder
            /* GET_CUSTOMERS_PRODUCTS STATE */
            .addCase(getCustomerProduct.pending, (state) => {
                if (state.getCustomersProductsAsyncState === 'idle') {
                    state.getCustomersProductsAsyncState = 'loading';
                }
            })
            .addCase(getCustomerProduct.fulfilled, (state, action) => {
                if (state.getCustomersProductsAsyncState === 'loading') {
                    state.getCustomersProductsAsyncState = 'succeeded';
                    if (action.payload.first.size > 0)
                        customersProductsAdapter.setAll(
                            state,
                            action.payload.first.toArray().map((p) => deserializeProduct(p))
                        );
                } else if (state.getCustomersProductsAsyncState === 'succeeded') {
                    if (action.payload.first.size > 0)
                        customersProductsAdapter.setAll(
                            state,
                            action.payload.first.toArray().map((p) => deserializeProduct(p))
                        );
                }
            })
            .addCase(getCustomerProduct.rejected, (state, action) => {
                if (state.getCustomersProductsAsyncState === 'loading') {
                    state.getCustomersProductsAsyncState = 'failed';
                }
            })
            .addCase(fillProducts.pending, (state) => {
                if (state.fillProductsAsyncState === 'idle') {
                    state.fillProductsAsyncState = 'loading';
                }
            })
            .addCase(fillProducts.fulfilled, (state, action) => {
                const products = action.payload.first;
                if (products.size > 0)
                    products.toArray().forEach((product) => {
                        state.entities[product.id] = deserializeProduct(product);
                    });
            })
            .addCase(fillProducts.rejected, (state, action) => {
                if (state.fillProductsAsyncState === 'loading') {
                    state.fillProductsAsyncState = 'failed';
                }
            })
            /* ADD_CUSTOMER_PRODUCT STATE */
            .addCase(addCustomerProduct.pending, (state) => {
                if (state.addCustomersProductAsyncState === 'idle') {
                    state.addCustomersProductAsyncState = 'loading';
                }
            })
            .addCase(addCustomerProduct.fulfilled, (state, action) => {
                if (state.addCustomersProductAsyncState === 'loading') {
                    state.addCustomersProductAsyncState = 'succeeded';
                    customersProductsAdapter.addOne(state, deserializeProduct(action.payload.first));
                }
            })
            .addCase(addCustomerProduct.rejected, (state, action) => {
                if (state.addCustomersProductAsyncState === 'loading') {
                    state.addCustomersProductAsyncState = 'failed';
                }
            })
            /* UPDATE_CUSTOMER_PRODUCT STATE */
            .addCase(updateCustomerProduct.pending, (state) => {
                state.updateCustomersProductAsyncState = 'loading';
            })
            .addCase(updateCustomerProduct.fulfilled, (state, action) => {
                state.updateCustomersProductAsyncState = 'succeeded';
                const product = action.payload.first;
                state.entities[product.id] = deserializeProduct(product);
            })
            .addCase(updateCustomerProduct.rejected, (state, action) => {
                state.updateCustomersProductAsyncState = 'failed';
            })
            /* DELETE_CUSTOMER_PRODUCT STATE */
            .addCase(deleteCustomerProduct.pending, (state) => {
                state.deleteCustomersProductAsyncState = 'loading';
            })
            .addCase(deleteCustomerProduct.fulfilled, (state, action) => {
                state.deleteCustomersProductAsyncState = 'succeeded';
                const product = action.payload.first;
                state.entities[product.id] = deserializeProduct(product);
            })
            .addCase(deleteCustomerProduct.rejected, (state, action) => {

                state.deleteCustomersProductAsyncState = 'failed';

            })
            .addCase(updateMassCustomerProduct.pending, (state) => {
                if (state.massCustomersProductAsyncState === 'idle') {
                    state.massCustomersProductAsyncState = 'loading';
                }
            })
            .addCase(updateMassCustomerProduct.fulfilled, (state, action) => {

                if (state.massCustomersProductAsyncState === 'loading') {
                    state.massCustomersProductAsyncState = 'succeeded';
                    const products = action.payload.first;
                    products.toArray().forEach((product) => {
                        state.entities[product.id] = deserializeProduct(product);
                    });
                }
            })
            .addCase(updateMassCustomerProduct.rejected, (state, action) => {
                if (state.massCustomersProductAsyncState === 'loading') {
                    state.massCustomersProductAsyncState = 'failed';
                }
            })
            .addCase(updateAllProduct.pending, (state) => {
                if (state.massCustomersAllProductAsyncState === 'idle') {
                    state.massCustomersAllProductAsyncState = 'loading';
                }
            })
            .addCase(updateAllProduct.fulfilled, (state, action) => {

                if (state.massCustomersAllProductAsyncState === 'loading') {
                    state.massCustomersAllProductAsyncState = 'succeeded';
                    const products = action.payload.first;
                    products.toArray().forEach((product) => {
                        state.entities[product.id] = deserializeProduct(product);
                    });
                }
            })
            .addCase(updateAllProduct.rejected, (state, action) => {
                if (state.massCustomersAllProductAsyncState === 'loading') {
                    state.massCustomersAllProductAsyncState = 'failed';
                }
            })

            .addCase(removeAllFilters.fulfilled, (state, action) => {
                state.filterBy = []
            })
        ;
    }
});

// actions
export const {
    initGetCustomersProductsAsyncState,
    initAddCustomersProductAsyncState,
    initMassCustomersProductAsyncState,
    initUpdateCustomersProductAsyncState,
    initDeleteCustomersProductAsyncState,
    applyCustomersProductsSort,
    applyCustomersProductsFilter,
    removeCustomersProductsFilter
} = customersProductsSlice.actions;

// Selectors
export const selectGetCustomersProductsAsyncState = (state: RootState) => state.customersProducts.getCustomersProductsAsyncState;
export const selectAddCustomersProductAsyncState = (state: RootState) => state.customersProducts.addCustomersProductAsyncState;
export const selectUpdateCustomersProductAsyncState = (state: RootState) => state.customersProducts.updateCustomersProductAsyncState;
export const selectUpdateCustomersAllProductAsyncState = (state: RootState) => state.customersProducts.massCustomersProductAsyncState;
export const selectDeleteCustomersProductAsyncState = (state: RootState) => state.customersProducts.deleteCustomersProductAsyncState;
export const selectCustomersProductsSortBy = (state: RootState) => state.customersProducts.sortBy;
export const selectCustomersProductsFilterBy = (state: RootState) => state.customersProducts.filterBy;

export const {
    selectAll: selectCustomersProducts,
    selectById: selectCustomerProductById
} = customersProductsAdapter.getSelectors(
    (state: RootState) => state.customersProducts
);
export const selectActiveProducts = createSelector(selectCustomersProducts, (products) => products.filter((p) => p.active));
const applyCustomersSort = (customers, sortBy) => stableSort(customers, getComparator(sortBy.order, sortBy.orderBy));

// Memoized Selectors
export const selectCustomersProductsIds = createSelector(selectActiveProducts, (products) => products.map((p) => p.id));
export const selectCustomersProductsNames = createSelector(selectActiveProducts, (products) => products.map((p) => p.name));
export const selectCustomersProductsWithSort = createSelector(
    selectActiveProducts,
    selectCustomersProductsSortBy,
    (customers, sortBy) => applyCustomersSort(customers, {orderBy: 'position', order: 'asc'})
);
export const selectCustomersOrderProductsWithSort = createSelector(
    selectActiveProducts,
    selectCustomersProductsSortBy,
    (customers, sortBy) => applyCustomersSort(customers.filter((f) => f.available), {orderBy: 'position', order: 'asc'})
);
export const selectCustomersProductsIdsWithSort = createSelector(
    selectActiveProducts,
    selectCustomersProductsSortBy,
    (customers, sortBy) => applyCustomersSort(customers, sortBy).map((c) => c.id)
);
export const selectCustomersProductsIdsWithFilter = createSelector(
    selectActiveProducts,
    selectCustomersProductsFilterBy,
    (customers, filterBy) => applyFilters(customers, filterBy).map((c) => c.id)
);
export const selectCustomersProductsIdsWithFilterSort = createSelector(
    selectActiveProducts,
    selectCustomersProductsFilterBy,
    selectCustomersProductsSortBy,
    (products, filterBy, sortBy) => applyCustomersSort(applyFilters(products, filterBy), sortBy).map((c) => c.id)
);
export const selectCustomersProductsObjList = createSelector(
    selectCustomersProducts,
    selectCustomersProductsFilterBy,
    selectCustomersProductsSortBy,
    (_, booleanParam) => booleanParam,
    (products, filterBy, sortBy, booleanParam) => {
        return applyCustomersSort(applyFilters(products.filter((s) => (booleanParam && s.active) || (!booleanParam && !s.active)), filterBy), sortBy)
    }
);

// Derived selectors
export const selectCustomersProductsDistinctValues = createSelector(selectCustomersProducts, distinct);


export const categories = (data: Product[]) => {
    const s = new Set(data.map(item => item.category));
    s.delete('');
    return [...s];
};
export const categories2 = (data: Product[]) => {
    const s = new Set(data.map(item => item.category2));
    s.delete('');
    return [...s];
};
export const units = () => {
    const u = JSDB().getUnits();
    if (u.size > 0)
        return u.toArray();
    return [];
};


export const selectCustomersProductsDistinctCategoryValues = createSelector(selectCustomersProducts, categories);
export const selectCustomersProductsDistinctCategory2Values = createSelector(selectCustomersProducts, categories2);
export const selectCustomersProductsUnits = createSelector(units);

export const allCategories = createSelector(selectCustomersProducts, categories);
export const allCategories2 = createSelector(selectCustomersProducts, categories2);
export const allUnits = createSelector(selectCustomersProducts, units);

export default customersProductsSlice.reducer;

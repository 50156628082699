import React, { memo } from 'react';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DataSheetGridContext } from '../index2';
import { IconDeviceFloppy } from '@tabler/icons';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const LimitedActionCell = React.memo(({
    rowData,
}: any
) => {
    const {
        saveOrder,
        updatedRowIds,
        updatedDriverRowIds,
        dvs_flag,
        setAll,
        openDrawer,
        setOpenDrawer,
        setDrawerSingleData,
    } = React.useContext(DataSheetGridContext);

    const [loading, setLoading] = React.useState<boolean>(undefined);
    React.useEffect(() => {
        if (loading != undefined && !loading) {
            saveOrder([rowData?.id])
            setLoading(undefined);
        }
    }, [loading]);
    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row-reverse',
            alignItems: 'center'
        }}>
            <Tooltip title='שמור'>
                <span>
                    {loading ? <CircularProgress size={16} /> :
                        <IconButton size='small' disabled={dvs_flag || rowData?.readonly || (!updatedRowIds.has(rowData?.id) && !updatedDriverRowIds.has(rowData?.id))}
                                    style={{ color: 'blue', cursor: 'pointer' }}
                                    onClick={(e) => {
                                        setLoading(true)
                                        setTimeout(() => {
                                            setLoading(false)



                                        }, 1000);
                                    }}
                        >
                            <IconDeviceFloppy style={{ cursor: 'pointer !important' }} />
                        </IconButton>
                    }
                </span>
            </Tooltip>
            <Tooltip title='פתח חלון הזמנה'>
                <IconButton style={{ color: 'green' }} size='small' onClick={(e) => {
                    setAll && setAll(false)
                    setOpenDrawer(!openDrawer)
                    setDrawerSingleData([rowData])
                }}>
                    <OpenInNewIcon fontSize='small' />
                </IconButton>
            </Tooltip>
        </Box>
    );
});

export default memo(LimitedActionCell);

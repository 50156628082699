/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

// material-ui
import {Box, Grid, Typography} from '@mui/material';
import {useTheme} from '@mui/styles';

import {useDispatch, useSelector} from 'react-redux';
import {getCustomers, selectCustomerById} from 'store/customers/customersSlice';
import {RootState} from 'store';

// project imports
import config from 'config';
import SubCard from 'ui-component/cards/SubCard';
import Logo from 'ui-component/Logo';
import MyChip from 'ui-component/extended/Chip';

// Locale
import {format} from 'date-fns';
import {enUS, he} from 'date-fns/locale';
import {FormattedMessage} from 'react-intl';

// types
import DocumentHeaderPreview from '../document_header_preview/DocumentHeaderPreview';
import DocumentClientDetails from '../document_header_preview/DocumentClientDetails';
import {ExtendedDocument} from '../../../../../types/DocumentsTypes/Document';
import {roundDecimals} from "../../../../../fromKotlin/nkutils";
import PreviewDocumentComments from '../PreviewComments';
import { NewVisitActions } from './NewVisit';
import MyDatePicker from "../../../../../ui-component/MyDatePicker";

interface visitPreviewProps extends ExtendedDocument {
    editting?: boolean;
    setInternalEdit?:any;
    internalEdit?:any;
    pool?:string;
}

const VisitPreview = ({ editting,setInternalEdit,internalEdit,pool, ...document }: visitPreviewProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    // TODO: Change to single customer fetching
    const customer = useSelector((state: RootState) => selectCustomerById(state, document.entId));
    React.useEffect(() => {
        if (!customer) dispatch(getCustomers());
    }, [ dispatch]);
    if (!customer) return <Box />;

    const address = customer.street && customer.streetNumber ? `${customer.street}, ${customer.streetNumber}` : customer.street;
    return (
        <SubCard
            darkTitle
            title={
                <Typography fontWeight='bold'>
                    תעודת ביקור
                </Typography>
            }
            secondary={<Logo width={50} />}
        >
            <Grid container spacing={2}>
                <DocumentHeaderPreview />
                <Grid item xs={12}>
                    <Grid container>
                        <DocumentClientDetails address={address} name={customer.name}
                                               businessName={customer.businessName} businessId={customer.businessId}
                                               phone={customer.phone} email={customer.email} id={customer.id} external_id={customer.externalId}/>
                        <Grid item sm={6}>
                            <Grid container>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    פרטי ביקור
                                </Typography>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                <FormattedMessage id='Date' />:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {editting ? <MyDatePicker date={internalEdit.date} autoFocus={true}
                                                                          setDate={(d)=>{
                                                                              setInternalEdit({...internalEdit,date:d})
                                                                          }}
                                                                          isLoading={undefined}/> :
                                                <Typography variant='body2'>
                                                    {format(document.date, 'PPP', {
                                                        locale: config.i18n === 'he' ? he : enUS
                                                    })}
                                                </Typography>}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                תאריך הנפקה:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body2'>
                                                {format(document.issueDate, 'PPPPp', {
                                                    locale: config.i18n === 'he' ? he : enUS
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                <FormattedMessage id='Status' />:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MyChip
                                                label={<FormattedMessage id={document.status} />}
                                                variant='outlined'
                                                size='small'
                                                chipcolor={
                                                    document.status === 'pending_approval'
                                                        ? 'warning'
                                                        : document.status === 'approved'
                                                            ? 'success'
                                                            : document.status === 'open'
                                                                ? 'error'
                                                                : ''
                                                }
                                            />
                                        </Grid>
                                        <>
                                            <Grid item xs={4}>
                                                <Typography variant='body2'>
                                                    סוכן:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant='body2'>
                                                    {document.agent}
                                                </Typography>
                                            </Grid>
                                        </>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


               {editting ? <NewVisitActions   upid={document.entId} setInternalEdit={setInternalEdit} internalEdit={internalEdit}/> :
                <Grid item xs={12}>
                    <SubCard
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                        }}
                    >
                        <Grid container rowSpacing={0}>
                            {document.states.map((s)=>{
                                return <Grid item xs={12}>
                                    <Typography variant='subtitle1' fontWeight='bold'>
                                        {s}
                                    </Typography>
                                </Grid>
                            })}

                        </Grid>
                        {roundDecimals((document?.nkObject?.getAmounts())) ?
                            <>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>
                                            כמות: {roundDecimals((document?.nkObject?.getAmounts()))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>
                                            מחיר: {roundDecimals((document?.nkObject?.getConnectedValue()/document?.nkObject?.getAmounts()))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>
                                            סה''כ: {roundDecimals((document?.nkObject?.getConnectedValue()))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>

                            :undefined}

                        {roundDecimals((document?.nkObject?.getConnectedPayment())) ?
                            <Grid item xs={12}>
                                {(document.comment !== '') ?
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1'>
                                            תשלום: {roundDecimals((document?.nkObject?.getConnectedPayment()))}
                                        </Typography>
                                    </Grid>
                                    : undefined}
                            </Grid>
                            :undefined}

                        <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <PreviewDocumentComments comment={document.comment} doc={document} pool={pool} />
                                </Grid>
                        </Grid>
                        {customer.comments !== '' ?
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1'>
                                        היערות קבועות: {customer.comments }
                                    </Typography>
                                </Grid>
                            </Grid>
                            :undefined}
                    </SubCard>
                </Grid>}
            </Grid>
        </SubCard>
    );
};

export default VisitPreview;

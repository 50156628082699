import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import JSDB from 'fromKotlin/nk';
import { AsyncStatus } from 'store/types/asyncState';
import { BusinessInfo, serializeBusinessInfo } from '../../types/BussinessInfo';


// THUNKS
export const getUser = createAsyncThunk('user/getUser', async () => JSDB().getUser());

const initialState: {
    info?:BusinessInfo;
    getUserAsyncState: AsyncStatus;
} = {
    info: undefined,
    getUserAsyncState: 'idle'
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        initGetUserAsyncState: (state) => {
            state.getUserAsyncState = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            /* GET_AGENTS STATE */
            .addCase(getUser.pending, (state) => {
                if (state.getUserAsyncState === 'idle') {
                    state.getUserAsyncState = 'loading';
                }
            })
            .addCase(getUser.fulfilled, (state, action) => {
                if (state.getUserAsyncState === 'loading') {
                    state.getUserAsyncState = 'succeeded';
                }
                state.info = serializeBusinessInfo(action.payload.first)
            })
            .addCase(getUser.rejected, (state, action) => {
                if (state.getUserAsyncState === 'loading') {
                    state.getUserAsyncState = 'failed';
                }
            });
    }
});

// actions
export const { initGetUserAsyncState } = userSlice.actions;

// Selectors
export const selectGetAgentsAsyncState = (state: RootState) => state.user.getUserAsyncState;
export const selectUser = (state: RootState) => state.user.info!;

export default userSlice.reducer;

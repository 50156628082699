import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';
import config from 'config';
import useAuth from 'hooks/useAuth';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DefaultRootStateProps } from 'types';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// ==============================|| PROFILE MENU ||============================== //

// investigateProfileSection
const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const { logout, user, replace, getSession } = useAuth();
    const [open, setOpen] = React.useState(false);
    const [companies] = React.useState(JSON.parse(localStorage.getItem('companies') ?? '[]'));
    const [currentCompany, setCurrentCompany] = React.useState('');

    const anchorRef = React.useRef<any>(null);
    const handleReplaceUser = async (name) => {
        try {
            await replace(name);
        } catch (err) {
            console.error(err);
        }
    };
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };
    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    React.useEffect(() => {
        if (currentCompany === '') {
            const { company_name } = getSession();
            setCurrentCompany(company_name);
        }
    }, [currentCompany]);

    return (
        <>
            <Chip
                sx={{
                    height: '38px',
                    padding: '4px 0 !important',
                    alignItems: 'center',
                    borderRadius: '12px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: 'white',
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={AccountCircleIcon || ''}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                            color: '#5e35b1 !important',
                            backgroundColor: '#ede7f6',
                            borderRadius: '50%'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color="#616161" />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 1, direction: config.rtlLayout ? 'rtl' : 'ltr' }}>
                                        <Typography
                                            component="span"
                                            variant="h4"
                                            sx={{
                                                fontWeight: 400,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingTop: '2px',
                                                paddingBottom: '2px'
                                            }}
                                        >
                                            {user?.name}
                                        </Typography>
                                        <Typography
                                            component="span"
                                            variant="h5"
                                            sx={{
                                                fontWeight: 400,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingTop: '2px',
                                                paddingBottom: '2px'
                                            }}
                                        >
                                            {currentCompany}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: 1 }}>
                                        <Divider style={{ padding: '0px' }} />
                                    </Box>
                                    <Box sx={{ padding: '0 4px', direction: config.rtlLayout ? 'rtl' : 'ltr' }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 0}
                                                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                    handleListItemClick(event, 0, '/account-profile')
                                                }
                                            >
                                                <ListItemIcon>
                                                    <IconSettings stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <FormattedMessage id="Account Settings" />
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1rem" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <FormattedMessage id="Logout" />
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            {companies.map((row, index) => (
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={(e) => handleReplaceUser(row)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        backgroundColor: row === currentCompany ? '#e9daff' : 'white'
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconUser stroke={1.5} size="1rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2"> {row}</Typography>} />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;

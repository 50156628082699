import axios from 'axios';

import {CUSTOMER_PRODUCTS_API} from 'api/routes';
import IItem from 'types/product_delivery';
import JSDB, {ApiCall, NKTypeConvertor} from '../../fromKotlin/nk';
import {getImgFromDB} from '../../types/product';
import {dateObjectToString} from '../../fromKotlin/nkutils';
import {toArrayOrEmpty} from "../../types/DocumentsTypes/utils";

export function itemApiIInternal(client: any, date: string, id: number, sup: boolean = false): IItem {
    const p = client.getPrice(id);

    const op = p.product.getOrderProduct();
    let barcode = '';
    if (p.product.getBarcode() != '0000000000000')
        barcode = p.product.getBarcode();
    let unit1 = p.product.getUnitName();
    const units: string[] = [];
    if (unit1 == '') {
        unit1 = 'יח\'';
    }
    units.push(unit1);
    if (op != null) {
        const unit2 = op.getExtraUnitName();
        if (unit2 != null && unit2 != '') {
            units.push(unit2);
        }
    }
    const cost = JSDB().getProductCost(p.product.id)?.get(date)
    let totalCost = (cost?.first ?? 0) * (1 - (cost?.second ?? 0) / 100)
    const av = JSDB().getClientProductAvailable(client.id, id);
    const available =Number((av?.available ?? 1)  && (op?.available ?? 1));
    const priority = av?.priority ?? 0;
    const master = av?.master


    let d = date<= '2011-01-01' ? JSDB().getDatesManipulator().dateNowNoHour() : date;
    const price = p.get(d);
    let priceInclude = p.getWithTax(client,date)

    const x = {
        quantitySecond: 0, quantitySecondReturns: 0,
        discount: price.second,
        nkObject: p,
        quantityReturns: 0,
        totalPrice: price.first,
        id: p.product.id,
        external_id: p.product.external_id,
        name: p.product.getName(),
        // image: getImgFromDB(p.product),
        image: '',
        barcode,
        position: p.product.getPosition(),
        quantity: 0,
        costPrice: totalCost,
        no_tax_product: p.product.getNoTaxProduct() === 1,
        unit: unit1,
        available: available,
        category: p.product.getCategory(),
        group: p.product.getCategory2(),
        available_units: units,
        conversion_ratio: op?.conversion_rate,
        jump : op?.step,
        ent: client,
        ent_name: client.getName(),
        ent_bname: client.getBusinessName(),
        priority: priority,
        master : master,
        totalPriceInclude:priceInclude.second,
        totalPriceExclude: priceInclude.first
    }
    if (!sup) {
        x.agent = client.getAgent()
        x.driver = client.getDriver()?.user_name
    }
    return x

}


export function itemSpecialInternal(p: any, date: string): IItem {


    const op = p.product.getOrderProduct();
    let barcode = '';
    if (p.product.getBarcode() != '0000000000000')
        barcode = p.product.getBarcode();
    let unit1 = p.product.getUnitName();
    const units: string[] = [];
    if (unit1 == '') {
        unit1 = 'יח\'';
    }
    units.push(unit1);
    if (op != null) {
        const unit2 = op.getExtraUnitName();
        if (unit2 != null && unit2 != '') {
            units.push(unit2);
        }
    }

    const price = p.get(date);
    const x = {
        quantitySecond: 0, quantitySecondReturns: 0,
        discount: price.second,
        nkObject: p,
        quantityReturns: 0,
        totalPrice: price.first,
        id: p.product.id,
        name: p.product.getName(),
        // image: getImgFromDB(p.product),
        image: '',
        barcode,
        position: p.product.getPosition(),
        quantity: 0,
        no_tax_product: p.product.getNoTaxProduct() === 1,
        unit: unit1,
        category: p.product.getCategory(),
        category2: p.product.getCategory2(),
        external_id: p.product.external_id,
        available_units: units,
        conversion_ratio: op?.conversion_rate,

    }
    return x

}

export function itemsApi(client: any, date: string, all: boolean, sup: boolean = false): IItem[] {
    const items: IItem[] = [];
    const prices = !all ? client.getActivePrices(date).toArray() : client.getActiveProductsPrices(date).toArray();
    prices.forEach((p: any) => {
        items.push(itemApiIInternal(client, date, p.product.id, sup));

    });


    return items.sort((a, b) => a.position - b.position);
}

export const getSpecialPrice: (cid: string, date: string,toDate?:string) => Promise<IItem[]> = async (cid, date,toDate) => {
    if (cid === '')
        return [];
    const req = new ApiCall('GET_CLIENT_SPECIAL_PRICES');
    req.putParam('ids', NKTypeConvertor().toKotlinList([Number(cid)]));
    req.putParam('fromDate', date);
    req.putParam('toDate', toDate??date);
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });


    const items = JSDB().getAllProductCost()
    if (items.size > 0) {
        const ret: IItem[] = items.toArray().map((a) => itemSpecialInternal(a, date))
        return ret
    } else {
        return []
    }


};


export const getCustomerProductsPrices: (cid?: string, date?: string, all?: boolean, ids?: number[], with_cost?: boolean, available?: boolean) => Promise<IItem[]> = async (cid, date, all, ids, with_cost, available) => {
    if (cid === '')
        return [];
    if (with_cost && date) {
        const costs = await getSpecialPrice("-1", date)
    }
    let req
    if( available && (!ids || ids.length==1)){
        req = new ApiCall('GET_CLIENT_PRIORITY_PRICES');
        if (ids)
            req.putParam('ids', NKTypeConvertor().toKotlinList(ids.map((n) => Number(n))));
        else if (cid)
            req.putParam('ids', NKTypeConvertor().toKotlinList([Number(cid)]));
        req.putParam('date', date);
    } else {

        req = new ApiCall('GET_CLIENT_PRICES');
        if (ids)
            req.putParam('ids', NKTypeConvertor().toKotlinList(ids.map((n) => Number(n))));
        else if (cid)
            req.putParam('id', Number(cid));
        req.putParam('fromDate', date);
        req.putParam('toDate', date);
        if(available)
            req.putParam('available', true);

    }



    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    const cid_active = ids ? ids[0] : cid

    const client = JSDB().getClient(Number(cid_active)).first;
    if(all && available){
        req = new ApiCall('GET_PRODUCT_AVAILABLE');
        if (ids)
            req.putParam('ids', NKTypeConvertor().toKotlinList(ids.map((n) => Number(n))));
        else if (cid)
            req.putParam('ids', NKTypeConvertor().toKotlinList([Number(cid)]));
        const result2 = await req.execute();

        if (result2 == null || result2.second.name !== 'SUCCESS')
            return new Promise((resolve, reject) => {
                reject(Error);
            });

    }
    const ret: IItem[] = itemsApi(client, date!, all!);

    return ret;
};

export const getSupplierProductsPrices: (cid: string, date: string, all: boolean) => Promise<IItem[]> = async (cid, date, all) => {
    if (cid === '')
        return [];
    const req = new ApiCall('GET_SUPPLIER_PRICES');
    req.putParam('id', Number(cid));
    req.putParam('fromDate', date);
    req.putParam('toDate', date);
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    const client = JSDB().getSupplier(Number(cid)).first;
    const ret: IItem[] = itemsApi(client, date, all, true);
    return ret;
};
export const getAllCustomerProductPrices: (pid: number, date: string) => Promise<IItem[]> = async (pid, date) => {
    const req = new ApiCall('GET_CLIENT_PRICES');
    req.putParam('product_id', pid);
    req.putParam('fromDate', date);
    req.putParam('toDate', date);
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    const clients: any[] = JSDB().getAllClient(true).first.toArray();
    const ret: IItem[] = [];
    clients.forEach((c) => {
        ret.push(itemApiIInternal(c, date, pid));
    });
    return ret;
};

export const getAllCpData: () => Promise<any[]> = async () => {
    const req = new ApiCall('GET_CP_DATA');


    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    const d = []
    const clients: any[] =  JSDB().getAllClient(true).first.toArray();
    const products = JSDB().getAllClientProduct(true).first.toArray();
    clients.forEach((c) => {
        products.forEach((p) => {

            const cp =JSDB().getClientCPDataOffline(c.id, p.id)

            d.push({client_id: c.id, product_id: p.id,barcode:cp?.barcode})
        })
    })

    return d;
};

export const newAllCpData: (data) => Promise<any[]> = async (data) => {
    const req = new ApiCall('NEW_CP_DATA');
    const ids = data.map((d) => d.client_id)
    req.putParam('ids', NKTypeConvertor().toKotlinList(ids));
    const products = data.map((d) => d.product_id)
    req.putParam('productIds', NKTypeConvertor().toKotlinList(products));
    const barcodes = data.map((d) => d.barcode ?? null)
    req.putParam( 'barcodes', NKTypeConvertor().toKotlinList(barcodes));

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    const d = []
    ids.forEach((c) => {
        products.forEach((p) => {

            const cp =JSDB().getClientCPDataOffline(c, p)

            d.push({client_id: c, product_id: p,barcode:cp?.barcode})
        })
    })

    return d;
};
export const getAllCustomerProductAvailable: (ids?: number[], product_ids?: number[]) => Promise<IItem[]> = async (ids, product_ids) => {
    const req = new ApiCall('GET_PRODUCT_AVAILABLE');
    const clients: any[] = ids? ids.map((i) => {
        return JSDB().getClient(NKTypeConvertor().toKotlinInt(i)).first;
    }) : JSDB().getAllClient(true).first.toArray();
    const products = product_ids? product_ids.map((i) => {
        return JSDB().getClientProduct(NKTypeConvertor().toKotlinInt(i)).first;
    }) : JSDB().getAllClientProduct(true).first.toArray();

    if (ids)
        req.putParam('ids', NKTypeConvertor().toKotlinList(ids.map((n) => Number(n))));
    if (product_ids)
        req.putParam('product_ids', NKTypeConvertor().toKotlinList(product_ids.map((n) => Number(n))));

    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    const ret: IItem[] = [];
    clients.forEach((c) => {
        products.forEach((p) => {
            ret.push(itemApiIInternal(c, JSDB().getDatesManipulator().dateNowNoHour(), p.id));
        })
    });

    return ret;
};

export const updateSupplierProductPrice: (cids: number[], p: number[], date: string, price?: number, percent?: number, price_percent?: number, toDate?: string, taxMutate?: boolean, byPrice?: number, unique?: boolean) => Promise<IItem[]> = async (cids, p, date, price, percent, price_percent, toDate, taxMutate, byPrice, unique) => {

    const req = new ApiCall('NEW_ALL_SUPPLIER_PRICES');
    const suppliers: any[] = cids.map((i) => {
        return JSDB().getSupplier(NKTypeConvertor().toKotlinInt(i)).first;
    });
    req.putParam('date', date);
    req.putParam('ids', NKTypeConvertor().toKotlinList(suppliers.map((c) => c.id)));
    req.putParam('product_ids', NKTypeConvertor().toKotlinList(p));
    if (price != undefined)
        req.putParam('price', price);
    else if (price_percent != undefined)
        req.putParam('percent_price', price_percent);
    if (percent != undefined)
        req.putParam('percent', percent);
    if (unique)
        req.putParam('unique', unique);
    if (toDate) {
        req.putParam('toDate', toDate);
        if (toDate < date)
            return new Promise((resolve, reject) => {
                reject('תאריכים לא תקינים');
            });
    }

    if (byPrice)
        req.putParam('byPrice', byPrice);
    if (taxMutate)
        req.putParam('tax_mutate', taxMutate);
    else
        req.putParam('tax_mutate', false);
    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject('אירעה שגיאה בעדכון המוצר');
        });


    // const ret: IItem[] = itemsApi(client,date);
    const ret: IItem[] = [];
    suppliers.forEach((c) => {
        p.forEach((pr) => {
            ret.push(itemApiIInternal(c, date, pr, true));
        });
    });


    // const ret: IItem[] = itemsApi(client,date);
    return ret;


};
export const updateCustomerProductAvailable: (cids: number[], p: number[], availables?: boolean[],available?:boolean) => Promise<IItem[]> = async (cids, p, availables,available) => {

    let req = new ApiCall('NEW_ALL_PRODUCT_AVAILABLE');


    const clients: any[] = cids.map((i) => {
        return JSDB().getClient(NKTypeConvertor().toKotlinInt(i)).first;
    });

    req.putParam('ids', NKTypeConvertor().toKotlinList(clients.map((c) => c.id)));
    req.putParam('product_ids', NKTypeConvertor().toKotlinList(p));
    if(availables)
    req.putParam('availables',NKTypeConvertor().toKotlinList(availables.map((c) =>Number(c))));
    if(available!=undefined)
    req.putParam('available',(Number(available)));
    const result = await req.execute();
    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {

            reject('אירעה שגיאה בעדכון המוצר');
        });


    // const ret: IItem[] = itemsApi(client,date);
    const ret: IItem[] = [];

    clients.forEach((c) => {
        p.forEach((pr) => {
            ret.push(itemApiIInternal(c, JSDB().getDatesManipulator().dateNowNoHour(), pr));
        });
    });

    // const ret: IItem[] = itemsApi(client,date);
    return ret;


};
export const updateCustomerProductPrice: (cids: number[], p: number[], date: string, price?: number, percent?: number, price_percent?: number, toDate?: string, taxMutate?: boolean, byPrice?: number, unique?: boolean, special?: boolean, baseChange?: boolean, alreadyPrice?: boolean) => Promise<IItem[]> = async (cids, p, date, price, percent, price_percent, toDate, taxMutate, byPrice, unique, special, baseChange, alreadyPrice) => {

    let req = new ApiCall('NEW_ALL_CLIENT_PRICES');


    const clients: any[] = cids.map((i) => {
        return JSDB().getClient(NKTypeConvertor().toKotlinInt(i)).first;
    });
    if (special) {
        req = new ApiCall('NEW_ALL_SPECIAL_PRICES');
        req.putParam('ids', NKTypeConvertor().toKotlinList(cids));
    } else {
        req.putParam('ids', NKTypeConvertor().toKotlinList(clients.map((c) => c.id)));
    }
    req.putParam('date', date);

    req.putParam('product_ids', NKTypeConvertor().toKotlinList(p));
    if (price != undefined)
        req.putParam('price', price);
    else if (price_percent != undefined)
        req.putParam('percent_price', price_percent);
    if (percent != undefined)
        req.putParam('percent', percent);
    if (baseChange)
        req.putParam('baseChange', baseChange);
    if (alreadyPrice)
        req.putParam('alreadyPrice', alreadyPrice);
    if (unique)
        req.putParam('unique', unique);
    if (toDate) {
        req.putParam('toDate', toDate);
        if (toDate < date)
            return new Promise((resolve, reject) => {
                reject('תאריכים לא תקינים');
            });
    }

    if (byPrice)
        req.putParam('byPrice', byPrice);
    if (taxMutate)
        req.putParam('tax_mutate', taxMutate);
    else
        req.putParam('tax_mutate', false);


    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject('אירעה שגיאה בעדכון המוצר');
        });


    // const ret: IItem[] = itemsApi(client,date);
    const ret: IItem[] = [];
    if (special) {
        const items = JSDB().getAllProductCost()
        if (items.size > 0) {
            const ret: IItem[] = items.toArray().map((a) => itemSpecialInternal(a, date))
            return ret
        } else {
            return []
        }
    } else {
        clients.forEach((c) => {
            p.forEach((pr) => {
                ret.push(itemApiIInternal(c, date, pr));
            });
        });
    }


    // const ret: IItem[] = itemsApi(client,date);
    return ret;


};

export interface Price {
    id: number;
    product_id: number;
    price: number;
    discount: number;
    master: number;
}

export const updateAllPrice: (prices: Price[], date: string, toDate?: string, special?: boolean, supplier?: boolean,availables?:number[],unique?:boolean) => Promise<IItem[]> = async (prices, date, toDate, special, supplier,availables,unique) => {

    let req = new ApiCall('NEW_ALL_PRICES');


    req.putParam('ids', NKTypeConvertor().toKotlinList(prices.map((c) => c.id)));
    req.putParam('date', date);
    if (toDate)
        req.putParam('toDate', toDate);
    if (supplier)
        req.putParam('supplier', supplier);

    req.putParam('product_ids', NKTypeConvertor().toKotlinList(prices.map((c) => c.product_id)));
    req.putParam('prices', NKTypeConvertor().toKotlinList(prices.map((c) => JSDB().createRawPrice(date, c.id, c.product_id, c.price, c.discount,c.master))));
    if(availables)
    req.putParam('availables',NKTypeConvertor().toKotlinList(availables));
    if(unique)
    req.putParam('unique',unique);

    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject('אירעה שגיאה בעדכון המוצר');
        });


    // const ret: IItem[] = itemsApi(client,date);
    const ret: IItem[] = [];
    if (special) {
        const items = JSDB().getAllProductCost()
        if (items.size > 0) {
            const ret: IItem[] = items.toArray().map((a) => itemSpecialInternal(a, date))
            return ret
        } else {
            return []
        }
    } else {
        prices.forEach((c) => {
            ret.push(itemApiIInternal(JSDB().getClient(Number(c.id)).first, date, c.product_id));
        })
    }


    // const ret: IItem[] = itemsApi(client,date);
    return ret;


};


export const copyClientPrice: (from: number, to: number, fromDate: Date, datePrice: Date, toDate?: Date, unique?: boolean) => Promise<boolean> = async (from, to, fromDate, datePrice, toDate, unique) => {

    const req = new ApiCall('COPY_CLIENT_PRICES');

    req.putParam('fromDate', dateObjectToString(fromDate));
    if (toDate)
        req.putParam('toDate', dateObjectToString(toDate));
    if (unique)
        req.putParam('unique', unique);
    req.putParam('datePrice', dateObjectToString(datePrice));
    req.putParam('from', from);
    req.putParam('to', to);
    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return true;


};
export default getCustomerProductsPrices;

import React, { createContext, useEffect, useReducer } from 'react';


// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { initialLoginContextProps } from 'types';
import { NKContextType } from 'types/auth';
import { getUser } from '../store/user/userSlice';
import JSDB, { ApiCallWrapper } from '../fromKotlin/nk';
import { useDispatch } from 'react-redux';
import { fillCustomers } from '../store/customers/customersSlice';
import {fillSuppliers} from "../store/suppliers/suppliersSlice";

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (company_name?: string | null, user_name?: string | null, password?: string | null) => {
    if (company_name) {
        localStorage.setItem('company_name', company_name);
    } else {
        localStorage.removeItem('serviceToken');
    }
    if (user_name) {
        localStorage.setItem('user_name', user_name);
    } else {
        localStorage.removeItem('serviceToken');
    }
    if (password) {
        localStorage.setItem('password', password);
    } else {
        localStorage.removeItem('serviceToken');
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const NKContext = createContext<NKContextType | null>(null);

export const NKProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const dispatch2 = useDispatch();
    useEffect(() => {
        const init = async () => {
            try {
                const company_name = window.localStorage.getItem('company_name');
                const user_name = window.localStorage.getItem('user_name');
                const password = window.localStorage.getItem('password');

                if (company_name && user_name && password) {
                    setSession(company_name, user_name, password);
                    JSDB().currentAgent = user_name;
                    const callback2 = (result) => {
                        dispatch2(getUser());
                        dispatch2(fillCustomers());
                        const pi2 = ApiCallWrapper('GET_LATEST_CLIENT_PRICES', ()=>{});
                        pi2.execute();
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: {
                                    id: '0',
                                    email: '',
                                    name: user_name
                                }
                            }
                        });
                    };
                    const empty = (result) => {


                    };
                    const callback = (result) => {
                        if (result.second.name === 'SUCCESS') {
                            const pi2 = ApiCallWrapper('FETCH', callback2);
                            pi2.putParam("withPrices",false)
                            pi2.execute();
                            // setTimeout(() => {
                            //     const pi3 = ApiCallWrapper('OPTIMIZED_GET_IMAGES', (empty));
                            //     pi3.execute();
                            // }, 1000);

                        } else {
                            logout();
                        }
                    };

                    const pi = ApiCallWrapper('LOGIN', callback);
                    pi.putParam('user_name', user_name).putParam('password', password).putParam('company', company_name).putParam('web', true).execute();


                } else {
                    logout();
                }
            } catch (err) {
                console.error(err);
                logout();
            }
        };

        init();
    }, []);

    const login = async (result: any, company_name: string, user_name: string, password: string) => {

        const callback2 = (r) => {
            if (r.name === 'SUCCESS') {
                const companies = JSON.parse(window.localStorage.getItem('companies') ?? '[]') as string[];
                const set = new Set(companies)
                set.add(company_name)
                localStorage.setItem("companies",JSON.stringify(Array.from(set.values())))
                JSDB().currentAgent = user_name;
                dispatch2(getUser());
                dispatch2(fillCustomers());
                dispatch2(fillSuppliers());
                const pi2 = ApiCallWrapper('GET_LATEST_CLIENT_PRICES', ()=>{});
                pi2.execute();
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            id: '0',
                            email: '',
                            name: user_name
                        }
                    }
                });
            }

        };


        if (result.second.name === 'SUCCESS') {
            setSession(company_name, user_name, password);
            const pi2 = ApiCallWrapper('FETCH', callback2);
            pi2.putParam("withPrices",false)
            pi2.execute();
        }

    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        // todo: this flow need to be recode as it not verified

    };

    const logout = () => {
        setSession(null, null, null);
        JSDB().clearDB();
        dispatch({ type: LOGOUT });
        dispatch2({ type: 'USER_LOGOUT' });

    };
    const replace = (name) => {
        const company_name = window.localStorage.getItem('company_name');
        const user_name = window.localStorage.getItem('user_name');
        const password = window.localStorage.getItem('password');
        setSession(name, user_name, password);
        JSDB().clearDB();
        dispatch({ type: LOGOUT });
        dispatch2({ type: 'USER_LOGOUT' });
    };

    const getSession = () => {
        const company_name = window.localStorage.getItem('company_name') ?? '';
        const user_name = window.localStorage.getItem('user_name') ?? '';
        return { company_name, user_name };
    };

    const resetPassword = (email: string) => console.log(email);

    const updateProfile = () => {
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <NKContext.Provider
            value={{ ...state, login, logout,replace, register, resetPassword, updateProfile, getSession }}>{children}</NKContext.Provider>
    );
};

export default NKContext;

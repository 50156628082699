export interface QueryOptions {
    limit?: number;
    offset?: number;
    from?: string;
    to?: string;
}

export const buildQueryOptions = (opts?: QueryOptions) => {
    if (!opts || Object.keys(opts).length === 0) return '';

    return `?${Object.keys(opts)
        .filter((o) => opts[o])
        .map((o) => `${o}=${opts[o]}`)
        .join('&')}`;
};

export const compareArray = (arr1: any[], arr2: any[]) => {
    if (arr2.length==0 || arr1.length !== arr2.length) return false;
    return arr1.every((item) => arr2.includes(item));
}

export const handleKeyDown = (event) => {
    event.stopPropagation();
    const current = event.target;
    const currentCell = current.closest("td")
    const currentRow = current.closest("tr")
    const inputs = [...currentRow.getElementsByTagName("input")]
    const ind = inputs.indexOf(current)
    if (ind == -1)
        return
    switch (event.key) {
        case "ArrowRight":
            if (ind > 0) {
                const right = inputs[ind - 1]
                right?.focus()
                setTimeout(function () { right?.select() }, 5);
            } else {
                const prev = currentRow?.previousElementSibling?.getElementsByTagName("input")[inputs.length - 1]
                prev?.focus()
                setTimeout(function () { prev?.select() }, 50);
            }
            break;
        case "ArrowUp":
            const prev = currentRow?.previousElementSibling?.getElementsByTagName("input")[ind] ?? currentRow?.previousElementSibling?.getElementsByTagName("input")[0]
            prev?.focus()
            setTimeout(function () { prev?.select() }, 50);
            break;
        case "ArrowLeft":
            if (ind < inputs.length - 1) {
                const left = inputs[ind + 1]
                left?.focus()
                setTimeout(function () { left?.select() }, 50);

            } else {
                const next = currentRow?.nextElementSibling?.getElementsByTagName("input")[0]
                next?.focus()
                setTimeout(function () { next?.select() }, 50);
            }
            break;
        case "ArrowDown":
            let next = currentRow?.nextElementSibling?.getElementsByTagName("input")[ind]
                if(!next || next.type=="checkbox")
                next= currentRow?.nextElementSibling?.getElementsByTagName("input")[0]
            next?.focus()
            setTimeout(function () { next?.select() }, 50);
            break;
        default: break;
    }
};

import React, { useCallback, useMemo, useState } from 'react';
import { Box, CircularProgress, Dialog, Paper, TableCell, Tooltip, Typography } from '@mui/material';
import MasterTable from 'ui-component/master-table/MasterTable';
import Loader from 'ui-component/Loader';
import MainCard from 'ui-component/cards/MainCard';
import { HeadCell } from 'types';
import { endOfYear, format, parseJSON, startOfYear, subYears } from 'date-fns';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import { buildDebts, CartesetDataHolder } from '../../../../types/CartesetDataHolder';
import {IconArchive, IconCurrencyShekel, IconPhone} from '@tabler/icons';
import { no_padding, number_style } from '../../../../styles/tablecell';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getSpecificCustomer, selectCustomerById } from '../../../../store/customers/customersSlice';
import { CartesetFilterBar } from './CartesetFilterBar';
import DocumentPreview from '../documents/DocumentPreview';
import {
    CUSTOMER_DOCUMENTS, refreshDocs,
    useGetCustomerAllDocuments,
    usePaidDvs,
    usePaidTN
} from '../../../../hooks/customer/documents.hooks';
import { documentStatuses, ExtendedDocument } from '../../../../types/DocumentsTypes/Document';
import {deserializeCarteset, deserializeDocument} from '../../../../types/DocumentsTypes/utils';
import config from '../../../../config';
import { enUS, he } from 'date-fns/locale';
import { MasterTableHeaderToolbarProps } from 'ui-component/master-table/MasterTableColumnHeader';
import { useQueryClient } from 'react-query';
import Button from '@mui/material/Button';
import DialogAddManualDate from '../../../../ui-component/dialogs/addManualDebt';
import Avatar from "../../../../ui-component/extended/Avatar";
import GenerativeLoader, {
    GenerativeLoaderChip, GenerativeLoaderChipForPrint,
    GenerativeLoaderChipSum
} from "../../../../ui-component/GenerativeLoader";
import JSDB from "../../../../fromKotlin/nk";
import { getFile, pricesPdf } from "../../../../api/customer/files.api";
import {
    buildNotesSum,
    buildSupNotesSum, clearCarteset,
    createDvsTaxNoteSplitted,
    getCartesetPDF, getDocumentPhoto, getMassDocumentPdf, getNameOfDoc,
    sendPdfEmail
} from "../../../../api/customer/documents.api";
import {Email, Share} from "@mui/icons-material";
import xslx, {email, pdf, whatsapp2} from "../../../../assets/images";
import AgTable, { ITableControls, selectionMode } from 'ui-component/ag-grid/Ag-Table';
import AddIcon from '@mui/icons-material/Add';
import { numberPinnedRowRenderer, rowGroupRenderer } from 'ui-component/ag-grid/ag-table-utils';
import MyChip from "../../../../ui-component/extended/Chip";
import { FormattedMessage } from "react-intl";
import { AllTaxNoteHolder } from "../../../../types/AllTaxNoteHolder";
import Swal from "sweetalert2";
import { SNACKBAR_OPEN } from "../../../../store/actions";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import PrintIcon from "@mui/icons-material/PrintTwoTone";
import ShowImageDialog from "../../../../ui-component/dialogs/showImageDialog";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {dateObjectToString, dateObjectToStringIsrael} from "../../../../fromKotlin/nkutils";
import { IAgChip } from 'ui-component/ag-grid/components/Ag-Chip';
import basicPinnedControl from "../../../../ui-component/ag-grid/utils/basicPinnedTopRow";
import SendMessageDialog from "../../../../ui-component/dialogs/sendMessage";
import DocumentNew from "../documents/DocumentNew";
import {cloneDeep} from "lodash";

const cartesetColumns: HeadCell[] = [
    {
        id: 'checkbox',
        numeric: false,
        label: '',
        align: 'center',
        type: 'string',
    },
    { id: 'name', numeric: true, label: 'סוג מסמך', align: 'center', type: 'string', filterable: true, sortable: true, enableRowGroup: true },
    {
        id: 'id',
        numeric: true,
        label: 'מזהה',
        align: 'center',
        filterable: true,
        cellRenderer: ({ params, setCurrentDocument, setPreviewOpen }) => {
            return (
                <div style={{
                    cursor: "pointer",
                    color: "blue",
                }} onClick={() => {
                    setCurrentDocument(params?.node?.data?.doc);
                    setPreviewOpen(true);
                }}>
                    {params?.data?.id}
                </div>
            )
        },
    },
    { id: 'agent', numeric: true, label: 'סוכן', align: 'center', type: 'number', filterable: true, hide: true, enableRowGroup: true },
    { id: 'driver', numeric: true, label: 'נהג', align: 'center', type: 'number', filterable: true, hide: true },
    { id: 'cname', numeric: true, label: 'שם לקוח', align: 'center', type: 'string', filterable: true, sortable: true, enableRowGroup: true },
    { id: 'comment', numeric: true, label: 'הערות', align: 'center', type: 'string', filterable: true, sortable: true,hide:true },
    {
        id: 'date', numeric: false, label: 'תאריך מסמך', align: 'center', filterable: true, enableRowGroup: true,
        searchFunc: (row) => {
            const v = row.date.getFullYear() == 2011 ? 'ללא תאריך' : format(row.date, 'PPP', { locale: config.i18n === 'he' ? he : enUS })
            const z = row.date.getFullYear() == 2011 ? 'ללא תאריך' : JSDB().getDatesManipulator().dateIsrael((dateObjectToStringIsrael(row.date)));
            return [v, z];
        },
        extractFunc: (row) => {
            if (!row?.date) return '';
            return row?.date?.getFullYear() == 2011 ? 'ללא תאריך' : ((dateObjectToStringIsrael(row?.date)));
        }
    },
    {
        id: 'status', numeric: false, label: 'סטטוס', align: 'center', type: 'string', filterable: false,
        extractFunc: (row) => {
            return documentStatuses[row?.status];
        },
        cellRenderer({ params }) {
            if (params?.node?.group) return params.value || '';
            return (params?.data?.status ?
                <MyChip
                    label={<FormattedMessage id={params?.data?.status} />}
                    variant='outlined'
                    size='small'
                    chipcolor={
                        // eslint-disable-next-line no-nested-ternary
                        params?.data?.status === 'pending_approval'
                            ? 'warning'
                            // eslint-disable-next-line no-nested-ternary
                            : params?.data?.status === 'approved'
                                ? 'success'
                                : params?.data?.status === 'open'
                                    ? 'error'
                                    : ''
                    }
                /> : <></>
            );
        },
    },
    {
        id: 'value_tn',
        numeric: true,
        label: 'חובה',
        align: 'center',
        type: 'number',
        filterable: false,
        pinnedRowProps: {
            action: "sum",
            format: "currency"
        },
        moneyFunc: (d: any[]) => d.reduce((sum, x) => sum + x.value_tn, 0),
        cellRenderer({ params }) {
            if (params?.node?.rowPinned) return params.value || "";
            return (
                <div style={{ textAlign: "right", direction: "ltr" }}>
                    ₪ {params?.value?.toLocaleString()}
                </div>
            );
        },
    },
    {
        id: 'value_pay',
        numeric: true,
        label: 'זכות',
        align: 'center',
        type: 'number',
        filterable: false,
        pinnedRowProps: {
            action: "sum",
            format: "currency"
        },
        moneyFunc: (d: any[]) => d.reduce((sum, x) => sum + x.value_pay, 0),
        cellRenderer({ params }) {
            if (params?.node?.rowPinned) return params.value || "";
            return (
                <div style={{ textAlign: "right", direction: "ltr" }}>
                    ₪ {params?.value?.toLocaleString()}
                </div>
            );
        },
    },
    {
        id: 'debt',
        numeric: false,
        label: 'יתרה',
        align: 'center',
        type: 'string',
        filterable: false,
        pinnedRowProps: {
            action: "sum",
            format: "currency"
        },
        cellRenderer({ params, totalDebt }) {
            if (params?.node?.group) return rowGroupRenderer(params?.data?.debt);
            if (params?.node?.rowPinned) return rowGroupRenderer(totalDebt) || "";

            if (params?.data?.debt === 0) {
                return (
                    <div style={{
                        textAlign: "right",
                        direction: "ltr",
                        backgroundColor: "#00800080",
                        fontWeight: "bold"
                    }}>
                        ₪ {params?.data?.debt?.toLocaleString()}
                    </div>
                );
            }
            return (
                <div style={{ textAlign: "right", direction: "ltr" }}>
                    ₪ {params?.data?.debt?.toLocaleString()}
                </div>
            );
        },
    },
    {
        id: 'days_to_pay',
        numeric: false,
        label: 'תאריך לתשלום', hide: true,
        align: 'center',
        type: 'dateStr', filterable: true,

        extractFunc: (row) => {
            if (row.type != 'invoice')
                return ''
            const client = JSDB().getClient(Number(row?.entId))?.first;
            const dayToPay = client?.days_to_pay ?? 0;
            const curD = dateObjectToString(row?.date)
            if(dayToPay == 0)
                return JSDB().getDatesManipulator().dateIsrael(curD);
            const nextMonth = JSDB().getDatesManipulator().getNextMonth(curD).split("-");
            const firstDateNextMonth = `${nextMonth[0]}-${nextMonth[1]}-01`
            return JSDB().getDatesManipulator().dateIsrael(JSDB().getDatesManipulator().getNextDay(firstDateNextMonth, dayToPay-1));
        },
    },
    {
        id: 'notes5',
        numeric: false,
        label: 'הערות 5', hide: true,
        align: 'center',
        type: 'dateStr', filterable: true,

        extractFunc: (row) => {
            return row.doc?.notes5 ?? '';
        },
    },
    {
        id: "open_tn",
        numeric: false,
        label: 'סכום חשבונית פתוחה',
        align: 'center',
        type: 'string',
        hide:true,
        filterable: false,
        pinnedRowProps: {
            action: "sum",
            format: "currency"
        },
        cellRenderer({ params }) {
            if (params?.node?.rowPinned) return params.value || "";
            if (params?.node?.group) return rowGroupRenderer(params?.data?.open_tn);
            return (
                <div style={{ textAlign: "right", direction: "ltr" }}>
                    ₪ {params?.data?.open_tn?.toLocaleString()}
                </div>
            );
        },
        editable: true,
    },
];

export const MultiDocumentsActions = React.memo(({
    closeDVS,
    selected,
    range
}: { closeDVS: any, selected: CartesetDataHolder[], range: RangeInput<null> }) => {
    const [loader, setLoader] = useState(false);
    return <div className='document-actions-div' style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 12,
    }}>
        <Tooltip title='סגירת חשבוניות ידנית' onClick={(a) => {
            Swal.fire({
                title: 'האם תרצה לסגור חשבוניות ידנית?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'סגור חשבוניות',
                cancelButtonText: 'בטל'
            }).then((result) => {
                if (result.isConfirmed) {
                    /* Perform insert, update, and delete to the database here */
                    closeDVS.mutate(selected.map((x) => x.doc));
                    // callbackNetworkResult(()=>close_all_dv(selected),(result)=>{})
                }
            });

        }}>
            <MyChip icon={<DoNotDisturbOnOutlinedIcon />} sx={{
                ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                    color: '#000080',
                },
            }} label="סגירת חשבוניות" variant="outlined" />

        </Tooltip>
        <Tooltip title='שליחה ישירה למייל' onClick={() => {

            selected?.forEach((x) => {
                getFile(false, () => getCartesetPDF(parseJSON(range[0]!), parseJSON(range[1]!), Number(x?.doc?.entId)), `כרטסת`, undefined, undefined, setLoader, async (b, t) => {
                    const c = JSDB().getClient(Number(x?.doc?.entId)).first;
                    if (c.getEmail() != '') {
                        const x = sendPdfEmail(b, c.getEmail(), t);
                        setLoader(false);
                        return x;
                    } else {
                        setLoader(false);
                        return false;
                    }
                });
            });
        }
        }>
            <MyChip icon={loader ? <CircularProgress size={20}
             /> : <Email />} sx={{
                ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                    color: '#000080',
                },
            }} label="שליחה ישירה למייל" variant="outlined" />
        </Tooltip>
    </div>
}
);

const CartesetList = ({
    cid,
    rowsPerPage = 100,
    withSelection = false,
    selectionOptions,
    selectedInit,

}: {
    cid: string;
    rowsPerPage?: number;
    withSelection?: boolean;
    selectionOptions?: MasterTableHeaderToolbarProps<CartesetDataHolder>;
    selectedInit?: ExtendedDocument[],
}) => {
    const dispatch = useDispatch();
    const [isMutating, setIsMutating] = React.useState(false);
    const [range, setRange] = React.useState<RangeInput<null>>([subYears(startOfYear(new Date()), 1), endOfYear(new Date())]);
    const [cols, setCols] = React.useState<HeadCell[]>(cartesetColumns);
    const customer = useSelector((state: RootState) => selectCustomerById(state, cid));
    const cache = useQueryClient();
    const pool = "CUSTOMER_DOCUMENTS_FOR_DEBT"
    React.useEffect(() => {
        cache.removeQueries(pool);

    }, []);
    React.useEffect(() => {
        const newCols = cloneDeep((cols))

        const notes5 = JSDB().getUser().first?.getJsonDesc("notes5")
        if (notes5) {
            newCols.push(    {
                id: "notes5",
                label: notes5.name,
                numeric: false,
                hide: true,
                priority: -1,

            },)

        } else {
            newCols.push(    {
                id: "notes5",
                label: "הערות 5",
                numeric: false,
                hide: true,
                priority: -1,
            })

        }
        setCols(newCols)


    }, []);
    const { data: docs, isLoading, refetch } = useGetCustomerAllDocuments(
        parseJSON(range[0]!),
        parseJSON(range[1]!),
        true,
        cid,
        true,
        true,
        undefined,
        undefined,
        pool
    );

    const [carteset, setCarteset] = React.useState<CartesetDataHolder[]>([]);
    const [addOpen, setAddOpen] = React.useState<boolean>(false);
    const [debt, setDebt] = React.useState<number>(0);
    const [recipt, setRecipt] = React.useState<any>(undefined);
    const [send, setSend] = React.useState(false);
    React.useEffect(() => {
        if (!customer) dispatch(getSpecificCustomer(Number(cid)));
    }, [dispatch]);
    // fetch events data


    React.useEffect(() => {
        if (!isLoading) {
            cache.removeQueries(pool);
            refetch();
        }
    }, [range, dispatch]);

    React.useEffect(() => {
        if (docs) {
            const data = buildDebts(docs.filter((d) => (d.type == 'receipt' || d.type == 'invoice' || d.type == 'tax_invoice' || d.type == 'refund' || d.type == 'debt')).map((t) => deserializeCarteset(t)), parseJSON(range[0]!))
            if (data.length > 0) {
                setDebt(data[0].debt)
            }
            setCarteset(data);
        }
    }, [docs]);
    const clearCartesetVoid = useCallback(() => {
        if(debt !=0){
            clearCarteset(Number(cid), new Date()).then((res) => {
                if (res) {
                    cache.invalidateQueries(pool);
                    refetch();
                }
            })

        }
    }, [carteset,cid]);
    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [currentDocument, setCurrentDocument] = React.useState<ExtendedDocument | undefined>(undefined);
    const closeTNS = usePaidTN(pool);
    const refresh = refreshDocs(pool)
    const chipDetails = useMemo(() => {
        return [
            {
                label: () => "אפס כרטסת",
                action: () => Math.abs(debt) <100 && debt!= 0 ? Swal.fire({
                    title: 'האם תרצה לאפס כרטסת?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'אפס כרטסת',
                    cancelButtonText: 'בטל'
                }).then((result) => {
                    if (result.isConfirmed) {
                        /* Perform insert, update, and delete to the database here */
                        clearCartesetVoid()
                    }
                }) :undefined,
                icon:   <IconArchive />,
                tooltip: "אפס כרטסת",
                active: () => Math.abs(debt) <100 && debt!= 0,
            },
            {
                label: () => "הוסף חוב ידני",
                action: () => setAddOpen(true),
                icon: <AddIcon />,
                tooltip: "הוסף חוב ידני",
                active: () => addOpen,
            },
            {
                action: (params, setLoader) => {
                    getFile(false, () => getCartesetPDF(parseJSON(range[0]!), parseJSON(range[1]!), cid), 'כרטסת', undefined, undefined, setLoader);
                },
                label: () => "יצא לPDF",
                icon: <Avatar variant='square'
                    sx={{ cursor: 'pointer', bgcolor: 'white', width: '24px', height: '24px' }}
                    src={pdf} />,
                tooltip: 'יצא לPDF',
                active: () => false,
                withLoader: true
            }, {
                action: (params, setLoader) => {
                    setSelectedCustomersSend({
                        selected: [Number(cid)],
                        defaultPlatform: 'email',
                        files: {
                            ids: [Number(cid)],
                            types: [9],
                        },
                        msg: 'מסמכים',
                        subject: 'שליחת מסמכים',
                    })
                },
                label: () => "שלח ווטסאפ/ אימייל",
                icon: <Share color={send ? 'success' : 'error'}
                    sx={{ width: '24px', height: '24px' }} />,
                tooltip: 'יצא לPDF',
                active: () => send,
                withLoader: true
            },
        ]
    }, [addOpen, send, range, cid,debt]);

    const selectionControls = useCallback((selectedItems) => {
        return <MultiDocumentsActions
            closeDVS={closeTNS}
            selected={selectedItems}
            range={range}
        />
    }, [closeTNS]);

    const contextMenu = useCallback((params) => {
        const ranges = params?.api?.getCellRanges();
        let nodesInRange: any = [];
        const nodesInRangeSet: any = new Set();
        const selected = params?.api?.getSelectedRows()

        selected?.forEach((row) => {

            if (row && !nodesInRangeSet.has(row)) {
                nodesInRangeSet.add(row);
                nodesInRange.push(row);
            }
        })
        if(!selected.length) {
            ranges?.forEach((range) => {

                const startRowIndex = range?.startRow.rowIndex < range?.endRow.rowIndex ? range?.startRow.rowIndex : range?.endRow.rowIndex;
                const endRowIndex = range?.endRow.rowIndex > range?.startRow.rowIndex ? range?.endRow.rowIndex : range?.startRow.rowIndex;
                for (let i = startRowIndex; i <= endRowIndex; i++) {
                    const row = params.api?.getDisplayedRowAtIndex(i);

                    if (row?.allLeafChildren) {
                        row?.allLeafChildren?.forEach((child) => {
                            if (!nodesInRangeSet.has(child?.data)) {
                                nodesInRangeSet.add(child?.data);
                                nodesInRange.push(child?.data);
                            }
                        })
                    } else {
                        if (!nodesInRangeSet.has(row?.data)) {
                            nodesInRangeSet.add(row?.data);
                            nodesInRange.push(row?.data);
                        }
                    }


                }
            });
        }
        const contextItems: {
            name: string;
            action: (params: any) => void;
        }[] | {
            name: string;
            subMenu: {
                name: string;
                action: (params: any) => void;
            }[];
        }[] = [
                {
                    name: "פעולות לחשבוניות",
                    subMenu: [
                        {
                            name:  "הנפק קבלה מהחשבוניות הפתוחות המסומנות",
                            action() {

                                const goodNodes = nodesInRange.filter((n) => n.doc && n.status == 'open' && (n.doc.type == 'invoice' || n.doc.type == 'refund')).map((n) => n.doc)
                                if ( goodNodes.length >= 1) {
                                    setRecipt({
                                        documents: goodNodes,
                                        action: "create",
                                        type: "receipt",
                                    })
                                }

                            },
                        },
                        {
                            name: "סגירת חשבוניות",
                            action: () => {
                                Swal.fire({
                                    title: 'האם תרצה לסגור חשבוניות ידנית?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'סגור חשבוניות',
                                    cancelButtonText: 'בטל'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        /* Perform insert, update, and delete to the database here */
                                        closeTNS.mutate(nodesInRange.map((x) => x.doc));
                                        // callbackNetworkResult(()=>close_all_dv(selected),(result)=>{})
                                    }
                                });
                            }
                        },
                        {
                            name: "פתיחת חשבוניות",
                            action: () => {
                                Swal.fire({
                                    title: 'האם תרצה לפתוח את החשבוניות?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'פתח חשבוניות',
                                    cancelButtonText: 'בטל'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        // ADD CALLBACK
                                    }
                                });
                            }
                        },


                    ],
                },           {
                name: "שלח מסמכים למייל",
                icon: `<img src="${email}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                action() {
                    const lst = [...nodesInRange]
                    setSelectedCustomersSend({
                        selected: lst.map((u) => Number(u.doc.entId)),
                        defaultPlatform: 'email',
                        files: {
                            ids: lst.map((u) => Number(u.doc.id)),
                            types: lst.map((u) => u.doc.nkObject.getConnectedDocType()),
                        },
                        msg: 'מסמכים',
                        subject: 'שליחת מסמכים',


                    })

                    close();
                },
            },

            {
                name: "שלח תעודות לווטסאפ",
                icon: `<img src="${whatsapp2}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                action() {
                    const lst = [...nodesInRange]
                    setSelectedCustomersSend({
                        selected: lst.map((u) => Number(u.doc.entId)),
                        defaultPlatform: 'whatsapp',
                        files: {
                            ids: lst.map((u) => Number(u.doc.id)),
                            types: lst.map((u) => u.doc.nkObject.getConnectedDocType()),
                        },
                        msg: 'מסמכים',
                        subject: 'שליחת מסמכים',
                    })

                    close();
                },
            },
            ];

        return contextItems;
    }, []);

    const tableControls: ITableControls = {
        tableName: "costumers/costumers-list/carteset-list",
        sidebar: true,
        groupBy: {
            enable: true,
            rowGroup: true,
            pivot: true,
            value: true,
            allowSave: true,
        },
        selection: {
            enable: true,
            mode: "multiple" as selectionMode,
        },

        extractId: (row) => `${row.data.name}_${row.data.id}`,

    }

    const cellRenderer = React.useCallback(
        (params, column) => {

            if (column.cellRenderer) {
                return column.cellRenderer({
                    params,
                    setPreviewOpen,
                    setCurrentDocument,
                    totalDebt: debt
                });
            }
            if (typeof params?.value === "object") {
                const value = params?.value?.value || "";
                return value;
            }
            return params.value || "";
        },
        [debt]
    );

    const saveDataCallback = (params: any) => {
        // ADD CALLBACK
        console.log("mainn params", params);
    }
    const [selectedCustomersSend, setSelectedCustomersSend] = React.useState<any>(undefined)
    return (
        <>
            {(!customer || isMutating || isLoading) && <Loader />}

            <MainCard
                title={
                    <Box>
                        <CartesetFilterBar range={range} setRange={setRange} cid={cid} />
                    </Box>
                }
            >
                <Box style={{
                    marginBottom: '10px',
                }}>
                    <Typography variant="h2" style={{ color: 'blue', textAlign: 'center' }}>
                        כרטסת
                    </Typography>
                    <Typography variant="h2" style={{ color: 'red', textAlign: 'center' }}>
                        חוב לקוח : {debt} שח
                    </Typography>
                </Box>
                <AgTable
                    data={carteset}
                    columns={cols}
                    tableControls={tableControls}
                    cellRenderer={cellRenderer}
                    chipDetails={chipDetails}
                    selectionControls={selectionControls}
                    pinnedControl={basicPinnedControl()}
                    extentedContextMenu={contextMenu}
                    saveDataCallback={saveDataCallback}
                />
                {currentDocument ?  <Dialog fullWidth open={true} onBackdropClick={() => setCurrentDocument(undefined)}
                                            sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                    <DocumentPreview setSelectedCustomersSend={setSelectedCustomersSend} document={currentDocument} pool={pool}/>
                </Dialog>: undefined}

                <DialogAddManualDate id={customer!.id} open={addOpen} setOpen={setAddOpen} pool={pool}/>
            </MainCard>
            {selectedCustomersSend!=undefined ? <SendMessageDialog
                open={selectedCustomersSend!=undefined}
                setOpen={(open)=>setSelectedCustomersSend(undefined)}

                toSelected={selectedCustomersSend?.selected??[]}
                files={selectedCustomersSend?.files??[]}
                msg={selectedCustomersSend?.msg??""}
                subject={selectedCustomersSend?.subject??""}
                defaultPlatform={selectedCustomersSend?.defaultPlatform??"email"}
                withFiles={false}

            /> :undefined}
            {recipt ? <Dialog maxWidth={'md'} fullWidth open={true} disableEscapeKeyDown={true} onBackdropClick={() => {
                const docs = recipt?.documents?.map((d)=>d.id)
                if(docs) {
                    const types = recipt?.documents?.map((d)=>d.nkObject.getConnectedDocType())
                    refresh.mutate({ids:docs,types:types, offline:false})
                }

                setRecipt(undefined)

            }}
            >
                <DocumentNew
                    customerId={ recipt?.documents?.[0]?.entId}
                    type={recipt?.type  }
                    refId={recipt?.documents?.map((d)=>d.id)}
                    types={recipt?.documents?.map((d)=>d.type)}
                />
            </Dialog> : undefined}
        </>
    );
};

export default CartesetList;

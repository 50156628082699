import {
    Autocomplete,
    Box,
    Chip,
    createFilterOptions,
    Grid,
    IconButton,
    InputAdornment,
    Popper,
    Tooltip
} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {Search} from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import {useDispatch, useSelector} from 'react-redux';
import {documentTypes} from '../../types/DocumentsTypes/utils';
import {useDocumentEventListener} from '../../datasheetreact/src/hooks/useDocumentEventListener';
import {selectCustomersidname} from '../../store/customers/customersSlice';
import DocumentNew from '../../views/customer/profile/documents/DocumentNew';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {memo} from "react";
import {getSuppliers, selectSupplierById, selectSuppliersidname} from "../../store/suppliers/suppliersSlice";
import {getSupplierProduct, selectSuppliersProducts} from "../../store/SupplierProducts/supplierProductsSlice";
import {subStringMatch} from "../../utils/sort";

export interface DialogChooserClass {
    id: number;
    name: string;
}

const styles = () => ({
    popper: {
        maxWidth: 'fit-content'
    }
});
const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement='bottom-start'/>;
};

function DialogAddDoc({fromOrder}: { fromOrder?: any }) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [curCustomer, setCustomer] = React.useState<any>(undefined);
    const [docType, setDocType] = React.useState<any>(fromOrder ?
        {...documentTypes.delivery_certificate,type:'delivery_certificate'} : undefined);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const customers: any[] = useSelector(selectCustomersidname);
    const suppliers: any[] = useSelector(selectSuppliersidname);
    const suppliers_products: any[] = useSelector(selectSuppliersProducts);
    React.useEffect(() => {
        if(open){
            if (suppliers.length == 0)
                dispatch(getSuppliers());
            if (suppliers_products.length == 0)
                setTimeout(() => dispatch(getSupplierProduct(true)), 1000);

        }

    }, [open]);
    // const [data, setData] = React.useState<any[]>([]);
    const [options, setOptions] = React.useState<any[]>(Object.keys(documentTypes).map((i) => {
            return {...documentTypes[i], type: i};
        }
    ));
    const onKeyDown = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.keyCode === 69 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                // Process event...
                setOpen(true);
            }
            if (e.keyCode == 27) {
                setOpen(false)
                setPreviewOpen(false)
            }
            if (e.keyCode === 13 && open) {
                e.preventDefault();
                handleOk();
            }

        }, [curCustomer, open, docType]);
    useDocumentEventListener('keydown', onKeyDown);


    const handleOk = async () => {
        try {

            if (curCustomer != undefined && docType != undefined) {
                setPreviewOpen(true);

            } else {
                return;
            }
        } catch (e) {

        }

        handleClose();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    // React.useEffect(() => {
    //     console.log(updateCustomerAsyncState);
    //     if (updateCustomerAsyncState === 'succeeded') {
    //         dispatch({
    //             type: SNACKBAR_OPEN,
    //             open: true,
    //             message: 'המוצרים עודכנו בהצלחה!',
    //             variant: 'alert',
    //             alertSeverity: 'success'
    //         });
    //
    //         dispatch(initMassCustomersProductAsyncState());
    //
    //     } else if (updateCustomerAsyncState === 'failed') {
    //         Swal.fire({
    //             title: 'שגיאה בעדכון מוצרים',
    //             target: '#custom-target',
    //             text: '',
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'המשך'
    //         });
    //         dispatch(initMassCustomersProductAsyncState());
    //     }
    // }, [updateCustomerAsyncState, dispatch]);

    const handleClose = () => {
        setOpen(false);
    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue((event.target as HTMLInputElement).value);
    // };
    const [externalIdSearch, setExternalIdSearch] = React.useState(parseInt(localStorage.getItem('externalIdOrders') ?? '0') == 1);

    const onApprove =
        () => {
            handleOk();
        };

    return (
        <div>
            <Chip onClick={handleClickOpen} icon={<NoteAddIcon fontSize={'medium'}/>} sx={{
                ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                    color: '#800080',
                },
            }} label="מסמך חדש" variant="outlined"/>
            {/* <Tooltip title={'מסמך חדש'}>
                <IconButton size='large' onClick={handleClickOpen} style={{ color: '#800080' }}>
                    <NoteAddIcon fontSize={'medium'} />
                </IconButton>
            </Tooltip> */}
            {open ? <Dialog id='doc_search_dialog' maxWidth={'sm'} fullWidth={true}
                            open={open} onClose={handleClose}>
                <DialogTitle> {'מסמך חדש..'}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={5} sm={5}>

                            <Autocomplete
                                id='document'
                                value={docType}
                                onChange={(e, value) => {
                                    if (value)
                                        setDocType(value);
                                    else
                                        setDocType(undefined);
                                }}

                                getOptionLabel={(option) => option.name}
                                options={options ?? []}
                                fullWidth
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='standard'
                                        label='בחר סוג מסמך.. '
                                        autoFocus

                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Search size={16}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box/>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <Autocomplete
                                value={curCustomer}
                                onChange={((event, value) => {
                                        if (value) {
                                            setCustomer(value);
                                        }
                                    }
                                )}
                                PopperComponent={PopperMy}
                                options={docType?.type == 'supplier_note' ? suppliers : customers}
                                getOptionLabel={(o) => `${externalIdSearch ? o.externalId : o.id} - ${o.name}`}
                                size='small'
                                filterOptions={(options, {inputValue}) => {
                                    return options.filter(option => {
                                            return subStringMatch(inputValue, [option.name, externalIdSearch ? option.externalId : option.id])
                                        }
                                    ).sort((a, b) => {


                                        if (inputValue == '') {
                                            return a.position > b.position ? 1 : -1
                                        } else {
                                            const n1 = (externalIdSearch  ? a.externalId : a.id)
                                            const n2 = (externalIdSearch  ? b.externalId : b.id)

                                            if (Number(n1) && Number(n2)) {
                                                return Number(n1) > Number(n2) ? 1 : -1
                                            } else if (Number(n1)) {
                                                return 1
                                            } else if (Number(n2)) {
                                                return -1
                                            } else
                                                return n1 > n2 ? 1 : -1
                                        }
                                    });
                                }}
                                autoHighlight
                                renderInput={(params) =>
                                    <TextField {...params} label={'חפש לקוח..'} variant='standard'
                                               fullWidth size='small'/>
                            }
                            />
                        </Grid>
                    </Grid>
                    {/*<MasterTable*/}
                    {/*    headCells={columns}*/}
                    {/*    data={data ?? []}*/}
                    {/*    keyExtractor={(p) => p.id.toString()}*/}
                    {/*    withPagination*/}
                    {/*    paginationOptions={{*/}
                    {/*        rowsPerPage: 1000*/}
                    {/*    }}*/}
                    {/*    withSort*/}
                    {/*    sortOptions={{*/}
                    {/*        sortBy: {*/}
                    {/*            order: 'desc',*/}
                    {/*            orderBy: 'position'*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    withFilter*/}
                    {/*    TableItem={(row) => <ChangeItem row={row} />}*/}
                    {/*/>*/}

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{backgroundColor: 'green', color: 'white'}}> מסמך חדש
                    </Button>
                    <Button onClick={handleClose} style={{backgroundColor: 'red', color: 'white'}}>ביטול</Button>
                </DialogActions>
            </Dialog> : undefined}
            {previewOpen ? <Dialog maxWidth={'md'} fullWidth open={previewOpen} disableEscapeKeyDown={true}
                                   onBackdropClick={() => setPreviewOpen(false)}
            >
                <DocumentNew customerId={curCustomer.id.toString()}
                             type={docType.type} fromOrder={fromOrder}/>
            </Dialog> : undefined}

        </div>
    );
}

export default memo(DialogAddDoc);

import { Dialog, DialogTitle, DialogActions, TextField, Button } from "@mui/material";
import {memo, useState} from "react";
import {extractBeforeNumber} from "./ag-grid-functions";

const CreateStateDialog = ({ openCreateStateDialog, setOpenCreateStateDialog, tableRef, customeUserButtons, setCustomeUserButtons, tableControls, setCustomeUserState }) => {
  const [newStateName, setNewStateName] = useState('');

  const handleSaveState = () => {
    setOpenCreateStateDialog(false);
    const tableState = tableRef?.current?.columnApi?.getColumnState();
    const columnState = tableRef?.current?.columnApi?.getColumnState();
    const stateToSave = columnState?.map((column, i) => {
      return {
        ...column,
        colId: extractBeforeNumber(column?.colId) ?? column?.colId,
        hide: column?.hide,
      }
    });
    const gstate =  tableRef?.current?.columnApi?.getColumnGroupState()

    const filters = tableRef?.current?.api?.getFilterModel();
    const newButtonToAdd = {
      buttonLabel: newStateName,
      state: stateToSave,
      gstate:gstate,
      id: customeUserButtons.length + 1,
      filters: filters,
    }
    const newButtons = [...customeUserButtons, newButtonToAdd];
    localStorage.setItem(tableControls?.tableName + "-customeButtons", JSON.stringify(newButtons));
    setCustomeUserButtons(newButtons);
    setCustomeUserState(newButtonToAdd.id);
    setNewStateName('');
  }

  const handleCancel = () => {
    setOpenCreateStateDialog(false);
    setNewStateName('');
  }

  return (
    <Dialog
      open={openCreateStateDialog}
      onClose={() => handleCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title">{"הכנס שם למצב החדש"}</DialogTitle>
      <DialogActions style={{
        display: "flex",
        flexDirection: "column",
      }}>
        <TextField
          id="outlined-basic"
          label="שם מצב"
          variant="outlined"
          value={newStateName}
          onChange={(e) => setNewStateName(e.target.value)}
        />
        <div style={{
          display: "flex",
        }}>
          <Button onClick={() => setOpenCreateStateDialog(false)} color="primary">
            ביטול
          </Button>
          <Button onClick={() => handleSaveState()} color="primary" autoFocus>
            אישור
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default memo(CreateStateDialog);

/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect} from 'react';

// material-ui
import {Box, Grid, Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Chip, Input} from '@mui/material';
import {useTheme} from '@mui/styles';

import {useDispatch, useSelector} from 'react-redux';
import {getSpecificCustomer, selectCustomerById} from 'store/customers/customersSlice';
import {RootState} from 'store';

// project imports
import config from 'config';
import SubCard from 'ui-component/cards/SubCard';
import Logo from 'ui-component/Logo';
import MyChip from 'ui-component/extended/Chip';

import {IconCurrencyShekel} from '@tabler/icons';

// Locale
import {format} from 'date-fns';
import {enUS, he} from 'date-fns/locale';
import {FormattedMessage} from 'react-intl';

// types
import {TaxNote} from 'types/DocumentsTypes/TaxNote';
import CustomerProductsPreviewList from '../../products/CustomerProductsPreviewList';
import IItem from 'types/product_delivery';
import DocumentHeaderPreview from '../document_header_preview/DocumentHeaderPreview';
import {roundDecimals} from '../../../../../fromKotlin/nkutils';
import DocumentClientDetails from '../document_header_preview/DocumentClientDetails';
import {NoNegativeInput} from '../../../../../ui-component/textFieldNumber';
import PreviewDocumentComments from '../PreviewComments';
import {deserializeDocument} from "../../../../../types/DocumentsTypes/utils";
import {callbackNetworkResult} from "../../../../../api/customer/files.api";
import {hashIsraelToken, hashSign, productsFromNotes, sendReqForApi} from "../../../../../api/customer/documents.api";
import JSDB from "../../../../../fromKotlin/nk";
import {SNACKBAR_OPEN} from "../../../../../store/actions";
import Swal from "sweetalert2";

interface InvoicePreviewProps extends TaxNote {
    onProductChange?: (product: IItem) => void;
    onIdChange?:(num) =>void;
    editting?: boolean;
    setInternalEdit?: (edit: any) => void;
    internalEdit?: any;
    pool?:string
    outerDoc?: boolean
}

const InvoicePreview = ({ onProductChange,onIdChange, editting, internalEdit, pool,setInternalEdit, outerDoc, ...document }: InvoicePreviewProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // TODO: Change to single customer fetching
    const customer = useSelector((state: RootState) => selectCustomerById(state, document.entId));
    const [sendSuccess, setSendSuccess] = React.useState<boolean>(false);
    const [pfromNotes, setPfromNotes] = React.useState<any>([]);
    React.useEffect(() => {
        if (!customer) dispatch(getSpecificCustomer(Number(document.entId)));
    }, [dispatch]);

    if (!customer) return <Box />;
    const address = document.details!.street && document.details!.streetNumber ? `${document.details!.street}, ${document.details!.streetNumber}` : document.details!.street;
    const apiConfig = JSDB().apiConfig
    const [displayOrderProducts, setDisplayOrderProducts] = React.useState(false);
    const startLog = useCallback(async () => {
        if(!document) return
        // if(true) return
        try {
            const x = await hashSign(document.id,document.type === 'invoice' ? 0:document.type === 'refund' ? 2:1)
            console.log(x)
            if(!x){
                // token err
                // const newWindow = window.open('https://openapi.taxes.gov.il/shaam/tsandbox/longtimetoken/oauth2/authorize?response_type=code&client_id=e65677412ee9bcfc582f4b7f6f2d801f&scope=scope&redirect_uri=http://localhost:3001/reports/tax-israel', '_blank', 'noopener,noreferrer');
                const newWindow = window.open('https://openapi.taxes.gov.il/shaam/production/longtimetoken/oauth2/authorize?response_type=code&client_id=35828e73b8b9edbc05c4ac943f5634f0&scope=scope&redirect_uri=https://solvitcompany.com/reports/tax-israel', '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null;

            }
            if (setInternalEdit) {
                setInternalEdit({...internalEdit, taxConfirmation: (x.taxConfirmation)})
            }

            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'טוקן החשבונית עודכן בהצלחה!',
                variant: 'alert',
                alertSeverity: 'success'
            });
        }
        catch (e) {
            console.log(e)
            Swal.fire({
                title: 'לא הצליח לממשק מס הקצאה לחשבונית',
                target: '#custom-target',
                text: 'יתכן כי ח.פ הלקוח שגוי/ חסר או תאריך החשבונית שגוי',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#d63030'
            });
        }

    }, [document])
    const notes2 =  JSDB().getUser().first?.getJsonDesc("notes2")?.name ?? 'הערות 2'
    const notes3 =  JSDB().getUser().first?.getJsonDesc("notes3")?.name ?? 'הערות 3'
    const notes4 =  JSDB().getUser().first?.getJsonDesc("notes4")?.name ?? 'הערות 4'
    const notes5 =  JSDB().getUser().first?.getJsonDesc("notes5")?.name ?? 'הערות 5'
    React.useEffect(() => {
        const f = async () => {
            const products = await productsFromNotes(document) ??[]
            setPfromNotes(products)
        }
        f()
    },[])


     //  FOR TEST - REMOVE AFTER IMPLEMENTING THE REAL OLD PRODUCTS
    // const oldProducts = [...document.products.map((x, i) => {
    //     // return every second product in the array and change the quantity to be the between 1 to 10
    //     if (i % 2 == 0) {
    //         return {
    //             ...x,
    //             quantity: Math.floor(Math.random() * 10) + 1,
    //             quantitySecond: Math.floor(Math.random() * 10) + 1,
    //         };
    //     }
    // }).filter((x) => x != undefined), {
    //     "discount": 0,
    //     "nkObject": {
    //         "productId": 5,
    //         "value": 2,
    //         "returns": 0,
    //         "price": 6,
    //         "supplier_id": -1,
    //         "use_price": 0,
    //         "use_price_tax_note": 0,
    //         "discount": 0,
    //         "wrapped_amount": 0,
    //         "wrapped_amount_return": 0,
    //         "conversion_ratio": 0,
    //         "wrapped_unit": 0,
    //         "notes": null,
    //         "doneState": 0,
    //         "cost": null,
    //         "tempPosition": 0,
    //         "isClient": true,
    //         "useWrapped": 0,
    //         "changedPrice": 1
    //     },
    //     "quantityReturns": 0,
    //     "quantitySecondReturns": 0,
    //     "totalPrice": 6,
    //     "id": 5,
    //     "external_id": "73",
    //     "name": "חלונות",
    //     "image": "",
    //     "barcode": "666",
    //     "costPrice": 4,
    //     "position": 2,
    //     "quantity": 2,
    //     "quantitySecond": 0,
    //     "no_tax_product": false,
    //     "unit": "קרטון",
    //     "in_unit": 10,
    //     "category": "קטגוריה 1",
    //     "category2": "",
    //     "available_units": [
    //         "קרטון"
    //     ],
    //     "notes": null,
    //     "conversion_ratio": 0
    // }
    // ];
    // document.order_id = "123456789"
    // document.order_products = oldProducts;
    // END OF TEST TO REMOVE

    return (
        <SubCard
            darkTitle
            title={
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Grid container sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                <Typography fontWeight='bold'>
                    {(document.type === 'invoice') ? 'חשבונית מס' :  document.type === 'refund' ? 'חשבונית מס זיכוי':'חשבונית מס קבלה'} {onIdChange ?
                    <NoNegativeInput

                        placeholder={'אוטומטי'}
                        changer={(v)=>{
                            onIdChange(v)
                        }}

                    />: document.id ? document.id : ''}
                </Typography>
                {
                    document?.type === "invoice" && (document?.orderId && document?.nkObject?.getConnectedOrder()) ? ( //document?.old_prodcuts?.length > 0 ? (
                        <Chip
                            label={`הזמנה ${document?.orderId}`}
                            color={"info"}
                            sx={{
                                ml: 2,
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                setDisplayOrderProducts(!displayOrderProducts);
                            }}
                        />
                    ) : null
                }
                    </Grid>
                    {(document && document.id != 0 && apiConfig && apiConfig.hasOne()) ?
                        <MyChip color={'primary'}
                                onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id, document.nkObject.getConnectedDocType()), (result) => {
                                    setSendSuccess(true)
                                })}
                                label={(document.one != undefined && document.one != null && document.one.send || sendSuccess) ? 'נשלח לOne' : 'לא נשלח לOne'}></MyChip> :
                        undefined
                    }
                    {(document && document.id != 0 && apiConfig && apiConfig.hasManager()) ?
                        <MyChip color={'primary'}
                                onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id,  document.nkObject.getConnectedDocType()), (result) => {
                                    setSendSuccess(true)
                                })}
                                label={(document.manager != undefined && document.manager != null && document.manager.send || sendSuccess) ? 'נשלח למנגר' : 'לא נשלח למנגר'}></MyChip> :
                        undefined
                    }
                    {(document && document.id != 0 && apiConfig && apiConfig.hasStoreNext()) ?
                        <MyChip color={'primary'}
                                onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id,  document.nkObject.getConnectedDocType()), (result) => {
                                    setSendSuccess(true)
                                })}
                                label={(document.storeNext != undefined && document.storeNext != null && document.storeNext.send || sendSuccess) ? 'נשלח לסטורנקס' : 'לא נשלח סטורנקטס'}></MyChip> :
                        undefined
                    }
                    {(document && document.id != 0 && apiConfig && apiConfig.hasHashavshevet()) ?
                        <MyChip color={'primary'}
                                onClick={(c) => callbackNetworkResult(() => sendReqForApi(document.id,  document.nkObject.getConnectedDocType()), (result) => {
                                    setSendSuccess(true)
                                })}
                                label={(document.hashavshevet != undefined && document.hashavshevet != null && document.hashavshevet.send || sendSuccess) ? 'נשלח לחשבשבת' : 'לא נשלח לחשבשבת'}></MyChip> :
                        undefined
                    }
              </div>

            }
            secondary={<Logo width={50} />}
        >
            <Grid container spacing={2}>
                <DocumentHeaderPreview />
                <Grid item xs={12}>
                    <Grid container>
                        <DocumentClientDetails address={address} name={customer.name}
                                               businessName={document.details!.companyName}
                                               businessId={document.details!.companyId}
                                               id={customer.id} external_id={customer.externalId}
                                               phone={document.details!.phone} email={document.details!.email}  />
                        <Grid item sm={6}>
                            <Grid container>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    <FormattedMessage id={document.type === 'invoice' ? 'Invoice Details' : 'Refund Invoice Details'}/>
                                </Typography>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                <FormattedMessage id='Date' />:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body2'>
                                                {format(document.date, 'PPP', {
                                                    locale: config.i18n === 'he' ? he : enUS
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                תאריך הנפקה:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='body2'>
                                                {format(document.issueDate, 'PPPPp', {
                                                    locale: config.i18n === 'he' ? he : enUS
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant='body2'>
                                                <FormattedMessage id='Status' />:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MyChip
                                                label={<FormattedMessage id={document.status} />}
                                                variant='outlined'
                                                size='small'
                                                chipcolor={
                                                    document.status === 'pending_approval'
                                                        ? 'warning'
                                                        : document.status === 'approved'
                                                            ? 'success'
                                                            : document.status === 'open'
                                                                ? 'error'
                                                                : ''
                                                }
                                            />
                                        </Grid>
                                        <>
                                            <Grid item xs={4}>
                                                <Typography variant='body2'>
                                                    סוכן:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant='body2'>
                                                    {document.agent}
                                                </Typography>
                                            </Grid>
                                        </>
                                        {(!editting) ?
                                            <>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        מספר הקצאה:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='body2'>
                                                        {document.taxConfirmation}
                                                    </Typography>
                                                </Grid>
                                            </> : undefined
                                        }
                                        {
                                            editting &&  (
                                                    <>
                                                        <Grid item xs={4}>
                                                            <Typography variant='body2'>
                                                                מספר הקצאה:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Input
                                                                type='text'
                                                                value={internalEdit?.taxConfirmation ??  document.taxConfirmation}
                                                                onChange={(e) => setInternalEdit && setInternalEdit({...internalEdit,taxConfirmation:(e.target.value)})}
                                                            />
                                                        </Grid>
                                                        {JSDB().getUser().first.hesh_israel==1 ?  <button onClick={() =>{
                                                            startLog()
                                                        }}>
                                                            ממשק מס הכנסה
                                                        </button>:undefined}

                                                    </>
                                                )
                                        }
                                        {document.deliveryCertificatesIds.length > 0 ? (
                                            <Grid item xs={12}>
                                                <Typography variant='body2'>
                                                    <FormattedMessage id="Delivery Certificates ID's" />:
                                                </Typography>
                                            </Grid>
                                        ) : undefined}
                                        {document.deliveryCertificatesIds.map((d, i) => (
                                            <Grid key={i} item xs={4}>
                                                <Typography variant='subtitle1'>{d}</Typography>
                                            </Grid>
                                        ))}
                                                                              <>
                                        {(editting ||  outerDoc ) &&
                                            <Grid container xs={12} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes2}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Input
                                                        value={internalEdit?.notes2 ?? document?.notes2}
                                                        onChange={(e) => outerDoc ? outerDoc(prev=>{
                                                            return {...prev,notes2:e.target.value}
                                                        }): setInternalEdit && setInternalEdit({...internalEdit,notes2:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {document?.notes2?.length > 0 && !(editting ||  outerDoc ) &&
                                            <Grid container xs={12} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes2}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='body2'>
                                                        {
                                                            document?.notes2
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        </>
                                        <>
                                        {(editting ||  outerDoc ) &&
                                            <Grid container xs={12} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes3}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Input
                                                        value={internalEdit?.notes3 ?? document?.notes3}
                                                        onChange={(e) => outerDoc ? outerDoc(prev=>{
                                                            return {...prev,notes3:e.target.value}
                                                        }): setInternalEdit && setInternalEdit({...internalEdit,notes3:e.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        {document?.notes3?.length > 0 && !(editting ||  outerDoc ) &&
                                            <Grid container xs={12} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <Grid item xs={4}>
                                                    <Typography variant='body2'>
                                                        { notes3}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant='body2'>
                                                        {
                                                            document?.notes3
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        </>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {document.handArray && document.handArray?.[0].value !== 0 && document.handArray?.[0].amount !== 0 ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table aria-label="">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize: "0.9rem", fontWeight: "bold", textAlign: "right"}} align="right">תיאור ידני</TableCell>
                                        <TableCell style={{fontSize: "0.9rem", fontWeight: "bold", textAlign: "right"}} align="right">כמות</TableCell>
                                        <TableCell style={{fontSize: "0.9rem", fontWeight: "bold", textAlign: "right"}} align="right">{`מחיר${customer!.includeTax ? '(כולל)' : ''}`}</TableCell>
                                        <TableCell style={{fontSize: "0.9rem", fontWeight: "bold", textAlign: "right"}} align="right">{`סהכ${customer!.includeTax ? '(כולל)' : ''}`}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {document.handArray.map((row) => (
                                        <TableRow
                                            key={row.description}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{textAlign: "right"}} align="right">{row.description}</TableCell>
                                            <TableCell style={{textAlign: "right"}} align="right">{row.amount}</TableCell>
                                            <TableCell style={{textAlign: "right", minWidth: row.value.toString().length * 20 < 121 ? row.value.toString().length * 20 : 115}} align="right">{row.value}<IconCurrencyShekel size={10} color='black' /></TableCell>
                                            <TableCell style={{textAlign: "right"}} align="right">{
                                            roundDecimals((row.value * row.amount), 2)
                                            }
                                            <IconCurrencyShekel size={10} color='black' />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    ) : undefined}
                {pfromNotes.length > 0  ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1' fontWeight='bold'>
                                סיכום מוצרים מתעודות
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomerProductsPreviewList products={pfromNotes.sort((a, b) => (
                                (a?.notePlacement != undefined || b?.notePlacement != undefined) ? ((a?.notePlacement ?? 0) - (b?.notePlacement ?? 0)) :
                                    (a?.position ?? 2000 - b?.position ?? 2000))

                            )}
                                                         withReturns
                                                         disableHoverableIncrementer={document.status === 'approved'}
                                                         date={document.date}
                                                         id={customer.id}

                            />
                        </Grid>
                    </>
                ) : undefined}

                {document.products.length > 0 || onProductChange ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1' fontWeight='bold'>
                                מוצרים
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomerProductsPreviewList products={document.products.sort((a, b) => (
                                (a?.notePlacement != undefined || b?.notePlacement != undefined) ? ((a?.notePlacement ?? 0) - (b?.notePlacement ?? 0)) :
                                    (a?.position ?? 2000 - b?.position ?? 2000))

                            )}
                                                         withReturns
                                                         disableHoverableIncrementer={document.status === 'approved'}
                                                         onProductChange={onProductChange} date={document.date}
                                                         id={customer.id}
                                                         orderProducts={displayOrderProducts && !editting ?  deserializeDocument(document?.nkObject?.getConnectedOrder())?.products ??[] :undefined}
                                                         displayOrderProducts={!editting && displayOrderProducts}
                                                         />
                        </Grid>
                    </>
                ) : undefined}
                {document.round !== 0 ?
                    <>
                        <Grid item xs={6}>
                            <Typography variant='subtitle1' fontWeight='bold'>
                                עיגול סכום
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{direction:'ltr'}} variant='subtitle1' fontWeight='bold' >
                                {roundDecimals(document.round,4)} <IconCurrencyShekel size={10} color='black' />
                            </Typography>

                        </Grid>

                    </> : undefined}
                <Grid item xs={12}>
                    <SubCard
                        sx={{
                            bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                        }}
                    >
                        <Grid container rowSpacing={0}>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    סהכ לפני מעמ
                                </Typography>
                            </Grid>
                            <Grid item style={{direction:'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black' />
                                {roundDecimals(document.preValue ??0).toLocaleString() }

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    {(document.discount >0) ? `הנחה ${document.discount}%` : `תוספת ${Math.abs(document.discount)}%`}
                                </Typography>
                            </Grid>
                            <Grid item style={{direction:'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black' />
                                {roundDecimals(document.mainValue - ((document.taxValue) ? document.taxValue : 0) -document.preValue!).toLocaleString()}

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    סהכ אחרי
                                </Typography>
                            </Grid>
                            <Grid item style={{direction:'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black' />
                                {roundDecimals(document.mainValue - ((document.taxValue) ? document.taxValue : 0)).toLocaleString()}

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                                    מע"מ
                                </Typography>
                            </Grid>
                            <Grid item style={{direction:'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black' />
                                {roundDecimals( ((document.taxValue) ? document.taxValue : 0)).toLocaleString()}

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                    <FormattedMessage
                                        id='Total' />
                                </Typography>
                            </Grid>
                            <Grid item style={{direction:'ltr'}} xs={5}>
                                <IconCurrencyShekel size={10} color='black' />
                                {roundDecimals(document.mainValue).toLocaleString()}

                            </Grid>

                        </Grid>
                        {document?.type === "invoice" && (document?.orderId && document?.nkObject?.getConnectedOrder()?.getKartonString()) ?
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>
                                    ליקוט: {     document?.nkObject?.getConnectedOrder()?.getKartonString()}
                                </Typography>
                        </Grid>  : undefined
                        }

                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <PreviewDocumentComments outerDoc={outerDoc} comment={document.comment} doc={ document} pool={pool}/>
                            </Grid>
                        </Grid>


                    </SubCard>
                </Grid>
            </Grid>
        </SubCard>
    );
};

export default InvoicePreview;

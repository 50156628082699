/* eslint-disable no-nested-ternary */
import React from 'react';

// material-ui
import {Grid, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';


interface DocumentClientDetailsInterface {
    name: string,
    businessName: string,
    businessId: string,
    address: string,
    phone: string,
    email: string,
    id:string,
    external_id:string
}

const DocumentClientDetails = ({ ...details }: DocumentClientDetailsInterface) =>
    // TODO: Change to single customer fetching
    (
        <Grid item sm={6}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' fontWeight='bold'>
                        <FormattedMessage id='Customer' />
                        &nbsp;
                        מס'
                        &nbsp;
                        {details.id}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>{details.name}</Typography>
                        </Grid>
                        {(details.businessName !== details.name) ?
                            <Grid item xs={12}>
                                <Typography variant='subtitle1'>{details.businessName}</Typography>
                            </Grid>
                            : undefined}
                        <Grid item xs={12}>
                            <Typography variant='caption'>{details.address}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2'>{details.phone}</Typography>
                        </Grid>
                        {details.external_id && details.external_id!='-1' ?    <Grid item xs={12}>
                            <Typography variant='body2'>מזהה חיצוני {details.external_id}</Typography>
                        </Grid> :undefined}

                        <Grid item xs={12}>
                            <Typography variant='body2' color='primary'>
                                {details.email}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
;

export default DocumentClientDetails;

import { useEffect, useState } from "react";
import { Popover, Box, Grid, Typography, Button, TextField } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import config from "config";
import { enUS, he } from 'date-fns/locale';
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import { addDays } from "date-fns";

const ChipRangeDate = ({
    open,
    setOpen,
    filterFunc
}) => {
    const [range, setRange] = useState<RangeInput<null>>([addDays(new Date(), -30), new Date()]);
    useEffect(() => {
        const rangeFromLocalStorage = localStorage.getItem('defaultOrdersFilterRange');
        if (rangeFromLocalStorage) {
            switch (rangeFromLocalStorage) {
                case 'today':
                    setRange([new Date(), new Date()]);
                    break;
                case 'yesterday':
                    setRange([addDays(new Date(), -1), addDays(new Date(), -1)]);
                    break;
                case 'lastWeekSameDay':
                    setRange([addDays(new Date(), -7), addDays(new Date(), -7)]);
                    break;
                case 'lastWeek':
                    setRange([addDays(new Date(), -7), new Date()]);
                    break;
                case 'lastMonth':
                    setRange([addDays(new Date(), -30), new Date()]);
                    break;
                default:
                    setRange([addDays(new Date(), -30), new Date()]);
            }
        }
    }, [open]);
    return (
        <Popover
            open={open}
            anchorEl={document.getElementById('orders-customer-range')}
            onClose={() => {
                setOpen(false)
                setRange([new Date(), new Date()]);
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box p={1}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        טווח תאריכים
                    </Typography>
                </Grid>
                <Grid item sx={{
                    display: 'flex',
                    gap: "10px",
                }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                        <DateRangePicker
                            startText="מתאריך"
                            endText="עד תאריך"
                            value={range}

                            onChange={(newValue) => {
                                setRange(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField {...startProps} variant="outlined" />
                                    <Box sx={{ mx: 2 }}> עד </Box>
                                    <TextField {...endProps} variant="outlined" />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setOpen(false);
                            // CALLBACK
                            if (filterFunc)
                                filterFunc(range);
                            setRange([new Date(), new Date()]);
                        }}
                    >
                        אישור
                    </Button>
                </Grid>
            </Box>
        </Popover>
    )
}

export default ChipRangeDate;

import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// project
import MainRoutes from 'routes/MainRoutes';

// assets
import { IconChevronLeft } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import HomeIcon from '@mui/icons-material/Home';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center'
};

const staticSX = {
    display: 'flex',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center',
    color: 'grey.500'
};

// Simple breadcrumbs navigation that follows the current location pathname
const AppBreadcrumbsNavigation = () => {
    const theme = useTheme();
    const location = useLocation();

    const iconStyle = {
        marginRight: theme.spacing(0.75),
        marginTop: `-${theme.spacing(0.25)}`,
        width: '1rem',
        height: '1rem',
        color: theme.palette.secondary.main
    };

    const routes = useMemo(() => MainRoutes.children.map((c) => c.path), []);
    const subLocations = useMemo(() => location.pathname.split('/'), [location]);
    const hasMatchRoute = (s: string) => {
        const sub = s.split('/');

        return routes.some((r) => {
            const rSub = r.split('/');
            if (rSub.length !== sub.length) return false;

            return rSub.every((s, i) => s.startsWith(':') || s === sub[i]);
        });
    };

    if (subLocations.every((s) => s === '')) return <Box />;

    return (
        <Card
            sx={{
                border: '1px solid',
                borderColor: theme.palette.primary[200] + 75,
                background: theme.palette.background.default
            }}
        >
            <Box sx={{ p: 2, pl: 2 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item>
                        <MuiBreadcrumbs
                            sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
                            aria-label="breadcrumb"
                            maxItems={8}
                            separator={<IconChevronLeft />}
                        >
                            <Typography component={Link} to="/" color="inherit" variant="subtitle1" sx={linkSX}>
                                <HomeIcon sx={{ ...iconStyle, mr: 0 }} />
                            </Typography>
                            {subLocations.slice(1, subLocations.length - 1).map((p) => {
                                const curRoute = location.pathname.substring(0, location.pathname.lastIndexOf(p)) + p;

                                return hasMatchRoute(curRoute) ? (
                                    <Typography component={Link} to={curRoute} sx={linkSX} variant="subtitle1">
                                        <FormattedMessage id={p} />
                                    </Typography>
                                ) : (
                                    <Typography sx={staticSX} variant="subtitle1">
                                        <FormattedMessage id={p} />
                                    </Typography>
                                );
                            })}

                            {subLocations.length > 1 && (
                                <Typography variant="subtitle1" sx={staticSX}>
                                    <FormattedMessage id={subLocations[subLocations.length - 1]} />
                                </Typography>
                            )}
                        </MuiBreadcrumbs>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

export default AppBreadcrumbsNavigation;

import {
    IconButton,
    Tooltip,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TextField, CircularProgress, Autocomplete
} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';
import {useDispatch, useSelector} from 'react-redux';
import {SNACKBAR_OPEN} from '../../store/actions';
import {ChangeEvent, memo} from "react";
import Swal from "sweetalert2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {getAsByteArray, load_from_xlsx} from "../../api/customer/files.api";
import {read, utils} from "xlsx";
import {getAgents} from "../../store/agents/agentsSlice";
import Customer from "../../types/customer";
import {
    selectActiveCustomers,
    selectActiveNetworks,
    selectDistinctValuesNetwork
} from "../../store/customers/customersSlice";
import NoNegative from "../textFieldNumber";
import MyDatePicker from "../MyDatePicker";

type excelheader = "string" | "number" | "date" | "boolean";

export interface ExcelHeader {
    name: string;
    must: boolean;
    type: excelheader;
    column?: number;
    picker: any[];
    extractor?: (value: any) => string;
    defultValue?: any;
}

const translateType = (type: excelheader) => {
    switch (type) {
        case "string":
            return "טקסט";
        case "number":
            return "מספר";
        case "date":
            return "תאריך";
        case "boolean":
            return "בוליאני";
    }
}

const typeExample = (type: excelheader) => {
    switch (type) {
        case "string":
            return "טקסט - לדוגמא: יוני";
        case "number":
            return "מספר - לדוגמא: 123";
        case "date":
            return "תאריך - לדוגמא: 01/01/2021";
        case "boolean":
            return "בוליאני - לדוגמא: true/ false";
    }
}

function DialogUploadFile({
                              uploadCallback,
                              title,
                              body,
                              finishCallBack,
                              excelHeaders,
                        doOpen,
    setDoOpen,
                              withDate
                          }: { uploadCallback?: any, title?: string, body?: ExcelHeader[], finishCallBack?: any, excelHeaders?: ExcelHeader[] ,doOpen,setDoOpen,withDate}) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const [callbackDialog, setCallbackDialog] = React.useState(false);
    const [startFrom, setStartFrom] = React.useState(0);
    const [columnsSet, setColumns] = React.useState(body?.map((header) => undefined) ?? []);
    const [pickerItems, setPickerItems] = React.useState(excelHeaders?.map((header) => {
        return {
            name: header.name,
            value: header.defultValue ?? "",
            options: header.picker,
            extractor: header.extractor,
            default: header.defultValue
        }
    }));
    React.useEffect(() => {
        setPickerItems(excelHeaders?.map((header) => {
            return {name: header.name, value: "", options: header.picker, extractor: header.extractor}
        }));
    }, [excelHeaders])

    React.useEffect(() => {
        if(doOpen){
            setOpen(doOpen)
            setDoOpen(false)
        }

    }, [doOpen])
    const [callbackData, setCallbackData] = React.useState<any>(null);
    const [file, setFile] = React.useState<File>();
    const inputRef = React.useRef<HTMLInputElement | null>(null);


    const handleUploadClick = () => {
        // 👇 We redirect the click event onto the hidden input element
        inputRef.current?.click();
    };
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const isFileValid = await checkValidExcel(e.target.files[0]);

        if (!isFileValid) {
            Swal.fire({
                title: 'שגיאה',
                icon: 'error',
                text: 'קובץ לא תקין, אנא בדוק שכל עמודות החובה רשומות כנדרש'
            });
            return;
        }

        setFile(e.target.files[0]);

        // 🚩 do the file upload here normally...
    };

    const checkValidExcel = async (file: File) => {
        if (!body) return true;
        const wb = read(await file.arrayBuffer(), {type: 'array'});
        const ws = wb.Sheets[wb.SheetNames[0]];
        const headers = utils.sheet_to_json(ws, {header: 1, raw: false})[0] as string[];
        // Manually decode the headers

        let columns = {}
        columnsSet.forEach((header, i) => {
            if (header!=undefined && !isNaN(Number(header))) {

                columns[body[i].name] = Number(header);
            }
        })
        const decoder = new TextDecoder('windows-1255');
        const headers2 = headers.map(header => decoder.decode(new Uint8Array(header.split('').map(char => char.charCodeAt(0)))));
        let eMust = []
        console.log(columns)
        console.log(headers2)
        console.log(body)
        body.forEach((header) => {
            let must = header.must
            headers.forEach((h, i) => {
                if (header.name === h) {
                    if (columns[header.name] == undefined)
                        columns[header.name] = i;
                    if (header.must) {
                        must = false;
                    }
                }
            })
            headers2.forEach((h, i) => {
                if (header.name === h) {
                    if (columns[header.name] == undefined)
                        columns[header.name] = i;
                    if (header.must) {
                        must = false;
                    }
                }
            })
            if (must) {
                eMust.push(header.name);
            }


        })
        console.log(eMust)
        if (eMust.filter((f) => columns[f] == undefined).length > 0) {
            return false;
        }

        return columns;

    };


    const handleOk = async () => {
        handleClose();
        try {
            if (file && uploadCallback) {
                const args = {}
                pickerItems?.forEach((item) => {
                    if (item.value != undefined && item.value != "")
                        args[item.name] = item.value
                })
                if(withDate)
                    args.date = date
                const cols = await checkValidExcel(file);
                args.cols = cols
                const d = await uploadCallback(file, args)
                if (typeof  d == "string")
                    listSuccessfullUpload(JSON.parse(d.replaceAll("\\", "")))
                else
                    listSuccessfullUpload(d)
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    message: ` הקובץ נטען`,
                    variant: 'alert',
                    alertSeverity: 'success'
                });

            } else {
                return;
            }
        } catch (e) {
            console.log(e)
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                message: `לא הצליח לטעון קובץ`,
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
        if (finishCallBack)
            finishCallBack()
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    // React.useEffect(() => {
    //     console.log(updateCustomerAsyncState);
    //     if (updateCustomerAsyncState === 'succeeded') {
    //         dispatch({
    //             type: SNACKBAR_OPEN,
    //             open: true,
    //             message: 'המוצרים עודכנו בהצלחה!',
    //             variant: 'alert',
    //             alertSeverity: 'success'
    //         });
    //
    //         dispatch(initMassCustomersProductAsyncState());
    //
    //     } else if (updateCustomerAsyncState === 'failed') {
    //         Swal.fire({
    //             title: 'שגיאה בעדכון מוצרים',
    //             target: '#custom-target',
    //             text: '',
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'המשך'
    //         });
    //         dispatch(initMassCustomersProductAsyncState());
    //     }
    // }, [updateCustomerAsyncState, dispatch]);

    const handleClose = () => {
        setOpen(false);
        setFile(undefined);
    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue((event.target as HTMLInputElement).value);
    // };

    type successStatus = 1 | 0;
    const listSuccessfullUpload = (callback: {
        line: number;
        status: successStatus;
        msg: string;
        score:number
    }[]) => {
        const allFailsToTop = callback.sort((a, b) => {
            if (a.status === 0 && b.status === 1) return -1;
            if (a.status === 1 && b.status === 0) return 1;
            return 0;
        });
        setCallbackDialog(true);
        setCallbackData(allFailsToTop);
    };

    const onApprove =
        () => {
            handleOk();
        };

    return (
        <div>
            {doOpen==undefined ?      <Tooltip title={'לטעון מאקסל'}>
                <IconButton size='medium' id='upload-file-dialog-button' onClick={handleClickOpen}>
                    <CloudUploadIcon/>
                </IconButton>
            </Tooltip>:undefined}

            {/* <Tooltip title={'הוסף סוכן/נהג/מלקט חדש'}>
                <IconButton size='large' onClick={handleClickOpen} style={{'color':'#008000'}}>
                    <PersonAddIcon fontSize={'medium'} />
                </IconButton>
            </Tooltip> */}
            {open ? <Dialog id='doc_upload_file' maxWidth={'sm'} fullWidth={true}
                            open={open} onClose={handleClose}>
                <DialogTitle> {title ? title : 'טען קובץ'}
                </DialogTitle>
                <DialogContent>

                    <div>
                        <div>
                            {body &&
                                <TableContainer component={Paper}>
                                    <Table className="excel-headers-list" aria-label="simple table">

                                        <TableBody>
                                            {body.map((header: ExcelHeader, index) => (
                                                <TableRow key={header.name} style={{}}>
                                                    <TableCell size='small' component="th" scope="row">
                                                        {header.name}
                                                    </TableCell>
                                                    <TableCell size='small' component="th" scope="row">
                                                        <NoNegative
                                                            id='head'
                                                            fullWidth
                                                            label='עמודה'
                                                            value={columnsSet[index]}

                                                            changer={(v) =>
                                                                setColumns((prev) => {
                                                                    prev[index] = v;
                                                                    return [...prev];
                                                                })
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell size='small' align="right">
                                                        {header.must ? "חובה" : "לא חובה"}
                                                    </TableCell>
                                                    <TableCell size='small' align="right" style={{
                                                        cursor: 'pointer',
                                                        color: 'blue'
                                                    }}>
                                                        <Tooltip title={typeExample(header.type)}>
                                                            <span>{translateType(header.type)}*</span>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </div>
                        <br/>
                        <br/>
                        <div style={{display: 'flex', flexDirection: 'row', gap: '16px'}}>
                            {pickerItems?.map((pickerItem, index) => {
                                return (
                                    <div style={{flexGrow: 1, minWidth: 0}} key={index}>
                                        <Autocomplete
                                            id={pickerItem.name}
                                            onChange={(event, value) => {
                                                if (value) {

                                                    setPickerItems(prev => {
                                                        const newPickerItems = [...prev];
                                                        newPickerItems[index].value = value;
                                                        return newPickerItems;
                                                    })
                                                } else {
                                                    setPickerItems(prev => {
                                                        const newPickerItems = [...prev];
                                                        newPickerItems[index].value = pickerItem.default;
                                                        return newPickerItems;
                                                    })

                                                }
                                            }}
                                            options={pickerItem.options}
                                            getOptionLabel={(option) => pickerItem.extractor ? pickerItem.extractor(option) : option}
                                            multiple={pickerItem.extractor != undefined}
                                            fullWidth
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={pickerItem.name}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>

                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}

                                                />
                                            )}
                                        />
                                    </div>
                                );
                            })}

                        </div>
                        <br/>

                        <br/>
                        <div><b>הקובץ:</b></div>

                        {/* 👇 Our custom button to select and upload a file */}
                        <button onClick={handleUploadClick}>
                            {file ? `${file.name}` : 'לחץ כאן כדי לעלות'}
                        </button>
                        {withDate?  <DialogActions>
                            <MyDatePicker date={date} setDate={setDate}/>
                        </DialogActions>:undefined}
                        {/* 👇 Notice the `display: hidden` on the input */}
                        <input
                            type="file"
                            ref={inputRef}
                            onChange={handleFileChange}
                            style={{display: 'none'}}
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{backgroundColor: 'green', color: 'white'}}>טען קובץ
                    </Button>
                    <Button onClick={handleClose} style={{backgroundColor: 'red', color: 'white'}}>ביטול</Button>
                </DialogActions>


            </Dialog> : undefined}

            <Dialog
                open={callbackDialog}
                onClose={() => setCallbackDialog(false)}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
            >
                <DialogTitle id="success-dialog-title">
                    {
                        callbackData?.filter((item: { status: successStatus; }) => item.status === 0).length > 0 ?
                            <span style={{color: "red"}}>
                                הקובץ נטען עם {callbackData?.filter((item: { status: successStatus; }) => item.status === 0).length} שגיאות מתוך {callbackData?.length} שורות </span> :
                            <span style={{color: "green"}}>
                                הקובץ נטען בהצלחה
                            </span>
                    }
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className="excel-headers-list" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell size='small'>שורה</TableCell>
                                    <TableCell size='small' align="right">סטטוס</TableCell>
                                    <TableCell size='small' align="right">הערה</TableCell>
                                    <TableCell size='small' align="right">ציון</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {callbackData?.map((item: { line: number; status: successStatus; msg: string; score:number}) => (
                                    <TableRow key={item.line}>
                                        <TableCell size='small' component="th" scope="row">
                                            {item.line}
                                        </TableCell>
                                        <TableCell size='small' align="right">
                                            {item.status === 1 ? "הצלחה" : "כשלון"}
                                        </TableCell>
                                        <TableCell size='small' align="right">
                                            {item.msg}
                                        </TableCell>
                                        <TableCell size='small' align="right">
                                            {item.score ??0}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCallbackDialog(false)} autoFocus>
                        סגור
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default memo(DialogUploadFile);

import Customer from 'types/customer';
import MyChip from 'ui-component/extended/Chip';

function CustomerStatus({ customer }: { customer: Customer }) {
    return (
        // eslint-disable-next-line no-nested-ternary
        customer?.status === 'חולק' ? (
            <MyChip label="חולק" chipcolor="success" size="small" />
        ) : customer?.status === 'הזמנה פתוחה' ? (
            <MyChip label="הזמנה פתוחה" chipcolor="orange" size="small" />
        ) : (
            <></>
        )
    );
}

export default CustomerStatus;

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/lab';

import styledTag from '../styles';

// locale
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// assets
import CloseIcon from '@mui/icons-material/Close';
import { IconCalendar } from '@tabler/icons';

// types
import { TagProps } from '../types';

function DateTag(props: TagProps) {
    const { onApplyFilter, filter, onDelete, ...other } = props;
    const [date, setDate] = React.useState<Date>(new Date());

    return (
        <Box {...other} sx={{ cursor: 'pointer' }}>
            <Typography variant="h5" fontWeight="bold">
                {filter.label ?? filter.id}:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    components={{
                        OpenPickerIcon: () => <IconCalendar size={22} />
                    }}
                    renderInput={(p) => <TextField {...p} />}
                    value={date}
                    onChange={(newValue) => {
                        if (newValue) {
                            setDate(newValue);
                            if (onApplyFilter) {
                                onApplyFilter({ property: filter.id, contains: '', from: newValue });
                            }
                        }
                    }}
                />
            </LocalizationProvider>
            <CloseIcon onClick={onDelete} />
        </Box>
    );
}

const StyledDateTag = styledTag(DateTag);

export default StyledDateTag;

export const agTableLocalisation = {
    rowGroupColumnsEmptyMessage: 'גרור לכאן עמודות לפילטור',
    valueColumnsEmptyMessage: 'גרור לכאן עמודות לפילטור',
    noRowsToShow: 'אין נתונים להצגה',
    columns: 'עמודות',
    filters: 'פילטרים',
    pivotMode: 'פיבוט',
    values: 'ערכים',
    contains: 'מכיל',
    notContains: 'לא מכיל',
    equals: 'שווה',
    notEqual: 'לא שווה',
    lessThan: 'קטן מ',
    startsWith: 'מתחיל ב',
    endsWith: 'מסתיים ב',
    blank: 'ריק',
    notBlank: 'לא ריק',
    pinColumn: 'צמצם עמודה',
    autosizeThiscolumn: 'התאמת גודל עמודה',
    autosizeAllColumns: 'התאמת גודל כל העמודות',
    resetColumns: 'איפוס עמודות',
    filterOoo: 'פילטר',
    searchOoo: 'חיפוש',
    groupBy: 'קבץ לפי',
    ungroupBy: 'בטל קיבוץ לפי',
    ariaRowGroupDropZonePanelLabel: 'גרור לכאן עמודות לקיבוץ לפי',
    groups: 'קבוצות',
    noPin: 'ללא הצמדה',
    pinLeft: 'הצמדה לשמאל',
    pinRight: 'הצמדה לימין',
    totalRows: 'סה״כ שורות',
    totalAndFilteredRows: 'שורות',
    filteredRows: 'שורות מסוננות',
    selectedRows: 'שורות נבחרות',
    lessThanOrEqual: 'קטן או שווה',
    greaterThanOrEqual: 'גדול או שווה',
    greaterThan: 'גדול מ',
    inRange: 'בטווח',
    group: 'קבוצה',
    chartRange: 'גרפים',
    sum: 'סכום',
    first: 'ערך ראשון',
    last: 'ערך אחרון',
    min: 'מינימום',
    max: 'מקסימום',
    none: 'ללא',
    count: 'ספירה',
    avg: 'ממוצע',

    columnChart: 'גרף עמודות',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',
    histogramFrequency: 'Frequency',

    combinationChart: 'Combination',
    columnLineCombo: 'Column & Line',
    AreaColumnCombo: 'Area & Column',
    selectAll: '(בחר הכל)',
    selectAllSearchResults: 'בחר את כל תוצאות החיפוש',
    blanks: '(ריקים)',
    noMatches: 'ללא התאמות'
};

export const AG_GRID_LOCALE_HE = {
    // הגדר פילטר
    selectAll: '(בחר הכל)',
    selectAllSearchResults: '(בחר את כל תוצאות החיפוש)',
    searchOoo: 'חפש...',
    blanks: '(ריק)',
    noMatches: 'אין התאמות',
    // פילטר מספרים ופילטר טקסט
    filterOoo: 'פילטר...',
    equals: 'שווה ל',
    notEqual: 'לא שווה ל',
    blank: 'ריק',
    notBlank: 'לא ריק',
    empty: 'בחר אחד',

    // פילטר מספרים
    lessThan: 'פחות מ',
    greaterThan: 'גדול מ',
    lessThanOrEqual: 'פחות או שווה ל',
    greaterThanOrEqual: 'גדול או שווה ל',
    inRange: 'בטווח',
    inRangeStart: 'מתאריך',
    inRangeEnd: 'עד תאריך',

    // פילטר טקסט
    contains: 'מכיל',
    notContains: 'לא מכיל',
    startsWith: 'מתחיל ב',
    endsWith: 'נגמר ב',

    // פילטר תאריכים
    dateFormatOoo: 'dd-mm-yyyy',

    // תנאי פילטר
    andCondition: 'וגם',
    orCondition: 'או',

    // כפתורי פילטר
    applyFilter: 'החל',
    resetFilter: 'אפס',
    clearFilter: 'נקה',
    cancelFilter: 'בטל',

    // Filter Titles
    textFilter: 'סנן טקסט',
    numberFilter: 'סנן מספרים',
    dateFilter: 'סנן תאריכים',
    setFilter: 'הגדר סינון',

    // Group Column Filter
    groupFilterSelect: 'בחר שדה:',

    // Side Bar
    columns: 'עמודות',
    filters: 'סינונים',

    // columns tool panel
    pivotMode: 'מצב Pivot',
    groups: 'קבוצות שורות',
    rowGroupColumnsEmptyMessage: 'גרור לכאן כדי להגדיר קבוצות שורות',
    values: 'ערכים',
    valueColumnsEmptyMessage: 'גרור לכאן כדי לסכם',
    pivots: 'תוויות עמודה',
    pivotColumnsEmptyMessage: 'גרור לכאן כדי להגדיר תוויות עמודות',

    // Header of the Default Group Column
    group: 'קבוצה',

    // Row Drag
    rowDragRow: 'שורה',
    rowDragRows: 'שורות',

    // Other
    loadingOoo: 'טוען...',
    loadingError: 'שגיאה',
    noRowsToShow: 'אין שורות להצגה',
    enabled: 'מופעל',

    // Menu
    pinColumn: 'נעילת עמודה',
    pinLeft: 'נעילה לשמאל',
    pinRight: 'נעילה לימין',
    noPin: 'לא מנועל',
    valueAggregation: 'סכימת ערך',
    noAggregation: 'ללא',
    autosizeThiscolumn: 'התאמה אוטומטית לגודל עמודה זו',
    autosizeAllColumns: 'התאמה אוטומטית לכל העמודות',
    groupBy: 'קבץ לפי',
    ungroupBy: 'בטל קיבוץ לפי',
    addToValues: 'הוסף את ${variable} לערכים',
    removeFromValues: 'הסר את ${variable} מהערכים',
    addToLabels: 'הוסף את ${variable} לתוויות',
    removeFromLabels: 'הסר את ${variable} מהתוויות',
    resetColumns: 'איפוס עמודות',
    expandAll: 'הרחב הכל',
    collapseAll: 'סגור הכל',
    copy: 'העתק',
    // צירופי מקשים
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'העתק עם כותרות',
    copyWithGroupHeaders: 'העתק עם כותרות קבוצה',
    cut: 'גזור',
    paste: 'הדבק',
    ctrlV: 'Ctrl+V',
    export: 'ייצוא',
    csvExport: 'ייצוא ל-CSV',
    excelExport: 'ייצוא ל-Excel',

    // תפריט מקורה וסרגל מצב
    sum: 'סכום',
    first: 'ראשון',
    last: 'אחרון',
    min: 'מינימום',
    max: 'מקסימום',
    none: 'ללא',
    count: 'ספירה',
    avg: 'ממוצע',
    filteredRows: 'מסוננות',
    selectedRows: 'נבחרות',
    totalRows: 'סך השורות',
    totalAndFilteredRows: 'שורות',
    more: 'יותר',
    to: 'עד',
    of: 'מתוך',
    page: 'עמוד',
    nextPage: 'העמוד הבא',
    lastPage: 'העמוד האחרון',
    firstPage: 'העמוד הראשון',
    previousPage: 'העמוד הקודם',

    // פיבוטים
    pivotColumnGroupTotals: 'סכומים',

    // תפריט מקורה (גרפים)
    pivotChartAndPivotMode: 'טבלת פיבוט ומצב פיבוט',
    pivotChart: 'טבלת פיבוט',
    chartRange: 'טווח תרשים',

    columnChart: 'עמודות',
    groupedColumn: 'קבוצות',
    stackedColumn: 'מוערך',
    normalizedColumn: '100% מוערך',

    barChart: 'גרף עמודות',
    groupedBar: 'קבוצות',
    stackedBar: 'מוערכות',
    normalizedBar: 'מוערכות 100%',

    pieChart: 'גרף עוגה',
    pie: 'עוגה',
    doughnut: 'דאונאט',

    line: 'קו',

    xyChart: 'X Y (פיזור)',
    scatter: 'פיזור',
    bubble: 'בועה',

    areaChart: 'אזור',
    area: 'אזור',
    stackedArea: 'מוערכת',
    normalizedArea: 'מוערכת 100%',

    histogramChart: 'היסטוגרמה',
    histogramFrequency: 'תדירות',

    combinationChart: 'שילוב',
    columnLineCombo: 'עמודות וקווים',
    AreaColumnCombo: 'אזור ועמודות',

    // גרפים
    pivotChartTitle: 'גרף ציר צמתים',
    rangeChartTitle: 'גרף טווח',
    settings: 'הגדרות',
    data: 'נתונים',
    format: 'פורמט',
    categories: 'קטגוריות',
    defaultCategory: '(ללא)',
    series: 'סדרות',
    xyValues: 'ערכי XY',
    paired: 'מצב מקורב',
    axis: 'ציר',
    navigator: 'נווט',
    color: 'צבע',
    thickness: 'עובי',
    xType: 'סוג X',
    automatic: 'אוטומטי',
    category: 'קטגוריה',
    number: 'מספר',
    time: 'זמן',
    autoRotate: 'סיבוב אוטומטי',
    xRotation: 'סיבוב X',
    yRotation: 'סיבוב Y',
    ticks: 'סימניות',
    width: 'רוחב',
    height: 'גובה',
    length: 'אורך',
    padding: 'ריפוד',
    spacing: 'מרווח',
    chart: 'גרף',
    title: 'כותרת',
    titlePlaceholder: 'כותרת הגרף - לחץ פעמיים לעריכה',
    background: 'רקע',
    font: 'גופן',
    top: 'עליון',
    right: 'ימין',
    bottom: 'תחתון',
    left: 'שמאל',
    labels: 'תוויות',
    size: 'גודל',
    minSize: 'גודל מינימלי',
    maxSize: 'גודל מקסימלי',
    legend: 'מקרא',
    position: 'מיקום',
    markerSize: 'גודל סימולציה',
    markerStroke: 'מתאם קווים',
    markerPadding: 'ריפוד סימולציה',
    itemSpacing: 'מרווח פריטים',
    itemPaddingX: 'ריפוד פריטים X',
    itemPaddingY: 'ריפוד פריטים Y',
    layoutHorizontalSpacing: 'מרווח אופקי',
    layoutVerticalSpacing: 'מרווח אנכי',
    strokeWidth: 'עובי קו',
    lineDash: 'סוג קו',
    offset: 'התאמה',
    offsets: 'התאמות',
    tooltips: 'סטייה',
    callout: 'קריאה',
    markers: 'סימולציות',
    shadow: 'צל',
    blur: 'טשטוש',
    xOffset: 'התאמת X',
    yOffset: 'התאמת Y',
    lineWidth: 'רוחב קו',
    normal: 'רגיל',
    bold: 'מודגש',
    italic: 'נטוי',
    boldItalic: 'מודגש ונטוי',
    predefined: 'מוגדר מראש',
    fillOpacity: 'שקיפות מילוי',
    strokeOpacity: 'שקיפות קו',
    histogramBinCount: 'מספר מגרעות',
    columnGroup: 'עמודות',
    barGroup: 'עמודי עמודות',
    pieGroup: 'עוגה',
    lineGroup: 'קווים',
    scatterGroup: 'X Y (פיזור)',
    areaGroup: 'שטח',
    histogramGroup: 'היסטוגרמה',
    combinationGroup: 'שילוב',
    groupedColumnTooltip: 'קבוצות',
    stackedColumnTooltip: 'מוערכות',
    normalizedColumnTooltip: 'מוערכות 100%',
    groupedBarTooltip: 'קבוצות',
    stackedBarTooltip: 'מוערכות',
    normalizedBarTooltip: 'מוערכות 100%',
    pieTooltip: 'עוגה',
    doughnutTooltip: 'עוגיית שיש',
    lineTooltip: 'קו',
    groupedAreaTooltip: 'שטח קבוצות',
    stackedAreaTooltip: 'מוערכות',
    normalizedAreaTooltip: 'מוערכות 100%',
    scatterTooltip: 'פיזור',
    bubbleTooltip: 'בועה',
    histogramTooltip: 'היסטוגרמה',
    columnLineComboTooltip: 'עמודות וקווים',
    areaColumnComboTooltip: 'שטח ועמודות',
    customComboTooltip: 'שילוב מותאם אישית',
    noDataToChart: 'אין נתונים זמינים לצורך יצירת גרף.',
    pivotChartRequiresPivotMode: 'גרף מוצג דרוש מצב פיבוט.',
    chartSettingsToolbarTooltip: 'תפריט',
    chartLinkToolbarTooltip: 'מקושר לרשת',
    chartUnlinkToolbarTooltip: 'מנותק מהרשת',
    chartDownloadToolbarTooltip: 'הורד גרף',
    seriesChartType: 'סוג גרף לסדרה',
    seriesType: 'סוג סדרה',
    secondaryAxis: 'ציר משני',

    // ARIA
    ariaChecked: 'סומן',
    ariaColumn: 'עמודה',
    ariaColumnGroup: 'קבוצת עמודות',
    ariaColumnList: 'רשימת עמודות',
    ariaColumnSelectAll: 'החלף בחירת כל העמודות',
    ariaDateFilterInput: 'קלט סינון לפי תאריך',
    ariaDefaultListName: 'רשימה',
    ariaFilterColumnsInput: 'קלט סינון לפי עמודות',
    ariaFilterFromValue: 'סינון מערך מ',
    ariaFilterInput: 'קלט סינון',
    ariaFilterList: 'רשימת סינונים',
    ariaFilterToValue: 'סינון מערך עד',
    ariaFilterValue: 'ערך סינון',
    ariaFilteringOperator: 'סינון עפ"י מפעיל',
    ariaHidden: 'מוסתר',
    ariaIndeterminate: 'לא מוגדר',
    ariaInputEditor: 'עורך קלט',
    ariaMenuColumn: 'לחץ CTRL ENTER לפתיחת תפריט עמודה.',
    ariaRowDeselect: 'לחץ SPACE לביטול בחירת השורה הזאת',
    ariaRowSelectAll: 'לחץ SPACE להחלפת בחירת כל השורות',
    ariaRowToggleSelection: 'לחץ SPACE להחלפת בחירת השורה',
    ariaRowSelect: 'לחץ SPACE לבחירת השורה הזאת',
    ariaSearch: 'חפש',
    ariaSortableColumn: 'לחץ ENTER למיון',
    ariaToggleVisibility: 'לחץ SPACE להחלפת נראות',
    ariaUnchecked: 'לא מסומן',
    ariaVisible: 'נראה',
    ariaSearchFilterValues: 'חפש ערכי סינון',

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'קבוצות שורות',
    ariaValuesDropZonePanelLabel: 'ערכים',
    ariaPivotDropZonePanelLabel: 'תוויות עמודה',
    ariaDropZoneColumnComponentDescription: 'לחץ DELETE להסרה',
    ariaDropZoneColumnValueItemDescription: 'לחץ ENTER לשינוי סוג הצבירה',
    ariaDropZoneColumnGroupItemDescription: 'לחץ ENTER למיון',

    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' של ',
    ariaDropZoneColumnComponentSortAscending: 'מיין בסדר עולה',
    ariaDropZoneColumnComponentSortDescending: 'מיין בסדר יורד',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'תפריט עמודות',
    ariaLabelCellEditor: 'עורך תא',
    ariaLabelDialog: 'דו"ח',
    ariaLabelSelectField: 'בחר שדה',
    ariaLabelTooltip: 'סברת כלי',
    ariaLabelContextMenu: 'תפריט הקשר',
    ariaLabelSubMenu: 'תת תפריט',
    ariaLabelAggregationFunction: 'פונקציית כלכלה',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: '.',
    decimalSeparator: ',',

    customeUserColumns: 'עמודות מותאמות',
    valueAlertText: 'הערך שהכנסת אינו מתאים לסוג העמודה',
    defaultGroupAlertText: 'קבוצה זו אינה ניתנת להסרה'
};

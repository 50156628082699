import { Avatar, Checkbox, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Popover, Select, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import xslx from "../../assets/images";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { extractBeforeNumber } from "./ag-grid-functions";

const getEmptyColumns = (tableRef, columnsToExportIds) => {
  let columnsWithValue = {};
  tableRef?.current?.api?.forEachNodeAfterFilterAndSort((node) => {
    columnsToExportIds.forEach((col) => {
      if (node.data?.[col] || node.data?.[col] === false) {
        if (!columnsWithValue?.[col]) {
          columnsWithValue = {
            ...columnsWithValue,
            [col]: true,
          };
        }
      }
    });
  });
  const emptyColumns = columnsToExportIds.filter((col) => !columnsWithValue?.[col]);
  return emptyColumns;
}
const getEmptyColumnsData = (data, columnsToExportIds) => {
  let columnsWithValue = {};
  data.forEach((node) => {
    columnsToExportIds.forEach((col) => {
      if (node.data?.[col] || node.data?.[col] === false) {
        if (!columnsWithValue?.[col]) {
          columnsWithValue = {
            ...columnsWithValue,
            [col]: true,
          };
        }
      }
    });
  });
  const emptyColumns = columnsToExportIds.filter((col) => !columnsWithValue?.[col]);
  return emptyColumns;
}

const prependData = (columnsToExportIds, tableRef,tableControls) => {
  const data = columnsToExportIds?.map((col) => {

    return {
      data: {
        value: tableControls?.excelPrependTop ? tableControls?.excelPrependTop(col) :tableRef?.current?.api?.getColumnDef(col)?.headerName, // translate gere
      }
    }
  });
  return [
    {
      cells: data,
    }
  ]
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const ExcelExportModal = ({ open, setOpen, tableRef, tableControls }) => {

  const [columnsToExport, setColumnsToExport] = useState<{
    headerName: string;
    id: string;
    column: any,
    checked: boolean;
  }[]>([]);

  const [columnToDivarge, setColumnToDivarge] = useState<{
    headerName: string;
    id: string;
    column: any,
    checked: boolean;
  } | null>(null);

  const [extraOptions, setExtraOptions] = useState<{
    divarge?: string;
    ignoreEmptyColumns?: boolean;
    fontSize: number;
    rowHeight: number;
    prependContent?: boolean;
  }>({
    divarge: undefined,
    ignoreEmptyColumns: false,
    fontSize: 12,
    rowHeight: 25,
    prependContent: false,
  });

  const [fileName, setFileName] = useState<string>("");
  const [openMore, setOpenMore] = useState<boolean>(false);

  useEffect(() => {
    const tableHead = document?.querySelector(".ag-table-name-head");
    if (!tableHead) {
      return;
    }
    const tableHeadText = tableHead?.textContent;
    if (!tableHeadText) {
      return;
    }
    setFileName(tableHeadText);
  }, [open]);

  useEffect(() => {
    const allColumns = tableRef?.current?.columnApi?.getAllGridColumns();
    if (!allColumns) {
      return;
    }
    setColumnsToExport(allColumns.map((column) => ({
      headerName: column.getColDef().headerName,
      id: column.colId,
      checked: column.isVisible(),
    })));
  }, [tableRef, open]);

  const onExport = async  () => {
    let columnsToExportIds = columnsToExport.filter((col) => col.checked).map((col) => col.id);
    if (extraOptions.ignoreEmptyColumns) {
      const emptyColumns = getEmptyColumns(tableRef, columnsToExportIds);
      if (emptyColumns.length) {
        columnsToExportIds = columnsToExportIds.filter((col) => !emptyColumns.includes(col));
      }
    }
    const selectedRows = tableRef?.current?.api?.getSelectedRows();
    const defaultExportParams = {
      columnKeys: columnsToExportIds,
      sheetName: 'נתונים',
      fileName: `סולביט - ${fileName}`,
      fontSize: extraOptions.fontSize,
      rowHeight: extraOptions.rowHeight,
      rowGroupExpandState: tableControls?.groupBy?.removeSingleChildren ? 'expanded':'collapsed',
      prependContent: extraOptions?.prependContent ? prependData(columnsToExportIds, tableRef,tableControls) : undefined,

    }
    if (extraOptions.divarge) {
      const divarge = extractBeforeNumber(extraOptions.divarge)
      var records: Record<string, boolean> = {};
      tableRef.current!.api.forEachNode(function (node) {
        if (!records[node.data!?.[divarge!]]) {
          records[node.data!?.[divarge!]] = true;
        }
      });

      var spreadsheets = [tableRef?.current?.api?.getSheetDataForExcel({ ...defaultExportParams, sheetName: 'כל הנתונים' })];
      const col = tableRef.current?.api?.getColumnDefs().find((col) => col.field === divarge);
      if (!col) {

        tableRef.current!.api.exportMultipleSheetsAsExcel({
          data: spreadsheets,
          fileName: `סולביט - ${fileName}`,
        });
        return
      }
      var sportFilterInstanceFather = tableRef.current.api.getFilterInstance(col.colId);
      var sportFilterInstance = sportFilterInstanceFather?.getChildFilterInstance(0) ?? sportFilterInstanceFather;
      sportFilterInstance.setModel(null)
      for (var r in records) {

        if (r == '') {
          sportFilterInstance.setModel({ values: [] });
        } else {
          sportFilterInstance.setModel({ values: [r] });
        }


        tableRef.current.api.onFilterChanged();
        await delay(2700);
        const n =[]
        let colmn = columnsToExport.filter((col) => col.checked).map((col) => col.id)
        tableRef.current.api.forEachNodeAfterFilterAndSort((node) => {
          n.push(node)
        })
        if (extraOptions.ignoreEmptyColumns) {
          const emptyColumns = getEmptyColumnsData(n, colmn);
          if (emptyColumns.length) {
            colmn = colmn.filter((col) => !emptyColumns.includes(col));
          }
        }

        if (sportFilterInstance.getModel() == null) {
          sportFilterInstance.setModel(null);
          tableRef.current!.api.onFilterChanged();
          continue
        }
        const sheet = tableRef.current.api.getSheetDataForExcel({...defaultExportParams,  columnKeys: colmn,
          sheetName: r,
        });
        if (sheet) {
          spreadsheets.push(sheet);
        }
      }
      sportFilterInstance.setModel(null);
      tableRef.current!.api.onFilterChanged();
      tableRef.current!.api.exportMultipleSheetsAsExcel({
        data: spreadsheets,
        fileName: `סולביט - ${fileName}`,
      });
      spreadsheets = [];
    } else {
      if (selectedRows?.length) {
        tableRef?.current?.api?.exportDataAsExcel({
          ...defaultExportParams,
          onlySelected: true,
        });
      } else {
        tableRef?.current?.api?.exportDataAsExcel(defaultExportParams);
      }
    }
  }

  const setPosition = () => {
    const divToAttach = "export-to-excel-icon";
    const div = document.getElementById(divToAttach);
    if (!div) {
      return;
    }
    const rect = div.getBoundingClientRect();
    const top = rect.top;
    const select = document.getElementById('columns-select');
    if (!select) {
      return;
    }

    return {
      top: `${top}px`,
    }
  }

  const { top } = setPosition() || {};

  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [anchorElPopover, setAnchorElPopover] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setOpen(false)
    setOpenPopover(false)
    setAnchorElPopover(null)
  }

  return (
    <div style={{
      position: 'relative',
    }}>
      <Select
        id="columns-select"
        open={open}
        sx={{
          position: 'absolute',
          opacity: 0,
          top: -40,
          zIndex: -1,
        }}
        MenuProps={{
          onClose: handleClose,
          style: {
            maxHeight: '450px',
          }
        }}
      >
        <List>

          <ListItem>
            <TextField
              variant="standard" label="שם קובץ" value={fileName} onChange={(e) => setFileName(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />
          </ListItem>

          <ListItemButton style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: '#e0ffd8',
            borderBottom: '2px solid #e0e0e0',
          }} onClick={() => onExport()}>
            <ListItemText primary="ייצא" />
            <ListItemIcon>
              <Avatar variant='square'
                sx={{ cursor: 'pointer', bgcolor: '#f8f8f8', width: '30px', height: '30px', mr: 1 }}
                src={xslx} />

            </ListItemIcon>
          </ListItemButton>

          <ListItemButton onClick={() => setOpenMore(!openMore)} style={{
            height: '40px',
            borderBottom: '2px solid #e0e0e0',
          }}>
            <ListItemText>
              אפשרויות ייצוא
            </ListItemText>
            <ListItemIcon>
              {
                openMore ?
                  <ExpandLess /> :
                  <ExpandMore />
              }
            </ListItemIcon>
          </ListItemButton>

          <Collapse
            in={openMore}
          // onClick={(e) => e.stopPropagation()}
          >
            <MenuItem
              onClick={(e) => {
                if (openPopover) {
                  setOpenPopover(false);
                  setAnchorElPopover(null);
                  return;
                } else {
                  setOpenPopover(true);
                  setAnchorElPopover(e.currentTarget);
                }
              }}
            >
              <ListItemText>
                פצל קבוצות ללשונייות <span style={{
                  color: 'grey',
                  marginRight: '5px',
                }}>{columnToDivarge?.headerName ? columnToDivarge?.headerName : ''}</span>
              </ListItemText>
              <Popover
                id="mouse-over-popover"
                open={openPopover && Boolean(anchorElPopover)}
                anchorEl={anchorElPopover}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                {columnsToExport.map((column) => (
                  <ListItem key={column.id + "-divarge"} value={column.id}>
                    <Checkbox onChange={(e) => {
                      // e.stopPropagation();
                      if (e.target.checked) {
                        setColumnToDivarge(column);
                        setExtraOptions({ ...extraOptions, divarge: column?.id });
                      } else {
                        setColumnToDivarge(null);
                        setExtraOptions({ ...extraOptions, divarge: undefined });
                      }
                    }} checked={columnToDivarge?.id === column.id} />
                    <ListItemText primary={column.headerName} />
                  </ListItem>
                ))}
              </Popover>
            </MenuItem>
            <MenuItem>
              <ListItemText>
                התעלם מעמודות ריקות
              </ListItemText>
              <Checkbox checked={!!extraOptions.ignoreEmptyColumns} onChange={(e) => {
                setExtraOptions({ ...extraOptions, ignoreEmptyColumns: e.target.checked });
              }} />
            </MenuItem>
            <MenuItem>
              <ListItemText>
                הוסף תרגום לראשי עמודות
              </ListItemText>
              <Checkbox checked={!!extraOptions.prependContent} onChange={(e) => {
                setExtraOptions({ ...extraOptions, prependContent: e.target.checked });
              }} />
            </MenuItem>
            <MenuItem>
              <TextField label="גודל פונט" value={extraOptions.fontSize} onChange={(e) => setExtraOptions({ ...extraOptions, fontSize: Number(e.target.value) })} variant="standard" />
            </MenuItem>
            <MenuItem>
              <TextField label="גובה שורה" value={extraOptions.rowHeight} onChange={(e) => setExtraOptions({ ...extraOptions, rowHeight: Number(e.target.value) })} variant="standard" />
            </MenuItem>
          </Collapse>

          <ListItem>
            <ListItemText>
              בחר את העמודות שברצונך לייצא
            </ListItemText>
          </ListItem>

          <ListItemButton onClick={() => {
            const isAllSelected = columnsToExport.every((col) => col.checked);
            if (isAllSelected) {
              setColumnsToExport(columnsToExport.map((col) => ({ ...col, checked: false })));
            } else {
              setColumnsToExport(columnsToExport.map((col) => ({ ...col, checked: true })));
            }
            setOpen(true)
          }}>
            <ListItemText primary={columnsToExport.every((col) => col.checked) ? 'בטל בחירה' : 'בחר הכל'} />
          </ListItemButton>

          {columnsToExport.map((column) => (
            <ListItem key={column.id} value={column.id}>
              <Checkbox onChange={(e) => {
                if (e.target.checked) {
                  setColumnsToExport(columnsToExport.map((col) => {
                    if (col.id === column.id) {
                      return { ...col, checked: true };
                    }
                    return col;
                  }
                  ));
                } else {
                  setColumnsToExport(columnsToExport.map((col) => {
                    if (col.id === column.id) {
                      return { ...col, checked: false };
                    }
                    return col;
                  }
                  ));
                }
              }} checked={column.checked} />
              <ListItemText primary={column.headerName} />
            </ListItem>
          ))}

        </List>
      </Select>
    </div>
  )
}

export default ExcelExportModal

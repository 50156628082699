import JSDB, {ApiCall, NKTypeConvertor} from "../../fromKotlin/nk";
import InventoryItem, {deserializeInventory, serializeInventory} from "../../types/inventoryCompare";
import {dateObjectToString} from "../../fromKotlin/nkutils";
import {distinct, distinctByField} from "../../utils/sort";


export const getInventory: (product_id?: number[], agent_id?: number[], fromDate?: Date, toDate?: Date,last?:boolean) => Promise<any[]> = async (product_id, agent_id, fromDate, toDate,last) => {

    const req: ApiCall = new ApiCall('GET_INVENTORY');
    if (product_id)
        req.putParam('product_id', NKTypeConvertor().toKotlinList(product_id));
    if (agent_id)
        req.putParam('agent_id', NKTypeConvertor().toKotlinList(agent_id));
    if (fromDate)
        req.putParam('fromDate', dateObjectToString(fromDate));
    if (toDate)
        req.putParam('toDate', dateObjectToString(toDate));
    if (last!==undefined)
        req.putParam('last', true);

    console.log(req)
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    if (result.first.size > 0)
        return (result.first).toArray().map((c) => deserializeInventory(c)).sort((a, b) => a.position - b.position)
    return []

};

export const getInventoryNote: (fromDate: Date, toDate: Date, full?: boolean, agent_id?: number[],zero?:boolean,type?:number) => Promise<any[]> = async (fromDate, toDate, full, agent_id,zero,type) => {
    if(!agent_id)
        return []
    const req: ApiCall = new ApiCall('GET_INVENTORY_NOTE');
    req.putParam('fromDate', dateObjectToString(fromDate));
    req.putParam('toDate', dateObjectToString(toDate));
    if (full)
        req.putParam('full', full);
    if (agent_id)
        req.putParam('agent_id', NKTypeConvertor().toKotlinList(agent_id));
    if(zero)
        req.putParam('empty', true);
    if(type)
        req.putParam('inv_type', type);
    console.log(req)
    const result = await req.execute();

    if (result == null || result.second.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });
    if (result.first.size > 0)
        return (result.first).toArray().map((c) => deserializeInventory(c,zero)).sort((a, b) => a.position - b.position)
    return []

};

export const newInventoryNote: (date: Date,data:InventoryItem[],type) => Promise<boolean> = async (date,data,type) => {
    const req: ApiCall = new ApiCall('NEW_INVENTORY_NOTE_WEB');
    req.putParam('date', dateObjectToString(date));
    req.putParam('agent', JSDB().currentAgent);
    req.putParam('data', NKTypeConvertor().toKotlinList(data.map((p)=>serializeInventory(p))));
    req.putParam('inv_type', type??0);

    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return true

};

export const clearInventory: () => Promise<boolean> = async () => {
    const req: ApiCall = new ApiCall('CLEAR_INVENTORY');


    const result = await req.execute();
    if (result == null || result.name !== 'SUCCESS')
        return new Promise((resolve, reject) => {
            reject(Error);
        });

    return true

};

import {Checkbox, FormControlLabel, Grid, IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from '../../config';
import { enUS, he } from 'date-fns/locale';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import {memo} from "react";
import JSDB from "../../fromKotlin/nk";

function ApproveNoteDialog({approve}:{approve:any}) {
    const [open, setOpen] = React.useState(false);
    const [savePriceInSpecificNote, setSavePriceInSpecific] = React.useState(!(JSDB().getUser()?.first?.note_price==4) );
    const [savePriceInGeneral, setSavePriceInGeneral] = React.useState((JSDB().getUser()?.first?.note_price==4));

    const handleOk = async () => {
        approve(savePriceInSpecificNote,savePriceInGeneral)
        handleClose();
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    // React.useEffect(() => {
    //     console.log(updateCustomerAsyncState);
    //     if (updateCustomerAsyncState === 'succeeded') {
    //         dispatch({
    //             type: SNACKBAR_OPEN,
    //             open: true,
    //             message: 'המוצרים עודכנו בהצלחה!',
    //             variant: 'alert',
    //             alertSeverity: 'success'
    //         });
    //
    //         dispatch(initMassCustomersProductAsyncState());
    //
    //     } else if (updateCustomerAsyncState === 'failed') {
    //         Swal.fire({
    //             title: 'שגיאה בעדכון מוצרים',
    //             target: '#custom-target',
    //             text: '',
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'המשך'
    //         });
    //         dispatch(initMassCustomersProductAsyncState());
    //     }
    // }, [updateCustomerAsyncState, dispatch]);

    const handleClose = () => {
        setOpen(false);

    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue((event.target as HTMLInputElement).value);
    // };

    const onApprove =
        () => {
            handleOk();
        };

    return (
        <div>
            <Tooltip title={'האם תרצה לשמור מסמך'}>
                <IconButton style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '25px',

                    padding: '5px',
                }} size='medium' onClick={handleClickOpen}>
                    <SaveSharpIcon color='error' />
                    <span style={{
                        fontSize: '15px',
                        margin: '0 5px',
                    }}>שמור עריכה</span>
                </IconButton>
            </Tooltip>
            {open ? <Dialog id='doc_search_dialog'  maxWidth={'xs'} fullWidth={true}
                            open={open} onClose={handleClose}>
                <DialogTitle> {'שינוי מסמך'}

                </DialogTitle>
                <DialogContent>
                    &nbsp;
                    <Grid container>
                        <Grid item xs={6} sm={6}>
                            <FormControlLabel
                                value='low'
                                control={
                                    <Checkbox
                                        checked={savePriceInSpecificNote}
                                        onChange={(value) => {
                                            setSavePriceInSpecific(value.target.checked);
                                            localStorage.setItem("savePriceInSpecificNote",String(value.target.checked));
                                        }}
                                        color='primary'
                                        sx={{ color: 'primary.main' }}
                                    />
                                }
                                label='שינוי מחירים עבור מסמך זה בלבד'
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <FormControlLabel
                                value='low'
                                control={
                                    <Checkbox
                                        checked={savePriceInGeneral}
                                        onChange={(value) => {
                                            setSavePriceInGeneral(value.target.checked);
                                            localStorage.setItem("savePriceInGeneral",String(value.target.checked));
                                        }}
                                        color='primary'
                                        sx={{ color: 'primary.main' }}
                                    />
                                }
                                label='שינוי מחירים בכרטיס לקוח'
                            />
                        </Grid>



                    </Grid>
                    {/*<MasterTable*/}
                    {/*    headCells={columns}*/}
                    {/*    data={data ?? []}*/}
                    {/*    keyExtractor={(p) => p.id.toString()}*/}
                    {/*    withPagination*/}
                    {/*    paginationOptions={{*/}
                    {/*        rowsPerPage: 1000*/}
                    {/*    }}*/}
                    {/*    withSort*/}
                    {/*    sortOptions={{*/}
                    {/*        sortBy: {*/}
                    {/*            order: 'desc',*/}
                    {/*            orderBy: 'position'*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    withFilter*/}
                    {/*    TableItem={(row) => <ChangeItem row={row} />}*/}
                    {/*/>*/}

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'red', color: 'white' }}> שינוי מסמך
                    </Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'blue', color: 'white' }}>חזור</Button>
                </DialogActions>
            </Dialog> : undefined}

        </div>
    );
}
export default memo(ApproveNoteDialog);

import JSDB, {ApiCall, NKTypeConvertor} from 'fromKotlin/nk';
import {ActiveUpdate} from 'store/utls/utils';
import Product, {deserializeDayLock} from 'types/product';
import {base64ToArrayBuffer, dateObjectToString} from '../../fromKotlin/nkutils';


export const fillProductsImp = async () => {

    return JSDB().getAllClientProduct(false);
};
export const getClientProductAsync = async () => {
    const req = new ApiCall('GET_CLIENT_PRODUCTS');
    req.putParam('fromDate', dateObjectToString(new Date()));
    req.putParam('toDate', dateObjectToString(new Date()));
    return req.execute();
};

function resizeImage(base64: string, targetWidth: number, targetHeight: number, quality: number): Promise<string> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject(new Error('Failed to get canvas context'));
                return;
            }
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            const resizedBase64 = canvas.toDataURL('image/jpeg', quality);
            resolve(resizedBase64);
        };
        img.onerror = () => {
            reject(new Error('Failed to load image'));
        };
        img.src = base64;
    });
}

async function putProducts(p: Product, req: ApiCall, date: string, addProductToSup: boolean = false) {
    const no_tax_product = p.isNoTax ? 1 : 0;
    const defaultP = p.default_price;
    const unit = p.unit;
    const unit_amount = p.unit_amount;
    const cost_price = p.cost_price;
    const base_price = p.base_price;
    const ex = p.external_id;
    const code = p.barcode;

    const img = p.image ? base64ToArrayBuffer(await resizeImage(p.image, 600, 600, 1)) : null;
    req.putParam('name', p.name);
    req.putParam('date', date);
    req.putParam('barcode', String(code));
    req.putParam('default_price', Number(defaultP));
    req.putParam('no_tax_product', Number(no_tax_product));
    req.putParam('external_id', String(ex));

    req.putParam('unit', unit);
    req.putParam('unit_amount', unit_amount);
    req.putParam('category', p.category);
    req.putParam('cost_price', cost_price);
    req.putParam('category2', p.category2);
    req.putParam('base_price', base_price);
    req.putParam('cost_include_returns', Number(p.return_cost));

    req.putParam('order_product_id', -1); // replace later if extending
    req.putParam('extra_unit', p.extra_unit);
    req.putParam('conversion_rate', p.conversion_rate);
    req.putParam('available', p.available);
    req.putParam('weight', p.weight);
    req.putParam('volume', p.volume);
    req.putParam('englishName', p.englishName);
    req.putParam('orderName', p.orderName);
    req.putParam('orderPosition', p.orderPosition);
    req.putParam('image', img);
    req.putParam('step', p.step);
    if (p.unit_preferece)
        req.putParam('unit_preferece', p.unit_preferece);
    if (p.sale)
        req.putParam('sales', Number(p.sale ?? 0));
    if (p.min_amount)
        req.putParam('min_order', p.min_amount);
    req.putParam('forceLock', Number(p.forceLock));
    if (p.hourLock && p.hourLock.getHours){
        const time = `${p.hourLock.getHours()}:${p.hourLock.getMinutes()}`
        console.log(time)
        req.putParam('hourLock', time);
    } else if (p.hourLock)
        req.putParam('hourLock', p.hourLock);

    req.putParam('dayLock', deserializeDayLock(p.dayLock));
    if (p.collector != undefined)
        req.putParam('collector', p.collector)
    if (p.taklit_id != undefined)
        req.putParam('taklit_id', p.taklit_id)
    if (p.taklit_type != undefined)
        req.putParam('taklit_type', p.taklit_type)



    if (addProductToSup) {
        req.putParam('addToSupplier', true);
    }



}

export const addClientProductAsync = async ({p, addProductToSup}: { p: Product, addProductToSup: boolean }) => {
    const req = new ApiCall('NEW_PRODUCT_API');
    const date = JSDB()
        .getDatesManipulator()
        .getNextMonth(JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour()), -1);
    if(JSDB().getCompany()=="unicoshivuk")
        req.putParam('notAvailable', true);
    await putProducts(p, req, date, addProductToSup);
    return req.execute();
};

export const updateClientProductAsync = async ({p, addProductToSup}: { p: Product, addProductToSup: boolean }) => {
    const req = new ApiCall('NEW_PRODUCT_API');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());
    await putProducts(p, req, date, addProductToSup);
    req.putParam('product_id', p.id);
    return req.execute();
};
export const updateMassClientProductAsync = async ({
                                                       ids,
                                                       category,
                                                        category2,
                                                       no_tax_product,
                                                       available, position,
                                                       sale,
                                                       min_amount,
                                                       forceLock,
                                                       hourLock,
                                                       dayLock,
                                                       step,
                                                       default_unit,

    collector,
                                                       unit,
                                                       extra_unit,
                                                       unit_amount,
                                                       conversion_rate
                                                   }: {
    ids: number[],
    category?: string,
    category2?: string,
    no_tax_product?: number,
    available?: boolean,
    position?: number,
    sale?: boolean,
    min_amount?: number,
    forceLock?: boolean,
    hourLock?: number,
    dayLock?: number[],
    step?: number,
    default_unit?: number,
    collector?: number,
    unit?: string,
    extra_unit?: string,
    unit_amount?: number,
    conversion_rate?: number

}) => {
    const req = new ApiCall('UPDATE_ALL_CLIENT_PRODUCT');
    req.putParam('products', NKTypeConvertor().toKotlinList(ids));
    if (category)
        req.putParam('category', category);
    if (no_tax_product !== undefined)
        req.putParam('no_tax_product', no_tax_product);
    if (available !== undefined)
        req.putParam('available', Boolean(available));
    if (position !== undefined)
        req.putParam('position', position);
    if (sale !== undefined)
        req.putParam('sales', Number(sale));
    if (collector!=undefined)
        req.putParam('collector', collector);
    if (min_amount !== undefined)
        req.putParam('min_order', min_amount);
    if (forceLock !== undefined)
        req.putParam('forceLock', Number(forceLock));
    if(unit!== undefined)
        req.putParam('unit', unit);
    if (extra_unit !== undefined)
        req.putParam('extra_unit', extra_unit);
    if (unit_amount !== undefined)
        req.putParam('unit_amount', unit_amount);
    if (conversion_rate !== undefined)
        req.putParam('conversion_rate', conversion_rate);


    if (hourLock && hourLock.getHours){
        const time = `${hourLock.getHours()}:${hourLock.getMinutes()}`
        req.putParam('hourLock', time);
    } else if (hourLock)
        req.putParam('hourLock', hourLock);

    if (dayLock !== undefined)
        req.putParam('dayLock', deserializeDayLock(dayLock));
    if (step !== undefined)
        req.putParam('step', step);
    if (default_unit !== undefined)
        req.putParam('unit_preferece', default_unit);
    return req.execute();
};
export const deleteCustomerProductAsync = async (active: ActiveUpdate) => {
    const req = new ApiCall('UPDATE_CLIENT_PRODUCT');
    const date = JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour());

    return req.putParam('id', Number(active.id)).putParam('date', date).putParam('active', Number(active.active)).execute();
};
function productToNewObject(p: Product): any {
    let u =JSDB().getWrappedUnitFromString(p.unit)
    let u2 =0
    if(p.extra_unit)
        u2=JSDB().getWrappedUnitFromString(p.extra_unit)
    let time
    if (p.hourLock && p.hourLock.getHours){
         time = `${p.hourLock.getHours()}:${p.hourLock.getMinutes()}`
    } else if (p.hourLock)
        time=p.hourLock

    const x= {
        product_id: p.id,
        date:  JSDB().getDatesManipulator().minDay(JSDB().getDatesManipulator().dateNowNoHour()),
        name: p.name,
        category: p.category,
        category2: p.category2,
        default_price: p.default_price,
        cost_price: p.cost_price,
        cost_include_returns: Number(p.return_cost),
        weight: p.weight,
        barcode: p.barcode,
        split_category: p.nkObject.split_category,
        volume: p.volume,
        external_id: p.external_id,
        no_tax_product: Number(p.isNoTax),
        available: Number(p.available),
        position: p.position,
        orderPosition: p.orderPosition,
        active: Number(p.active),
        sale: Number(p.sale),
        min_amount: p.min_amount,
        forceLock: p.forceLock,
        orderName: p.orderName,
        englishName: p.englishName,
        hourLock: time,
        dayLock: deserializeDayLock(p.dayLock),
        step: p.step,
        unit_preferece: p.unit_preferece,
        collector: p.collector,
        unit: u,
        extra_unit: u2,
        taklit_id: p.nkObject.taklit_id,
        taklit_type: p.nkObject.taklit_type,
        unit_amount: p.unit_amount,
        conversion_rate: p.conversion_rate

    };
    return x
}
export const changeAllProducts = async (product: Product[]) => {
    const req = new ApiCall('UPDATE_CLIENT_ALL_PRODUCT');
    return req.putParam("full",true).putParam('mapper', (NKTypeConvertor().jsToKotlinMap(product.map(productToNewObject)))).execute();
};

import JSDB from "../fromKotlin/nk";


export const deserializeInventory = (c,zero): InventoryItem => {

    const p = JSDB().getClientProduct(c.product_id).first;
    const user = JSDB().getAgentBy(c.agent_id, null,false,false,false,true).first;
    let unit1 = p.getUnitName();
    if (unit1 == '') {
        unit1 = 'יח\'';
    }
    let unit2 = undefined;
    const unitTemp = p.getOrderProduct()?.getExtraUnitName()
    if (unitTemp != null && unitTemp != '') {
       unit2=unitTemp
    }



    return {
        id: c.product_id,
        external_id: p.external_id,
        name: p.name,
        barcode:p.barcode,
        position: (c?.valueSumSec ||  c?.valueSum)  ?  -5000+p.position : p.position,
        user: user?.user_name?? 'כללי',
        user_type: user?.getDescription() ?? 'כללי',
        user_id: c.agent_id ?? -1,
        inventoryStart: zero ? 0 : (c?.inventoryStart ?? 0),
        inventoryStart2:  zero ? 0 :(c?.inventoryStart2 ?? 0),
        amountDistribution:  c?.amountDistribution ?? 0,
        amountDistribution2: c?.amountDistribution2 ?? 0,
        totalInventory: c?.valueSum  ?? 0,
        totalInventory2: c?.valueSumSec  ?? 0 ,
        inventoryMatch: c?.inventoryMatch ?? 0,
        inventoryMatchSec: c?.inventoryMatchSec ?? 0,
        inventoryLast: c?.lastInventory ?? 0,
        inventoryLastSec: c?.lastInventorySec ?? 0,
        unit1: unit1,
        unit2: unit2,
        nkObject: c

    }
};

export const serializeInventory = (c: InventoryItem) => {
    return {
        product_id: c.id,
        inventoryStart: isNaN(c.inventoryStart) ? 0 : c.inventoryStart,
        inventoryStart2: isNaN(c.inventoryStart2) ? 0 : c.inventoryStart2,
        amountDistribution: c.amountDistribution,
        amountDistribution2: c.amountDistribution2,
        valueSum: c.totalInventory,
        valueSumSec: c.totalInventory2,
        agent_id: c.user_id
    }
}

interface InventoryItem {
    id: number;
    external_id?: string;
    name: string
    barcode: string
    user: string
    user_id?: number
    position: number
    user_type?: string

    inventoryStart: number
    inventoryStart2: number
    amountDistribution: number
    amountDistribution2: number
    totalInventory: number
    totalInventory2: number
    inventoryMatch?:number
    inventoryMatchSec?:number
    inventoryLast?:number
    inventoryLastSec?:number
    unit1?: string
    unit2?: string
    nkObject: any;

}

export default InventoryItem;

import type { IFloatingFilterComp, IFloatingFilterParams, IFloatingFilterParentCallback } from 'ag-grid-community';
import { RadioNumberFilter } from './CustomRadioFilter';

export class RadioFloatingFilter implements IFloatingFilterComp {
    parentFilterInstance!: (callback: IFloatingFilterParentCallback<RadioNumberFilter>) => void;
    eGui!: HTMLDivElement;
    rbAllYears: any;
    rbAfter2004: any;
    rbEqualsZero: any;
    uniqueId: string;
    columnName: string;
    constructor() {
        this.uniqueId = Math.random().toString(36).substr(2, 9);
    }
    init(params: IFloatingFilterParams<RadioNumberFilter>) {
        this.columnName = params.column.getColId();
        this.parentFilterInstance = params.parentFilterInstance;
        this.eGui = document.createElement('div');
        this.eGui.innerHTML = `<div class="floating-filter">
                <div class="filter-option">
                    <label for="rbFloatingAll-${this.uniqueId}">הכל</label>
                    <input type="radio" name="yearFloatingFilter-${this.uniqueId}" checked="checked" id="rbFloatingAll-${this.uniqueId}" />
                </div>
                     <div class="filter-option">
                    <label for="rbFloatingEqualsZero-${this.uniqueId}">=0</label>
                    <input type="radio" name="yearFloatingFilter-${this.uniqueId}" id="rbFloatingEqualsZero-${this.uniqueId}" />
                </div>
                <div class="filter-option">
                    <label for="rbFloatingYearAfter2004-${this.uniqueId}">>0</label>
                    <input type="radio" name="yearFloatingFilter-${this.uniqueId}" id="rbFloatingYearAfter2004-${this.uniqueId}" />
                </div>
           
            </div>`;

        this.rbAllYears = this.eGui.querySelector(`#rbFloatingAll-${this.uniqueId}`);
        this.rbAfter2004 = this.eGui.querySelector(`#rbFloatingYearAfter2004-${this.uniqueId}`);

        this.rbAllYears.addEventListener('change', this.onSelectionChanged.bind(this));
        this.rbAfter2004.addEventListener('change', this.onSelectionChanged.bind(this));

        this.rbEqualsZero = this.eGui.querySelector(`#rbFloatingEqualsZero-${this.uniqueId}`);

        this.rbEqualsZero.addEventListener('change', this.onSelectionChanged.bind(this));
    }
    onSelectionChanged() {
        let filterType;
        if (this.rbAfter2004.checked) {
            filterType = 'greaterThanZero';
        } else if (this.rbEqualsZero.checked) {
            filterType = 'equalsZero';
        } else {
            filterType = 'all';
        }
        this.parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged(filterType);
        });
    }

    onParentModelChanged(parentModel: string) {
        if (parentModel === 'greaterThanZero') {
            this.rbAllYears.checked = false;
            this.rbAfter2004.checked = true;
            this.rbEqualsZero.checked = false;
        } else if (parentModel === 'equalsZero') {
            this.rbAllYears.checked = false;
            this.rbAfter2004.checked = false;
            this.rbEqualsZero.checked = true;
        } else {
            this.rbAllYears.checked = true;
            this.rbAfter2004.checked = false;
            this.rbEqualsZero.checked = false;
        }
    }

    getGui() {
        return this.eGui;
    }
}

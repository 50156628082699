import './table-container.scss';

const AgTableContainer = ({tableName, controls, children, overflow}: {
    tableName: string | React.ReactNode,
    controls?: () => React.ReactNode,
    children: React.ReactNode,
    overflow?: string
}) => {

    return <div className="table-container-div" style={{overflow: overflow ?? 'hidden'}}>
        <div className='top-part' style={{
            width: '100%',

        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                minWidth: 'fit-content',
                marginLeft: '10px',
            }}>
                <h1 style={{
                    width: '100%',
                }} className='ag-table-name-head'>
                    {tableName}
                </h1>
            </div>
            <div style={{
                width: '100%',
            }}>
                {controls && controls()}
            </div>
        </div>
        {children}
    </div>
}

export default AgTableContainer

import React, {Dispatch, memo, SetStateAction, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store';
import {nanoid} from '@reduxjs/toolkit';
import {getSpecificCustomer, selectCustomerById} from 'store/customers/customersSlice';
import {
    CUSTOMER_DOCUMENTS,
    useCreateCustomerDocument,
    useGetSpecificCustomerDocuments
} from 'hooks/customer/documents.hooks';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem, Snackbar,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// locale
import {format, isBefore} from 'date-fns';
import {enUS, he} from 'date-fns/locale';
import {FormattedMessage} from 'react-intl';

// assets
import {IconArchive, IconCirclePlus, IconCurrencyShekel, IconTrash} from '@tabler/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';

// project imports
import config from 'config';
import MainCard from 'ui-component/cards/MainCard';
import DocumentCustomerDetails from '../DocumentCustomerDetails';
import DocumentPreview from '../DocumentPreview';
import CartesetList from 'views/customer/profile/debts';

// types
import {CartesetDataHolder} from 'types/CartesetDataHolder';

import Swal from 'sweetalert2';
import {
    Check,
    createDocument,
    Details,
    Payment,
    PaymentMethod,
    paymentMethods
} from '../../../../../types/DocumentsTypes/utils';
import {TaxNote} from '../../../../../types/DocumentsTypes/TaxNote';
import {ExtendedDocument} from '../../../../../types/DocumentsTypes/Document';
import JSDB from '../../../../../fromKotlin/nk';
import {PayNote} from '../../../../../types/DocumentsTypes/PayNote';
import {dateObjectToString, roundDecimals} from '../../../../../fromKotlin/nkutils';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoNegative, {WithNegative} from '../../../../../ui-component/textFieldNumber';
import {
    cancelPaymentLine,
    getClientChecks,
    getSpecificDocument,
    newAllDailyNew,
    newAllOrderNote
} from '../../../../../api/customer/documents.api';
import {number_style} from "../../../../../styles/tablecell";
import {Delete, Image, ImageSearch} from '@mui/icons-material';
import {useQueryClient} from "react-query";
import {orderKey, writeOrdersToOD} from "../../../../../types/OrderData";
import {SNACKBAR_OPEN} from "../../../../../store/actions";

const MultiDocumentsActions = ({setReceipts, selected}: { setReceipts: any; selected: CartesetDataHolder[] }) => {
    const onAddHandler = () => setReceipts(prev => prev.concat(selected.filter(s => s.doc.type === 'invoice' && !prev.map(p => p.id).includes(s.doc.id)).map(s => s.doc)));

    return (
        <Tooltip title={<FormattedMessage id='Choose'/>}>
            <IconButton size='medium' onClick={onAddHandler}>
                <CheckCircleOutlineIcon color='success'/>
            </IconButton>
        </Tooltip>
    );
};

interface PaymentCompProps {
    payment: Payment;
    onPaymentChange: (payment: Payment) => void;
    onPaymentAdd: () => void;
    onPaymentDelete: (id: string) => void;
    addable?: boolean;
    deletable?: boolean;
    images?: {
        id: string;
        file: File;
    }[] | null;
    setImages?: Dispatch<SetStateAction<{
        id: string;
        file: File;
    }[] | null>>;
}

export const PaymentComp = ({
                                payment,
                                onPaymentChange,
                                onPaymentAdd,
                                onPaymentDelete,
                                addable = false,
                                deletable = false,
                                images,
                                setImages,
                            }: PaymentCompProps) => {
    const theme = useTheme();

    return (
        <Grid container spacing={2}>
            <Grid item xs={4} sm={4}>

                <WithNegative
                    fullWidth
                    id='amount'
                    name='amount'
                    label='סכום'
                    value={payment.amount}
                    changer={(v) => onPaymentChange({
                        ...payment,
                        amount: Number(v)
                    })}
                />
            </Grid>
            <Grid item xs={4} sm={4}>
                <TextField
                    id='outlined-select-currency'
                    select
                    label='בחר שיטת תשלום'
                    fullWidth
                    value={payment.method}
                    onChange={(m) => onPaymentChange({...payment, method: m.target.value as PaymentMethod})}
                >
                    {Object.keys(paymentMethods).map((p) => (
                        <MenuItem key={p} value={p}>
                            {paymentMethods[p].name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {payment.method === 'check' || payment.method=='fastCheck'? (
                <>
                    <Grid item xs={3} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                            <DatePicker
                                mask=''
                                renderInput={(props) => <TextField {...props} />}
                                label={<FormattedMessage id='Due Date'/>}
                                value={payment.check?.dueDate}
                                onChange={(newValue) => {
                                    if (newValue) onPaymentChange({
                                        ...payment,
                                        check: {...payment.check, dueDate: newValue}
                                    });
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <NoNegative
                            id='account'
                            fullWidth
                            label='מספר חשבון'
                            value={payment.check?.account ?? 0}

                            changer={(v) =>
                                onPaymentChange({
                                    ...payment,
                                    check: {...payment.check, account: Math.max(0, Number(v))}
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <NoNegative
                            id='branch'
                            fullWidth
                            label='מספר סניף'
                            value={payment.check?.branch ?? 0}
                            changer={(v) =>
                                onPaymentChange({
                                    ...payment,
                                    check: {...payment.check, branch: Math.max(0, Number(v))}
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <Autocomplete
                            id='bank'
                            fullWidth
                            label='מזהה בנק'
                            type='number'
                            value={payment.check?.bank ?? 0}
                            onChange={(event, value) => {
                                if (value) onPaymentChange({...payment, check: {...payment.check, bank: (value)}});
                            }}
                            options={JSDB().getPaymentMethods().toArray() ?? []}

                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='בנק'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <NoNegative
                            id='check'
                            fullWidth
                            label={<FormattedMessage id='Check No.'/>}
                            value={payment.check?.id ?? 0}
                            changer={(v) =>
                                onPaymentChange({
                                    ...payment,
                                    check: {...payment.check, id: Math.max(0, Number(v))}
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={11} sm={11}>
                        {
                            images?.find((i) => i.id === payment?.id)?.file ? (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <div>
                                        <img
                                            src={URL.createObjectURL(images?.find((i) => i.id === payment?.id)?.file as File)}
                                            alt='check' style={{
                                            maxWidth: '900px',
                                            maxHeight: '500px',
                                            width: '100%',
                                            height: '100%'
                                        }}/>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        width: '100%',
                                        gap: '10px'
                                    }}>
                                        <Button
                                            variant='contained'
                                            component='label'
                                            fullWidth
                                            sx={{height: '100%'}}
                                            onClick={() => {
                                                setImages ? setImages(images?.filter((i) => i.id !== payment?.id) ?? []) : undefined;
                                            }}
                                            color='error'
                                        >
                                            <Delete style={{
                                                marginLeft: "10px"
                                            }}/>
                                            מחק תמונה
                                        </Button>
                                        <Button
                                            variant='contained'
                                            component='label'
                                            fullWidth
                                            sx={{height: '100%'}}
                                            onClick={() => {
                                                // CALLBACK
                                            }}
                                        >
                                            <ImageSearch style={{
                                                marginLeft: "10px"
                                            }}/>
                                            זיהוי אוטומטי
                                        </Button>
                                    </div>
                                </Box>
                            ) : (
                                <Button
                                    variant='contained'
                                    component='label'
                                    fullWidth
                                    sx={{height: '100%'}}
                                    onClick={() => {
                                        const input = document.getElementById(`check-imge-${images?.findIndex(i => i?.id === payment?.id)}`);
                                        if (input) input.click();
                                    }}
                                >
                                    <Image style={{
                                        marginLeft: "10px"
                                    }}/>
                                    העלאה תמונה
                                </Button>
                            )
                        }
                        <input type="file" id={`check-imge-${images?.findIndex(i => i?.id === payment?.id)}`}
                               style={{display: "none"}}
                               onChange={(e) => {
                                   if (e.target.files) {
                                       const file = e.target.files[0];
                                       if (file) {
                                           setImages ? setImages([...images ?? [], {
                                               id: payment?.id,
                                               file: file
                                           }]) : undefined;
                                       }
                                   }
                               }}
                        />
                    </Grid>
                </>
            ) : undefined}
            {payment.method === 'deposit' ? (
                <>

                    <Grid item xs={3} sm={3}>
                        <Autocomplete
                            id='bank'
                            fullWidth
                            label='מזהה בנק'
                            type='number'
                            value={payment.check?.bank ?? 0}
                            onChange={(event, value) => {
                                if (value) onPaymentChange({...payment, check: {...payment.check, bank: (value)}});
                            }}
                            options={JSDB().getPaymentMethods().toArray() ?? []}

                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='בנק'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                            <DatePicker
                                mask=''
                                renderInput={(props) => <TextField {...props} />}
                                label="תאריך העברה"
                                value={payment?.check?.dueDate}
                                onChange={(newValue) => {
                                    if (newValue) onPaymentChange({
                                        ...payment,
                                        check: {...payment.check, dueDate: newValue}
                                    });
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </>
            ) : undefined
            }
            <Grid item xs={1} sm={1} alignItems='center'>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        mt: 1,
                        mb: 1,
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    {addable ? (
                        <Tooltip title={<FormattedMessage id='add'/>}>
                            <IconButton size='small' onClick={onPaymentAdd}>
                                <IconCirclePlus color={theme.palette.primary.main}/>
                            </IconButton>
                        </Tooltip>
                    ) : undefined}
                    {deletable ? (
                        <Tooltip title={<FormattedMessage id='delete'/>}>
                            <IconButton size='small' onClick={() => onPaymentDelete(payment.id)}>
                                <IconTrash color={theme.palette.error.main}/>
                            </IconButton>
                        </Tooltip>
                    ) : undefined}
                </Box>
            </Grid>

        </Grid>

    );
};
const icon = <CheckBoxOutlineBlankIcon fontSize='small'/>;
const checkedIcon = <CheckBoxIcon fontSize='small'/>;
const NewReceipt = ({
                        upid,
                        narrow,
                        refId,
                        types
                    }: { upid: string, narrow?: boolean, refId?: number[], types?: any[] }) => {
    const theme = useTheme();
    const {id} = useParams();
    const dispatch = useDispatch();

    // TODO: Change to single customer fetching
    const customer = useSelector((state: RootState) => selectCustomerById(state, id ?? upid));
    const [details, setDetails] = React.useState<Details>({
        city: '', companyId: '', companyName: '', email: '', id: 0, phone: '', street: '', streetNumber: ''


    });
    const [comment, setComment] = React.useState<string>('');
    const [checkDetails, setCheckDetails] = React.useState<Check>();
    React.useEffect(() => {
        if (!customer) {
            dispatch(getSpecificCustomer(Number(id ?? upid)));
            return;
        }
        setDetails({
            city: customer!.city,
            companyId: customer!.businessId,
            companyName: customer!.businessName,
            email: customer!.email,
            id: Number(customer!.id),
            phone: customer!.phone,
            street: customer!.street,
            streetNumber: customer!.streetNumber
        });
    }, [dispatch]);
    React.useEffect(() => {
        const x = async () => {
            const checkDetails = await getClientChecks(Number(id ?? upid));
            setCheckDetails(checkDetails?.check);
            setPayments(prevState => prevState.map((p) => {

                    if (p.check?.account != undefined) {
                        return p;
                    } else {

                        return {
                            ...p, check: {
                                dueDate: new Date(),
                                bank: checkDetails?.check?.bank,
                                account: checkDetails?.check?.account,
                                branch: checkDetails?.check?.branch
                            }
                        };
                    }
                })
            );

        };
        x();
    }, []);

    const createCustomerDocument = useCreateCustomerDocument(id ?? upid);

    const [date, setDate] = React.useState<Date | null>(new Date());
    const [payments, setPayments] = React.useState<Payment[]>([{
        id: nanoid(),
        amount: 0,
        method: 'cash',
        check: {
            dueDate: new Date(),
            bank: checkDetails?.bank,
            account: checkDetails?.account,
            branch: checkDetails?.branch
        }
    }]);
    const [receipts, setReceipts] = React.useState<TaxNote[]>([]);
    const [previewOpen, setPreviewOpen] = React.useState<{ document?: ExtendedDocument; open: boolean }>({open: false});
    const commentchange = (e) => {
        setComment(e.target.value);
    };
    const [taxNotes, setTaxNotes] = React.useState<ExtendedDocument[]>([]);
    const pool = "CUSTOMER_DOCUMENTS_FOR_PAY"
    const [appliedRef, setAppliedRef] = React.useState(false);
    // fetch events data
    // eslint-disable-next-line prefer-const
    const cache = useQueryClient();
    React.useEffect(() => {
        cache.removeQueries(pool);
    }, []);
    const {
        data: notes,
        isLoading: isLoading,
        refetch
    } = useGetSpecificCustomerDocuments(['invoice', 'refund'], Number(id ?? upid), undefined, undefined, true, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, pool);
    React.useEffect(() => {
        refetch();

    }, [dispatch]);

    React.useEffect(() => {
        if (notes) {
            setTaxNotes(notes.filter((s) => s.status === 'open' && (s.type == 'invoice' || s.type == 'refund')))
            if (!appliedRef && refId && types) {
                let rr = []
                notes?.forEach((dn) => {

                    refId.find((r, index) => {
                        if (r === dn.id && dn.status === 'open' && (dn.type == types[index])) {
                            rr.push(dn)
                        }
                    })
                })
                setAppliedRef(true)
                setReceipts(rr)
                return;

            }

        }
        ;
    }, [notes]);
    const onPaymentChange = React.useCallback(
        (payment: Payment) => setPayments((prevPayments) => prevPayments.map((pmt) => (payment.id === pmt.id ? payment : pmt))),
        []
    );

    const onPaymentAdd = React.useCallback(
        () => setPayments((prevPayments) => prevPayments.concat({
            id: nanoid(),
            amount: 0,
            method: payments[payments.length - 1].method,
            check: {
                dueDate: new Date(),
                bank: checkDetails?.bank,
                account: checkDetails?.account,
                branch: checkDetails?.branch,
            }
        })),
        [checkDetails, payments]
    );

    const onPaymentDelete = React.useCallback((pid: string) => setPayments((prevPayments) => prevPayments.filter((p) => p.id !== pid)), []);
    const getOptionLabel = (option) =>
        `${option.title} - ${option.entName} - ${format(option.date, 'PPP', {
            locale: config.i18n === 'he' ? he : enUS
        })}  - ${option.mainValue} ש''ח `;

    const onPreviewOpen = React.useCallback((document) => (e) => setPreviewOpen({document, open: true}), []);
    const onPreviewClose = () => setPreviewOpen((preview) => ({...preview, open: false}));
    const [refundRest, setRefundRest] = React.useState<boolean>(false);
    const [openCustomerArchive, setOpenCustomerArchive] = React.useState(false);
    const openCustomerArchiveHandler = React.useCallback(() => setOpenCustomerArchive((p => !p)), []);
    const onPaymentCHangeReal = (createCustomerDocument.data) ? undefined : onPaymentChange;
    const [displayEmptyErr, setDisplayEmptyErr] = React.useState<boolean>(false);
    const [images, setImages] = React.useState<{
        id: string;
        file: File;
    }[] | null>(null);
    if (!customer || !id && !upid) return <Box/>;
    const [creditRecepitValue, setCreditRecepitValue] = React.useState<boolean>(false);

    return (
        <MainCard
            title={
                <Typography variant='h3'>
                    <FormattedMessage id='Create new receipt'/>
                </Typography>
            }
            secondary={
                <>
                    <Tooltip onClick={openCustomerArchiveHandler} title={<FormattedMessage id='Customer Archive'/>}>
                        <Chip icon={<IconArchive size={26}/>} sx={{
                            ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                                color: '#000080',
                            },
                        }} label="כרטסת לקוח" variant="outlined"/>
                    </Tooltip>
                    <Dialog
                        fullWidth
                        maxWidth='lg'
                        scroll='body'
                        open={openCustomerArchive}
                        onBackdropClick={openCustomerArchiveHandler}>
                        <CartesetList cid={id ?? upid} rowsPerPage={10} withSelection selectedInit={receipts}
                                      pool={pool}
                                      selectionOptions={{
                                          selectedActions: (selected) => <MultiDocumentsActions
                                              setReceipts={setReceipts}
                                              selected={selected}/>
                                      }}/>
                    </Dialog>
                </>
            }
        >
            <Grid container spacing={5}>
                {createCustomerDocument.data === undefined ?
                    <Grid item xs={8} sx={{
                        minWidth: narrow ? '100%' : undefined,
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <DocumentCustomerDetails customer={customer} date={date} setDate={setDate}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    id='comment'
                                    name='comment'
                                    label='היערות'
                                    value={comment}
                                    onChange={commentchange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant='h4'>חשבוניות</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControlLabel
                                    value='low'
                                    control={
                                        <Checkbox
                                            onChange={(v) => v.target.checked ? setReceipts(taxNotes.filter(d => d.status === 'open')) : setReceipts([])}
                                            color='primary'
                                        />
                                    }
                                    label='בחר את כל חשבוניות המס הפתוחות'
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    id='receipt'
                                    value={receipts}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={(o, v) => o.id === v.id}
                                    onChange={(event, d) => {
                                        if (d) setReceipts(d);
                                    }}
                                    options={
                                        taxNotes
                                            ?.sort((d1, d2) => ((dateObjectToString(d1.date) == dateObjectToString(d2.date)) ? 0 : isBefore(d1.date, d2.date) ? 1 : -1)) ?? []
                                    }
                                    loading={isLoading}
                                    fullWidth
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='בחר חשבונית מס'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: isLoading ? (
                                                    <CircularProgress color='inherit' size={20}/>
                                                ) : (
                                                    params.InputProps.endAdornment
                                                )
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option, {selected}) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                px: 1,
                                                flex: 1,
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                            key={option.id}
                                        >
                                            <li {...props} style={{flexGrow: 1}}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {getOptionLabel(option)}
                                            </li>
                                            <Tooltip title={<FormattedMessage id='View'/>}>
                                                <IconButton size='small' onClick={onPreviewOpen(option)}>
                                                    <VisibilityIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    )}
                                />
                            </Grid>
                            {receipts.length > 0 ? <>   <Grid item xs={6} sm={6}>
                                <TextField
                                    id='outlined-read-only-input'
                                    label='סכום חשבוניות'
                                    style={number_style}
                                    variant='standard'
                                    value={roundDecimals(receipts.reduce((acc, p) => acc + (p.type == 'invoice' ? p.mainValue : -p.mainValue), 0), 4)}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position='start'>
                                                <IconCurrencyShekel size={12}/>
                                            </InputAdornment>
                                        )
                                    }}

                                />
                            </Grid>

                                {false ? <Grid item xs={6} sm={6}>
                                    <TextField
                                        id='outlined-read-only-input'
                                        label='סה"כ נותר לתשלום'

                                        variant='standard'

                                        value={Math.max(0, roundDecimals(roundDecimals(receipts.reduce((acc, p) => acc + p.mainValue, 0), 4) - roundDecimals(payments.reduce((acc, p) => acc + p.amount, 0), 4), 4))}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position='start'>
                                                    <IconCurrencyShekel size={12}/>
                                                </InputAdornment>
                                            )
                                        }}

                                    />
                                    זכה את היתרה לתשלום
                                    <Checkbox
                                        checked={refundRest}
                                        onChange={(v) => {
                                            setRefundRest(v.target.checked);
                                        }}
                                        color='primary'
                                        sx={{color: 'primary.main'}}
                                    />
                                </Grid> : undefined}
                            </> : undefined}


                            <Grid item xs={12} sm={12}>
                                <Typography variant='h4'>תשלומים</Typography>
                            </Grid>
                            {payments.map((p, i) => (
                                <Grid item xs={12} sm={12} key={p.id}>

                                    <Typography style={{marginBottom: 9}} variant='h5'>תשלום מס' {i + 1}</Typography>
                                    <PaymentComp
                                        payment={p}
                                        onPaymentChange={onPaymentChange}
                                        onPaymentAdd={onPaymentAdd}
                                        onPaymentDelete={onPaymentDelete}
                                        addable={i === payments.length - 1}
                                        deletable={i !== 0}
                                        images={images}
                                        setImages={setImages}
                                    />

                                </Grid>

                            ))}
                        </Grid>
                    </Grid> : undefined}
                <Grid item xs={narrow ? 12 : 4}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <DocumentPreview
                                document={
                                    {
                                        entId: customer.id,
                                        date: date ?? new Date(),
                                        entName: customer.name,
                                        type: 'receipt',
                                        payments: (createCustomerDocument.data as PayNote)?.payments ?? payments,
                                        receiptsIds: (createCustomerDocument.data as PayNote)?.receiptsIds ?? receipts.map((d) => d.id.toString()),
                                        status: createCustomerDocument.data?.status ?? 'pending_approval',
                                        id: createCustomerDocument.data?.id ?? 0,
                                        end: new Date(),
                                        color: '',
                                        textColor: '',
                                        baseColor: '',
                                        description: '',
                                        title: (createCustomerDocument.data as PayNote)?.title ?? 'קבלה חדשה',
                                        allDay: false,
                                        nkObject: (createCustomerDocument.data as PayNote)?.nkObject ?? undefined,
                                        agent: JSDB().currentAgent,
                                        name: '',
                                        issueDate: new Date(),
                                        onPaymentChange: onPaymentCHangeReal,
                                        mainValue: roundDecimals(payments.reduce((acc, p) => acc + p.amount, 0), 4),
                                        details: details,
                                        comment: comment,
                                        entName: '',
                                        recepitValue: roundDecimals(receipts.reduce((acc, p) => acc + (p.type == 'invoice' ? p.mainValue : -p.mainValue), 0), 4),
                                        creditRecepitValue: creditRecepitValue,
                                        setCreditRecepitValue: setCreditRecepitValue,
                                        pool:pool
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Box
                                onClick={() => {
                                    if (receipts.length == 1 && receipts[0].type == 'refund') {
                                        setDisplayEmptyErr(true);
                                    }
                                }}
                                sx={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                <LoadingButton

                                    onClick={() =>
                                        Swal.fire({
                                            title: 'האם תרצה להנפיק קבלה?',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: theme.palette.primary.dark,
                                            cancelButtonColor: theme.palette.error.light,
                                            confirmButtonText: 'הנפק קבלה',
                                            cancelButtonText: 'בטל'
                                        }).then((result) => {

                                            if (result.isConfirmed) {
                                                const pay = createDocument({
                                                    comment,
                                                    mainValue: roundDecimals(payments.reduce((acc, p) => acc + p.amount, 0), 4),
                                                    details,
                                                    date: date ?? new Date(),
                                                    type: 'receipt',
                                                    receiptsIds: receipts.map((d) => d.type == 'invoice' ? d.id : -d.id),
                                                    payments,
                                                    entId: id,
                                                    status: 'approved',
                                                    refundRest: refundRest,
                                                })
                                                pay.refundRest = creditRecepitValue
                                                createCustomerDocument.mutate(
                                                    pay
                                                );
                                            }
                                        })
                                    }
                                    loading={createCustomerDocument.isLoading}
                                    disabled={(receipts.length == 1 && receipts[0].type == 'refund') || createCustomerDocument.isSuccess || payments.reduce((acc, p) => acc + p.amount, 0) == 0}
                                    variant='contained'
                                    type='submit'
                                    fullWidth
                                    sx={{m: 2}}
                                >
                                    הנפק
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog fullWidth open={previewOpen.open} onBackdropClick={onPreviewClose}
                    sx={{'& .MuiDialog-paper': {p: 0}}}>
                <DocumentPreview document={previewOpen.document} icons={false} pool={pool}/>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={displayEmptyErr} autoHideDuration={3000} onClose={() => {
                setDisplayEmptyErr(false);

            }}>
                <Alert onClose={() => {
                    setDisplayEmptyErr(false);
                }} severity="error"
                       sx={{width: '100%', backgroundColor: "#ff7373", color: "black", fontWeight: "bold"}}>
                    {'לא ניתן להוציא קבלה על חשבונית זיכוי בלבד.'}
                </Alert>
            </Snackbar>
        </MainCard>
    );
};
export default memo(NewReceipt);

import React, { useEffect } from 'react';
import { Alert, Autocomplete, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, ListItem, ListItemButton, ListItemIcon, Menu, MenuItem, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

// project imports
import StyledTextTag from './tags/TextTag';
import StyledAutocompleteTag from './tags/AutocompleteTag';
import StyledDateTag from './tags/DateTag';
import StyledBooleanTag from './tags/BooleanTag';

// types
import { Filterable } from './types';
import { Filter } from 'types';

// assets
import { IconFilter } from '@tabler/icons';

import MyDatePicker from "../MyDatePicker";

import SourceIcon from '@mui/icons-material/Source';
import DeleteIcon from '@mui/icons-material/Delete';

// save master filter state
export const setMasterFilterState = (table, data) => {
    const tableName = table + "-master-filter";
    localStorage.setItem(tableName, JSON.stringify(data));
}

// get master filter state
export const getMasterFilterState = (table) => {
    const tableName = table + "-master-filter";
    const data = localStorage.getItem(tableName);
    return data ? JSON.parse(data) : null;
}

interface MasterFilterBarProps {
    filters?: Filterable[];
    tableName?: string;
    onApplyFilter?: (f: Filter) => void;
    onRemoveFilter?: (f: Filter) => void;
    ref: any,
    slowFilter?: boolean,
    activeFilterFromAbove?: Filterable[]
    isLoading?: boolean,
    date?: Date,
    setDate?: (d: Date) => void,
    dataFilters?: any,
}

const MasterFilterBar = React.forwardRef(({
                                              tableName,
                                              filters = [], onApplyFilter = () => {
    }, onRemoveFilter = () => {
    }, slowFilter = false, activeFilterFromAbove = [], isLoading, date, setDate, dataFilters
                                          }: MasterFilterBarProps, ref) => {
    const theme = useTheme();

    const [search, setSearch] = React.useState('');
    const [activeFilters, setActiveFilters] = React.useState<Filterable[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [savedFilters, setSavedFilters] = React.useState<{
        label: string;
        state: {
            filters: Filterable[];
            filtersData: any;
        }
    }[]>([]);
    const [openSaveFilter, setOpenSaveFilter] = React.useState(false);
    const [saveFilterName, setSaveFilterName] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);

    React.useEffect(() => {

        setActiveFilters(activeFilterFromAbove)

    }, [activeFilterFromAbove]);
    const removeActiveFilter = (f: Filterable) => () => setActiveFilters(p => p.filter(fl => fl.id !== f.id));

    React.useEffect(() => {
        const savedFilters = localStorage.getItem('savedMasterFilters-' + tableName);
        if (savedFilters) {
            setSavedFilters(JSON.parse(savedFilters));
        }
    }, []);

    return (
        <>
            <Grid
                container
                alignItems='center'
                sx={{
                    minHeight: 40,
                    my: 2,
                    border: 1,
                    borderWidth: 0.5,
                    borderRadius: 2,
                    borderColor: theme.palette.grey[800],
                    backgroundColor: theme.palette.grey[50],
                    justifyContent: 'space-between'
                }}
            >
                <Grid
                    item
                    xs={1}
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        maxWidth: 60,
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        px: 1,
                        mx: 1,
                        borderRight: 1,
                        borderRightWidth: 0.5,
                        borderColor: theme.palette.grey[800]
                    }}
                >
                    <Typography variant='h4'>{activeFilters.length}</Typography>
                    <IconFilter />
                </Grid>
                <Grid item xs={date != undefined ? 9 : 10} sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    // '& .MuiGrid-root': {
                    //     '& .MuiGrid-item': {
                    //         margin: "5px 15px",
                    //         padding: "5px 15px"
                    //     },
                    // }
                }}>
                    <Autocomplete
                        disabled={isLoading}
                        value={activeFilters}
                        onChange={(e, v) => {

                            if (v.length !== 0) {
                                setActiveFilters(v);
                                // setMasterFilterState(tableName, v);
                            } else {
                                if (onRemoveFilter) {
                                    activeFilters.forEach((f) => onRemoveFilter({
                                        property: f.id,
                                        contains: '',
                                        queryFunc: f.queryFunc
                                    }));
                                }
                                setActiveFilters([]);
                                // setMasterFilterState(tableName, []);
                            }
                        }}
                        multiple
                        freeSolo
                        inputValue={search}

                        onInputChange={(e, v) => setSearch(v)}
                        options={filters}
                        getOptionLabel={(f) => f.label ?? f.id}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        size='medium'
                        fullWidth

                        autoHighlight
                        renderInput={(params) => (
                            <TextField
                                inputRef={ref}
                                {...params}
                                value=''
                                size='medium'
                                placeholder={
                                    filters.length !== activeFilters.length
                                        ? `סנן לפי ${filters
                                            .filter((f) => !activeFilters.map((fl) => fl.id).includes(f.id))
                                            .map((f) => f.label ?? f.id)
                                            .join(' ו\\או ')}`
                                        : ''
                                }
                                variant='standard'
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        e.stopPropagation();
                                    }
                                    if (e.key === 'ArrowDown') {
                                        e.stopPropagation();
                                    }
                                    if (e.key === 'ArrowUp') {
                                        e.stopPropagation();
                                    }
                                }}
                                fullWidth
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((v) => {
                                if (v.type === 'text') return <StyledTextTag {...getTagProps({ index: filters.findIndex(f => f.id === v.id) })}
                                                                             filter={v} onApplyFilter={onApplyFilter}
                                                                             onRemoveFilter={onRemoveFilter}
                                                                             onDelete={removeActiveFilter(v)} />;
                                if (v.type === 'autocomplete') return <StyledAutocompleteTag {...getTagProps({ index: filters.findIndex(f => f.id === v.id) })}
                                                                                             filter={v}
                                                                                             onApplyFilter={onApplyFilter}
                                                                                             onRemoveFilter={onRemoveFilter}
                                                                                             onDelete={removeActiveFilter(v)}
                                                                                             slowFilter={slowFilter} />;
                                if (v.type === 'date') return <StyledDateTag
                                    filter={v} {...getTagProps({ index: filters.findIndex(f => f.id === v.id) })}
                                    onApplyFilter={onApplyFilter} onRemoveFilter={onRemoveFilter}
                                    onDelete={removeActiveFilter(v)} />;
                                return <StyledBooleanTag {...getTagProps({ index: filters.findIndex(f => f.id === v.id) })}
                                                         filter={v} onApplyFilter={onApplyFilter}
                                                         onRemoveFilter={onRemoveFilter} onDelete={removeActiveFilter(v)} />;
                            })}
                    />
                </Grid>
                {date != undefined ? <Grid
                    item
                    xs={1}
                    sx={{

                        maxWidth: 50,



                        borderLeftWidth: 0.5,
                        borderColor: theme.palette.grey[800]
                    }}
                >
                    <Tooltip title={'שינוי תאריך הפלטור. לפי איזה תאריך תרצה שהמערכת תתייחס לפלטורים'}>
                        <IconButton color={date ? 'error' : undefined}>
                            <MyDatePicker date={date} setDate={setDate} isLoading={isLoading} onlyIcon={true} />
                        </IconButton>


                    </Tooltip>


                </Grid> : undefined}
                {<Grid
                    item
                    xs={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        maxWidth: 40,
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        borderLeft: 1,
                        borderLeftWidth: 0.5,
                        borderColor: theme.palette.grey[800],
                    }}
                >
                    <Tooltip title={"מצבים שמורים"}>
                        <>
                            <IconButton
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                            >
                                <SourceIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                            >
                                <MenuItem onClick={() => {
                                    if (activeFilters.length > 0) {
                                        setOpenSaveFilter(true);
                                    } else {
                                        setOpenAlert(true);
                                    }
                                }}>שמור מצב</MenuItem>
                                {savedFilters.map((f) => {
                                    return (
                                        <span style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}>
                                            <MenuItem onClick={() => {
                                                setAnchorEl(null);
                                                setActiveFilters(f.state.filters);
                                                onApplyFilter(f.state.filtersData);
                                            }}>{f.label}</MenuItem>
                                            <DeleteIcon style={{
                                                cursor: 'pointer',
                                            }} onClick={() => {
                                                setSavedFilters(savedFilters.filter((sf) => sf.label !== f.label));
                                                localStorage.setItem('savedMasterFilters-' + tableName, JSON.stringify(savedFilters.filter((sf) => sf.label !== f.label)));
                                            }} />
                                        </span>
                                    )
                                })}
                            </Menu>
                        </>
                    </Tooltip>
                </Grid>}
            </Grid>
            <Dialog
                open={openSaveFilter}
                onClose={() => setOpenSaveFilter(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">שמירת מצב</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{
                        marginTop: 10,
                    }}>
                        <TextField
                            label="שם המצב"
                            value={saveFilterName}
                            onChange={(e) => setSaveFilterName(e.target.value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSaveFilter(false)} color="primary">
                        ביטול
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenSaveFilter(false);
                            setSavedFilters([...savedFilters, {
                                label: saveFilterName, state: {
                                    filters: activeFilters,
                                    filtersData: dataFilters
                                }
                            }]);
                            localStorage.setItem('savedMasterFilters-' + tableName, JSON.stringify([...savedFilters, {
                                label: saveFilterName, state: {
                                    filters: activeFilters,
                                    filtersData: dataFilters
                                }
                            }]) ?? '[]');
                            setSaveFilterName('');
                        }}
                        color="primary"
                        autoFocus
                    >
                        שמור
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openAlert} autoHideDuration={2000} onClose={() => setOpenAlert(false)}>
                <Alert sx={{ width: '100%', backgroundColor: "#fde376eb" }} severity="error">אין פילטרים פעילים</Alert>
            </Snackbar>
        </>
    );
});

export default MasterFilterBar;

import React from 'react';

// material-ui

// third-party

// locale

// project imports
import LoadingButton from '@mui/lab/LoadingButton';
import { Chip } from '@mui/material';


const GenerativeLoader = ({ icon, func, disabled, doText }: { icon: any, func: any, disabled?: boolean, doText?: string }) => {
    const [loader, setLoader] = React.useState<boolean>(false);
    return (
        <LoadingButton
            size='small'
            onClick={() => {

                func(setLoader)
            }}

            sx={{ fontSize: doText ? 12 : 0 }}
            endIcon={icon}

            disabled={disabled}
            loading={loader}
            loadingPosition='end'
            variant={doText ? 'outlined' : 'text'}
        > {doText ? doText : undefined}</LoadingButton>
    );
};
export const GenerativeLoaderChip = ({ icon, func, disabled, doText,labelText }: { icon: any, func: any, disabled?: boolean, doText?: string,labelText?:string }) => {
    const [loader, setLoader] = React.useState<boolean>(false);
    return (
        <Chip onClick={() => { func(setLoader) }} icon={<LoadingButton
            size='small'
            sx={{ fontSize: doText ? 12 : 0 }}
            endIcon={icon}
            disabled={disabled}
            loading={loader}
            loadingPosition='end'
            variant={doText ? 'outlined' : 'text'}
        > {doText ? doText : undefined}</LoadingButton>} sx={{
            ml: 0, mr: 0, '& .MuiChip-icon': {
                color: '#008000',
            },
        }} label={labelText ? labelText :"יצא" } variant="outlined"
        >

        </Chip>
    );
};
export const GenerativeLoaderChipSum = ({ icon, func, disabled, doText }: { icon: any, func: any, disabled?: boolean, doText?: string }) => {
    const [loader, setLoader] = React.useState<boolean>(false);
    return (
        <Chip onClick={() => { func(setLoader) }} icon={<LoadingButton
            size='small'
            sx={{ fontSize: doText ? 12 : 0 }}
            endIcon={icon}
            disabled={disabled}
            loading={loader}
            loadingPosition='end'
            variant={doText ? 'outlined' : 'text'}
        > {doText ? doText : undefined}</LoadingButton>} sx={{
            ml: 0, mr: 0,p:0, '& .MuiChip-icon': {
                color: '#008000',
            },
        }} label="סיכום" variant="outlined"
        >

        </Chip>
    );
};
export const GenerativeLoaderChipForPrint = ({ icon, func, disabled, doText }: { icon: any, func: any, disabled?: boolean, doText?: string }) => {
    const [loader, setLoader] = React.useState<boolean>(false);
    return (
        <Chip onClick={() => { func(setLoader) }} icon={<LoadingButton
            size='small'
            sx={{ fontSize: doText ? 12 : 0 }}
            endIcon={icon}
            disabled={disabled}
            loading={loader}
            loadingPosition='end'
            variant={doText ? 'outlined' : 'text'}
        > {doText ? doText : undefined}</LoadingButton>} sx={{
            ml: 0, mr: 0, '& .MuiChip-icon': {
                color: '#008000',
            },
        }} label="הדפס" variant="outlined"
        >

        </Chip>
    );
};

export default GenerativeLoader;

import React, {memo, useEffect, useLayoutEffect, useRef} from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Checkbox, Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton, Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import {styled, Theme, useTheme} from '@mui/material/styles';
import {
    CellComponent,
    CellProps,
    Column,
    DataSheetGridRef,
    floatColumn,
    intColumn,
    keyColumn,
    textColumn
} from 'react-datasheet-grid';
import Swal from 'sweetalert2';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import cx from 'classnames';
// types
import {Filter} from 'types';


// styles
import 'react-datasheet-grid/dist/style.css';
import './style.css';

// project imports
import {selectColumn} from './custom-cells/SelectableCell';
import ActionCell from './custom-cells/ActionCell';
import SortableColumnHeader from './custom-column-headers/SortableColumnHeader';
import BottomBar from './BottomBar';
import ContextMenu from './ContextMenu';

// assets
import Product from '../../../types/product';
import {createOrderData, getKartonNum, OrderData} from '../../../types/OrderData';
import {useDispatch} from 'react-redux';
import {useFirstRender} from 'react-datasheet-grid/dist/hooks/useFirstRender';
import {xslx} from '../../../assets/images';
import {OrderActiveState} from '../../../types/OrderActiveState';

import {ExtendedDocument} from '../../../types/DocumentsTypes/Document';
import {DynamicDataSheetGrid} from 'datasheetreact/src';

import {makeCsv2} from '../../../utils/csvManipulator';

import AddCustomer from '../../customer/AddCustomer';
import {Person} from '@mui/icons-material';
import JSDB from '../../../fromKotlin/nk';
import CloseIcon from "@mui/icons-material/Close";
import CustomerPreview from "../../customer/profile/documents/CustomerPreview";
import {Transition} from "../../customer/CustomerList";

type TextColumnOptions<T> = {
    placeholder?: string
    alignRight?: boolean
    // When true, data is updated as the user types, otherwise it is only updated on blur. Default to true
    continuousUpdates?: boolean
    // Value to use when deleting the cell
    deletedValue?: T
    // Parse what the user types
    parseUserInput?: (value: string) => T
    // Format the value of the input when it is blurred
    formatBlurredInput?: (value: T) => string
    // Format the value of the input when it gets focused
    formatInputOnFocus?: (value: T) => string
    // Format the value when copy
    formatForCopy?: (value: T) => string
    // Parse the pasted value
    parsePastedValue?: (value: string) => T
}

type TextColumnData<T> = {
    placeholder?: string
    alignRight: boolean
    continuousUpdates: boolean
    parseUserInput: (value: string) => T
    formatBlurredInput: (value: T) => string
    formatInputOnFocus: (value: T) => string
}

const TextComponent2 = React.memo<CellProps<any | null, TextColumnData<any | null>>>(
    ({
         active,
         focus,
         rowData,
         setRowData,
         columnData: {
             placeholder,
             alignRight,
             formatInputOnFocus,
             formatBlurredInput,
             parseUserInput,
             continuousUpdates
         }
     }) => {
        const ref = useRef<HTMLInputElement>(null);
        const firstRender = useFirstRender();


        // We create refs for async access so we don't have to add it to the useEffect dependencies
        const asyncRef = useRef({
            rowData,
            formatInputOnFocus,
            formatBlurredInput,
            setRowData,
            parseUserInput,
            continuousUpdates,
            firstRender,
            // Timestamp of last focus (when focus becomes true) and last change (input change)
            // used to prevent un-necessary updates when value was not changed
            focusedAt: 0,
            changedAt: 0,
            // This allows us to keep track of whether or not the user blurred the input using the Esc key
            // If the Esc key is used we do not update the row's value (only relevant when continuousUpdates is false)
            escPressed: false
        });
        asyncRef.current = {
            rowData,
            formatInputOnFocus,
            formatBlurredInput,
            setRowData,
            parseUserInput,
            continuousUpdates,
            firstRender,
            // Keep the same values across renders
            focusedAt: asyncRef.current.focusedAt,
            changedAt: asyncRef.current.changedAt,
            escPressed: asyncRef.current.escPressed
        };
        const {
                setData,
            updatedRowIds,

        } = React.useContext(DataSheetGridContext);
        useLayoutEffect(() => {
            // When the cell gains focus we make sure to immediately select the text in the input:
            // - If the user gains focus by typing, it will replace the existing text, as expected
            // - If the user gains focus by clicking or pressing Enter, the text will be preserved and selected
            if (focus) {
                if (ref.current) {
                    // Make sure to first format the input
                    ref.current.value = asyncRef.current.formatInputOnFocus(
                        asyncRef.current.rowData.customer
                    );
                    ref.current.focus();
                    ref.current.select();
                }

                // We immediately reset the escPressed
                asyncRef.current.escPressed = false;
                // Save current timestamp
                asyncRef.current.focusedAt = Date.now();
            }
                // When the cell looses focus (by pressing Esc, Enter, clicking away...) we make sure to blur the input
            // Otherwise the user would still see the cursor blinking
            else if (ref.current) {
                // Update the row's value on blur only if the user did not press escape (only relevant when continuousUpdates is false)
                if (
                    !asyncRef.current.escPressed &&
                    !asyncRef.current.continuousUpdates &&
                    !asyncRef.current.firstRender &&
                    // Make sure that focus was gained more than 10 ms ago, used to prevent flickering
                    asyncRef.current.changedAt >= asyncRef.current.focusedAt
                ) {
                    asyncRef.current.setRowData({
                            ...rowData,
                            customer: asyncRef.current.parseUserInput(ref.current.value)
                        }
                    );
                }
                ref.current.blur();
            }
        }, [focus]);
        const [openDrawer, setOpenDrawer] = React.useState(false);
        const handleDrawerClose = React.useCallback(() => {
            setOpenDrawer(false);
        }, []);
        useEffect(() => {
            if (!focus && ref.current) {
                // On blur or when the data changes, format it for display
                ref.current.value = asyncRef.current.formatBlurredInput(rowData.customer);
            }
        }, [focus, rowData]);

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',

                alignItems: 'center',

                flexWrap: 'wrap'

            }}>

                {openDrawer ? <AddCustomer
                    open={openDrawer}
                    handleDrawerOpen={handleDrawerClose}
                    id={rowData.id}
                    editable={true}
                /> : undefined}

                <Tooltip title='חולק היום'>
                    <Checkbox disabled checked={rowData.readonly}
                              style={{ 'color': 'green', paddingInline: 0 }} size='small' />
                </Tooltip>
                <Tooltip title='הוזמן היום'>
                    <Checkbox disabled checked={rowData.orderId !== ''}
                              style={{ 'color': 'red', paddingInline: 2 }} size='small' />
                </Tooltip>
                <IconButton
                    style={{ color: rowData.notes && rowData.notes != '' ? 'orange':undefined }} size='medium' onClick={(e) => {


                    Swal.fire({
                        title: 'הערות',
                        input: !rowData.readonly ? 'textarea':undefined,
                        inputValue:rowData.notes ?? "",
                        inputAttributes: {

                            'aria-label': rowData.notes ?? ""
                        },
                        showCancelButton: true,
                        showConfirmButton: !rowData.readonly,
                        text: rowData.readonly ? rowData.notes:undefined,
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        }
                        , confirmButtonText: 'צור הערה'
                        , cancelButtonText: 'סגור'
                    }) .then((result) => {
                        /* Read more about handling dismissals below */

                        if(result.isConfirmed && !rowData.readonly) {
                            updatedRowIds.add(rowData.id)
                            setData((prev) =>
                                prev.map((d, i) =>
                                    d.id! == rowData.id
                                        ? {...d,  notes:String(result.value),me:d} : d
                                )
                            );

                        }
                    });
                }}>
                    <InsertCommentIcon />
                </IconButton>

                <input
                    // We use an uncontrolled component for better performance
                    defaultValue={formatBlurredInput(rowData.customer)}
                    className={cx('dsg-input', '')}
                    placeholder={active ? placeholder : undefined}
                    // Important to prevent any undesired "tabbing"
                    tabIndex={-1}
                    ref={ref}

                    // Make sure that while the cell is not focus, the user cannot interact with the input
                    // The cursor will not change to "I", the style of the input will not change,
                    // and the user cannot click and edit the input (this part is handled by DataSheetGrid itself)
                    style={{ pointerEvents: focus ? 'auto' : 'none', textAlign: 'right' }}
                    onChange={(e) => {
                        asyncRef.current.changedAt = Date.now();

                        // Only update the row's value as the user types if continuousUpdates is true
                        if (continuousUpdates) {
                            setRowData({ ...rowData, customer: parseUserInput(e.target.value) });
                        }
                    }}
                    onKeyDown={(e) => {
                        // Track when user presses the Esc key
                        if (e.key === 'Escape') {
                            asyncRef.current.escPressed = true;
                        }
                    }}
                />
                {parseInt(localStorage.getItem('externalIdOrders') ?? '0') == 1 ? ((rowData?.externalId)? <Typography fontSize={12}>-{rowData?.externalId}</Typography>:undefined):(Number(rowData?.id)? <Typography fontSize={12}>-{JSDB().getClient(Number(rowData.id))?.first?.id}</Typography>:undefined)}

                <Tooltip title='פרטי לקוח' style={{ color: 'blue', marginRight: 0, marginLeft: 0, paddingInline: 0 }}>
                    <IconButton
                        style={{ color: 'blue', marginRight: 0, marginLeft: 0, paddingInline: 0 }} size='medium'
                        onClick={() => {

                            setOpenDrawer(true);
                        }}>
                        <Person />
                    </IconButton>
                </Tooltip>

            </div>
        );
    }
);

TextComponent2.displayName = 'TextComponent';
export const costColumn = createTextColumn<any | null>();

export function createTextColumn<T = any | null>({
                                                     placeholder,
                                                     alignRight = false,
                                                     continuousUpdates = true,
                                                     deletedValue = null as unknown as T,
                                                     parseUserInput = (value) => (value.trim() || null) as unknown as T,
                                                     formatBlurredInput = (value) => String(value ?? ''),
                                                     formatInputOnFocus = (value) => String(value ?? ''),
                                                     formatForCopy = (value) => String(value ?? ''),
                                                     parsePastedValue = (value) =>
                                                         (value.replace(/[\n\r]+/g, ' ').trim() || (null as unknown)) as T
                                                 }: TextColumnOptions<T> = {}): Partial<Column<T, TextColumnData<T>, string>> {
    return {
        component: TextComponent2 as unknown as CellComponent<T, TextColumnData<T>>,
        columnData: {
            placeholder,
            alignRight,
            continuousUpdates,
            formatInputOnFocus,
            formatBlurredInput,
            parseUserInput
        },
        deleteValue: () => deletedValue,
        copyValue: ({ rowData }) => formatForCopy(rowData),
        pasteValue: ({ value }) => parsePastedValue(value),
        isCellEmpty: ({ rowData }) => rowData === null || rowData === undefined
    };
}

export const DataSheetGridContext = React.createContext({
    activeRow: 0,
    openDrawer: false,
    handleOpenDrawer: () => {
    },
    setSingleSave: (rowid) => {

    },
    setSingleDelete: (rowid) => {

    },
    callPrevOrder: (callDate: Date, ids?: number[], day?: number) => {

    },
    date: new Date(),
    unsavedRowsLength: 0,
    current: null,
    markedIds: new Set(),
    shrinkActions: false,
    updatedRowIds: null,
    updatedDriverRowIds: null,
    data: null,
    setData: null,
    columns: null,
    disableKeyBoard: null,
    saveOrder: null,
    callPrevDaily: null,
    deleteOrder: null,
    dvs_flag: false,
    activeState: null,
    onApplyFilter: null,
    addCustomerFilter: null,
    removeAllFilters: null,
    setAll: null,
    setActiveRow: null
});

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('sm')]: {
            width: `100%`
        },
        [theme.breakpoints.down('sm')]: {
            width: `100%`
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `100%`,
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));
export const simulateClick = (className) => {
    const clickEvent = document.createEvent('MouseEvents');
    clickEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0,
        false, false, false, false, 0, null);

    const element = document.getElementsByClassName(className).item(0);
    element?.dispatchEvent(clickEvent);
};

interface orderFilters {
    agents: Filter[];
    collectors: Filter[];
    drivers: Filter[];
    clients: Filter[];
    cities: Filter[];
    categories: Filter[];
    orders: Filter[];
    order_assistent: Filter[];
    dvs: Filter[];
    products: Filter[];
}

const OrdersDataSheet = ({
                             products,
                             agentsNames,
                             collectorsNames,
                             driversNames,
                             date,
                             activeState,

                              tempData, setTempData,
                             updatedRowIds,
                             updatedDriverRowIds,
                             callPrevOrder,
                             callPrevDaily,
                             statuses,

                             setSingleDelete,
                             deleteOrder,
                             saveOrder,
                             openDrawer,
                             handleOpenDrawer,
                             setDrawerSingleData,
                             activeRow,
                             setActiveRow,

                             setAll,

                             restoreAllChanges,
                             dvs_flag,
                             setDvsFlag,
                             showProducts,
                             shrinkActions,
                             setShowProducts,
                             setShrinkActions,
                             orderFilters,
                             onApplyFilter,
                             showData,
                             color_surface,
                             setColorSurface,
                             sortBy,

                             handleApplySort,
                             addCustomerFilter,
                             removeAllFilters,
                             setOpenStatistics,
                             openStatistics,
                             setActiveP,cancelDV
                         }: {
    products: Product[];
    agentsNames: string[];
    collectorsNames: string[];
    driversNames: string[];
    date: Date,
    activeState: OrderActiveState,
    tempData: OrderData[],
    setTempData: any,
    callPrevOrder: any,
    updatedRowIds: any,
    updatedDriverRowIds: any,
    callPrevDaily: any,
    statuses: any[]

    setSingleDelete: any,
    deleteOrder: any,
    saveOrder: any,
    openDrawer: any,
    handleOpenDrawer: any,
    setDrawerSingleData: any,
    activeRow: any,
    setActiveRow: any,

    setAll: any

    restoreAllChanges: any
    dvs_flag: boolean
    setDvsFlag: any
    showProducts: any,
    shrinkActions: any
    setShowProducts: any
    setShrinkActions: any
    orderFilters: orderFilters
    onApplyFilter: any
    showData: any,
    color_surface,
    setColorSurface,
    sortBy: any,

    handleApplySort: any
    addCustomerFilter: any
    removeAllFilters: any,
    setOpenStatistics: any,
    openStatistics: any,
    cancelDV: any,
    setActiveP
}) => {
    const theme = useTheme();


    const [disableKeyBoard, setDisableKeyBoard] = React.useState(false);


    const ref = useRef<DataSheetGridRef>(null);

    const dispatch = useDispatch();


    const columns = React.useMemo(
        () => [
            ...(showProducts ? products.slice().filter((p)=>
                    {
                        return orderFilters.products.length==0 || orderFilters.products[0].values.some((v)=>v == p.name)
                    }


            ).reverse().map((p) => ({
                ...keyColumn(`p_${p.id}`, floatColumn),
                title: <SortableColumnHeader id={`p_${p.id}`}
                                             name={(p.orderName && p.orderName.length > 0) ? p.orderName : p.name}
                                             sortBy={sortBy}
                                             handleApplySort={handleApplySort}
                                             sum={showData.reduce((acc, e) => acc + (e[`p_${p.id}`] ? Number(e[`p_${p.id}`]) : 0), 0)} />,
                width: (localStorage.getItem(`p_${p.id}_column`)) ? localStorage.getItem(`p_${p.id}_column`) : '60px',
                minWidth: 30,
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    let border = localStorage.getItem(`p_${p.id}_column_border`) ? Number(localStorage.getItem(`p_${p.id}_column_border`)) : 0;
                    if (border) {
                        if (opt.rowData.break_row)
                            return 'break_row_right';
                        return 'right_border';
                    } else if (opt.rowData.break_row)
                        return 'break_row';
                    return '';

                }),
                disabled: ({ rowData }) => (rowData?.status === 'נעולה' || rowData.readonly)
            })) : []),

            {
                ...keyColumn('category', textColumn),
                title: <SortableColumnHeader id='category' name='קטגוריה' sortBy={sortBy}
                                             handleApplySort={handleApplySort} />,
                width: (localStorage.getItem('category_column')) ? localStorage.getItem('category_column') : '70px',
                minWidth: 0,
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    if (opt.rowData.break_row)
                        return 'break_row_left';
                    return 'left_border';


                }),
                disabled: ({ rowData }) => (rowData?.status === 'נעולה' || rowData.readonly)
            },
            {
                ...keyColumn('mName', intColumn),
                title: <SortableColumnHeader id='mName' name='משטח' sortBy={sortBy}
                                             handleApplySort={handleApplySort} />,
                width: (localStorage.getItem(`mName_column`)) ? localStorage.getItem(`mName_column`) : '49px',
                minWidth: 0,
                disabled: ({ rowData }) => (rowData?.status === 'נעולה' || rowData.readonly),
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    if (opt.rowData.break_row)
                        return 'break_row';


                })
            },
            {
                ...selectColumn({ choices: collectorsNames.map((n) => ({ label: n, value: n })), id: 'collectorName' }),
                title: <SortableColumnHeader id='collectorName' id2='collector_position' name='שם מלקט' sortBy={sortBy}
                                             handleApplySort={handleApplySort} />,
                width: (localStorage.getItem(`collectorName_column`)) ? localStorage.getItem(`collectorName_column`) : '130px',
                minWidth: 0,
                disabled: ({ rowData }) => (rowData?.status === 'נעולה' || rowData.readonly),
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    if (opt.rowData.break_row)
                        return 'break_row';


                })
            },
            {
                ...selectColumn({ choices: driversNames.map((n) => ({ label: n, value: n })), id: 'driverName' }),
                title: <SortableColumnHeader id='driverName' id2='driver_position' name='שם נהג' sortBy={sortBy}
                                             handleApplySort={handleApplySort} />,
                width: (localStorage.getItem(`driverName_column`)) ? localStorage.getItem(`driverName_column`) : '130px',
                minWidth: 0,
                disabled: ({ rowData }) => (rowData?.status === 'נעולה' || rowData.readonly),
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    if (opt.rowData.break_row)
                        return 'break_row';


                })
            },
            {
                ...selectColumn({ choices: agentsNames.map((n) => ({ label: n, value: n })), id: 'agent' }),
                title: <SortableColumnHeader id='agent' name='שם קו' sortBy={sortBy}
                                             handleApplySort={handleApplySort} />,
                width: (localStorage.getItem(`agent_column`)) ? localStorage.getItem(`agent_column`) : '110px',
                minWidth: 0,
                disabled: ({ rowData }) => (rowData?.status === 'נעולה' || rowData.readonly),
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    if (opt.rowData.break_row)
                        return 'break_row';


                })
            },
            {
                ...selectColumn({
                    choices: statuses.map((n) => ({
                        label: n.orderName(),
                        value: n.orderName(),
                        disabled: n.disableSelect()
                    })), id: 'status'
                }),
                title: <SortableColumnHeader id='status' name='סטטוס הזמנה' sortBy={sortBy}
                                             handleApplySort={handleApplySort} />,
                width: (localStorage.getItem(`status_column`)) ? localStorage.getItem(`status_column`) : '130px',
                minWidth: 30,
                disabled: ({ rowData }) => (rowData.readonly),
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    if (opt.rowData.break_row)
                        return 'break_row';


                })

            },
            {
                ...keyColumn('city', textColumn),
                title: <SortableColumnHeader id='city' name='עיר' sortBy={sortBy}
                                             handleApplySort={handleApplySort} />,
                width: (localStorage.getItem('city_column')) ? localStorage.getItem('city_column') : '70px',
                minWidth: 0,
                cellClassName: ((opt: { rowData; rowIndex }) => {
                    if (opt.rowData.break_row)
                        return 'break_row_left';
                    return 'left_border';


                }),
                disabled: ({ rowData }) => true
            },

        ],
        [showProducts, products, sortBy, handleApplySort, collectorsNames, driversNames, agentsNames, showData,orderFilters.products]
    );


    let timeOutId: NodeJS.Timeout | null = null;
    const handleOnActiveCellChange = React.useCallback((cell) => {
        if (cell?.cell) {
            if (timeOutId)
                clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {

                setActiveRow(cell!.cell.row);
                setActiveP(columns.length - 1 - cell!.cell.col > 4 ? Number(cell!.cell.colId.replace('p_', '')) : undefined);
            }, 200);

        }

    }, []);

    const createdRowIds = React.useMemo(() => new Set(), []);
    const deletedRowIds = React.useMemo(() => new Set(), []);

    const markedIds = React.useMemo(() => new Set(), []);


    React.useEffect(() => {

        ref.current?.setActiveCell({ row: 0, col: columns.length - 1 });
    }, [showProducts]);

    React.useEffect(() => {
        setDrawerSingleData(showData);

        // dispatch({
        //     type: SNACKBAR_OPEN,
        //     open: true,
        //     anchorOrigin: {
        //         vertical: 'bottom',
        //         horizontal: 'left'
        //     },
        //     message: 'לקוח התחלף',
        //     variant: 'alert',
        //     alertSeverity: 'warning'
        // });
    }, [showData]);
    React.useEffect(() => {
        handleApplySort(sortBy.orderBy, sortBy.order, sortBy.property2);
    }, [orderFilters]);


    return (
        <DataSheetGridContext.Provider
            value={{
                activeRow,
                openDrawer,
                handleOpenDrawer,
                setSingleDelete,
                callPrevOrder,
                current: ref?.current,
                markedIds,
                data: showData,
                setData: setTempData,
                columns,
                cancelDV,
                updatedRowIds,
                updatedDriverRowIds,
                shrinkActions,
                date,
                disableKeyBoard: setDisableKeyBoard,
                saveOrder,
                unsavedRowsLength: updatedRowIds.size + createdRowIds.size,
                callPrevDaily,
                deleteOrder,
                dvs_flag,
                activeState,
                onApplyFilter,
                addCustomerFilter,
                removeAllFilters,
                setAll,
                setActiveRow
            }} disableKeyBoard
        >

            <Box>

                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FormControl>
                            {products.length > 1 && (
                                <FormControlLabel
                                    value='low'
                                    control={
                                        <Checkbox
                                            checked={showProducts}
                                            onChange={() => {

                                                setShowProducts((p) => !p)
                                                setTimeout(()=>window.location.reload(),200)
                                            }}
                                            color='primary'
                                            sx={{ color: 'primary.main' }}
                                        />
                                    }
                                    label='הצג מוצרים בטבלה'
                                />
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        {activeState.day == -1 ? <FormControl>
                            <FormControlLabel
                                value='low'
                                control={
                                    <Checkbox
                                        checked={dvs_flag}
                                        onChange={() => {
                                            if (updatedRowIds.size > 0 && dvs_flag) {
                                                Swal.fire({
                                                    title: 'ישנם שינויים שעשית במצב תעודות',
                                                    text: 'מעבר למצב רגיל מוחק את השינויים האם אתה בטוח?',
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonColor: theme.palette.primary.main,
                                                    cancelButtonColor: theme.palette.error.main,
                                                    confirmButtonText: 'עבור',
                                                    cancelButtonText: 'בטל'
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        /* Perform insert, update, and delete to the database here */
                                                        restoreAllChanges();
                                                        setDvsFlag((p) => !p);
                                                    }
                                                });
                                            } else {
                                                restoreAllChanges();
                                                setDvsFlag((p) => !p);
                                            }

                                        }}
                                        color='primary'
                                        sx={{ color: 'primary.main' }}
                                    />
                                }
                                label='הנפקת תעודות משלוח גורפת'
                            />
                        </FormControl> : undefined}

                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <FormControlLabel
                                value='low'
                                control={
                                    <Checkbox
                                        checked={color_surface}
                                        onChange={() => {
                                            setColorSurface((p) => !p);
                                        }}
                                        color='primary'
                                        sx={{ color: 'primary.main' }}
                                    />
                                }
                                label='צבע משטחים'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            {products.length > 1 && (
                                <FormControlLabel
                                    value='low'
                                    control={
                                        <Checkbox
                                            checked={shrinkActions}
                                            onChange={() => setShrinkActions((p) => !p)}
                                            color='primary'
                                            sx={{ color: 'primary.main' }}
                                        />
                                    }
                                    label='הצג פעולות'
                                />
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <FormControlLabel
                                value='low'
                                control={
                                    <Checkbox
                                        checked={openStatistics}
                                        onChange={() => setOpenStatistics((p) => !p)}
                                        color='primary'
                                        sx={{ color: 'primary.main' }}
                                    />
                                }
                                label='פתח סטטיסטיקה'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip style={{ marginRight: '5px' }} title='יצא לאקסל' onClick={() => {
                            makeCsv2(showData, `${'מסמכים'}.csv`, [
                                { label: 'שם לקוח', id: 'customer' },
                                { label: 'מזהה חיצוני', id: 'externalId' },
                                {
                                    label: 'כתובת', id: 'address', extractFunc: (row) => {
                                        return JSDB().getClient(Number(row.id)).first?.getAddressStr() ?? '';
                                    }
                                },
                                {
                                    label: 'טלפון', id: 'phone', extractFunc: (row) => {
                                        return JSDB().getClient(Number(row.id)).first?.getPhone() ?? '';
                                    }
                                },
                                {
                                    label: 'מספר קרטונים', id: 'karton', extractFunc: (row) => {
                                        return getKartonNum(row);
                                    }
                                }
                                , ...columns.map((it) => {
                                    return {
                                        label: it.title.props.name,
                                        id: it.id
                                    };
                                }).reverse()]);

                        }}>
                            <Avatar variant='square'
                                    sx={{ cursor: 'pointer', bgcolor: 'white', width: '24px', height: '24px' }}
                                    src={xslx} />
                        </Tooltip>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex' }}>
                    <Main theme={theme} open={openDrawer}>
                        <DynamicDataSheetGrid
                            ref={ref}
                            value={showData}
                            disableKeyBoard={disableKeyBoard}
                            setDisableKeyBoard={setDisableKeyBoard}
                            height='700'
                            contextMenuComponent={ContextMenu}
                            headerRowHeight={100}
                            columns={columns}
                            gutterColumn={{
                                component: ActionCell,
                                title: 'פעולות',
                                minWidth: (shrinkActions) ? 220 : 60
                            }}
                            style={{ '--dsg-header-text-color': 'black' }}
                            stickyRightColumn={{
                                ...keyColumn('me', costColumn),
                                title: <SortableColumnHeader id='customer' name='שם לקוח' sortBy={sortBy}
                                                             handleApplySort={handleApplySort} />,
                                id: 'me',
                                width: (localStorage.getItem('me_column')) ? localStorage.getItem('me_column') : '230px',
                                minWidth: 230,
                                cellClassName: ((opt: { rowData; rowIndex }) => {
                                    if (opt.rowData.break_row)
                                        return 'break_row';


                                })
                            }}
                            onActiveCellChange={handleOnActiveCellChange}
                            createRow={() => createOrderData({ readonly: false })}
                            addRowsComponent={BottomBar}
                            rowClassName={({ rowData, rowIndex }) => {
                                if (rowData?.all_dvs_printed === true) {
                                    return 'row-all_dvs_printed';
                                }
                                if (rowData?.readonly === true) {
                                    return 'row-readonly';
                                }

                                if (!rowData?.id) return '';

                                if (deletedRowIds.has(rowData.id)) {
                                    return 'row-deleted';
                                }
                                if (createdRowIds.has(rowData.id)) {
                                    return 'row-created';
                                }

                                if (updatedRowIds.has(rowData.id) || updatedDriverRowIds.has(rowData.id)) {
                                    return 'row-updated';
                                }
                                if (rowData?.status === 'נעולה') {
                                    return 'row-locked';
                                }
                                if (dvs_flag && rowData?.orderId) {
                                    return 'row-dvs';
                                }

                                if (activeRow === rowIndex) {
                                    return 'row-active';
                                }


                                if (color_surface && (Number(rowData.mName) ?? 0) % 2 == 1) {
                                    return 'row-mname';
                                }
                                return '';
                            }}
                            onChange={(newValue, operations) => {
                                for (const operation of operations) {
                                    if (operation.type === 'CREATE') {
                                        newValue
                                            .slice(operation.fromRowIndex, operation.toRowIndex)
                                            .forEach(({ id }) => createdRowIds.add(id));
                                    }

                                    if (operation.type === 'UPDATE') {

                                        newValue.slice(operation.fromRowIndex, operation.toRowIndex).forEach(({ id }) => {
                                            if (!createdRowIds.has(id) && !deletedRowIds.has(id)) {
                                                updatedRowIds.add(id);
                                                updatedDriverRowIds.add(id);
                                            }
                                        });
                                    }

                                    if (operation.type === 'DELETE') {
                                        let keptRows = 0;

                                        tempData.slice(operation.fromRowIndex, operation.toRowIndex).forEach(({ id }, i) => {
                                            updatedRowIds.delete(id);

                                            if (createdRowIds.has(id)) {
                                                createdRowIds.delete(id);
                                            } else {
                                                deletedRowIds.add(id);
                                                // eslint-disable-next-line no-plusplus
                                                newValue.splice(operation.fromRowIndex + keptRows++, 0, tempData[operation.fromRowIndex + i]);
                                            }
                                        });
                                    }
                                    const ids = (newValue
                                        .slice(operation.fromRowIndex, operation.toRowIndex)).reduce((map, obj) => {

                                        if(JSDB().userCofcConf?.conversion_rate_blocker==1 || JSDB().userCofcConf?.conversion_rate_blocker==2){

                                            products.forEach((p) => {
                                                if (obj[`p_${p.id}`]&& p.extra_unit  && obj[`u_${p.id}`]==undefined) {
                                                    obj[`u_${p.id}`]=1
                                                }
                                            })
                                        }


                                        map[obj.id] = obj;
                                        return map;
                                    }, {});
                                    setTempData((prev) =>
                                        prev.map((d, i) =>
                                            d.id! in ids
                                                ? ids[d.id!] : d
                                        )
                                    );

                                }

                            }}
                        />
                    </Main>
                </Box>
            </Box>

        </DataSheetGridContext.Provider>
    );
};

export default memo(OrdersDataSheet);

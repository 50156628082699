import config from 'config';
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  TextField,
  MenuItem,
  Autocomplete,
  useTheme,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  Input
} from "@mui/material";
import { IconCurrencyShekel } from "@tabler/icons";
import { DatePicker } from '@mui/lab';
import { format, parseJSON } from "date-fns";
import { he, enUS } from "date-fns/locale";
import JSDB from "fromKotlin/nk";
import React, {useCallback} from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getSpecificCustomer, selectCustomerById } from "store/customers/customersSlice";
import { paymentMethods, PaymentMethod } from "types/document";
import SubCard from "ui-component/cards/SubCard";
import Chip from "ui-component/extended/Chip";
import HoverableIncrementer from "ui-component/HoverableIncrementer";
import Logo from "ui-component/Logo";
import { NoNegativeInput } from "ui-component/textFieldNumber";
import DocumentClientDetails from "../document_header_preview/DocumentClientDetails";
import DocumentHeaderPreview from "../document_header_preview/DocumentHeaderPreview";
import { roundDecimals } from "fromKotlin/nkutils";
import CustomerProductsPreviewList from "../../products/CustomerProductsPreviewList";
import { PayNote } from 'types/DocumentsTypes/PayNote';
import { TaxNote } from 'types/DocumentsTypes/TaxNote';
import PreviewDocumentComments from "../PreviewComments";
import {hashSign} from "../../../../../api/customer/documents.api";
import {SNACKBAR_OPEN} from "../../../../../store/actions";
import Swal from "sweetalert2";

interface InvoiceAndReciptPreviewProps extends PayNote, TaxNote {
  onPaymentChange?: (v: PaymentMethod) => void;
  onProductChange?: (v: any) => void;
  onIdChange?: (v: any) => void;
  pool?:string
  outerDoc?:any
  editting?: boolean;
  setInternalEdit?: (edit: any) => void;
  internalEdit?: any;
}

const InvoiceAndReciptPreview = ({ onPaymentChange, onProductChange,editting, internalEdit,setInternalEdit, onIdChange,pool,outerDoc, ...document }: InvoiceAndReciptPreviewProps) => {

  const theme = useTheme();
  const dispatch = useDispatch();

  // TODO: Change to single customer fetching
  const customer = useSelector((state: RootState) => selectCustomerById(state, document?.entId));
  React.useEffect(() => {
    if (!customer) dispatch(getSpecificCustomer(Number(document.entId)));
  }, [dispatch]);
  const address = document.details!.street && document.details!.streetNumber ? `${document.details!.street}, ${document.details!.streetNumber}` : document.details!.street;
  const startLog = useCallback(async () => {
    if(!document) return
    // if(true) return
    try {
      const x = await hashSign(document.id,document.type === 'invoice' ? 0:document.type === 'refund' ? 2:1)
      if(!x){
        // token err
        // const newWindow = window.open('https://openapi.taxes.gov.il/shaam/tsandbox/longtimetoken/oauth2/authorize?response_type=code&client_id=e65677412ee9bcfc582f4b7f6f2d801f&scope=scope&redirect_uri=http://localhost:3001/reports/tax-israel', '_blank', 'noopener,noreferrer');
        const newWindow = window.open('https://openapi.taxes.gov.il/shaam/production/longtimetoken/oauth2/authorize?response_type=code&client_id=35828e73b8b9edbc05c4ac943f5634f0&scope=scope&redirect_uri=https://solvitcompany.com/reports/tax-israel', '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;

      }
      if (setInternalEdit) {
        setInternalEdit({...internalEdit, taxConfirmation: (x.taxConfirmation)})
      }

      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: 'טוקן החשבונית עודכן בהצלחה!',
        variant: 'alert',
        alertSeverity: 'success'
      });
    }
    catch (e) {
      console.log(e)
      Swal.fire({
        title: 'לא הצליח לממשק מס הקצאה לחשבונית',
        target: '#custom-target',
        text: 'יתכן כי ח.פ הלקוח שגוי/ חסר או תאריך החשבונית שגוי',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#d63030'
      });
    }

  }, [document])
  return (
    <SubCard
      darkTitle
      title={
        <Typography fontWeight='bold'>
          {(document.type === 'invoice') ? 'חשבונית מס' : document.type === 'refund' ? 'חשבונית מס זיכוי' : 'חשבונית מס קבלה'} {onIdChange ?
            <NoNegativeInput

              placeholder={'אוטומטי'}
              changer={(v) => {
                onIdChange(v)
              }}

            /> : document.id ? document.id : ''}
        </Typography>
      }
      secondary={<Logo width={50} />}
    >
      <Grid container spacing={2}>
        <DocumentHeaderPreview />

        <Grid item xs={12}>
          <Grid container>
            <DocumentClientDetails address={address} name={customer?.name || ""}
              businessName={document.details!.companyName}
              businessId={document.details!.companyId}
              id={customer?.id || ""} external_id={customer?.externalId || ""}
              phone={document.details!.phone} email={document.details!.email} />
            <Grid item sm={6}>
              <Grid container>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {/* <FormattedMessage id={document.type === 'invoice' ? 'Invoice Details' : 'Refund Invoice Details'} /> */}
                  פרטי חשבונית מס ו/או קבלה
                </Typography>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant='body2'>
                        <FormattedMessage id='Date' />:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body2'>
                        {format(document.date, 'PPP', {
                          locale: config.i18n === 'he' ? he : enUS
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2'>
                        תאריך הנפקה:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant='body2'>
                        {format(document.issueDate, 'PPPPp', {
                          locale: config.i18n === 'he' ? he : enUS
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2'>
                        <FormattedMessage id='Status' />:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Chip
                        label={<FormattedMessage id={document.status} />}
                        variant='outlined'
                        size='small'
                        chipcolor={
                          document.status === 'pending_approval'
                            ? 'warning'
                            : document.status === 'approved'
                              ? 'success'
                              : document.status === 'open'
                                ? 'error'
                                : ''
                        }
                      />
                    </Grid>
                    <>
                      <Grid item xs={4}>
                        <Typography variant='body2'>
                          סוכן:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant='body2'>
                          {document.agent}
                        </Typography>
                      </Grid>
                    </>
                    {(!editting) ?
                        <>
                          <Grid item xs={4}>
                            <Typography variant='body2'>
                              מספר הקצאה:
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant='body2'>
                              {document.taxConfirmation}
                            </Typography>
                          </Grid>
                        </> : undefined
                    }
                    {
                        editting &&  (
                            <>
                              <Grid item xs={4}>
                                <Typography variant='body2'>
                                  מספר הקצאה:
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Input
                                    type='text'
                                    value={internalEdit?.taxConfirmation ??  document.taxConfirmation}
                                    onChange={(e) => setInternalEdit && setInternalEdit({...internalEdit,taxConfirmation:(e.target.value)})}
                                />
                              </Grid>
                              {JSDB().getUser().first.hesh_israel==1 ?  <button onClick={() =>{
                                startLog()
                              }}>
                                ממשק מס הכנסה
                              </button>:undefined}

                            </>
                        )
                    }
                    {document.deliveryCertificatesIds.length > 0 ? (
                      <Grid item xs={12}>
                        <Typography variant='body2'>
                          <FormattedMessage id="Delivery Certificates ID's" />:
                        </Typography>
                      </Grid>
                    ) : undefined}
                    {document.deliveryCertificatesIds.map((d, i) => (
                      <Grid key={i} item xs={4}>
                        <Typography variant='subtitle1'>{d}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {document.handArray && document.handArray?.[0].value !== 0 && document.handArray?.[0].amount !== 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "0.9rem", fontWeight: "bold", textAlign: "right" }} align="right">תיאור ידני</TableCell>
                    <TableCell style={{ fontSize: "0.9rem", fontWeight: "bold", textAlign: "right" }} align="right">כמות</TableCell>
                    <TableCell style={{ fontSize: "0.9rem", fontWeight: "bold", textAlign: "right" }} align="right">{`מחיר${customer!.includeTax ? '(כולל)' : ''}`}</TableCell>
                    <TableCell style={{ fontSize: "0.9rem", fontWeight: "bold", textAlign: "right" }} align="right">{`סהכ${customer!.includeTax ? '(כולל)' : ''}`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {document.handArray.map((row) => (
                    <TableRow
                      key={row.description}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell style={{ textAlign: "right" }} align="right">{row.description}</TableCell>
                      <TableCell style={{ textAlign: "right" }} align="right">{row.amount}</TableCell>
                      <TableCell style={{ textAlign: "right", minWidth: row.value.toString().length * 20 < 121 ? row.value.toString().length * 20 : 115 }} align="right">{row.value}<IconCurrencyShekel size={10} color='black' /></TableCell>
                      <TableCell style={{ textAlign: "right" }} align="right">{
                        roundDecimals((row.value * row.amount), 2)
                      }
                        <IconCurrencyShekel size={10} color='black' />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : undefined}
        {document.products.length > 0 || onProductChange ? (
          <>
            <Grid item xs={12}>
              <Typography variant='subtitle1' fontWeight='bold'>
                מוצרים
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomerProductsPreviewList products={document.products.sort((a, b) => (
                (a?.notePlacement != undefined || b?.notePlacement != undefined) ? ((a?.notePlacement ?? 0) - (b?.notePlacement ?? 0)) :
                  (a?.position ?? 2000 - b?.position ?? 2000))

              )}
                withReturns
                disableHoverableIncrementer={document.status === 'approved'}
                onProductChange={!onIdChange ? undefined: onProductChange} date={document.date}
                id={customer?.id || ""} />
            </Grid>
          </>
        ) : undefined}
        <Grid item xs={12}>
          <SubCard
            sx={{
              bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
            }}
          >

            <Grid container rowSpacing={0}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  סהכ לפני מעמ
                </Typography>
              </Grid>
              <Grid item style={{ direction: 'ltr' }} xs={5}>
                <IconCurrencyShekel size={10} color='black' />
                {roundDecimals(document.preValue ?? 0).toLocaleString()}

              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {(document.discount > 0) ? `הנחה ${document.discount}%` : `תוספת ${Math.abs(document.discount)}%`}
                </Typography>
              </Grid>
              <Grid item style={{ direction: 'ltr' }} xs={5}>
                <IconCurrencyShekel size={10} color='black' />
                {roundDecimals(document.mainValue - ((document.taxValue) ? document.taxValue : 0) - document.preValue!).toLocaleString()}

              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  סהכ אחרי
                </Typography>
              </Grid>
              <Grid item style={{ direction: 'ltr' }} xs={5}>
                <IconCurrencyShekel size={10} color='black' />
                {roundDecimals(document.mainValue - ((document.taxValue) ? document.taxValue : 0)).toLocaleString()}

              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  מע"מ
                </Typography>
              </Grid>
              <Grid item style={{ direction: 'ltr' }} xs={5}>
                <IconCurrencyShekel size={10} color='black' />
                {roundDecimals(((document.taxValue) ? document.taxValue : 0)).toLocaleString()}

              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  <FormattedMessage
                    id='Total' />
                </Typography>
              </Grid>
              <Grid item style={{ direction: 'ltr' }} xs={5}>
                <IconCurrencyShekel size={10} color='black' />
                {roundDecimals(document.mainValue).toLocaleString()}

              </Grid>

            </Grid>
            <Grid item xs={12}>
              {(document.comment !== '') ?
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>
                    הערות: {document.comment}
                  </Typography>
                </Grid>
                : undefined}
            </Grid>
          </SubCard>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item sm={12}>
              <Typography variant="subtitle1" fontWeight="bold">
                <FormattedMessage id="Payment Methods" />
              </Typography>
            </Grid>
            {document.payments.map((p) => (
              <React.Fragment key={p.id}>
                <Grid item sm={9}>
                  {!onPaymentChange ? (
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', }}>

                      <Typography variant="subtitle1">
                        {paymentMethods[p.method].name}
                      </Typography>
                      <Typography variant="subtitle1" style={{ direction: 'ltr' }}>
                        {p.amount}
                        <IconCurrencyShekel size={10} color="black" />
                      </Typography>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', }}>
                      <TextField
                        id='outlined-select-currency'
                        select
                        label='בחר/י שיטת תשלום'
                        sx={{ width: '50%', mb: 1, mt: 1 }}
                        value={p.method}
                        onChange={(m) => onPaymentChange({ ...p, method: m.target.value as PaymentMethod })}
                      >
                        {Object.keys(paymentMethods).map((p) => (
                          <MenuItem key={p} value={p}>
                            {paymentMethods[p].name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <HoverableIncrementer

                        value={p.amount}
                        disabled={false}
                        cantChange={!onIdChange}
                        onIncrement={() => {
                          onPaymentChange({
                            ...p,
                            amount: p.amount + 1
                          });
                        }}
                        onDecrement={() => {
                          onPaymentChange({
                            ...p,
                            amount: Math.max(0, p.amount - 1)
                          });
                        }}
                        onChange={(v) => {
                          onPaymentChange({ ...p, amount: Math.max(0, Number(v)) });
                        }}
                      />
                    </div>

                  )}

                </Grid>
                {p.check && p.method === 'check' && (

                  <>
                    <Grid item sm={4}>
                      {!onPaymentChange ? (
                        <Typography variant="subtitle2">
                          <FormattedMessage id="Account" />: {p.check.account}
                        </Typography>
                      ) : (
                        <div>
                          <Typography variant="subtitle2">
                            <FormattedMessage id="Account" />:
                          </Typography>
                          <HoverableIncrementer
                            value={p.check.account}
                            disabled={false}
                            onIncrement={() => {
                              {
                                ((p.check && p.check.account) || p.check?.account === 0) &&
                                  onPaymentChange({
                                    ...p,
                                    check: { ...p.check, account: +(p.check.account) + 1 }
                                  });
                              }
                            }}
                            onDecrement={() => {
                              {
                                (p.check && p.check.account) &&
                                  onPaymentChange({
                                    ...p,
                                    check: { ...p.check, account: Math.max(0, +(p.check.account) - 1) }
                                  });
                              }
                            }}
                            onChange={(v) => {
                              onPaymentChange({ ...p, check: { ...p.check, account: (v) } });
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={3}>
                      {!onPaymentChange ? (
                        <div>
                          <Typography variant="subtitle2">
                            <FormattedMessage id="Branch" />: {p.check.branch}
                          </Typography>
                        </div>
                      ) : (
                        <div>
                          <Typography variant="subtitle2">
                            <FormattedMessage id="Branch" />:
                          </Typography>
                          <HoverableIncrementer
                            value={p.check.branch}
                            disabled={false}

                            onIncrement={() => {
                              {
                                ((p.check && p.check.branch) || p.check?.branch === 0) &&
                                  onPaymentChange({
                                    ...p,
                                    check: { ...p.check, branch: +(p.check.branch + 1) }
                                  });
                              }
                            }}
                            onDecrement={() => {
                              {
                                (p.check && p.check.branch) &&
                                  onPaymentChange({
                                    ...p,
                                    check: { ...p.check, branch: Math.max(0, +(p.check?.branch) - 1) }
                                  });
                              }
                            }}
                            onChange={(v) => {
                              onPaymentChange({ ...p, check: { ...p.check, branch: (v) } });
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={3}>
                      <Grid item sm={3}>
                        {onPaymentChange ? (
                          <div>
                            <Typography variant="subtitle2">
                              <FormattedMessage id="Bank" />
                            </Typography>
                            <Autocomplete
                              id='bank'
                              size='small'
                              sx={{
                                // border: "transparent",
                                "& .MuiOutlinedInput-root": {

                                  borderRadius: "50",
                                  border: "transparent",
                                  background: "transparent",
                                  padding: 1,
                                  width: 180

                                },
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                  border: "transparent",

                                }
                              }}
                              fullWidth
                              label={'מזהה בנק'}
                              type='number'
                              value={p.check?.bank ?? 0}
                              onChange={(event, value) => {
                                if (value) onPaymentChange({ ...p, check: { ...p.check, bank: (value) } });
                              }}
                              options={JSDB().getPaymentMethods().toArray() ?? []}

                              autoHighlight
                              renderInput={(params) => (
                                <TextField
                                  {...params}

                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {params.InputProps.endAdornment}
                                      </>
                                    )
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <div style={{ width: '95px' }}>
                            <Typography variant="subtitle2" >
                              <FormattedMessage id="Bank" />: {p.check.bank}
                            </Typography>

                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item sm={2} />
                    <Grid item sm={4}>

                      {!onPaymentChange ? (
                        <Typography variant="subtitle2">
                          <FormattedMessage id="Check No." />: {p.check.id}
                        </Typography>
                      ) : (
                        <div>
                          <Typography variant="subtitle2">
                            <FormattedMessage id="Check No." />
                          </Typography>
                          <HoverableIncrementer
                            value={p.check.id}
                            disabled={false}

                            onIncrement={() => {
                              {
                                ((p.check && p.check.id) || p.check?.id === 0) &&
                                  onPaymentChange({
                                    ...p,
                                    check: { ...p.check, id: +(p.check.id) + 1 }
                                  });
                              }
                            }}
                            onDecrement={() => {
                              {
                                (p.check && p.check.id) &&
                                  onPaymentChange({
                                    ...p,
                                    check: { ...p.check, id: Math.max(0, +(p.check?.id) - 1) }
                                  });
                              }
                            }}
                            onChange={(v) => {
                              onPaymentChange({ ...p, check: { ...p.check, id: (v) } });
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={7}>
                      {!onPaymentChange ? (
                        <Typography variant="subtitle2">
                          <FormattedMessage id="Due Date" />:{' '}
                          {format(
                            p.check?.dueDate
                              ? typeof p.check.dueDate === 'object'
                                ? p.check.dueDate
                                : parseJSON(p.check.dueDate)
                              : document.date,
                            'PP',
                            {
                              locale: config.i18n === 'he' ? he : enUS
                            }
                          )}
                        </Typography>

                      ) : (
                        <div style={{ margin: 6 }} >
                          <LocalizationProvider sx={{ mt: 3 }} dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                            <DatePicker
                              mask=''
                              renderInput={(props) => <TextField {...props} />}
                              label={<FormattedMessage id='Due Date' />}
                              value={p.check?.dueDate}
                              onChange={(newValue) => {
                                if (newValue) onPaymentChange({
                                  ...p,
                                  check: { ...p.check, dueDate: newValue }
                                });
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      )}
                    </Grid>
                    <Grid item sm={1} />
                  </>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SubCard
            sx={{
              bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} >
                <FormattedMessage id="Total" />:
              </Grid>
              <Grid item xs={6} style={{ direction: 'ltr' }}>
                <Typography variant="subtitle1" fontWeight="bold" >
                  {roundDecimals(document?.payments.reduce((acc, p) => acc + p.amount, 0), 4).toLocaleString()}
                  <IconCurrencyShekel size={10} color="black" />
                </Typography>
              </Grid>
            </Grid>
             <Grid item xs={12}>
              <Grid item xs={12}>
                <PreviewDocumentComments outerDoc={outerDoc} comment={document.comment}  doc={ document} pool={pool}/>
              </Grid>
            </Grid>

          </SubCard>
        </Grid>
      </Grid>
    </SubCard >
  )
}

export default InvoiceAndReciptPreview;

import {Avatar, Box, Grid, IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from '../../config';
import { enUS, he } from 'date-fns/locale';
import { IconCalendar } from '@tabler/icons';
import {memo} from "react";
import MyChip from "../extended/Chip";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { ExtendedDocument } from 'types/DocumentsTypes/Document';
import {OrderActiveState} from "../../types/OrderActiveState";

function ChooseDateMultiAction({title,func,boxid, setDocumentAction}:{title:string,func:any,setDocumentAction: React.Dispatch<React.SetStateAction<{
    title: string;
    action: string;
    docs: ExtendedDocument[];
} | null>> ,boxid?:string}) {
    const [date, setDate] = React.useState(new Date());
    const [activeState, setActiveState] = React.useState<OrderActiveState>({
        date: true,
        day: -1
    });
    const handleOk = async () => {
        func(date,activeState)
        handleClose();
    };

    // React.useEffect(() => {
    //     console.log(updateCustomerAsyncState);
    //     if (updateCustomerAsyncState === 'succeeded') {
    //         dispatch({
    //             type: SNACKBAR_OPEN,
    //             open: true,
    //             message: 'המוצרים עודכנו בהצלחה!',
    //             variant: 'alert',
    //             alertSeverity: 'success'
    //         });
    //
    //         dispatch(initMassCustomersProductAsyncState());
    //
    //     } else if (updateCustomerAsyncState === 'failed') {
    //         Swal.fire({
    //             title: 'שגיאה בעדכון מוצרים',
    //             target: '#custom-target',
    //             text: '',
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'המשך'
    //         });
    //         dispatch(initMassCustomersProductAsyncState());
    //     }
    // }, [updateCustomerAsyncState, dispatch]);

    const handleClose = () => {
        setDocumentAction(null);
    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue((event.target as HTMLInputElement).value);
    // };

    const onApprove =
        () => {
            handleOk();
        };

    return (
        <div id={boxid}>

            <Dialog id='doc_search_dialog'  maxWidth={'xs'} fullWidth={true}
                            open={title && title !== "" ? true : false} onClose={handleClose}>
                <DialogTitle> {title}

                </DialogTitle>
                <DialogContent>
                    &nbsp;
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} type={'date'} />}
                                    label={'תאריך מסמך'}
                                    value={date}
                                    onChange={(newValue) => {
                                        if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            או לפי בסיס
                        </Grid>
                        <Grid sx={{
                            margin: '0 15px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center"}}>

                                <Avatar sx={{cursor: 'pointer', bgcolor: (activeState.day === 1) ? '' : 'white'}}
                                        onClick={(e) => {

                                            if(activeState.day === 2){
                                                setActiveState({date: true, day: -1});
                                            } else {
                                                setActiveState({date: false, day: 1});
                                            }
                                        }}>א</Avatar>
                                <Avatar sx={{cursor: 'pointer', bgcolor: (activeState.day === 2) ? '' : 'white'}}
                                        onClick={(e) => {
                                            if(activeState.day === 2){
                                                setActiveState({date: true, day: -1});
                                            } else {
                                                setActiveState({date: false, day: 2});
                                            }

                                        }}>ב</Avatar>
                                <Avatar sx={{cursor: 'pointer', bgcolor: (activeState.day === 3) ? '' : 'white'}}
                                        onClick={(e) => {
                                            if(activeState.day === 2){
                                                setActiveState({date: true, day: -1});
                                            } else {
                                                setActiveState({date: false, day: 3});
                                            }
                                        }}>ג</Avatar>
                                <Avatar sx={{cursor: 'pointer', bgcolor: (activeState.day === 4) ? '' : 'white'}}
                                        onClick={(e) => {
                                            if(activeState.day === 2){
                                                setActiveState({date: true, day: -1});
                                            } else {
                                                setActiveState({date: false, day: 4});
                                            }
                                        }}>ד</Avatar>
                                <Avatar sx={{cursor: 'pointer', bgcolor: (activeState.day === 5) ? '' : 'white'}}
                                        onClick={(e) => {
                                            if(activeState.day === 2){
                                                setActiveState({date: true, day: -1});
                                            } else {
                                                setActiveState({date: false, day: 5});
                                            }
                                        }}>ה</Avatar>
                                <Avatar sx={{cursor: 'pointer', bgcolor: (activeState.day === 6) ? '' : 'white'}}
                                        onClick={(e) => {
                                            if(activeState.day === 2){
                                                setActiveState({date: true, day: -1});
                                            } else {
                                                setActiveState({date: false, day: 6});
                                            }
                                        }}>ו</Avatar>
                            </Box>
                        </Grid>
                    </Grid>
                    {/*<MasterTable*/}
                    {/*    headCells={columns}*/}
                    {/*    data={data ?? []}*/}
                    {/*    keyExtractor={(p) => p.id.toString()}*/}
                    {/*    withPagination*/}
                    {/*    paginationOptions={{*/}
                    {/*        rowsPerPage: 1000*/}
                    {/*    }}*/}
                    {/*    withSort*/}
                    {/*    sortOptions={{*/}
                    {/*        sortBy: {*/}
                    {/*            order: 'desc',*/}
                    {/*            orderBy: 'position'*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    withFilter*/}
                    {/*    TableItem={(row) => <ChangeItem row={row} />}*/}
                    {/*/>*/}

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'red', color: 'white' }}> שנה תאריך מסמך
                    </Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'blue', color: 'white' }}>חזור</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
export default memo(ChooseDateMultiAction);

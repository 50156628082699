import {Autocomplete, Badge, Box, Chip, Grid, IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Person } from '@mui/icons-material';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from '../../config';
import { enUS, he } from 'date-fns/locale';
import {memo} from "react";

export interface DialogChooserClass {
    id: number;
    name: string;
}
const  DialogChooseAction=({
    chooseFrom,
    title,
    icon,
    tooltip,
    onClose,
    multi,
    withDate,
    openFromAbove
}: { chooseFrom: DialogChooserClass[], title: string, icon, tooltip, onClose: (value?: any, date?: Date) => void, multi?: boolean, withDate?: Date,openFromAbove?:boolean })=> {
    const [open, setOpen] = React.useState(openFromAbove ?? false);
    const [size, setSize] = React.useState(0);
    const [date, setDate] = React.useState(withDate ?? new Date());
    const [newValue, setNewValue] = React.useState<DialogChooserClass | DialogChooserClass[]>();
    const [options, setOptions] = React.useState<DialogChooserClass[]>(chooseFrom);
     const handleOk = () => {
        onClose(newValue, date);
        handleClose();
    };
    React.useEffect(() => {
        setSize(chooseFrom.length);
        setOptions(chooseFrom);
        setDate(withDate ?? new Date())
        if (multi)
            setNewValue([]);
        else
            setNewValue({ id: -1, name: '' });
    }, [open, chooseFrom]);
    React.useEffect(() => {
        setOpen(openFromAbove ?? false)
    }, [openFromAbove]);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };

    const onApprove =
        () => {
            handleOk();
        };
    return (
        <div>
            <Tooltip title={tooltip}>
                <Chip icon={icon} onClick={handleClickOpen} sx={{
                    ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                        color: '#000080',
                    },
                }} label={tooltip} variant="outlined"/>

            </Tooltip>
            <Dialog id='helper_dialog'
                open={open} onClose={handleClose}>
                <DialogTitle> {title} <Badge color='secondary' max={2000} badgeContent={size}>
                    <IconButton size='medium'>
                        <Person />
                    </IconButton>
                </Badge> </DialogTitle>
                <DialogContent>
                    <Grid container>
                        {withDate ? <Grid item xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}
                                locale={config.i18n === 'he' ? he : enUS}>
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} variant="standard" type={'date'} />}
                                    label={'תאריך מחירון'}
                                    value={date ?? new Date()}
                                    onChange={(newValue) => {
                                        if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid> : undefined}

                        <Grid item xs={12} sm={12}>

                            <Autocomplete
                                id='category'
                                value={newValue}
                                onChange={(e, value) => {
                                    if (value)
                                        setNewValue(value);
                                    else
                                        setNewValue(undefined);
                                }}
                                getOptionLabel={(option) => `${option.name}-${option.id}`}
                                options={options ?? []}
                                fullWidth
                                multiple={multi}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='בחר.. '
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'green', color: 'white' }}>בצע
                        פעולה</Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>ביטול</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default memo(DialogChooseAction);

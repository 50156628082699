export interface BusinessInfo {
    companyName: string;
    companyId: string;
    address: string;
    phone: string;
    email: string;
    nkObject: any
}
export const serializeBusinessInfo = (user): BusinessInfo => ({
    companyId: user.user_buisness_number,
    companyName: user.user_company_print_name,
    email: user.email,
    address: user.user_address,
    phone: user.user_phone_number,
    nkObject: user
});

import React from 'react';

// material-ui
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';

// third-party

// locale
import { enUS, he } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// project imports
import config from 'config';
import EventIcon from '@mui/icons-material/Event';

const MyDatePicker = React.memo(({ date, setDate, isLoading,autoFocus,onlyIcon,label }:{date:Date,setDate:any,isLoading?:boolean,autoFocus?:boolean,onlyIcon?:boolean,label?:string}) => {
    const [open, setOpen] = React.useState(false);
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
            <DatePicker
                loading={isLoading}
                disabled={isLoading}
                inputFormat="EEE, MMM d, yyyy"  // Format string
                renderInput={ (props) => (onlyIcon ? <EventIcon onClick={()=>setOpen(s=>!s)}/>: <TextField {...props}  autoFocus={autoFocus}/>)}
                label={label?? 'בחר תאריך'}
                allowSameDateSelection
                value={onlyIcon?( date ? date : new Date()) : date}
                onChange={(newValue) => {
                    if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                    if(onlyIcon){
                        setOpen(false);
                    }

                }}
                open={onlyIcon ? open:undefined}



            />

        </LocalizationProvider>
    );
});

export default MyDatePicker;

import {
    Autocomplete, Checkbox, Chip, FormControlLabel,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {useTheme} from '@mui/styles';
import {IconCurrencyShekel} from '@tabler/icons';
import {FormattedMessage} from 'react-intl';
import IItem from 'types/product_delivery';
import HoverableIncrementer from 'ui-component/HoverableIncrementer';
import JSDB from '../../../../fromKotlin/nk';
import {dateObjectToString, roundDecimals} from '../../../../fromKotlin/nkutils';
import React, {memo, useRef} from 'react';
import {itemApiIInternal} from '../../../../api/customer/products_prices.api';
import {handleKeyDown} from '../../../../utils/tableAsKeyBorad';
import {toArrayOrEmpty} from '../../../../types/DocumentsTypes/utils';
import {ExtendedDocument} from "../../../../types/DocumentsTypes/Document";
import {subStringMatch} from "../../../../utils/sort";
import {Percentage} from "tabler-icons-react";
import {CUSTOMER_PRODUCTS, useGetCustomerProductsPrices} from "../../../../hooks/customer/products.hooks";
import {useDispatch} from "react-redux";
import {useQueryClient} from "react-query";

interface CustomerProductsPreviewListProps {
    products: IItem[];
    onProductChange?: (product: IItem) => void;
    disableHoverableIncrementer?: boolean;
    withReturns?: boolean;
    date?: Date;
    id: string;
    sup?: boolean
    displayCost?: boolean;
    oldProducts?: IItem[] | undefined;
    displayOldProducts?: boolean;
    orderProducts?: IItem[] | undefined;
    displayOrderProducts?: boolean;
    collect?: boolean;
    jumpToWrapped?: boolean;
}

const ProductRowMemo = React.memo(({
                                       row,
                                       onProductChange,
                                       i,
                                       disableHoverableIncrementer,
                                       withReturns,
                                       withExtraUnit,
                                       displayCost,
                                       displayNoteColumn,
                                       displayQuantityColumn,
                                       displayCostPrice,
                                       oldProducts,
                                       removedProduct,
                                       existingOrderProducts,
                                       orderProducts,
                                          withDiscount,
                                       externalIdSearch,
                                       jumpToWrapped

                                   }: { row: IItem, onProductChange?: any, i: number, disableHoverableIncrementer: any, withReturns: boolean, withExtraUnit: boolean, displayCost?: boolean, displayNoteColumn?: boolean, displayQuantityColumn?: boolean, displayCostPrice?: boolean, oldProducts?: IItem[], removedProduct?: boolean, existingOrderProducts?: IItem[], orderProducts?: boolean, collect?: boolean,withDiscount?:boolean ,externalIdSearch?:boolean,jumpToWrapped?:boolean}) => {
    const oldQuantity = !oldProducts ? undefined : (removedProduct ? null : oldProducts?.find(item => item?.id === row?.id));
    const orderP = existingOrderProducts?.length || orderProducts ? (orderProducts ? row : existingOrderProducts?.find(item => item?.id === row?.id)) : undefined

    return <TableRow
        key={i}
        onKeyDown={(e) => handleKeyDown(e)}
        sx={{
            '&:last-child td, &:last-child th': {border: 0},
            backgroundColor: removedProduct ? '#ffd1d1' : orderProducts ? "#cfeeff" : undefined
        }}
    >
        <TableCell sx={{padding: 0}} align='left'>
            <Typography variant='h5'>      {externalIdSearch? row.external_id : row.id}  - {row.name}</Typography>


            <Typography variant='caption'> {row.barcode}</Typography>
        </TableCell>
        {existingOrderProducts?.length || orderProducts ? (
            <TableCell sx={{padding: 0}} align='center'>
                <Typography variant='h5' color={"#0288d1"}
                            fontWeight={"600"}>{(orderP ? ((!orderP?.quantitySecond) ? orderP?.quantity : `${orderP?.quantitySecond} ${orderP?.available_units[1]}`) : undefined) ?? 0}</Typography>
            </TableCell>
        ) : undefined
        }
        {withExtraUnit ? row.quantitySecond - row.quantitySecondReturns >= 0 && row.available_units.length == 2 ?
            <TableCell sx={{padding: 0}} align='left'>

                {!onProductChange ? (
                    <div style={{display: "flex", flexDirection: 'row'}}>
                        {oldQuantity?.quantitySecond ?
                            <Typography variant="h5" style={{
                                color: 'red',
                            }}>{oldQuantity?.quantitySecond}  &nbsp; </Typography>
                            : undefined
                        }
                        {!orderProducts ? <>
                            <Typography variant='h5'>{row.quantitySecond - row.quantitySecondReturns}   </Typography>
                            <Typography variant='h5'>    &nbsp; {row.available_units[1]}   </Typography>
                        </> : undefined}

                    </div>
                ) : (<>
                        {!jumpToWrapped && row.quantitySecond - row.quantitySecondReturns == 0 ?
                            <Chip label={`${row.available_units[1]} +`} chipcolor={'success'} size='small'
                                  onClick={(e) => {
                                      onProductChange({
                                          ...row,
                                          quantitySecond: row.quantitySecond + 1
                                      });
                                  }}/> : <div><HoverableIncrementer
                                value={row.quantitySecond}
                                disabled={disableHoverableIncrementer}
                                onIncrement={() => {
                                    onProductChange({
                                        ...row,
                                        quantitySecond: row.quantitySecond + 1
                                    });
                                }}
                                onDecrement={() => {
                                    onProductChange({
                                        ...row,
                                        quantitySecond: Math.max(0, row.quantitySecond - 1)
                                    });
                                }}
                                onChange={(v) => {
                                    onProductChange({...row, quantitySecond: Math.max(0, v)});
                                }}
                            />
                                <Typography variant='h5'>    &nbsp; {row.available_units[1]}   </Typography>
                            </div>
                        }
                    </>
                )}

                {row?.conversion_ratio < 1 && row?.conversion_ratio > 0 ? ` = ${1 / row?.conversion_ratio} ${row?.available_units[0]}` : row.quantitySecond > 0 && row.conversion_ratio > 0 && !orderProducts ?
                    `${row.quantitySecond * row.conversion_ratio} ${row.available_units[0]}`
                    : undefined}
            </TableCell> : <TableCell sx={{padding: 0}} align='left'>
                <Typography variant='h5'> </Typography>
            </TableCell> : undefined}


        <TableCell sx={{padding: 0}} align='center'>
            {!onProductChange ? (
                <div>

                    {oldQuantity?.quantity ?
                        <Typography variant="h5" style={{

                            color: 'red',
                        }}>{oldQuantity?.quantity}</Typography>
                        : undefined
                    }
                    {!orderProducts ? <>
                        <Typography variant='h5' style={{
                            margin: "0 5px"
                        }}>{row.quantity + row.conversion_ratio*row.quantitySecond}</Typography>

                        {row.in_unit && row.in_unit > 0 && row.quantity > 0 ?
                            <Typography
                                variant='caption'>  {`${row.quantity * row.in_unit} יח'`}</Typography> : undefined}
                    </> : undefined}


                </div>
            ) : (
                <HoverableIncrementer
                    value={row.quantity}
                    disabled={disableHoverableIncrementer}
                    onIncrement={() => {
                        onProductChange({...row, quantity: row.quantity + 1});
                    }}
                    onDecrement={() => {
                        onProductChange({...row, quantity: Math.max(0, row.quantity - 1)});
                    }}
                    onChange={(v) => {
                        onProductChange({...row, quantity: Math.max(0, v)});
                    }}
                />
            )}
        </TableCell>

        {withReturns && (displayQuantityColumn || onProductChange) ? <TableCell sx={{padding: 0}} align='center'>
            {!onProductChange ? (
                <div>
                    {oldQuantity?.quantityReturns != undefined ?
                        <Typography variant="h5" style={{
                            textDecoration: 'line-through',
                            color: 'red',
                        }}>{oldQuantity?.quantityReturns}   </Typography>
                        : undefined
                    }
                    {!orderProducts ? <Typography variant='h5' style={{
                        margin: "0 5px"
                    }}>{row.quantityReturns}</Typography> : undefined}

                </div>


            ) : (
                <HoverableIncrementer
                    value={row.quantityReturns}
                    disabled={disableHoverableIncrementer}
                    onIncrement={() => {
                        onProductChange({
                            ...row,
                            quantityReturns: row.quantityReturns + 1
                        });
                    }}
                    onDecrement={() => {
                        onProductChange({
                            ...row,
                            quantityReturns: Math.max(0, row.quantityReturns - 1)
                        });
                    }}
                    onChange={(v) => {
                        onProductChange({...row, quantityReturns: Math.max(0, v)});
                    }}
                />
            )}
        </TableCell> : undefined}

        <TableCell sx={{padding: 0}} align='center'>
            {!onProductChange ? (
                <>
                    {row.price_min && row.price_min!=row.price_max ?  `${row.price_min} - ${row.price_max}`: roundDecimals(row.totalPrice, 4)}
                    <IconCurrencyShekel size={10} color='black'/>
                </>
            ) : (
                <HoverableIncrementer
                    value={roundDecimals(row.totalPrice, 4)}
                    disabled={disableHoverableIncrementer}
                    onIncrement={() => {
                        onProductChange({
                            ...row,
                            totalPrice: row.totalPrice + 1
                        });
                    }}
                    onDecrement={() => {
                        onProductChange({
                            ...row,
                            totalPrice: Math.max(0, row.totalPrice - 1)
                        });
                    }}
                    onChange={(v) => {
                        onProductChange({...row, totalPrice: Math.max(0, v)});
                    }}
                />
            )}
            {row.discount > 0 ? `\t(${row.discount}%) => ${roundDecimals(row.totalPrice * (1 - row.discount / 100), 4)}` : ''}
        </TableCell>
        {withDiscount ?      <TableCell sx={{padding: 0}} align='center'>
            {!onProductChange ? (
                <>
                    {roundDecimals(row.discount, 4)}
                    <Percentage size={10} color='black'/>
                </>
            ) : (
                <HoverableIncrementer
                    value={roundDecimals(row.discount, 4)}
                    disabled={disableHoverableIncrementer}
                    onIncrement={() => {
                        onProductChange({
                            ...row,
                            discount: Math.min(row.discount + 1,100)
                        });
                    }}
                    onDecrement={() => {
                        onProductChange({
                            ...row,
                            discount: Math.max(0, row.discount - 1)
                        });
                    }}
                    onChange={(v) => {
                        onProductChange({...row, discount: Math.min(Math.max(0, v),100)});
                    }}
                />
            )}
        </TableCell>
            : undefined}
        <TableCell dir='ltr' sx={{padding: 0}} align='center'>
            {row.total? row.total: roundDecimals((row.totalPrice * (1 - row.discount / 100)) * (row.quantity - row.quantityReturns + (row.quantitySecond ?? 0) * (row.conversion_ratio ?? 0)), 4)}
            <IconCurrencyShekel size={10} color='black'/>
        </TableCell>
        {displayCost && displayCostPrice ?
            <TableCell dir='ltr' sx={{padding: 0, backgroundColor: removedProduct ? '#ffadad' : '#F6F6F6'}}
                       align='center'>
                {
                    !onProductChange ? (
                        <>
                            {row.costPrice ? roundDecimals(row.costPrice, 4) : ""}
                            {row.costPrice ? <IconCurrencyShekel size={10} color='black'/> : ""}
                        </>
                    ) : (
                        <HoverableIncrementer
                            value={row.costPrice ? roundDecimals(row.costPrice, 4) : 0}
                            disabled={disableHoverableIncrementer}
                            onIncrement={() => {
                                onProductChange({
                                    ...row,
                                    costPrice: row.costPrice ? row.costPrice + 1 : 1
                                });
                            }}
                            onDecrement={() => {
                                onProductChange({
                                    ...row,
                                    costPrice: Math.max(0, row.costPrice ? row.costPrice - 1 : 0)
                                });
                            }}
                            onChange={(v) => {
                                onProductChange({...row, costPrice: Math.max(0, v)});
                            }}
                        />
                    )
                }
            </TableCell>
            : undefined
        }
        {displayNoteColumn || onProductChange ?
            <TableCell dir='rtl' sx={{padding: 0, width: 30}} align='center'>
                {!onProductChange ? (
                    <>
                        <Typography variant='h5'>      {row.notes ?? ''}</Typography>
                    </>
                ) : <Input
                    type='text'
                    onChange={(v) => {
                        onProductChange({...row, notes: v.target.value});
                    }}
                    value={row.notes}
                />}

            </TableCell>
            : undefined
        }
    </TableRow>

})

const CustomerProductsPreviewList = ({
                                         products,
                                         onProductChange,
                                         disableHoverableIncrementer = false,
                                         withReturns = true,
                                         date = new Date(),
                                         id,
                                         sup = false,
                                         displayCost = false,
                                         oldProducts,
                                         displayOldProducts,
                                         orderProducts,
                                         displayOrderProducts,
                                         collect,
     jumpToWrapped
                                     }: CustomerProductsPreviewListProps) => {
    const theme = useTheme();
    const cache = useQueryClient();
    const dispatch = useDispatch();
    const [onlyClientProduct, setOnlyClientProduct] = React.useState(false)
    const [allProducts, setAllProducts] = React.useState(sup ? ( toArrayOrEmpty(onlyClientProduct ?JSDB().getSupplier(Number(id)).first.getActiveProducts() :JSDB().getSpecificSupplierProducts(id))) : (toArrayOrEmpty(onlyClientProduct? JSDB().getClient(Number(id)).first.getActiveProducts():JSDB().getAllClientProduct(true).first)))

    const {
        data: prices,
        isLoading,
        refetch
    } = useGetCustomerProductsPrices(id, dateObjectToString(date), true, undefined, true);
    React.useEffect(() => {
        cache.removeQueries(CUSTOMER_PRODUCTS);
        refetch();
    }, [ dispatch]);
    React.useEffect(() => {
        if(!isLoading && !sup ) {
            setAllProducts(prices.filter((p)=>(!onlyClientProduct || p.available && p.totalPrice > 0)))
        } else if (!isLoading && sup) {
            setAllProducts(( toArrayOrEmpty(onlyClientProduct ?JSDB().getSupplier(Number(id)).first.getActiveProducts() :JSDB().getSpecificSupplierProducts(id))))
        }
    }, [prices,onlyClientProduct]);

    const [newItem, setNewItem] = React.useState(undefined);
    const selRef = useRef<any>();
    const [externalIdSearch, setExternalIdSearch] = React.useState(parseInt(localStorage.getItem('externalIdOrders') ?? '0') == 1);
    const withExtraUnit = products.some((p) => p.available_units.length == 2)
    React.useEffect(() => {
        if (products.length > 0 && selRef?.current?.children && newItem) {
            const hasSec = newItem.available_units.length == 2
            const inpLoc = withExtraUnit  ?  (hasSec && jumpToWrapped) ? 1 :2 : 1
            if (selRef?.current?.children[selRef.current.children.length - 2]?.className == 'autocomp') {
                selRef?.current?.children[selRef.current.children.length - 3]?.children[inpLoc]?.getElementsByTagName("input")[0]?.focus()
            } else {
                selRef?.current?.children[selRef.current.children.length - 2]?.children[inpLoc]?.getElementsByTagName("input")[0]?.focus()
            }


        }
        setNewItem(undefined)
    }, [newItem,jumpToWrapped]);
    const unitSum = roundDecimals(products.reduce((sum, x) => sum + ((x.in_unit && x.in_unit > 0) ? x.quantity * (x.in_unit ?? 0) : 0), 0));
    const displayNoteColumn = products.some((p) => p.notes)
    const displayQuantityColumn = products.some((p) => p.quantityReturns)
    const displayCostPrice = products.some((p) => p.costPrice)
    const [existingOldProducts, setexistingOldProducts] = React.useState([])
    const [removedProducts, setremovedProducts] = React.useState([])
    const [existingOrderProducts, setexistingOrderProducts] = React.useState([])
    const [notExistingOrderProducts, setnotExistingOrderProducts] = React.useState([])
    const [withDiscount, setWithDiscount] =React.useState<boolean>(parseInt(localStorage.getItem('withDiscount') ?? '0') == 1);
    React.useEffect(() => {
        if (displayOldProducts) {
            const existingOldProductsTemp = []
            const removedProductsTemp = []
            oldProducts?.forEach((p) => {
                if (products.some((p2) => p2.id == p.id)) {
                    existingOldProductsTemp.push(p)
                } else {
                    removedProductsTemp.push(p)
                }
            });
            setexistingOldProducts(existingOldProductsTemp)
            setremovedProducts(removedProductsTemp)
        } else {
            setexistingOldProducts([])
            setremovedProducts([])
        }

    }, [displayOldProducts]);
    React.useEffect(() => {
        if (displayOrderProducts) {
            const existingOrderProductsTemp = []
            const notExistingOrderProductsTemp = []

            displayOrderProducts && orderProducts?.forEach((p) => {
                if (products.some((p2) => p2.id == p.id)) {
                    existingOrderProductsTemp.push(p)
                } else {
                    notExistingOrderProductsTemp.push(p)
                }
            });
            setexistingOrderProducts(existingOrderProductsTemp)
            setnotExistingOrderProducts(notExistingOrderProductsTemp)
        } else {
            setexistingOrderProducts([])
            setnotExistingOrderProducts([])
        }

    }, [displayOrderProducts]);


    return (
        <TableContainer component={Paper}>
            <Table size='small' aria-label='a dense table'>
                <TableHead>
                    <TableRow style={{backgroundColor: theme.palette.primary.light}}>
                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='left'>
                            <Typography variant='h5' fontWeight='bold'>
                                <FormattedMessage id='Product'/>
                            </Typography>
                        </TableCell>
                        {displayOrderProducts ?
                            <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                                <Typography variant='h5' fontWeight='bold'>
                                    הזמנה
                                </Typography>
                            </TableCell>
                            : undefined
                        }

                        {withExtraUnit ? <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='left'>
                            <Typography variant='h5' fontWeight='bold'>
                                תיאור
                            </Typography>
                        </TableCell> : undefined}
                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>
                                כמות
                            </Typography>
                        </TableCell>

                        {withReturns && (displayQuantityColumn || onProductChange) ?
                            <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                                <Typography variant='h5' fontWeight='bold'>
                                    חזרות
                                </Typography>
                            </TableCell> : undefined}
                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>
                                מחיר
                            </Typography>
                        </TableCell>
                        {withDiscount ? <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>
                                הנחה
                            </Typography>
                        </TableCell> : undefined}
                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                סה''כ
                            </Typography>
                        </TableCell>
                        {displayCost && displayCostPrice ?
                            <TableCell sx={{paddingRight: 0, paddingLeft: 0, width: "100px"}} align='center'>
                                <Typography variant='h5' fontWeight='bold'>
                                    עלות(לפני מעמ)
                                </Typography>
                            </TableCell>
                            : undefined}
                        {
                            (displayNoteColumn || onProductChange) ?
                                <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                                    <Typography variant='h5' fontWeight='bold'>
                                        הערות
                                    </Typography>
                                </TableCell>
                                : undefined
                        }
                    </TableRow>


                </TableHead>
                <TableBody ref={selRef}>
                    {products.map((row, i) => (
                        <ProductRowMemo oldProducts={existingOldProducts} key={i} displayNoteColumn={displayNoteColumn}
                                        displayQuantityColumn={displayQuantityColumn} row={row}
                                        onProductChange={onProductChange} i={i}
                                        disableHoverableIncrementer={disableHoverableIncrementer}
                                        displayCostPrice={displayCostPrice} displayCost={displayCost}
                                        withReturns={withReturns} withExtraUnit={withExtraUnit}
                                        existingOrderProducts={existingOrderProducts}
                                        externalIdSearch={externalIdSearch}
                                        withDiscount={withDiscount} jumpToWrapped={jumpToWrapped}/>
                    ))}
                    {displayOldProducts && removedProducts?.map((row, i) => (
                        <ProductRowMemo removedProduct={true} key={i} displayNoteColumn={displayNoteColumn}
                                        displayQuantityColumn={displayQuantityColumn} row={row} i={i}
                                        disableHoverableIncrementer={disableHoverableIncrementer}
                                        displayCostPrice={displayCostPrice} displayCost={displayCost}
                                        withReturns={withReturns} withExtraUnit={withExtraUnit}
                                        externalIdSearch={externalIdSearch}
                                        withDiscount={withDiscount} jumpToWrapped={jumpToWrapped}/>
                    ))}
                    {displayOrderProducts && notExistingOrderProducts?.map((row, i) => (
                        <ProductRowMemo orderProducts={true} key={i} displayNoteColumn={displayNoteColumn}
                                        displayQuantityColumn={displayQuantityColumn} row={row} i={i}
                                        disableHoverableIncrementer={disableHoverableIncrementer}
                                        displayCostPrice={displayCostPrice} displayCost={displayCost}
                                        withReturns={withReturns} withExtraUnit={withExtraUnit}
                                        externalIdSearch={externalIdSearch} jumpToWrapped={jumpToWrapped}
                                        withDiscount={withDiscount}/>
                    ))}
                    {onProductChange ? <TableRow class={'autocomp'}>
                        <TableCell colSpan={6} sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Autocomplete
                                onChange={((event, value) => {
                                        if (value) {
                                            const iItem = itemApiIInternal(sup ? JSDB().getSupplier(Number(id)).first : JSDB().getClient(Number(id)).first, dateObjectToString(date), value.id, sup);
                                            onProductChange({
                                                ...iItem,
                                                quantity: 0,
                                                forceShow: true,
                                                notePlacement: products.length
                                            });
                                            setNewItem(iItem)
                                        }
                                    }
                                )}
                                loading={isLoading}
                                options={allProducts}
                                getOptionLabel={(o) => `${externalIdSearch || o.external_id != '-1' && o.external_id != '' ? o.external_id : o.id}  - ${o.name}`}
                                getOptionDisabled={(o) => {
                                    return products.some((i) => i.id == o.id);
                                }
                                }
                                filterOptions={(options, {inputValue}) => {
                                    return options.filter(o => {
                                            return subStringMatch(inputValue, [externalIdSearch || o.external_id != '-1' && o.external_id != '' ? o.external_id : o.id, o.name])
                                        }
                                    ).sort((a, b) => {


                                        if (inputValue == '') {
                                            return a.position > b.position ? 1 : -1
                                        } else {
                                            const n1 = (externalIdSearch || a.external_id != '-1' && a.external_id != '' ? a.external_id : a.id)
                                            const n2 = (externalIdSearch || b.external_id != '-1' && b.external_id != '' ? b.external_id : b.id)
                                            const nn1 = Number(n1)
                                            const nn2 = Number(n2)
                                            if (!isNaN(nn1) && !isNaN(nn2)) {
                                                return nn1 >= nn2 ? 1 : -1
                                            } else if (!isNaN(nn1)){
                                                return 1
                                            } else if (!isNaN(nn2)){
                                                return -1
                                            }
                                            else
                                                return n1 > n2 ? 1 : -1
                                        }
                                    })
                                }}
                                size='small'
                                fullWidth
                                autoHighlight
                                renderInput={(params) => <TextField {...params} label={'הוסף מוצר'}
                                                                    fullWidth size='small'/>}
                            />
                        </TableCell>
                        <TableCell colSpan={1} sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <FormControlLabel
                                value='low'
                                control={
                                    <Checkbox checked={onlyClientProduct} onChange={(e) => setOnlyClientProduct(e.target.checked)}/>
                                }
                                label='זמינים'
                            />


                        </TableCell>
                    </TableRow> : undefined}
                    {products.length > 1 ? <TableRow style={{backgroundColor: '#F3F3F3'}}>
                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='left'>
                            <Typography variant='h5' fontWeight='bold'>
                                סיכום
                            </Typography>
                        </TableCell>
                        {
                            displayOrderProducts ?
                                <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                                    <Typography variant='h5' fontWeight='bold'>
                                        {existingOrderProducts.reduce((sum, x) => sum + x.quantity + x.quantitySecond, 0)}
                                    </Typography>
                                </TableCell>
                                : undefined
                        }
                        {withExtraUnit ? <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='left'>
                            <Typography variant='h5' fontWeight='bold'>
                                {roundDecimals(products.reduce((sum, x) => sum + x.quantitySecond, 0), 2)}
                            </Typography>
                        </TableCell> : undefined}

                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>

                            <Typography variant='h5' fontWeight='bold'>
                                {roundDecimals(products.reduce((sum, x) => sum + x.quantity + x.conversion_ratio*x.quantitySecond, 0), 2)}
                            </Typography>

                            {unitSum > 0 ? <Typography variant='h5'>({unitSum}) </Typography> : undefined}


                        </TableCell>
                        {withReturns ? <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>
                                {roundDecimals(products.reduce((sum, x) => sum + x.quantityReturns, 0))}
                            </Typography>
                        </TableCell> : undefined}


                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>

                            </Typography>
                        </TableCell>
                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}

                            </Typography>
                        </TableCell>
                        <TableCell sx={{paddingRight: 0, paddingLeft: 0}} align='center'>
                            <Typography variant='h5' fontWeight='bold'>

                            </Typography>
                        </TableCell>
                    </TableRow> : undefined}

                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default memo(CustomerProductsPreviewList);

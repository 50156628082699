import React from 'react';

// material-ui
import {
    Box,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Toolbar,
    Tooltip
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';

// third-party
import { FormattedMessage } from 'react-intl';

// locale
import { enUS, he } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// project imports
import config from 'config';
import Avatar from '../../../../ui-component/extended/Avatar';
import { pdf } from '../../../../assets/images';
import GenerativeLoader, { GenerativeLoaderChip } from '../../../../ui-component/GenerativeLoader';
import { getFile, pricesPdf } from '../../../../api/customer/files.api';

const PricesFilterBar = ({ radio_value, handleRadioChange, date, setDate, toDate, setToDate, data, titles, customer }) => {

    return (
        <Grid container spacing={2}>
            <Grid style={{
                display: 'flex',
                justifyContent: 'space-between',
            }} item xs={radio_value != "from" ? 4 : 2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                    <DatePicker
                        mask=''
                        renderInput={(props) => <TextField {...props} />}
                        label={<FormattedMessage id='Apply Changes From Date' />}
                        value={date}
                        onChange={(newValue) => {
                            if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                        }}
                    />
                </LocalizationProvider>
                {(radio_value != 'from') ?
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                        <DatePicker
                            mask=''
                            renderInput={(props) => <TextField {...props} />}
                            label={'עד תאריך כולל'}
                            value={toDate}
                            onChange={(newValue) => {
                                if (newValue && !isNaN(newValue.getDate())) setToDate(newValue);
                            }}
                        />
                    </LocalizationProvider> : undefined}
            </Grid>
            <Grid item xs={6}>
                <RadioGroup
                    row
                    value={radio_value}
                    aria-labelledby='demo-form-control-label-placement'
                    name='position'
                    onChange={(e) => {
                        handleRadioChange(e.target.value);
                    }}
                >
                    <FormControlLabel
                        value='from'
                        control={<Radio />}
                        label='עד השינוי הבא'
                        labelPlacement='top'
                    />
                    <FormControlLabel
                        value='unique'
                        control={<Radio />}
                        label='מבצע בין תאריכים'
                        labelPlacement='top'
                    />
                    <FormControlLabel
                        value='to'
                        control={<Radio />}
                        label='החל לכל התאריכים עד'
                        labelPlacement='top'
                    />
                </RadioGroup>
            </Grid>

            {/* <Grid item xs={2}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={checked} onChange={handleChecked} />}
                        label={<FormattedMessage id='All Products' />}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={1}>
                <Toolbar
                    style={{ float: 'left' }}
                    sx={{
                        p: 0,
                        pl: 0,
                        pr: 0
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {customer ? <Tooltip title='יצא לPDF'>
                            <GenerativeLoaderChip labelText={'יצא מחירים לPDF'} func={(setLoader) => {

                                getFile(false, () => pricesPdf(customer.nkObject.masterBranch?.id ?? customer.id, date), `מחירי מוצרים ${customer.name}.pdf`, 'application/octet-stream', undefined, setLoader);
                            }} icon={<Avatar variant='square'
                                sx={{ cursor: 'pointer', bgcolor: 'white', width: '24px', height: '24px' }}
                                src={pdf} />} />

                        </Tooltip>
                            : undefined}




                    </Box>
                </Toolbar>
            </Grid> */}

        </Grid>
    );

};

export default PricesFilterBar;

import React, { FC, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames'
import { Column } from '../types'

export const Cell: FC<{
  gutter: boolean
  stickyRight: boolean
  disabled?: boolean
  column: Column<any, any, any>
  className: string
  active?: boolean,
  setColumnsWidth?:any
}> = ({
  children,
  gutter,
  stickyRight,
  column,
  active,
  disabled,
  className,
                           setColumnsWidth
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  return (
    <div
    ref={containerRef}
      className={cx(
        'dsg-cell',
        gutter && 'dsg-cell-gutter',
        disabled && 'dsg-cell-disabled',
        gutter && active && 'dsg-cell-gutter-active',
        stickyRight && 'dsg-cell-sticky-right',
        className
      )}
      style={{
        flex: String(column.width),
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
          cursor: setColumnsWidth ? 'col-resize' : undefined
      }}
    >
      {children}
        {setColumnsWidth ?     <Resizer column={column} setColumnWidth={setColumnsWidth} father_ref={containerRef} /> :undefined }

    </div>
  )
}
export const useDocumentEventListener = (type: string, listener: (...args: any[]) => void) => {
  useEffect(() => {
    document.addEventListener(type, listener);

    return () => {
      document.removeEventListener(type, listener);
    };
  }, [listener, type]);
};
const Resizer: FC<{
  column: Column<any, any, any>
  setColumnWidth: any,
  father_ref:any
}> = ({ column, setColumnWidth,father_ref }) => {
  // Track the current position of mouse

  const x = useRef(0);
  const w = useRef(0);
  const resizerRef = useRef<any>();

  useEffect(() => {
    if(!setColumnWidth){
      return
    }
    resizerRef.current.addEventListener('mousedown', mouseDownHandler);
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };
  }, []);


  // When user releases the mouse, remove the existing event listeners
  const mouseMoveHandler = useCallback(
      (event: MouseEvent) => {
        if(!setColumnWidth){
          return
        }
        const dx = event.clientX - x.current;

        // Update the width of column
        setColumnWidth((cols: any) =>
            cols.map((x: any) => {
              if (x.id === column.id) {
                x.width = w.current + dx;
              }
              return x;
            })
        );
      },
      []
  );
  const mouseUpHandler = useCallback(
      () => {
          const width = resizerRef.current.parentElement.clientWidth;
          localStorage.setItem(`${column.id}_column`,`${width}px`)
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      },
      []
  );
  const mouseDownHandler = useCallback(
      (event: MouseEvent) => {
        if(!setColumnWidth){
          return
        }
        x.current = event.clientX;
        const clickInside = father_ref.current?.contains(event.target as Node);
        if (clickInside) {
          const width = resizerRef.current.parentElement.clientWidth;

          w.current = width;

          // Attach listeners for document's events

          document.addEventListener('mousemove', mouseMoveHandler);
          document.addEventListener('mouseup', mouseUpHandler);
        }

      },
      []
  );
  useDocumentEventListener('mousedown', mouseDownHandler);

  return <div ref={resizerRef} className='dsg-resizer'  />;
};

import moment from 'moment';
import {useEffect, useRef, useState} from 'react';
import 'moment/locale/he';
import './calendar-container.scss';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {ProductNote} from 'types/DocumentsTypes/Document';

moment.locale('he-IL');
import VisibilityIcon from '@mui/icons-material/Visibility';
import {addDays, endOfMonth, startOfMonth, subDays} from "date-fns";
import {dateObjectToString} from "../../fromKotlin/nkutils";

interface ICalendarContainerProps {
    events: any[];
    onAddDeliveryCertificate?: any;
    onRemoveDeliveryCertificate?: any;
    receipts?: ProductNote[];
    setOpenCalendar: any;
    setPreviewOpen?: any;
    setPreviewData?: any;
    useTooltip?: boolean;
    useControlls?: boolean;
    setRange?: any;
}

const CalendarContainer = ({
                               events,
                               onAddDeliveryCertificate,
                               onRemoveDeliveryCertificate,
                               receipts,
                               setOpenCalendar,
                               setPreviewOpen,
                               setPreviewData,
                               useTooltip = true,
                               useControlls = true,
                               setRange
                           }: ICalendarContainerProps) => {
    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());
    const [daysInMonth, setDaysInMonth] = useState(moment().daysInMonth());
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(
        moment().startOf('month').day()
    );
    const [lastDayOfMonth, setLastDayOfMonth] = useState(
        moment().endOf('month').day()
    );

    const onSetMonth = (monthToSet: number) => {
        if (monthToSet === 12) {
            setMonth(0);
            setYear(year + 1);
        } else if (monthToSet === -1) {
            setMonth(11);
            setYear(year - 1);
        } else {
            setMonth(monthToSet);
        }
    };

    const getItemsInDate = (day: number) => {
        const items = events?.filter((event) => {
            const dateFormated = dateObjectToString(event.date);
            return dateFormated === `${year}-${month + 1 < 10 ? '0' + (month + 1) : month + 1}-${
                // if day is 1 length add 0 before it
                day + 1 < 10 ? '0' + (day + 1) : day + 1
            }`;
        });
        return items;
    };

    // turn off to disable loading
    useEffect(() => {
        setDaysInMonth(moment().month(month).year(year).daysInMonth());
        const firstDay = moment().month(month).year(year).startOf('month')
        const secondDay = moment().month(month).year(year).endOf('month')
        if (setRange)
            setRange([(subDays(startOfMonth(firstDay.toDate()), 7)), addDays(endOfMonth(secondDay.toDate()), 7)])
        setFirstDayOfMonth(firstDay.day());
        setLastDayOfMonth(secondDay.day());
    }, [month, year]);

    return (
        <div className="calendar-container">
            <header className="calendar-header">
                <div className="year">
                    <div className="prev" onClick={() => {

                        setYear(year - 1)
                    }}>
                        &lt;
                    </div>
                    <span>{year}</span>
                    <div className="next" onClick={() => {
                        setYear(year + 1)
                    }}>
                        &gt;
                    </div>
                </div>
                <div className="month">
                    <div className="prev" onClick={() => onSetMonth(month - 1)}>
                        &#10094;
                    </div>
                    {' '}
                    <div className="month-name">
                        {moment().month(month).format('MMMM')}
                    </div>
                    {' '}
                    <div className="next" onClick={() => onSetMonth(month + 1)}>
                        &#10095;
                    </div>
                </div>
                <div className="weekdays">
                    <div className="weekday">א</div>
                    <div className="weekday">ב</div>
                    <div className="weekday">ג</div>
                    <div className="weekday">ד</div>
                    <div className="weekday">ה</div>
                    <div className="weekday">ו</div>
                    <div className="weekday">ש</div>
                </div>
            </header>
            <section className="calendar-body">
                <div className="days">
                    {Array.from(Array(firstDayOfMonth).keys()).map((day, index) => {
                        return <div className="day" key={index + 'empty'}></div>;
                    })}
                    {Array.from(Array(daysInMonth).keys()).map((day, index) => {
                        return (
                            <CalendarItem
                                key={index}
                                items={getItemsInDate(day)}
                                dateNumber={index + 1}
                                onAddDeliveryCertificate={onAddDeliveryCertificate}
                                onRemoveDeliveryCertificate={onRemoveDeliveryCertificate}
                                receipts={receipts}
                                setPreviewOpen={setPreviewOpen}
                                setPreviewData={setPreviewData}
                                useTooltip={useTooltip}
                            />
                        );
                    })}
                    {Array.from(Array(6 - lastDayOfMonth).keys()).map((day, index) => {
                        return <div className="day" key={index + 'end-empty'}></div>;
                    })}
                </div>
                <div
                    className="confirm"
                >
                    {useControlls ? (
                        <>
              <span
                  onClick={() => setOpenCalendar(false)}
              >הסתר</span>
                            <span onClick={() => {
                                const activeMonth = moment().month(month).format('MMMM')
                                const activeYear = year
                                const ordersFromMonth = events?.filter((event) => {
                                    const dateFormated = moment(event?.date).format('MMMM YYYY');
                                    return dateFormated === `${activeMonth} ${activeYear}` && event?.status === 'open';
                                });
                                ordersFromMonth.forEach((order) => {
                                    if (receipts?.find((receipt) => receipt?.id === order?.id)) return
                                    onAddDeliveryCertificate(order)
                                })
                            }}>הוסף את כול התעודות הפתוחות מהחודש הנוכחי</span>
                        </>
                    ) : null}
                </div>
            </section>
        </div>
    );
};

export default CalendarContainer;

const CalendarItem = ({
                          dateNumber,
                          items,
                          onAddDeliveryCertificate,
                          onRemoveDeliveryCertificate,
                          receipts,
                          setPreviewOpen,
                            setPreviewData,
                          useTooltip = true,
                      }: {
    dateNumber: number;
    items: ProductNote[];
    onAddDeliveryCertificate: any;
    onRemoveDeliveryCertificate: any;
    receipts?: ProductNote[];
    setPreviewOpen: any;
    setPreviewData: any;
    useTooltip?: boolean;
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [displayTooltip, setDisplayTooltip] = useState<any>(false);
    const [itemsPopup, setItemsPopup] = useState<any>(false);
    const [disableTooltip, setDisableTooltip] = useState(false);
    const [itemLocationOnScreen, setItemLocationOnScreen] = useState<any>(null);
    const [displayInitials, setDisplayInitials] = useState<any>(null);

    const loadIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

    const ToolTip = () => {
        const item = items?.find((item) => item?.id == displayTooltip);
        const [status, setStatus] = useState('');

        useEffect(() => {
            switch (item?.status) {
                case 'open':
                    setStatus('פתוח');
                    break;
                case 'closed':
                    setStatus('סגור');
                    break;
                case 'canceled':
                    setStatus('בוטל');
                    break;
                case 'approved':
                    setStatus('אושר');
                    break;
                case 'pending_approval':
                    setStatus('ממתין לאישור');
                    break;
                default:
                    setStatus('פתוח');
                    break;
            }
        }, [item?.status]);

        return (
            <div style={{
                position: "fixed",
                top: itemLocationOnScreen?.y - (itemLocationOnScreen?.height * 2.75),
            }} className="tooltip">
                <div className="tooltip-header">
                    <span>תעודה מספר: {item?.id}</span>
                    <span style={{display: "flex"}}>סטטוס: {status}</span>
                </div>
                <div className="tooltip-body">
                    <span>ראה תעודה: </span>
                    <span onClick={() => {
                        setDisplayTooltip(false)
                        setPreviewOpen(true)
                        setPreviewData(item)
                    }} style={{border: "solid black 1px"}} className='tooltip-display-button'><VisibilityIcon/></span>
                </div>
            </div>
        )
    };

    const onMouseEnter = (id) => {
        const itemLocationOnScreen = document.getElementById(id)?.getBoundingClientRect();
        const itemLocationOnScreenX = itemLocationOnScreen?.x;
        const itemLocationOnScreenY = itemLocationOnScreen?.y;
        const itemLocationOnScreenWidth = itemLocationOnScreen?.width;
        const itemLocationOnScreenHeight = itemLocationOnScreen?.height;
        setItemLocationOnScreen({
            x: itemLocationOnScreenX,
            y: itemLocationOnScreenY,
            width: itemLocationOnScreenWidth,
            height: itemLocationOnScreenHeight,
        });
        !displayTooltip && setDisplayTooltip(id);
    };

    const onMouseLeave = () => {
        setDisplayTooltip(false);
    };

    useEffect(() => {
        if (items) {
            setIsLoading(false);
        }
    }, [items]);

    const popupRef: any = useRef(null);
    const onClickOutside = (e) => {
        if (popupRef?.current && !popupRef?.current?.contains(e.target)) {
            setItemsPopup(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", onClickOutside);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth < 1100) {
                setDisplayInitials(true)
            } else {
                setDisplayInitials(false)
            }
        });

        return () => {
            window.removeEventListener("resize", () => {
                if (window.innerWidth < 1100) {
                    setDisplayInitials(true)
                } else {
                    setDisplayInitials(false)
                }
            });
        };
    }, [])

    return (
        <div onMouseLeave={() => onMouseLeave()} className="calendar-item-container">
            <header className="calendar-item-header">{dateNumber}</header>
            {isLoading ? (
                <Spin indicator={loadIcon}/>
            ) : (
                <section
                    className="stacked-items"
                >
                    {items?.length ? (
                        items?.map((item, i) => {
                            return i < 2 ? (
                                <>
                                    {displayTooltip && <ToolTip/>}
                                    <span onMouseEnter={(e: any) => {
                                        useTooltip && !disableTooltip && onMouseEnter(e.target?.id)
                                    }} onClick={() => {
                                        if (useTooltip) {
                                            if (receipts?.find((receipt) => receipt?.id === item?.id)) {
                                                onRemoveDeliveryCertificate(item)
                                            } else {
                                                onAddDeliveryCertificate(item)
                                            }
                                        } else {
                                            setDisplayTooltip(false)
                                            setPreviewOpen(true)
                                            setPreviewData(item)
                                        }
                                    }}
                                          id={item?.id?.toString()}
                                          className={`stacked-items-card ${receipts?.find((receipt) => receipt?.id === item?.id) ? 'checked' : ''
                                          }`}>
                    {
                        displayInitials ? 'ח.מ. ' + item?.id :
                            (displayTooltip == item?.id ? <b>{item?.title}</b> :
                                item?.title)}
                  </span>
                                    {itemsPopup && <ItemsPopup
                                        popupRef={popupRef}
                                        items={items}
                                        onMouseEnter={onMouseEnter}
                                        onRemoveDeliveryCertificate={onRemoveDeliveryCertificate}
                                        onAddDeliveryCertificate={onAddDeliveryCertificate}
                                        receipts={receipts}
                                        disableTooltip={disableTooltip}
                                        displayTooltip={displayTooltip}
                                        displayInitials={displayInitials}
                                        useTooltip={useTooltip}
                                        setDisplayTooltip={setDisplayTooltip}
                                        setPreviewOpen={setPreviewOpen}
                                        setPreviewData={setPreviewData}
                                    />}
                                </>
                            ) : i === 2 && (
                                <span className='more-button' onClick={() => {
                                    setDisplayTooltip(false)
                                    setDisableTooltip(true)
                                    setTimeout(() => {
                                        setDisableTooltip(false)
                                    }, 500)
                                    setItemsPopup(true)
                                }}>
                  עוד...{
                                    items?.length - 2
                                }
                </span>
                            );
                        })
                    ) : (
                        ''
                    )}
                </section>
            )}
        </div>
    );
};

interface IItemsPopup {
    popupRef: any;
    items: any;
    onMouseEnter: any;
    onRemoveDeliveryCertificate: any;
    onAddDeliveryCertificate: any;
    receipts: any;
    disableTooltip: boolean;
    displayTooltip: any;
    displayInitials: boolean;
    useTooltip: boolean;
    setDisplayTooltip: any;
    setPreviewOpen: any;
    setPreviewData: any;
}

const ItemsPopup = ({
                        items,
                        popupRef,
                        onMouseEnter,
                        onRemoveDeliveryCertificate,
                        onAddDeliveryCertificate,
                        receipts,
                        disableTooltip,
                        displayTooltip,
                        displayInitials,
                        useTooltip = true,
                        setDisplayTooltip,
                        setPreviewOpen,
                        setPreviewData
                    }: IItemsPopup) => {
    return (
        <div ref={popupRef} style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "fit-content",
            backgroundColor: "white",
            border: "solid #dfdfdf 1px",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999
        }}>
            {
                items.map((item) => {
                    return (
                        <>
                            {/* {displayTooltip && <ToolTip />} */}
                            <span onMouseEnter={(e: any) => {
                                useTooltip && !disableTooltip && onMouseEnter(e.target.id)
                            }} onClick={() => {
                                if (useTooltip) {
                                    if (receipts?.find((receipt) => receipt?.id === item?.id)) {
                                        onRemoveDeliveryCertificate(item)
                                    } else {
                                        onAddDeliveryCertificate(item)
                                    }
                                } else {
                                    setDisplayTooltip(false)
                                    setPreviewOpen(true)
                                    setPreviewData(item)
                                }
                            }}
                                  id={item?.id?.toString()}
                                  className={`stacked-items-card ${receipts?.find((receipt) => receipt?.id === item?.id) ? 'checked' : ''
                                  }`}>
                {
                    displayInitials ? 'ח.מ. ' + item?.id :
                        (displayTooltip == item?.id ? <b>{item?.title}</b> :
                            item?.title)}
              </span>
                        </>
                    )
                })
            }
        </div>
    )
}

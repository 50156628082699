import {Box, Grid, IconButton, TextField, Toolbar, Tooltip,} from '@mui/material';
import {DateRangePicker, LocalizationProvider} from '@mui/lab';
import {FormattedMessage} from 'react-intl';
import {enUS, he} from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from 'config';
import Avatar from '../../../../ui-component/extended/Avatar';
import {pdf, xslx} from '../../../../assets/images';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import {getFile} from '../../../../api/customer/files.api';
import {getCartesetPDF, sendPdfEmail} from '../../../../api/customer/documents.api';
import {parseJSON} from 'date-fns';
import GenerativeLoader from "../../../../ui-component/GenerativeLoader";
import JSDB from "../../../../fromKotlin/nk";
import {Email} from "@mui/icons-material";
import * as React from "react";


export const CartesetFilterBar = ({ range, setRange, cid }) =>
{

    return(<Grid container spacing={2}>
            <Grid item xs={8}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                    <DateRangePicker
                        mask=''
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField
                                    size='small'
                                    fullWidth
                                    {...startProps}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={<FormattedMessage id='clear' />}>
                                                <IconButton size='small'
                                                            onClick={() => setRange((prevRange) => [null, prevRange[1]])}>
                                                    <ClearIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                                <Box sx={{ mx: 2 }}>
                                    <FormattedMessage id='to' />
                                </Box>
                                <TextField
                                    size='small'
                                    fullWidth
                                    {...endProps}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={<FormattedMessage id='clear' />}>
                                                <IconButton size='small'
                                                            onClick={() => setRange((prevRange) => [prevRange[0], null])}>
                                                    <ClearIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            </>
                        )}
                        startText={<FormattedMessage id='From Date' />}
                        endText={<FormattedMessage id='To Date' />}
                        value={range}
                        onChange={(newValue) => {
                            if (newValue) setRange(newValue);
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
                <Box />
            </Grid>
            <Grid item xs={1}>

            </Grid>
        </Grid>
    )}



export default CartesetFilterBar;

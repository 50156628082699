import React from 'react';

// material-ui
import { Box, Fade, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';

// assets
import { IconSquareMinus, IconSquarePlus } from '@tabler/icons';
import {NoNegativeInput, WithNegative} from './textFieldNumber';

interface HoverableIncrementerProps {
    /**
     * Initial counter value
     */
    value?: number;
    /**
     * Whether the value is static and cannot be changed
     */
    disabled?: boolean;
    cantChange?: boolean;
    /**
     * On decrement handler
     */
    onDecrement?: () => void;
    /**
     * On increment handler
     */
    onIncrement?: () => void;
    /**
     * On hover handler
     */
    onChange?: (v:number) => void;
    /**
     * On hover handler
     */
    onMouseEnter?: () => void;
    /**
     * On hover ends handler
     */
    onMouseLeave?: () => void;
    withNegative?:boolean
}

const CounterItem = ({ counter,setCounter,cantChange,withNegative }: { counter: number,setCounter?:(number)=>void,cantChange?:boolean,withNegative?:boolean }) => (
    withNegative? <WithNegative
            id={'negativeinp'}
            changer={(v)=> setCounter((v))}
            type={'text'}
            value={counter}
            disabled={cantChange}
            sx={{
                textAlign: 'center',
                borderRadius: 1,
                flex: 1,
                width:10,
                minWidth: 25
            }}
        />:
    <NoNegativeInput
        id={'negativeinp'}
        changer={(v)=> setCounter((v))}
        type={'text'}
        value={counter}
        disabled={cantChange}
        sx={{
            textAlign: 'center',
            borderRadius: 1,
            flex: 1,
            width:10,
            minWidth: 25
        }}
    />
);

const HoverableIncrementer = ({
    value,
    disabled = false,
    onDecrement,
    onIncrement,
    onMouseEnter,
    onMouseLeave,
    onChange,
    cantChange,
                                  withNegative
}: HoverableIncrementerProps) => {
    const theme = useTheme();
    const [hover, setHover] = React.useState(false);
    const [counter, setCounter] = React.useState(value ?? 0);

    const handleOnMouseEnter = () => {
        onMouseEnter ? onMouseEnter() : setHover(true);
    };

    const handleOnMouseLeave = () => {
        onMouseLeave ? onMouseLeave() : setHover(false);
    };

    const handleOnDecrement = () => {
        onDecrement ? onDecrement() : setCounter((p) => p - 1);
    };

    const handleOnIncrement = () => {
        onIncrement ? onIncrement() : setCounter((p) => p + 1);
    };

    return (
        <Box

            sx={{ display: 'flex', flex: 1, flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'space-between', }}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        >
            <Fade in={!disabled && hover}>
                <IconButton size="small" onClick={handleOnDecrement}>
                    <IconSquareMinus color={theme.palette.primary.dark} />
                </IconButton>
            </Fade>
            <CounterItem counter={value ?? counter} setCounter={onChange} cantChange={cantChange} withNegative={withNegative}/>
            <Fade in={!disabled && hover}>
                <IconButton size="small" onClick={handleOnIncrement}>
                    <IconSquarePlus color={theme.palette.primary.dark} />
                </IconButton>
            </Fade>
        </Box>
    );
};

export default HoverableIncrementer;

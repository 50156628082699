import React, {memo} from 'react';

// material-ui
import {
    Autocomplete,
    Box,
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    ListItemIcon,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/lab';

// third-party
import {FormattedMessage} from 'react-intl';

// locale
import {enUS, he} from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// project imports
import config from 'config';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventIcon from '@mui/icons-material/Event';
import {IconSteeringWheel} from '@tabler/icons';

import {useSelector} from 'react-redux';
import {selectDrivers} from '../../../../store/agents/agentsSlice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Agent from '../../../../types/agent';
import SearchIcon from '@mui/icons-material/Search';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BaseOrDataSelectorDaily = ({ callPrevDaily, ids, date,disabled,driver_id=undefined,color='gray' ,boxid=undefined}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [value, setValue] = React.useState<Agent[]>([]);
    const drivers = useSelector(selectDrivers);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setValue([])
    };


    return (
        <Box >
            <Tooltip title='בחר מתוך סידור קיים'>
                <span>


                <IconButton
                    disabled={disabled}
                    onClick={handleClick}
                    id="get-file-from-existing-drivers-menu-orders"
                    size='small'
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                >
                    <IconSteeringWheel fontSize='small'   color={color}/>
                </IconButton>
                                    </span>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id={boxid}
                open={open}
                onClose={handleClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {(!ids && !driver_id) ?  <MenuItem>
                    <Autocomplete
                        style={{marginBottom:20,marginTop:10}}
                        value={value}
                        onChange={(e: any, v: Agent[]) => {
                            setValue(v);
                        }}
                        multiple

                        freeSolo
                        disableCloseOnSelect
                        options={drivers}
                        // TODO: use groupBy to support distinct values on other properties
                        // groupBy={(option) => option.firstLetter}
                        getOptionLabel={(o) => o.name}
                        size='small'
                        fullWidth
                        autoHighlight
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={"בחר נהגים.."}
                                autoFocus
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon fontSize='small' />
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        )}
                    />
                </MenuItem> : undefined}
                <MenuItem >
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                            <DatePicker
                                components={{
                                    OpenPickerIcon: () => (
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <Tooltip title={<FormattedMessage id='Fetch From Previous Order' />}>
                                                <CalendarTodayIcon fontSize='small' />
                                            </Tooltip>
                                            <Typography variant='h5' style={{marginRight:10}}>    מתוך סידור קיים   </Typography>

                                        </Box>
                                    )
                                }}
                                renderInput={({ inputRef, inputProps, InputProps }) => <Box
                                    ref={inputRef}>{InputProps?.endAdornment}</Box>}
                                label={<FormattedMessage id='Date' />}
                                allowSameDateSelection
                                value={date ?? new Date()}
                                onChange={(newValue) => {
                                    if(newValue)   callPrevDaily(newValue, ids, -1,driver_id ? [driver_id]: value?.map((a)=>a.id));
                                }}
                            />
                        </LocalizationProvider>

                </MenuItem>
                <Divider />
                <MenuItem onClick={(e) => {
                    callPrevDaily(date,ids, 1,driver_id ? [driver_id]: value?.map((a)=>a.id));
                }}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    סידור בסיס יום א
                </MenuItem>
                <MenuItem onClick={(e) => {
                    callPrevDaily(date, ids, 2,driver_id ? [driver_id]: value?.map((a)=>a.id));
                }}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    סידור בסיס יום ב
                </MenuItem>
                <MenuItem onClick={(e) => {
                    callPrevDaily(date, ids, 3,driver_id ? [driver_id]: value?.map((a)=>a.id));
                }}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    סידור בסיס יום ג
                </MenuItem>
                <MenuItem onClick={(e) => {
                    callPrevDaily(date, ids, 4,driver_id ? [driver_id]: value?.map((a)=>a.id));
                }}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    סידור בסיס יום ד
                </MenuItem>
                <MenuItem onClick={(e) => {
                    callPrevDaily(date, ids, 5,driver_id ? [driver_id]: value?.map((a)=>a.id) );
                }}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    סידור בסיס יום ה
                </MenuItem>
                <MenuItem onClick={(e) => {
                    callPrevDaily(date, ids, 6,driver_id ? [driver_id]: value?.map((a)=>a.id) );
                }}>
                    <ListItemIcon>
                        <EventIcon fontSize='small' />
                    </ListItemIcon>
                    סידור בסיס יום ו
                </MenuItem>
            </Menu>
        </Box>
    );
};


export default memo(BaseOrDataSelectorDaily);

import React from 'react';
import { Box, Chip, Grid, IconButton, ListItem, ListItemText, Popover, TextField, Tooltip } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import { enUS, he } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import config from 'config';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import { addDays } from 'date-fns';
import { CalendarMonth } from '@mui/icons-material';
import {Separator} from "tabler-icons-react";
import {
    dateObjectToString,
    endOfMonth,
    endOfWeek,
    startOfMonth,
    startOfWeek,
    subtractMonths
} from "../fromKotlin/nkutils";
const MyRangePicker = ({ range, setRange, isLoading }) => {
    const [open, setOpen] = React.useState(false);
    const [isAccept, setIsAccept] = React.useState(false);
    const [isTextField, setIsTextField] = React.useState(false);
    const [rangeInternal, setRangeInternal] = React.useState([null, null]);
    const [openFastDatePicker, setOpenFastDatePicker] = React.useState(false);
    React.useEffect(() => {
        setRangeInternal(range);
    }, [range]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                <DateRangePicker
                    mask=''
                    loading={isLoading}
                    disabled={isLoading}
                    renderInput={(startProps, endProps) => (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    {...startProps}
                                    onFocus={() => {
                                        setIsTextField(true);
                                    }}
                                    onBlur={() => {
                                        setIsTextField(false);
                                    }}
                                    onClick={() => {
                                        setOpen(true);
                                        setRangeInternal([null, null]);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            const value = e?.target?.value;
                                            const valueToDate = (value) => {
                                                const dateParts = value.split(/\/|-|\./);
                                                return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                                            }
                                            const date = valueToDate(value);
                                            if (date && !isNaN(date.getDate())) {
                                                setRangeInternal([date, rangeInternal[1]]);
                                                setRange([date, range[1]]);
                                                setOpen(false);
                                                document?.getElementById('my-range-picker-end-date')?.focus();
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={<FormattedMessage id='clear' />}>
                                                <IconButton size='small'
                                                    onClick={() => !isLoading ? setRange((prevRange) => [null, prevRange[1]]) : undefined}>
                                                    <ClearIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id='my-range-picker-end-date'
                                    size='small'
                                    fullWidth
                                    {...endProps}
                                    onFocus={() => {
                                        setIsTextField(true);
                                    }}
                                    onBlur={() => {
                                        setIsTextField(false);
                                    }}
                                    onClick={() => {
                                        setOpen(true);
                                        // setRangeInternal([null, null]);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            const value = e.target.value;
                                            const valueToDate = (value) => {
                                                const dateParts = value.split(/\/|-|\./);
                                                return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                                            }
                                            const date = valueToDate(value);
                                            if (date && !isNaN(date.getDate())) {
                                                setRangeInternal([rangeInternal[0], date]);
                                                setRange([range[0], date]);
                                                setOpen(false);
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={<FormattedMessage id='clear' />}>
                                                <IconButton size='small'
                                                    onClick={() => !isLoading ? setRangeInternal((prevRange) => [prevRange[0], null]) : undefined}>
                                                    <ClearIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    startText={<FormattedMessage id='From Date' />}
                    endText={<FormattedMessage id='To Date' />}
                    value={rangeInternal}
                    open={open}
                    onAccept={() => {
                        setIsAccept(true);
                    }}
                    onClose={() => {
                        setIsAccept(false);
                        if (!isTextField) {
                            setOpen(false);
                            setIsTextField(false);
                        }
                    }}
                    onOpen={() => {
                        if (!isAccept) {
                            setOpen(true);
                            setRangeInternal([null, null]);
                        }
                    }}
                    onChange={(newValue) => {
                        if (newValue && newValue[0] && newValue[1] && !isNaN(newValue[0].getDate()) && !isNaN(newValue[1].getDate())) {
                            setRangeInternal(newValue);
                            setRange(newValue);
                            setOpen(false);
                        };
                    }}
                />
            </LocalizationProvider>
            <Grid item xs={2} sx={{
                margin: '0 10px'
            }}>
                <Chip
                    label="תאריך מהיר"
                    onClick={() => {
                        setOpenFastDatePicker(true)
                    }}
                    id="fastDatePicker"
                    color="primary"
                    variant="outlined"
                    icon={<CalendarMonth />}
                />
                <Popover
                    open={openFastDatePicker}
                    anchorEl={document.getElementById('fastDatePicker')}
                    onClose={() => {
                        setOpenFastDatePicker(false)
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([addDays(new Date(), -1), addDays(new Date(), -1)])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="אתמול" />
                    </ListItem>
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([addDays(new Date(), 0), addDays(new Date(), 0)])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="היום" />
                    </ListItem>
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([addDays(new Date(), 1), addDays(new Date(), 1)])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="מחר" />
                    </ListItem>

                    <Separator />
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([startOfWeek(new Date()), endOfWeek(new Date())])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="שבוע נוכחי" />
                    </ListItem>
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([startOfWeek(addDays(new Date(), -7)), endOfWeek(addDays(new Date(), -7))])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="שבוע קודם" />
                    </ListItem>
                    <Separator />
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([startOfMonth(new Date()), endOfMonth(new Date())])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="חודש נוכחי" />
                    </ListItem>
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([subtractMonths(startOfMonth(new Date()),1), endOfMonth(subtractMonths((new Date()),1))])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="חודש קודם" />
                    </ListItem>
                    <Separator />
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([addDays(new Date(), -7), new Date()])
                        setOpenFastDatePicker(false)
                    }}>
                        <ListItemText primary="שבוע אחורה" />
                    </ListItem>
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([addDays(new Date(), -14), new Date()])
                        setOpenFastDatePicker(false)
                    }}
                    >
                        <ListItemText primary="שבועיים אחורה" />
                    </ListItem>
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        setRange([addDays(new Date(), -30), new Date()])
                        setOpenFastDatePicker(false)
                    }}
                    >
                        <ListItemText primary="חודש אחורה" />
                    </ListItem>
                    <ListItem style={{
                        cursor: 'pointer'
                    }} onClick={() => {
                        const d = new Date()
                        const startYear = new Date(d.getFullYear(), 0, 1);

                        setRange([startYear, d])
                        setOpenFastDatePicker(false)
                    }}
                    >
                        <ListItemText primary="מתחילת שנה נוכחית" />
                    </ListItem>
                </Popover>
            </Grid>
        </Box>
    );
};

export default MyRangePicker;

import React from 'react';

import { MutableRefObject, useRef } from 'react';

// All necessary functions to init the ag-table component

export const defaultColDef = (tableControls) => {
    return {
        resizable: true,
        sortable: true,
        width: 200,
        filter: true, //"agMultiColumnFilter",
        floatingFilter: tableControls.floatingFilters ?? true,
        flex: 1,
        enableRowGroup: tableControls?.groupBy?.rowGroup,
        enablePivot: tableControls?.groupBy?.pivot,
        enableValue: tableControls?.groupBy?.value
    };
};

export const loadRefsForAgTable = () => {
    const editQueueRef: MutableRefObject<any[]> = useRef([]);
    const debounceTimeoutRef: MutableRefObject<NodeJS.Timeout | null | undefined> = useRef();
    const setColumnsCallbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const colStateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const lockRefresh = useRef<boolean | null>(false);

    return { editQueueRef, debounceTimeoutRef, setColumnsCallbackTimeoutRef, colStateTimeoutRef, timeoutRef, lockRefresh };
};

export const getDetailCellRenderer = (params, tableControls) => {
    if (!params) return undefined;
    if (typeof tableControls?.nestedColumns?.datas === 'string') {
        return <div style={{ height: 50, fontSize: '1rem', padding: 10 }}>{params?.data[tableControls?.nestedColumns?.datas]}</div>;
    } else {
        const findKey = tableControls?.nestedColumns?.datas.find((data) => params?.data?.[data]);
        const findData = params?.data?.[findKey || ''];
        return <div style={{ height: 50, fontSize: '1rem', padding: 10 }}>{findData ? findData : ''}</div>;
    }
};

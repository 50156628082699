export default function JSDB() {
    return (window as any).NiHulKavJsConvertorApp.JsDB;
}

export function ApiCallWrapper(task: string, callback: any) {
    const A = (window as any).NiHulKavJsConvertorApp.ApiCallWrapper;
    const pi = new A(task, callback);
    return pi;
}

export class ApiCall {
    task: string;

    api: any;

    callback: any;

    constructor(task: string) {
        this.task = task;
        const callback = (result) => {

        };
        this.api = ApiCallWrapper(task, callback);

    }

    putParam(name: string, value: any): ApiCall {
        this.api.putParam(name, value);
        return this;
    }

    execute(other_allow_status=undefined): Promise<any> {
        return new Promise((resolve, reject) => {
            const callback = (result) => {
                if (result == null) {
                    reject(result);
                } else if (result.second != null) {
                    if (result.second.name !== 'SUCCESS' && (other_allow_status==undefined  || other_allow_status!=(result.second.name)))
                        reject(result.second);
                    else
                        resolve(result);
                } else if (result.name !== 'SUCCESS' && (other_allow_status==undefined  || other_allow_status!=(result.name))) {
                    reject(result.first);
                } else {
                    resolve(result);
                }
            };
            this.api.replaceCallback(callback);
            this.api.execute();
        });
    }

}

export function NKTypeConvertor() {
    return (window as any).NiHulKavJsConvertorApp.NKTypeConvertor;
}

/* eslint-disable no-nested-ternary */
import React, {memo, useEffect} from 'react';

// material-ui
import {Avatar, Box, Chip, IconButton, Toolbar, Tooltip} from '@mui/material';

// assets
import PrintIcon from '@mui/icons-material/PrintOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';


// project imports
import MainCard from 'ui-component/cards/MainCard';
import InvoicePreview from './invoice/InvoicePreview';
import DeliveryCertificatePreview from './deliveryCertificate/DeliveryCertificatePreview';
import ReceiptPreview from './receipt/ReceiptPreview';

// Locale
import {FormattedMessage} from 'react-intl';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// types
import {ExtendedDocument, ProductNote} from 'types/DocumentsTypes/Document';
import {PayNote} from '../../../../types/DocumentsTypes/PayNote';
import {TaxNote} from '../../../../types/DocumentsTypes/TaxNote';
import {
    buildSupNotesSum,
    getDocumentPdf,
    getDocumentPhoto,
    getDocumentSign,
    sendPdfEmail
} from '../../../../api/customer/documents.api';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {printPdf, saveByteArray} from '../../../../fromKotlin/nkutils';
import {useActivateCustomerDocument, useUpdateCustomerDocument} from '../../../../hooks/customer/documents.hooks';
import {pdf} from '../../../../assets/images';
import {getFile} from '../../../../api/customer/files.api';
import DebtPreview from './debt/DebtPreview';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import JSDB from '../../../../fromKotlin/nk';
import ShowImageDialog from '../../../../ui-component/dialogs/showImageDialog';
import {useTheme} from '@mui/material/styles';
import IItem from '../../../../types/product_delivery';
import './style.css';
import GenerativeLoader from '../../../../ui-component/GenerativeLoader';
import CancelNoteDialog from '../../../../ui-component/dialogs/cancelNoteDialog';
import {Email, MoneyOutlined, WhatsApp} from '@mui/icons-material';
import VisitPreview from "./visit/VisitPreview";
import {Payment} from 'types/DocumentsTypes/utils';
import InvoiceAndReciptPreview from './invoice/InvoiceAndReceiptPreview';
import ApproveNoteDialog from "../../../../ui-component/dialogs/approveChangeNote";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import SendMessageDialog from "../../../../ui-component/dialogs/sendMessage";

const DocumentPreview = ({
                             document,
                             ExtraOptions,
                             setSelectedCustomersSend,
                             pool,
                             outerDoc,
                            collect
                         }: { document?: ExtendedDocument; ExtraOptions?: () => JSX.Element, creditRecepitValue: boolean, setCreditRecepitValue: React.Dispatch<React.SetStateAction<boolean>>, setSelectedCustomersSend?: React.Dispatch<any> ,pool?:string, outerDoc?: any,collect?:boolean}) => {
    // let [doc,setDoc] = React.useState<ExtendedDocument|undefined>(document);
    let doc = document

    const createCustomerDocument = useActivateCustomerDocument(pool);
    const updateCustomerDocument = useUpdateCustomerDocument(doc?.type == 'supplier_note' ? 'supplier' : 'client', doc?.type == 'receipt' ? 1 : doc?.type=='visit_note'?2:0,pool);
    const [selectedCustomersSend2, setSelectedCustomersSend2] = React.useState<any>(undefined)
    const setSelectedCustomersSend3 = setSelectedCustomersSend ?? setSelectedCustomersSend2;
    const [docFromBot,setDocFromBot] = React.useState<ExtendedDocument | undefined>(undefined);
    if (doc) {

        // customerSpecificDoc= useGetSpecificCustomerDocuments(doc.type,undefined,undefined,undefined,undefined,doc.id,true);

        // React.useEffect(() => {
        //     customerSpecificDoc.refetch()
        //
        // }, []);

        // doc = (createCustomerDocument.data as ExtendedDocument)?? (customerSpecificDoc.data as ExtendedDocument[])[0]
        doc = docFromBot ?? doc;
        doc = (createCustomerDocument.data as ExtendedDocument) ?? doc;
        doc = (updateCustomerDocument.data as ExtendedDocument) ?? doc;

    }


    if (!doc)
        return <Box/>;

    // React.useEffect(() => {
    //     const genProds = async () => {
    //         const temp = await getSpecificDocument(doc.type, undefined, undefined, undefined, undefined, doc.id, true, undefined);
    //         setDoc(temp[0])
    //
    //     };
    //     if(doc) {
    //         setDoc((createCustomerDocument.data as ExtendedDocument) ?? doc)
    //         setDoc((updateCustomerDocument.data as ExtendedDocument) ?? doc)
    //     }
    //     if ((doc.type == 'delivery_certificate' || doc.type =='order') && (doc as ProductNote).products.length == 0)
    //         genProds();
    //
    // }, []);
    const icons = doc.type !== 'debt' && doc.nkObject !== undefined;
    const canCancel = doc.status !== 'canceled' && (doc.status !== 'closed' || doc.type === 'receipt' || doc.type === 'tax_invoice' || doc.type=='visit_note' )
    const canActivate =  doc?.status === 'canceled' && doc?.type === 'delivery_certificate';
    const canEdit = (doc.type === 'delivery_certificate' && doc.status === 'open') || (doc.type == 'order' && doc.status != 'closed') || (doc.type == 'supplier_note' && doc.status != 'closed') || (doc.type === 'receipt') || (doc.type === "visit_note") ||  (doc.type === "tax_invoice")|| (doc.type === "order" && doc.status === "open") || (doc.type === "invoice" );
    const withSign = doc.sign;
    const [withPhoto, setWithPhoto] = React.useState<boolean>(doc?.photo ? true : false);
    const [products, setProducts] = React.useState<IItem[]>([]);
    const [internalEdit, setInternalEdit] = React.useState<any>(doc);
    React.useEffect(() => {
        setInternalEdit(doc)
    }, [doc]);
    const [payments, setPayments] = React.useState<Payment[]>([]);
    const [withDiscount, setWithDiscount] =React.useState<boolean>(parseInt(localStorage.getItem('withDiscount') ?? '0') == 1);
    const [editting, setEditting] = React.useState<boolean>(false);
    const [makor, setMakor] = React.useState<boolean>(true);
    const [send, setSend] = React.useState(false);
    const setStartEdit = React.useCallback(() => {
        if (doc?.type === 'delivery_certificate' || doc?.type === 'order' || doc?.type === 'supplier_note' || doc?.type === 'invoice') {
            setEditting(true);
            setProducts((doc as ProductNote).products.map((p) => {
                return {
                    ...p
                };
            }));
        } else if (doc?.type === 'receipt') {
            setEditting(true);
            setPayments((doc as PayNote).payments.map((p) => {
                return {
                    ...p
                };
            }));
        } else if (doc?.type === "tax_invoice") {
            setEditting(true);
            setProducts((doc as ProductNote).products.map((p) => {
                return {
                    ...p
                };
            }));
            setPayments((doc as PayNote).payments.map((p) => {
                return {
                    ...p
                };
            }));
        } else if (doc?.type === "visit_note") {
            setEditting(true);
        }
    }, [doc]);
    const onProductChangeMain = React.useCallback((product: IItem) => {
        setProducts((prevProducts) => {
                let seen = false;
                const x = prevProducts.map((p) => {

                        if (product.id === p.id) {
                            seen = true;
                            return product;
                        }
                        return p;
                    }
                );
                if (!seen) {
                    x.push(product);
                }
                return x;

            }
        );
    }, []);

    const onProductChange = (editting) ? onProductChangeMain : undefined;
    const onPaymentChangeMain = React.useCallback(
        (payment: Payment) => setPayments((prevPayments) => prevPayments.map((pmt) => (payment.id === pmt.id ? payment : pmt))),
        []
    );
    const onPaymentChange = (editting) ? onPaymentChangeMain : undefined;

    const invoiceAndRecepitDoc = {
        ...doc, payments: ((editting) ?
            (payments) : (doc as PayNote).payments),
        products: ((editting) ?
            (products) : (doc as ProductNote).products)
    }

    return (
        <Box id='custom-target'>
            <MainCard>
                {(icons) ? <Toolbar
                    sx={{
                        p: 0,
                        pl: 1,
                        pr: 1
                    }}
                >
                    <Box sx={{flexGrow: 1}}/>
                    {ExtraOptions && <ExtraOptions/>}

                    {doc?.type === "invoice" ? (
                    <Tooltip title={""}>
                        <IconButton size='medium' style={{color: 'gray'}} onClick={(e) => {
                            // CALLBACK
                        }}>
                            <MoneyOutlined/>
                        </IconButton>
                    </Tooltip>
                    ) : null}

                    {(canEdit) ? <>

                    {!editting ? <Tooltip title={<FormattedMessage id='Edit'/>}>
                            <IconButton size='medium' style={{color: 'gray'}} onClick={(e) => {
                                setStartEdit();
                            }}>
                                <EditIcon/>
                            </IconButton>
                    </Tooltip> :  !withDiscount ||  (withDiscount && JSDB().getUser().first.note_price == 4) ? <IconButton size='medium' style={{color: 'red'}} onClick={(e) => {
                            updateCustomerDocument.mutate({ ...internalEdit , products: products, payments: payments});
                            setEditting(false);
                        }}>
                            <SaveSharpIcon color='error' />
                        </IconButton>:
                        <ApproveNoteDialog  approve={(specific,general)=>{

                            updateCustomerDocument.mutate({ ...internalEdit , products: products, payments: payments, savePriceInSpecificNote: specific, savePriceInGeneral: general});
                            setEditting(false);
                        }}/>}
                    </>

                        : undefined}
                    {withSign ? <ShowImageDialog calcFunction={() => {
                        return getDocumentSign(doc!);
                    }} icon={<BorderColorIcon/>} title={'חתימה בשטח'}
                                                 tooltip={'חתימה דיגיטלית'}
                                                 subtitle={`נחתם בתאריך ${doc.nkObject.signData().date} ע''י ${doc.nkObject.signData().signer} `}></ShowImageDialog> : undefined}

                    <ShowImageDialog
                    setWithPhoto={setWithPhoto}
                    calcFunction={() => {
                        return getDocumentPhoto(doc!);
                    }}
                    icon={<CameraAltIcon style={{
                        color: withPhoto ? 'green' : 'gray'
                    }}/>}
                    docs={[doc]}
                    title={'צילום בשטח'}
                    tooltip={withPhoto ? 'צילום בשטח' : "אין צילום"}
                    subtitle={withPhoto ? `צולם בתאריך ${doc?.nkObject?.signData()?.date ?? "לא יודע"}` : "אין צילום"}></ShowImageDialog>

                    {doc?.entId ? <Tooltip title={'שלח אימייל'}>
                        <GenerativeLoader disabled={JSDB().getClient(Number(doc.entId)).first?.getEmail() == ''}
                                          func={(setLoader) => {
                                              getFile(false, () => getDocumentPdf(doc!, true), doc!.title, undefined, undefined, setLoader, async (b, t) => {
                                                  const email = JSDB().getClient(Number(doc!.entId)).first?.getEmail()

                                                  if (email != "") {
                                                      const x = sendPdfEmail(b, email, t)
                                                      setSend(await x)
                                                      return x
                                                  } else {
                                                      return false
                                                  }
                                              });
                                          }} icon={<Email color={send ? 'success' : 'error'}
                                                          sx={{width: '24px', height: '24px'}}/>}/>

                    </Tooltip> : undefined}
                    {Number(doc?.entId) ? <Tooltip title={'שלח בוואטסאפ'}>
                        <GenerativeLoader
                        disabled={JSDB().getClient(Number(doc.entId)).first?.phone == '' || JSDB().getClient(Number(doc.entId)).first?.phone.length < 10}
                                          func={(setLoader) => {
                                            //   getFile(false, () => getDocumentPdf(doc!, true), doc!.title, undefined, undefined, setLoader, async (b, t) => {
                                            //       const phone = JSDB().getClient(Number(doc!.entId)).first?.phone

                                            //       if (phone != "") {
                                            //         // ADD POHNE CALLBACK
                                            //         //   const x = sendPdfEmail(b, email, t)
                                            //         //   setSend(await x)
                                            //         //   return x
                                            //       } else {
                                            //           return false
                                            //       }
                                            //   });
                                              setSelectedCustomersSend3 && setSelectedCustomersSend3({
                                                selected: [doc?.entId],
                                                defaultPlatform: 'whatsapp',
                                                files: {
                                                    ids: [doc?.id],
                                                    types: [doc?.nkObject.getConnectedDocType()],
                                                },
                                                msg: 'מסמכים',
                                                subject: 'שליחת מסמכים',
                                            })
                                          }} icon={<WhatsApp
                                                //color={send ? 'success' : 'disabled'}
                                                sx={{
                                                    width: '24px',
                                                    height: '24px',
                                                    color: "#25D366"
                                                }}
                                                          />}/>

                    </Tooltip> : undefined}

                    <Tooltip title={<FormattedMessage id='Download'/>}>
                        <GenerativeLoader func={(setLoader) => {
                            if (doc) {
                                if (doc.type == 'supplier_note') {
                                    getFile(false, () => buildSupNotesSum([doc], undefined), `מסמכים - ${doc.entName}`, 'application/pdf', undefined, setLoader);
                                } else {
                                    getFile(false, () => getDocumentPdf(doc!, makor), `${doc.title} - ${doc.entName}`, "application/pdf", undefined, setLoader);
                                }
                            }

                        }} icon={<Avatar variant='square'
                                         sx={{cursor: 'pointer', bgcolor: 'white', width: '24px', height: '24px'}}
                                         src={pdf}/>}/>

                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='Print'/>}>
                        <GenerativeLoader func={(setLoader) => {

                            if (doc) {
                                if (doc.type == 'supplier_note') {
                                    getFile(true, () => buildSupNotesSum([doc], undefined), 'מסמכים', 'application/pdf', undefined, setLoader);
                                } else {
                                    getFile(true, () => getDocumentPdf(doc!, makor), doc.title, undefined, undefined, setLoader);
                                }
                            }
                        }} icon={<PrintIcon color='primary' sx={{width: '24px', height: '24px'}}/>}/>

                    </Tooltip>
                    <Chip label={makor ? 'מקור' : 'העתק'} chipcolor={'success'} size='small' onClick={(e) => {
                        setMakor(prevState => !prevState)
                    }}/>
                    {(canActivate) ? <Tooltip style={{marginRight: '20px'}} title='החזר מסמך' onClick={() => {
                        const newDoc = {...doc, status: 'open'};
                        createCustomerDocument.mutate({doc:newDoc});
                    }}>
                        <IconButton style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #ccc',
                            borderRadius: '25px',
                            marginRight: '40px',
                            marginLeft: '-10px',
                            padding: '5px',
                        }} size='medium' >
                            <CheckCircleOutlineIcon color='success'/>
                            <span style={{
                                fontSize: '15px',
                                margin: '0 5px',
                            }}>החזר מסמך</span>
                        </IconButton>
                    </Tooltip> : undefined}
                    {canCancel ? <CancelNoteDialog
                        curDate={(doc.type == 'delivery_certificate' || doc.type == 'order' || doc.type == 'supplier_note') ? undefined : doc.date.getMonth() != (new Date()).getMonth() ? new Date() : doc.date}
                        cancelFunc={(date) => {
                            const newDoc = {...doc, status: 'canceled'};
                            createCustomerDocument.mutate({doc: newDoc, changeDate: date});
                        }
                        }/> : undefined}


                </Toolbar> : undefined}

                {(doc.type === 'receipt') ? (
                    <ReceiptPreview
                    creditRecepitValue={doc.creditRecepitValue}
                    setCreditRecepitValue={doc.setCreditRecepitValue}
                setDocFromBot = {setDocFromBot}
                     onPaymentChange={onPaymentChange}
                                    {...doc as PayNote}
                                    payments={((editting) ?
                                        (payments) : (doc as PayNote).payments)}/>
                ) : doc.type === 'delivery_certificate' || doc.type === 'order' || doc.type === 'supplier_note' ? (
                        <DeliveryCertificatePreview outerDoc={outerDoc} onProductChange={onProductChange}

                                                    {
                                                        ...doc as ProductNote
                                                    }
                                                    products={((editting) ?
                                                        (products) : (doc as ProductNote).products)}
                                                    setInternalEdit={setInternalEdit}
                                                    internalEdit={internalEdit}
                                                    editting={editting}
                                                    pool={pool}
                                                    collect={collect}
                        />
                    )
                    : doc.type === 'refund' ? (
                        <InvoicePreview outerDoc={outerDoc} {...doc as TaxNote} pool={pool} />
                    ) : doc.type === 'debt' ? (
                        <DebtPreview {...doc} />
                    ) : doc.type === 'visit_note' ? (
                        <VisitPreview {...doc} editting={editting} setInternalEdit={setInternalEdit} pool={pool}
                                      internalEdit={internalEdit}/>
                    ) : doc.type === 'tax_invoice' ? (
                        <>
                            {/* <ReceiptPreview onPaymentChange={onPaymentChange}
                                {...doc as PayNote}
                                payments={((editting) ?
                                    (payments) : (doc as PayNote).payments)} />
                            <InvoicePreview {...doc as TaxNote} /> */}
                            <InvoiceAndReciptPreview outerDoc={outerDoc} onProductChange={onProductChange} pool={pool}             setInternalEdit={setInternalEdit}
                                                     internalEdit={internalEdit}  editting={editting}
                                                     onPaymentChange={onPaymentChange} {...invoiceAndRecepitDoc} />
                        </>
                    ) : (
                        <InvoicePreview
                        outerDoc={outerDoc}
                            pool={pool}
                        setInternalEdit={setInternalEdit}
                        internalEdit={internalEdit}
                        {...doc as TaxNote}
                        editting={editting}/>
                    )}
            </MainCard>
            {selectedCustomersSend2!=undefined ? <SendMessageDialog
                open={selectedCustomersSend2!=undefined}
                setOpen={(open)=>setSelectedCustomersSend2(undefined)}

                toSelected={selectedCustomersSend2?.selected??[]}
                files={selectedCustomersSend2?.files??[]}
                msg={selectedCustomersSend2?.msg??""}
                subject={selectedCustomersSend2?.subject??""}
                defaultPlatform={selectedCustomersSend2?.defaultPlatform??"email"}
                withFiles={false}

            /> :undefined}
        </Box>
    );
};


export default memo(DocumentPreview);

import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    Autocomplete,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography
} from '@mui/material';
import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';
import {gridSpacing} from 'store/constant';
import PhonelinkRingTwoToneIcon from '@mui/icons-material/PhonelinkRingTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import Customer from 'types/customer';
import CustomerAddress from '../properties/CustomerAddress';
import CustomerStatus from '../properties/CustomerStatus';
import AddCustomerData from '../AddCustomerData';
import PeopleIcon from '@mui/icons-material/People';
import {CommentOutlined} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {selectDriversNames} from '../../../store/agents/agentsSlice';
import {updateDriver} from '../../../api/customer/documents.api';
import {SNACKBAR_OPEN} from '../../../store/actions';
import Swal from 'sweetalert2';
import {getSpecificCustomer} from '../../../store/customers/customersSlice';
import {useTheme} from '@mui/material/styles';
import {memo} from "react";

function CustomerProfile({ customer }: { customer: Customer }) {
    const driver = useSelector(selectDriversNames);
    const dispatch = useDispatch();
    const theme = useTheme();
    const [driverName, setDriver] = React.useState<string | null | undefined>(customer?.driver);
    React.useEffect(() => {
        setDriver(customer?.driver)
    }, [customer]);
    const saveDriver = React.useCallback(
        async (driver) => {
            try {

                await updateDriver(customer.id, driver, true);
                dispatch(getSpecificCustomer(Number(customer.id)));
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    message: `לקוח עודכן בהצלחה`,
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            } catch (e) {
                console.log(e);
                Swal.fire({
                    title: 'שגיאה בגישה לנתונים',
                    target: '#custom-target',
                    text: '',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#d63030'
                });
            }
        },
        [customer]
    );
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={6} xs={6}>
                <SubCard
                    title={
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item>
                                <Avatar alt='User 1' />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography align='left' variant='subtitle1'>
                                    {customer.name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CustomerStatus customer={customer} />
                            </Grid>
                        </Grid>
                    }
                >
                    <List component='nav' aria-label='main mailbox folders'>
                        {(customer?.nkObject?.branch ?? -1) >= 0 ? <ListItemButton>
                            <ListItemIcon>
                                <PeopleIcon style={{ color: customer?.nkObject?.master === -1 ? 'blue' : undefined }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant='subtitle1' fontWeight='bold'>
                                        רשת
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Typography variant='subtitle2' align='right'>

                                    {customer.businessName}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton> : undefined}
                        {customer.comments && customer.comments.length > 0 ? <ListItemButton>
                            <ListItemIcon>
                                <CommentOutlined sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant='subtitle1' fontWeight='bold'>
                                        הערות : {customer.comments}
                                    </Typography>
                                }
                            />
                        </ListItemButton> : undefined}

                        <ListItemButton>
                            <ListItemIcon>
                                <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant='subtitle1' fontWeight='bold'>
                                        <FormattedMessage id='email' />
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Typography variant='subtitle2' align='right'>
                                    {customer.email}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <PhonelinkRingTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant='subtitle1' fontWeight='bold'>
                                        <FormattedMessage id='phone' />
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Typography variant='subtitle2' align='right'>
                                    {customer.phone}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <PinDropTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant='subtitle1' fontWeight='bold'>
                                        <FormattedMessage id='address' />
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <CustomerAddress customer={customer} />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        {driver.length > 0 ? <>
                            <Divider />
                            <ListItemButton>
                                <ListItemIcon>
                                    <PinDropTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant='subtitle1' fontWeight='bold'>
                                            נהג
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <Autocomplete
                                        id='driver'
                                        value={driverName}
                                        onChange={(event, value) => {
                                            if (value) Swal.fire({
                                                title: 'שינוי נהג גורף',
                                                text: 'שינוי נהג לכל הבסיסים ותאריך מהיום',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: theme.palette.primary.dark,
                                                cancelButtonColor: theme.palette.error.light,
                                                confirmButtonText: 'שנה',
                                                cancelButtonText: 'בטל',
                                                allowEnterKey: true,
                                                allowEscapeKey: true,
                                                keydownListenerCapture: true,
                                                focusConfirm: true
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    saveDriver(value);
                                                }
                                            });
                                        }}

                                        options={driver ?? []}
                                        fullWidth
                                        autoHighlight

                                        renderInput={(params) => (
                                            <TextField
                                                style={{ width: 200 }}
                                                {...params}
                                                label=''
                                                variant='standard'
                                            />
                                        )}
                                    />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </> : undefined}

                    </List>
                </SubCard>
            </Grid>
            <Grid item lg={6} xs={6}>
                <AddCustomerData handleDrawerOpen={() => {
                }} id={customer.id} open editable={true} />
            </Grid>
        </Grid>
    );
}

export default memo(CustomerProfile);

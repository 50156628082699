import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Customer from 'types/customer';

const API = 'http://localhost:3000/fakeApi';

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: API }),
    tagTypes: ['Customer'],
    endpoints: (builder) => ({
        getCustomers: builder.query({
            query: () => '/customers',
            providesTags: (result = [], error, arg) => [
                'Customer',
                ...(result.customers?.map(({ id }: { id: string }) => ({
                    type: 'Customer',
                    id
                })) ?? [])
            ]
        }),
        addCustomer: builder.mutation({
            query: (c) => ({ url: '/customers', method: 'POST', body: c }),
            async onQueryStarted({ cid, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getCustomers', undefined, (draft) => {
                            const customer: Customer = data.customer;
                            draft.customers.unshift(customer);
                        })
                    );
                } catch (e) {
                    console.log(e);
                }
            }
        }),
        updateCustomer: builder.mutation({
            query: ({ cid, ...patch }) => ({
                url: `/customers/${cid}`,
                method: 'PATCH',
                body: patch
            }),
            async onQueryStarted({ cid, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getCustomers', undefined, (draft) => {
                            const customer: Customer = data.customer;
                            draft.customers = draft.customers.reduce(
                                (acc: Customer[], c: Customer) => (c.id === customer.id ? acc.concat(customer) : acc.concat(c)),
                                []
                            );
                        })
                    );
                } catch (e) {
                    console.log(e);
                }
            }
        }),
        deleteCustomer: builder.mutation({
            query: ({ id, ...patch }) => ({
                url: `/customers/${id}`,
                method: 'DELETE',
                body: patch
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getCustomers', undefined, (draft) => {
                            draft.customers = draft.customers.filter((c: Customer) => c.id !== id);
                        })
                    );
                } catch (e) {
                    console.log(e);
                }
            }
        })
    })
});

export const { useGetCustomersQuery, useAddCustomerMutation, useUpdateCustomerMutation, useDeleteCustomerMutation } = apiSlice;

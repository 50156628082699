import { applyColumnState, costumFunction } from '../ag-table-utils';
import { ParamsType } from '../interfaces';

/**
 * to set the table state from a saved local storage
 * @param state - column state, tableRef?.current?.columnApi?.getColumnState();
 * @param gstate - group state, tableRef?.columnApi?.getColumnGroupState()
 * @param tableRef - tableReference
 * @param filters -  tableRef?.current?.api?.getFilterModel();

 */
export const loadSavedStates = (state, gstate, tableRef, filters) => {
    try {
        applyColumnState(state, tableRef);
        if (gstate?.length) {
            tableRef?.current?.columnApi?.resetColumnGroupState();
            tableRef?.current?.columnApi?.setColumnGroupState(gstate);
        }
        tableRef?.current?.api?.setFilterModel({});
        tableRef?.current?.api?.setFilterModel(filters);
        setTimeout(() => {
            tableRef?.current?.api?.onFilterChanged();
        }, 100);
    } catch (e) {
        console.log('error', e);
    }
};

export const costumAggFunctions = (costumFunc) => {
    const x = costumFunction();
    if (costumFunc) {
        Object.keys(costumFunc).forEach((key) => {
            x[key] = costumFunc[key];
        });
    }
    return x;
};

export const calculateTableHeight = (tableId, isPhone) => {
    const table = document.getElementById(tableId);
    if (table) {
        const rect = table.getBoundingClientRect();
        const height = isPhone ? window.outerHeight : window.innerHeight;
        return height - rect.top - 20;
    }
    return 0;
};

export const timeoutWrapperFunction = (func) => {
    setTimeout(() => {
        func();
    }, 500);
};

export function removeDuplicatesById(data) {
    const uniqueDataMap = new Map();

    data.forEach((item) => {
        if (!uniqueDataMap.has(item.id)) {
            uniqueDataMap.set(item.id, item);
        }
    });

    return Array.from(uniqueDataMap.values());
}

// processors..

export function processCellValueChange(
    params: ParamsType,
    editList: any[],
    statusBarData: any,
    setStatusBarData: (data: any) => void,
    setEditMode: (mode: boolean) => void,
    setEditList: (callback: (prevState: any[]) => any[]) => void,
    menuiItems: { [key: string]: { label: string; value: any }[] } | undefined,
    setValueAlert: (value: boolean) => void,
    onCellEditingStoppedQ: (params: ParamsType) => void
) {
    if (params?.source === 'undo') {
        const isInEditList = editList?.find((node) => node?.id === params?.node?.id);
        if (isInEditList) {
            let noChanges = true;
            Object.keys(params?.node?.data).forEach((key) => {
                if (params?.node?.data[key] !== isInEditList.data[key]) {
                    noChanges = false;
                }
            });
            if (noChanges) {
                setStatusBarData({ ...statusBarData, editedRows: statusBarData.editedRows - 1 });
                if (statusBarData.editedRows === 1) {
                    setEditMode(false);
                }
                setEditList((prevState) => prevState.filter((node) => node?.id !== params?.node?.id));
                if (params?.data?.hasBeenEdited) {
                    delete params?.data?.hasBeenEdited;
                }
                params.api.refreshCells({ force: true });
            }
        }
    }

    if (params?.source === 'paste') {
        const colId = (params?.colDef as any)?.id;
        if (menuiItems?.[colId]) {
            const isValueInList = menuiItems?.[colId]?.find((item) => {
                if (typeof item.value === 'boolean' && (params?.newValue === 'true' || params?.newValue === 'false')) {
                    return true;
                }
                return item.value == params?.newValue;
            });
            if (!isValueInList) {
                params.node.setDataValue(params.column, params.oldValue);
                setValueAlert(true);
                return;
            }
        }

        onCellEditingStoppedQ(params);
    }

    if (params?.source === 'rangeService') {
        onCellEditingStoppedQ(params);
    }
}

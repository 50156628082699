import React, {memo} from 'react';

import 'sweetalert2/src/sweetalert2.scss';
// material-ui
import {Drawer} from '@mui/material';

// third party
// project imports
import AddCustomerData from './AddCustomerData';

// types
export interface AddCustomerProps {
    editable?: boolean;
    open: boolean;
    handleDrawerOpen: () => void;
    id: string;
    setPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateCallBack?: () => void;
    branches?: any[];
}

const AddCustomer = ({ editable = false, open, handleDrawerOpen, id, setPreviewOpen,updateCallBack, branches }: AddCustomerProps) => {

    return (
        <Drawer id='custom-target'
                sx={{
                    ml: open ? 3 : 0,
                    flexShrink: 0,
                    zIndex: 1200,
                    overflowX: 'hidden',
                    width: { xs: 320, md: 450 },
                    '& .MuiDrawer-paper': {
                        height: '100vh',
                        width: { xs: 320, md: 450 },
                        position: 'fixed',
                        border: 'none',
                        borderRadius: '0px'
                    }
                }}
                variant='temporary'
                anchor='right'
                open={open}
                ModalProps={{ keepMounted: true }}
                onClose={handleDrawerOpen}
        >
           <AddCustomerData setPreviewOpen={setPreviewOpen} handleDrawerOpen={handleDrawerOpen} id={id} open editable={editable} updateCallBack={updateCallBack} branches={branches} />
        </Drawer>
    );
};
export default memo(AddCustomer);

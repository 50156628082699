import {CircularProgress, IconButton, Tooltip, Typography} from '@mui/material';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {byteArrayToImg, saveByteArray} from '../../fromKotlin/nkutils';
import {callbackNetworkResult} from '../../api/customer/files.api';
import DownloadIcon from '@mui/icons-material/Download';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import DocumentPreview from "../../views/customer/profile/documents/DocumentPreview";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import {ExtendedDocument} from "../../types/DocumentsTypes/Document";
import {CUSTOMER_DOCUMENTS, refreshDocs} from "../../hooks/customer/documents.hooks";
import IItem from "../../types/product_delivery";
import {memo} from "react";
import { UploadFile } from '@mui/icons-material';
import { DeviceFloppy } from 'tabler-icons-react';
import {uploadDocumentPhoto} from "../../api/customer/documents.api";

function ShowImageDialog({
                                            title,
                                            subtitle,
                                            icon,
                                            tooltip,
                                            calcFunction,
                                            docs,
                                            setWithPhoto
                                        }: { title: string, subtitle: string, icon, tooltip, calcFunction: (d) => Promise<any>, docs?: ExtendedDocument[], setWithPhoto?: (b: boolean) => void }) {
    const [open, setOpen] = React.useState(false);
    const [imgSrc, setImgSrc] = React.useState<string | undefined>(undefined);
    const [rotate, setRotate] = React.useState<number>(0);
    const [pos, setPos] = React.useState<number>(0);
    const [imgByteArray, setImgByteArray] = React.useState<any | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [newImg, setNewImg] = React.useState<boolean>(false);
    const refresh = refreshDocs()
    const imgCall = React.useCallback((result) => {
        setImgSrc(byteArrayToImg(result));
        setImgByteArray(result)
    }, []);
    const handleClickOpen = React.useCallback(() => {
        setPos(0)
        callbackNetworkResult(() => calcFunction(docs ? docs[0] : undefined), imgCall)
        setOpen(true);
    }, [docs]);

    const handleClose = () => {
        setOpen(false);
    };
    React.useEffect(() => {
        if (open)
            callbackNetworkResult(() => calcFunction(docs ? docs[pos] : undefined), imgCall)
    }, [pos]);
    React.useEffect(() => {
        if (setWithPhoto)
            setWithPhoto(imgByteArray !== undefined)
    }, [imgByteArray]);
    return (
        <div>
            <Tooltip title={tooltip}>
                <IconButton size='small' onClick={handleClickOpen}>
                    {icon}
                </IconButton>
            </Tooltip>
            <Dialog id='helper_dialog'
                    fullWidth={true}
                    maxWidth={'xl'}
                    open={open} onClose={handleClose}>
                <DialogTitle> {title}
                    <Tooltip title={tooltip}>
                        <IconButton size='small' onClick={(e) => {
                            if (imgByteArray)
                                saveByteArray(imgByteArray, 'תמונה', 'image/jpeg');
                        }}>
                            <DownloadIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"העלאת תמונה"}>
                        <IconButton size='small' onClick={(e) => {
                            const uploadInput = document.getElementById('image-upload-for-documnet');
                            if (uploadInput) {
                                uploadInput.click();
                            }
                        }}>
                            <input type="file" id="image-upload-for-documnet" style={{display: 'none'}}
                                      onChange={(e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                                const file = e.target.files[0];
                                                const reader = new FileReader();

                                                reader.readAsArrayBuffer(file);
                                                reader.onload = function (e) {
                                                    const arrayBuffer = e?.target?.result;
                                                    const byteArray = new Int8Array(arrayBuffer as ArrayBufferLike);
                                                    setImgSrc(byteArrayToImg(byteArray));
                                                    setImgByteArray(byteArray);
                                                    setNewImg(true)
                                                }
                                            }
                                        }}/>
                            <UploadFile />
                        </IconButton>
                    </Tooltip>
                    {newImg && imgByteArray && docs?
                        <Tooltip title={'שמור'}>
                            <IconButton  size='small' onClick={(e) => {
                                setLoading(true);
                                uploadDocumentPhoto(docs[pos],imgByteArray, ).then((result) => {
                                    setLoading(false);
                                    if (result) {
                                        alert('התמונה נשמרה בהצלחה');
                                        setNewImg(false)
                                        refresh.mutate({ids:[docs[pos].id],types:[docs[pos].nkObject.getConnectedDocType()], offline:true})
                                    } else {
                                        alert('התמונה לא נשמרה');
                                    }
                                })
                            }}>
                                {
                                    loading ? <CircularProgress size={20}/> : <DeviceFloppy color={'red'}/>
                                }
                            </IconButton>
                        </Tooltip>
                        : undefined
                    }
                    <Tooltip title={'סיבוב תמונה'}>
                        <IconButton size='small' onClick={(e) => {
                            setRotate(prevState => (prevState + 1) % 4)
                        }}>
                            <CameraswitchIcon/>
                        </IconButton>
                    </Tooltip>
                    {docs && pos > 0 ? <Tooltip title={'מסמך קודם'} style={{float: 'right'}}>
                        <IconButton size='small' onClick={(e) => {
                            setPos(prevState => Math.max(0, prevState - 1))
                        }}>
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    </Tooltip> : undefined}
                    {docs && pos < docs.length - 1 ? <Tooltip title={'מסמך הבא'} style={{float: 'left'}}>
                        <IconButton size='small' onClick={(e) => {
                            setPos(prevState => Math.min(docs.length - 1, prevState + 1))
                        }}>
                            <ArrowBackIosNewIcon/>
                        </IconButton>
                    </Tooltip> : undefined}

                </DialogTitle>
                <DialogContent>
                    <Typography> {subtitle} </Typography>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        {imgSrc ?
                        <Zoom>
                            <img src={imgSrc} style={{
                                height: window.outerHeight - 100,
                                width: window.outerWidth / 2,
                                transform: `rotate(${rotate * 90}deg)`
                            }}>
                            </img>
                        </Zoom>
                        : null}
                        <div>
                            {docs ? <DocumentPreview document={docs[pos]}/> : undefined}
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default memo(ShowImageDialog);

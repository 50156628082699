import React, {memo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store';
import {getCustomers, getSpecificCustomer, selectCustomerById} from 'store/customers/customersSlice';
import {useCreateCustomerDocument, useGetSpecificCustomerDocuments} from 'hooks/customer/documents.hooks';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Alert,
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    Dialog,
    Fade,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import LoadingButton from '@mui/lab/LoadingButton';

// third party
import {EventHoveringArg} from '@fullcalendar/react';

// assets
import EventIcon from '@mui/icons-material/Event';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

// locale
import {addDays, endOfMonth, format, isBefore, startOfMonth, subDays} from 'date-fns';
import {enUS, he} from 'date-fns/locale';
import {FormattedMessage} from 'react-intl';

// project imports
import config from 'config';
import CustomerProductsList from 'views/customer/profile/products/CustomerProductsList';
import DocumentCustomerDetails from '../DocumentCustomerDetails';
import DocumentPreview from '../DocumentPreview';

// types
import {Check, createDocument, Details, Payment} from 'types/DocumentsTypes/utils';

import IItem from 'types/product_delivery';

import Swal from 'sweetalert2';
import JSDB, {NKTypeConvertor} from '../../../../../fromKotlin/nk';
import getCustomerProductsPrices from '../../../../../api/customer/products_prices.api';
import {dateObjectToString, roundDecimals} from '../../../../../fromKotlin/nkutils';
import {TaxNote} from '../../../../../types/DocumentsTypes/TaxNote';
import {DocumentType, ExtendedDocument, ProductNote} from '../../../../../types/DocumentsTypes/Document';
import {collect, getClientChecks} from '../../../../../api/customer/documents.api';
import {RangeInput} from '@mui/lab/DateRangePicker/RangeTypes';
import {selectUser} from '../../../../../store/user/userSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoNegative, {WithNegative} from '../../../../../ui-component/textFieldNumber';
import CalendarContainer from 'ui-component/calendar-container/calendar-container';
import {IconCirclePlus, IconCircleMinus, IconCurrencyShekel} from '@tabler/icons';
import {PaymentComp} from '../receipt/NewReceipt';
import {nanoid} from '@reduxjs/toolkit';
import {useQueryClient} from "react-query";

interface TaxNoteChangeValues {
    notesValue: number;
    notesTax: number;

    productsValue: number;
    productsTax: number;

    // LOOK HERE
    handArray?: {
        value: number;
        tax: number;
        withoutMaam: boolean;
        amount: number;
        description: string;
    }[];

    handValue: number;
    handTax: number;

    roundValue: number;
    roundTax: number;

    beforeValue: number;
    preValue: number;
    totalTax: number;
    mainValue: number;
    discount: number;
    discount_sum: number;

}

const HoverableEvent = ({
                            hoverData,
                            included,
                            onMouseLeaveHandler,
                            onAddDeliveryCertificate,
                            onRemoveDeliveryCertificate
                        }) => {
    const [previewDialog, setPreviewDialog] = React.useState(false);
    const handlePreviewOpen = () => setPreviewDialog(true);
    const handlePreviewClose = () => setPreviewDialog(false);

    const handleAddDocument = () => onAddDeliveryCertificate(hoverData.document);
    const handleRemoveDocument = () => onRemoveDeliveryCertificate(hoverData.document);

    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                position: 'absolute',
                top: hoverData.top,
                right: hoverData.right,
                zIndex: 10
            }}
            onMouseLeave={onMouseLeaveHandler}
        >
            <Fade in={hoverData.open} timeout={{enter: 200, exit: 100}}>
                {included ? (
                    <Tooltip
                        title={
                            <>
                                <FormattedMessage id='Remove'/> <FormattedMessage id='Delivery Certificate'/>
                            </>
                        }
                    >
                        <IconButton size='small' color='success' onClick={handleRemoveDocument}>
                            <CheckCircleOutlineOutlinedIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={
                            <>
                                <FormattedMessage id='Choose'/> <FormattedMessage id='Delivery Certificate'/>
                            </>
                        }
                    >
                        <IconButton size='small' onClick={handleAddDocument}>
                            <CheckCircleOutlineOutlinedIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                )}
            </Fade>
            <Fade in={hoverData.open} timeout={{enter: 100, exit: 200}}>
                <Tooltip title={<FormattedMessage id='View'/>}>
                    <IconButton size='small' onClick={handlePreviewOpen}>
                        <VisibilityOutlinedIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </Fade>
            <Dialog open={previewDialog} onBackdropClick={handlePreviewClose}>
                <DocumentPreview document={hoverData.document as ExtendedDocument}/>
            </Dialog>
        </Box>
    );
};
const icon = <CheckBoxOutlineBlankIcon fontSize='small'/>;
const checkedIcon = <CheckBoxIcon fontSize='small'/>;
const NewInvoice = ({
                        upid,
                        dtype,
                        narrow,
                        productsFromAbove,
                        refId
                    }: { upid: string, dtype: DocumentType, narrow: boolean, productsFromAbove?: IItem[], refId?:number[]  }) => {

    const theme = useTheme();
    const {id} = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const documentType = dtype ? dtype : (location.pathname.endsWith('Invoice') ? 'invoice' : 'refund');
    // TODO: Change to single customer fetching
    const customer = useSelector((state: RootState) => selectCustomerById(state, id ?? upid));
    React.useEffect(() => {
        if (!customer) dispatch(getSpecificCustomer(Number(id ?? upid)));
    }, [customer, dispatch]);
    const user = useSelector((state: RootState) => selectUser(state));
    const createCustomerDocument = useCreateCustomerDocument(id ?? upid);
    const [date, setDate] = React.useState<Date>(new Date());
    const [products, setProducts] = React.useState<IItem[]>([]);
    const [getAllProducts, setGetAllProducts] = React.useState<boolean>(false);
    const [commentControl, setCommentControl] = React.useState<any>({
        notes: '',
        notes2: '',
        notes3: '',
        notes4: '',
        notes5: '',
    });
    const [manuelText, setManualText] = React.useState<string>('סכום ידני');
    const [roundTaxNote, setRound] = React.useState<boolean>(parseInt(localStorage.getItem('roundTax') ?? '0') == 1);
    const [autoNum, setAutoNum] = React.useState<number | undefined>(undefined);
    const [sumDiscount, setSumDiscount] = React.useState<number | undefined>(undefined);
    const [details, setDetails] = React.useState<Details>({
        city: '', companyId: '', companyName: '', email: '', id: 0, phone: '', street: '', streetNumber: ''


    });
    React.useEffect(() => {
        if (!customer) {
            dispatch(getCustomers());
            return;
        }
        setDetails({
            city: customer!.city,
            companyId: customer!.businessId,
            companyName: customer!.businessName,
            email: customer!.email,
            id: Number(customer!.id),
            phone: customer!.phone,
            street: customer!.street,
            streetNumber: customer!.streetNumber
        });

    }, [customer, dispatch]);


    const [taxNoteValue, setTaxNoteValue] = React.useState<TaxNoteChangeValues>({
        preValue: 0,
        beforeValue: 0,
        handArray: [
            {
                value: 0,
                tax: 0,
                withoutMaam: user.nkObject.no_tax_p === 1,
                amount: 1,
                description: manuelText
            }
        ],
        handTax: 0,
        handValue: 0,
        notesTax: 0,
        notesValue: 0,
        productsTax: 0,
        productsValue: 0,
        roundTax: 0,
        roundValue: 0,
        totalTax: 0,
        mainValue: 0,
        discount: customer!.discount,
        discount_sum: 0
    });


    React.useEffect(() => {
        if (productsFromAbove) return;
        const genProds = async () => {
            const ps = await getCustomerProductsPrices(id ?? upid, dateObjectToString(date), getAllProducts);
            setProducts(ps);
        };
        genProds();

    }, [date, getAllProducts]);

    const onProductChange = React.useCallback((product: IItem) => {
        setProducts((prevProducts) => {
                let seen = false;
                const x = prevProducts.map((p) => {

                        if (product.id === p.id) {
                            seen = true;
                            return product;
                        }
                        return p;
                    }
                );
                if (!seen) {
                    x.push(product);
                }
                return x;

            }
        );
    }, []);
    const clearProducts = React.useCallback(() => {
        setProducts((prevProducts) => prevProducts.map((p) => ({...p, quantity: 0, quantityReturns: 0})));
    }, []);
    const onProductChangeReal = (createCustomerDocument.data) ? undefined : onProductChange;


    const collectValues = React.useCallback((t: TaxNoteChangeValues) => {
        let handValue = 0
        let handTax = 0
        if (t.handArray) {
            t.handArray.forEach((item) => {
                handValue += (item.value) * item.amount + ((!customer!.includeTax) ? ((item.tax) * item.amount) : 0)
                handTax += (item.tax) * item.amount

            })
        }
        const total_value = t.productsValue + t.handValue + t.notesValue + handValue;

        let total_tax = t.handTax + t.productsTax + t.notesTax + handTax;
        let total_before = roundDecimals(total_value - total_tax, 4);
        let discountPercent = t.discount_sum && t.discount_sum > 0 ? roundDecimals((1 - ((total_value - t.discount_sum) / total_value)) * 100, 4) : t.discount

        let total_value_after_discount = total_value * (1 - discountPercent / 100);
        let roundTax = 0;
        let round = 0;
        if (roundTaxNote) {
            const out = JSDB().calculateRoundingTax(total_before, total_value_after_discount, discountPercent, dateObjectToString(date));
            roundTax = roundDecimals(out.second, 4);
            total_tax += roundTax;
            round = roundDecimals(out.first, 4);
            total_before = roundDecimals(total_before + out.first - out.second, 4);
        }
        const preValue = total_before;
        total_tax *= (1 - discountPercent / 100);
        total_before *= (1 - discountPercent / 100);
        total_value_after_discount = (total_tax + total_before);
        const newT = {
            ...t,
            beforeValue: total_before,
            totalTax: total_tax,
            mainValue: total_value_after_discount,
            roundValue: round,
            roundTax,
            preValue,
            discount: discountPercent
        }
        setTaxNoteValue(newT);
        return newT


    }, [date, customer]);

    React.useEffect(() => {

        const pr = products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && p.conversion_ratio > 0));
        if (pr.length === 0) {
            collectValues({
                ...taxNoteValue,
                productsTax: 0, productsValue: 0
            });
            return;
        }
        const result = JSDB().productDeliveryToAmount(NKTypeConvertor().toKotlinList(collect(pr, Number(id ?? upid), true, false)), Number(id ?? upid), dateObjectToString(date));
        collectValues({
            ...taxNoteValue,
            productsTax: roundDecimals(result.second, 4), productsValue: roundDecimals(result.third, 4)
        });

    }, [products]);
    React.useEffect(() => {
        if (productsFromAbove) {

            setTimeout(() => {
                setProducts(productsFromAbove);
            },500)

            // calcProducts(productsFromAbove)
        }
    }, [productsFromAbove]);
    const [openCalendar, setOpenCalendar] = React.useState<boolean>(false);
    const [, setRange] = React.useState<RangeInput<null>>([(subDays(startOfMonth(new Date()), 7)), addDays(endOfMonth(new Date()), 7)]);
    const [receipts, setReceipts] = React.useState<ProductNote[]>([]);
    const [collapseProducts, setCollapseProducts] = React.useState(false);
    const [appliedRef, setAppliedRef] = React.useState(false);
    const [checkDetails, setCheckDetails] = React.useState<Check>();
    const [payments, setPayments] = React.useState<Payment[]>([{
        id: nanoid(),
        amount: 0,
        method: 'cash',
        check: {
            dueDate: new Date(),
            bank: checkDetails?.bank,
            account: checkDetails?.account,
            branch: checkDetails?.branch
        }
    }]);

    React.useEffect(() => {
        const x = async () => {
            const checkDetails = await getClientChecks(Number(id ?? upid));
            setCheckDetails(checkDetails?.check);
            setPayments(prevState => prevState.map((p) => {

                    if (p.check?.account != undefined) {
                        return p;
                    } else {

                        return {
                            ...p, check: {
                                dueDate: new Date(),
                                bank: checkDetails?.check?.bank,
                                account: checkDetails?.check?.account,
                                branch: checkDetails?.check?.branch
                            }
                        };
                    }
                })
            );

        };
        x();
    }, []);

    const dialogRef = React.useRef();
    const cache = useQueryClient();
    // TODO: Get only delivery_certificate using query params
    const pool = "customerDocsForTax"
    React.useEffect(() => {

        cache.removeQueries(pool);

    }, []);
    const {
        data: deliveryNotes,
        isLoading: isLoading,
        refetch
    } = useGetSpecificCustomerDocuments(['delivery_certificate'], Number(id ?? upid), undefined, undefined, true, undefined, true, undefined, undefined, true,undefined, undefined, undefined, undefined, pool );

    React.useEffect(() => {
        if(createCustomerDocument.isSuccess )
            return;

        const pr = receipts;
        if (pr.length === 0) {
            collectValues({
                ...taxNoteValue,
                notesValue: 0, notesTax: 0
            });
            return;
        }
        const result = JSDB().createTaxNoteDataFromNotesNew(Number(id ?? upid), NKTypeConvertor().toKotlinList(pr.map((i) => i.id)));

        collectValues({
            ...taxNoteValue,
            notesValue: result?.first?.value, notesTax: result?.third?.second
        });

    }, [receipts,deliveryNotes]);

    React.useEffect(() => {
        if(isLoading || !deliveryNotes || deliveryNotes.length ==0 || !deliveryNotes.find((dn) => Number(dn.entId)== Number(id ?? upid)))
            return;
        if(!appliedRef && refId){
            let rr = []
            deliveryNotes?.forEach((dn) => {

                refId.find((r) => {
                    if (r === dn.id) {
                        rr.push(dn)
                    }
                })
            })
            setAppliedRef(true)
            setReceipts(rr)
            return;

        }
    }, [deliveryNotes]);

    React.useEffect(() => {
        collectValues({
            ...taxNoteValue,
            discount_sum: Number(sumDiscount),
        });

    }, [sumDiscount]);

    React.useEffect(() => {
        refetch()

    }, [dispatch]);


    React.useEffect(() => {
        collectValues({
            ...taxNoteValue
        });

    }, [roundTaxNote]);


    // hover stuff
    const [hoverData, setOnHoverData] = React.useState({
        open: false,
        top: 0,
        right: 0,
        document: {}
    });


    const onMouseLeaveHandler = React.useCallback(() => setOnHoverData((prev) => ({...prev, open: false})), []);

    const onAddDeliveryCertificate = React.useCallback((d: ProductNote) => setReceipts((prev) => prev.concat(d)), []);
    const onRemoveDeliveryCertificate = React.useCallback(
        (d: ProductNote) => setReceipts((prev) => prev.filter((doc) => doc.id !== d.id)),
        []
    );

    const getOptionLabel = (option) =>
        `${option.title} - ${option.entName} - ${format(option.date, 'PPP', {
            locale: config.i18n === 'he' ? he : enUS
        })} - ${roundDecimals(option.mainValue, 2)} ש''ח `;

    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [previewData, setPreviewData] = React.useState<any>({});
    const onPreviewOpen = React.useCallback((document) => (e) => {
        setPreviewOpen(true);
        setPreviewData(document);
    }, []);
    const onPreviewClose = () => {
        setPreviewOpen(false);
        setPreviewData({});
    }

    const onPaymentChange = React.useCallback(
        (payment: Payment) => setPayments((prevPayments) => prevPayments.map((pmt) => (payment.id === pmt.id ? payment : pmt))),
        []
    );

    const onPaymentAdd = React.useCallback(
        () => setPayments((prevPayments) => prevPayments.concat({
            id: nanoid(),
            amount: 0,
            method: payments[payments.length - 1].method,
            check: {
                dueDate: new Date(),
                bank: checkDetails?.bank,
                account: checkDetails?.account,
                branch: checkDetails?.branch
            }
        })),
        [checkDetails, payments]
    );

    const onPaymentDelete = React.useCallback((pid: string) => setPayments((prevPayments) => prevPayments.filter((p) => p.id !== pid)), []);

    const [displayNotEqualErr, setDisplayNotEqualErr] = React.useState<boolean>(false);
    const [displayEmptyErr, setDisplayEmptyErr] = React.useState<boolean>(false);
    const createNewTaxNote = React.useCallback(() => {
        const newT = collectValues(taxNoteValue)
        Swal.fire({
            title: documentType === 'invoice' ? 'האם תרצה להנפיק חשבונית מס?' : documentType === "tax_invoice" ? "האם תרצה להנפיק חשבונית מס קבלה?" : 'האם תרצה להנפיק חשבונית מס זיכוי',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.dark,
            cancelButtonColor: theme.palette.error.light,
            confirmButtonText: documentType === 'invoice' ? 'הנפק חשבונית מס' : documentType === "tax_invoice" ? "הנפק חשבונית מס קבלה" : 'הנפק חשבונית מס זיכוי',
            cancelButtonText: 'בטל'
        }).then((result) => {
            if (result.isConfirmed) {
                const d = createDocument({
                    comment:commentControl.notes,
                    notes2:commentControl.notes2,
                    notes3:commentControl.notes3,
                    notes4:commentControl.notes4,
                    notes5:commentControl.notes5,
                    id: autoNum,
                    mainValue: newT.mainValue,
                    preValue: newT.preValue,
                    round: newT.roundValue,
                    round_tax: newT.roundTax,
                    hand: newT.handValue,
                    hand_tax: newT.handTax,
                    hand_text: manuelText,
                    agent: JSDB().currentAgent,
                    discount: newT.discount,
                    details,
                    date: date ?? new Date(),
                    type: documentType,
                    deliveryCertificatesIds: receipts.map((d) => d.id),
                    entId: id ?? upid,
                    handArray: newT.handArray,
                    products: products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || (p.quantitySecond > 0 && p.conversion_ratio > 0)),
                    status: 'approved',
                    payments: documentType == 'tax_invoice' ? payments : [],
                })
                if (refId && refId.length>0 && productsFromAbove)
                    d.refId = refId[0]
                createCustomerDocument.mutate(
                    d
                );
            }
        })


    }, [date, customer, payments, products, taxNoteValue, receipts, manuelText, autoNum, details, documentType, id, commentControl]);
    if (!customer || !id && !upid) return <Box/>;
    return (
        <MainCard
            title={
                <Typography variant='h3'>
                    {documentType === 'invoice' ? 'חשבוניס מס' : documentType === 'tax_invoice' ? 'חשבונית מס קבלה' : 'חשבונית מס זיכוי'}


                </Typography>
            }

        >
            <Grid container spacing={3}>
                {createCustomerDocument.data === undefined ?
                    <Grid item xs={7} sx={{
                        minWidth: narrow ? '100%' : undefined,
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <DocumentCustomerDetails customer={customer} date={date} setDate={setDate} setDetails={setDetails} details={details}/>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <TextField
                                    fullWidth
                                    id='comment'
                                    name='comment'
                                    label='הערות'
                                    value={commentControl.notes}
                                    onChange={(e) =>     setCommentControl({...commentControl, notes: e.target.value})}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <NoNegative
                                    fullWidth
                                    id='autoNum'
                                    name='autoNum'
                                    label='מספור ידני'
                                    changer={(v) => {
                                        if (v != 0)
                                            setAutoNum((v));
                                        else
                                            setAutoNum(undefined);
                                    }
                                    }
                                    value={autoNum}


                                />
                            </Grid>
                            {/* // WORK HERE */}
                            <Grid item xs={12} sm={12}>
                                <Typography variant='h4'>
                                    סכום עם תיאור ידני
                                </Typography>
                            </Grid>
                            {
                                taxNoteValue.handArray?.map((item, i) => {
                                    return (
                                        <>
                                            <Grid item xs={3} sm={3}>
                                                <TextField
                                                    id='manualText'
                                                    name='manuelText'
                                                    label='תיאור ידני'
                                                    value={taxNoteValue.handArray?.[i]?.description}
                                                    onChange={(e) => {
                                                        collectValues({
                                                            ...taxNoteValue,
                                                            handArray: taxNoteValue.handArray?.map((item, index) => {
                                                                if (index === i) {
                                                                    return {
                                                                        ...item,
                                                                        description: e.target.value
                                                                    }
                                                                }
                                                                return item;
                                                            })
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <WithNegative id='handleAmount' name='handleAmount' label="כמות"

                                                            value={taxNoteValue.handArray?.[i]?.amount}
                                                            changer={(v) => {

                                                                const value = Number(v);


                                                                collectValues({
                                                                    ...taxNoteValue,
                                                                    handArray: taxNoteValue.handArray?.map((item, index) => {
                                                                        if (index === i) {
                                                                            return {
                                                                                ...item,
                                                                                amount: value
                                                                            }
                                                                        }
                                                                        return item;
                                                                    })
                                                                });
                                                            }}/>
                                            </Grid>
                                            <Grid item xs={2} sm={2}>
                                                <NoNegative id='handValue' name='handValue'
                                                            label={`מחיר${customer!.includeTax ? '(כולל)' : ''}`}

                                                            value={taxNoteValue.handArray?.[i]?.value}
                                                            changer={(v) => {

                                                                const value = Number(v);

                                                                let tax = 0;
                                                                if (!(taxNoteValue.handArray?.[i]?.withoutMaam)) {
                                                                    if (customer!.includeTax)
                                                                        tax = value - value / JSDB().tax.get(dateObjectToString(date));
                                                                    else
                                                                        tax = value * JSDB().tax.getPercentDivided(dateObjectToString(date));
                                                                }
                                                                collectValues({
                                                                    ...taxNoteValue,
                                                                    handArray: taxNoteValue.handArray?.map((item, index) => {
                                                                        if (index === i) {
                                                                            return {
                                                                                ...item,
                                                                                value: value,
                                                                                tax: tax
                                                                            }
                                                                        }
                                                                        return item;
                                                                    })
                                                                });
                                                            }}/>
                                            </Grid>
                                            <Grid item xs={2} sm={2} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            {(user.nkObject.no_tax_p === 1) ? <>
                                                <Typography variant='h5'>ללא מעמ</Typography>
                                                <Checkbox
                                                    checked={taxNoteValue.handArray?.[i]?.withoutMaam} //{handWithoutTax}
                                                    onChange={(v) => {
                                                        //setHandWithoutTax(v.target.checked);
                                                        const value = Number(taxNoteValue.handArray?.[i]?.value);
                                                        let tax = 0;
                                                        if (!v.target.checked) {
                                                            if (customer!.includeTax)
                                                                tax = value - value / JSDB().tax.get(dateObjectToString(date));
                                                            else
                                                                tax = value * JSDB().tax.getPercentDivided(dateObjectToString(date));
                                                        }
                                                        collectValues({
                                                            ...taxNoteValue,
                                                            handArray: taxNoteValue.handArray?.map((item, index) => {
                                                                if (index === i) {
                                                                    return {
                                                                        ...item,
                                                                        value: value,
                                                                        tax: tax,
                                                                        withoutMaam: v.target.checked
                                                                    }
                                                                }
                                                                return item;
                                                            })
                                                        });
                                                    }}
                                                    color='primary'
                                                    sx={{color: 'primary.main'}}
                                                />
                                            </> : undefined}</Grid>
                                            <Grid item xs={2} sm={2}>
                                                <WithNegative id='handTotal' name='handTotal'
                                                            label={`סהכ${customer!.includeTax ? '(כולל)' : ''}`}
                                                            value={taxNoteValue.handArray && (taxNoteValue.handArray?.[i]?.value * taxNoteValue.handArray?.[i]?.amount) || 0}
                                                            changer={(v) => {

                                                                const value = Number(v);

                                                                let tax = 0;
                                                                if (!(taxNoteValue.handArray?.[i]?.withoutMaam)) {
                                                                    if (customer!.includeTax)
                                                                        tax = value - value / JSDB().tax.get(dateObjectToString(date));
                                                                    else
                                                                        tax = value * JSDB().tax.getPercentDivided(dateObjectToString(date));
                                                                }
                                                                collectValues({
                                                                    ...taxNoteValue,
                                                                    handArray: taxNoteValue.handArray?.map((item, index) => {
                                                                        if (index === i && taxNoteValue.handArray?.[i]?.amount) {
                                                                            return {
                                                                                ...item,
                                                                                value: roundDecimals(value / taxNoteValue.handArray?.[i]?.amount, 4),
                                                                                tax: tax
                                                                            }
                                                                        }
                                                                        return item;
                                                                    })
                                                                });
                                                            }}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={3} sm={3}>
                                <Typography variant='h5'> עגל סכומים בחשבונית</Typography>
                                <Checkbox
                                    checked={roundTaxNote}
                                    onChange={(v) => {
                                        localStorage.setItem('roundTax', v.target.checked ? '1' : '0')
                                        setRound(v.target.checked);
                                    }}
                                    color='primary'
                                    sx={{color: 'primary.main'}}
                                />
                            </Grid> */}
                                            {taxNoteValue.handArray && taxNoteValue.handArray.length !== i && i !== 0 &&
                                                (
                                                    <Grid item xs={1} sm={1} style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Tooltip title="מחיקת חשבונית עם תיאור">
                                                            <IconButton size='small' onClick={() => {
                                                                const newArr = taxNoteValue.handArray && taxNoteValue.handArray.filter((item, index) => index !== i);
                                                                collectValues({
                                                                    ...taxNoteValue,
                                                                    handArray: newArr,
                                                                });
                                                            }}>
                                                                <IconCircleMinus color={theme.palette.primary.main}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                            {taxNoteValue.handArray && taxNoteValue.handArray.length - 1 === i && (
                                                <Grid item xs={11} sm={11} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <IconButton size='small' onClick={() => {
                                                        const newArr = taxNoteValue.handArray && [
                                                            ...taxNoteValue.handArray,
                                                            {
                                                                value: 0,
                                                                tax: 0,
                                                                withoutMaam: user.nkObject.no_tax_p === 1,
                                                                amount: 1,
                                                                description: ''
                                                            }
                                                        ];
                                                        collectValues({
                                                            ...taxNoteValue,
                                                            handArray: newArr,
                                                        });
                                                    }}>
                                                        <IconCirclePlus color={theme.palette.primary.main}/>
                                                    </IconButton>
                                                    <Typography variant='h5'>הוספת חשבונית עם תיאור</Typography>
                                                </Grid>
                                            )}
                                        </>
                                    )
                                })
                            }
                            {/* //------------------------------------------------------------------------------------ */}
                            <Grid item xs={6} sm={6}>
                                <Typography variant='h4'>הנחה באחוזים</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant='h4'>הנחה בסכום(כולל)</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <NoNegative fullWidth id='discount' name='discount' label='הנחה קבועה באחוזים(%)'
                                            value={taxNoteValue.discount}
                                            changer={(v) => {
                                                collectValues({
                                                    ...taxNoteValue,
                                                    discount: Number(Math.min(Math.max(0, Number(v)), 100)),
                                                });
                                            }}
                                />

                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <div/>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <NoNegative fullWidth id='discount' name='discount' label='הנחה קבועה בסכום(₪)'
                                            value={sumDiscount}
                                            changer={(v) => {
                                                setSumDiscount(Number(v))
                                            }}
                                />

                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <div/>
                            </Grid>
                            {documentType!='refund' ? <>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='h4'>תעודות משלוח</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <FormControlLabel
                                        value='low'
                                        disabled={isLoading}
                                        control={
                                            <Checkbox
                                                onChange={(v) => v.target.checked ? setReceipts(deliveryNotes.filter(d => d.status === 'open')) : setReceipts([])}
                                                color='primary'
                                            />
                                        }
                                        label='בחר את כל תעודת המשלוח הפתוחות'
                                    />
                                </Grid>
                                {(customer?.nkObject?.branch ?? -1) >= 0 ? <Grid item xs={6} sm={6}>
                                    <FormControlLabel
                                        value='low'
                                        disabled={isLoading}
                                        control={
                                            <Checkbox
                                                onChange={(v) => v.target.checked ? setReceipts(deliveryNotes.filter(d => d.status === 'open' && d.entName == customer.name)) : setReceipts([])}
                                                color='primary'
                                            />
                                        }
                                        label='בחר את כל הפתוחות של הסניף'
                                    />
                                </Grid> : undefined}

                                <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                        multiple
                                        id='receipt'
                                        disableCloseOnSelect
                                        value={receipts}
                                        getOptionLabel={getOptionLabel}
                                        isOptionEqualToValue={(o, v) => o.id === v.id}
                                        onChange={(event, d) => {
                                            if (d) setReceipts(d as ProductNote[]);
                                        }}
                                        options={
                                            deliveryNotes
                                                ?.sort((d1, d2) => (isBefore(d1.date, d2.date) ? 1 : -1))?.sort((d1, d2) => d1.entName.localeCompare(d2.entName)) ?? []
                                        }
                                        loading={isLoading}
                                        fullWidth
                                        autoHighlight
                                        groupBy={(option) => option.entName}
                                        renderOption={(props, option, {selected}) => (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    px: 1,
                                                    flex: 1,
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}
                                                key={option.id}
                                            >
                                                <li {...props} style={{flexGrow: 1}}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{marginRight: 8}}
                                                        checked={selected}
                                                    />
                                                    {getOptionLabel(option)}
                                                </li>
                                                <Tooltip title={<FormattedMessage id='View'/>}>
                                                    <IconButton size='small' onClick={onPreviewOpen(option)}>
                                                        <VisibilityIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='בחר תעודות משלוח פתוחות'
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: isLoading ? (
                                                        <CircularProgress color='inherit' size={20}/>
                                                    ) : (
                                                        <Box sx={{flexDirection: 'row'}}>
                                                            <Tooltip title={<FormattedMessage id='Show in calendar'/>}>
                                                                <IconButton size='small'
                                                                            onClick={() => setOpenCalendar(true)}>
                                                                    <EventIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {params.InputProps.endAdornment}
                                                        </Box>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>:undefined}

                            <Grid item xs={12} sm={12}>
                                <List
                                    component='nav'
                                    aria-labelledby='nested-list-subheader'
                                    subheader={<Typography variant='h4'>מוצרים</Typography>}
                                >
                                    <ListItemButton onClick={() => setCollapseProducts((p) => !p)}
                                                    sx={{borderRadius: 3}}>
                                        <ListItemIcon>
                                            <AddShoppingCartIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography variant='h5'>הוסף מוצרים</Typography>}/>
                                        {collapseProducts ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItemButton>
                                    <Collapse in={collapseProducts} timeout='auto' unmountOnExit>
                                        <CustomerProductsList products={products} onProductChange={onProductChange}
                                                              clearFunc={clearProducts} withReturns
                                                              getAllProducts={getAllProducts}
                                                              setGetAllProducts={setGetAllProducts}
                                        />
                                    </Collapse>
                                </List>
                            </Grid>
                            {
                                dtype === "tax_invoice" ? (
                                    <>
                                        {receipts.length > 0 ? <>   <Grid item xs={6} sm={6}>
                                            <TextField
                                                id='outlined-read-only-input'
                                                label='סכום חשבוניות'
                                                variant='standard'
                                                value={roundDecimals(receipts.reduce((acc, p) => acc + p.mainValue, 0), 4)}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position='start'>
                                                            <IconCurrencyShekel size={12}/>
                                                        </InputAdornment>
                                                    )
                                                }}

                                            />
                                        </Grid>
                                        </> : undefined}


                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='h4'>תשלומים</Typography>
                                        </Grid>
                                        {payments.map((p, i) => (
                                            <Grid item xs={12} sm={12} key={p.id}>

                                                <Typography style={{marginBottom: 9}} variant='h5'>תשלום
                                                    מס' {i + 1}</Typography>
                                                <PaymentComp
                                                    payment={p}
                                                    onPaymentChange={onPaymentChange}
                                                    onPaymentAdd={onPaymentAdd}
                                                    onPaymentDelete={onPaymentDelete}
                                                    addable={i === payments.length - 1}
                                                    deletable={i !== 0}
                                                />

                                            </Grid>

                                        ))}
                                    </>
                                ) : undefined
                            }
                        </Grid>
                    </Grid> : undefined}
                <Grid item xs={narrow ? 12 : 5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <DocumentPreview
                                outerDoc={createCustomerDocument.data ? undefined :setCommentControl}
                                document={
                                    {

                                        entId: customer.id,
                                        date: date ?? new Date(),
                                        type: (createCustomerDocument.data as TaxNote[])?.at(0)?.type?? documentType,
                                        payments: payments,
                                        entName: customer.name,
                                        deliveryCertificatesIds: (createCustomerDocument.data as TaxNote[])?.at(0)?.deliveryCertificatesIds ?? receipts.map((d) => d.id.toString()),
                                        products: (createCustomerDocument.data as TaxNote[])?.at(0)?.products ?? products.filter((p) => p.quantity > 0 || p.quantityReturns > 0 || p.forceShow || (p.quantitySecond > 0 && p.conversion_ratio > 0)),
                                        status: (createCustomerDocument.data as TaxNote[])?.at(0)?.status ?? 'pending_approval',
                                        id: (createCustomerDocument.data as TaxNote[])?.at(0)?.id ?? autoNum ?? 0,
                                        end: new Date(),
                                        color: '',
                                        textColor: '',
                                        baseColor: '',
                                        description: '',
                                        title: (createCustomerDocument.data as TaxNote[])?.at(0)?.title ?? 'חשבונית חדשה',
                                        allDay: false,
                                        nkObject: (createCustomerDocument.data as TaxNote[])?.at(0)?.nkObject ?? undefined,
                                        onProductChange: onProductChangeReal,
                                        name: '',
                                        taxConfirmation:(createCustomerDocument.data as TaxNote[])?.at(0)?.taxConfirmation ?? undefined,
                                        details: details,
                                        agent: JSDB().currentAgent,
                                        discount: taxNoteValue.discount,
                                        issueDate: new Date(),
                                        comment: commentControl.notes,
                                        notes2: commentControl.notes2,
                                        notes3: commentControl.notes3,
                                        notes4: commentControl.notes4,
                                        notes5: commentControl.notes5,
                                        taxValue: taxNoteValue.totalTax,
                                        preValue: taxNoteValue.preValue,
                                        round: taxNoteValue.roundValue,
                                        round_tax: taxNoteValue.roundTax,
                                        handArray: taxNoteValue.handArray,
                                        hand: taxNoteValue.handValue,
                                        hand_tax: taxNoteValue.handTax,
                                        hand_text: manuelText,
                                        mainValue: taxNoteValue.mainValue,
                                        onIdChange: (createCustomerDocument.data as TaxNote[])?.at(0) ? undefined : setAutoNum,
                                        entName: '',
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Box
                                onClick={() => {
                                    if (taxNoteValue.mainValue == 0) {
                                        setDisplayEmptyErr(true);
                                    }
                                    if (dtype === "tax_invoice" && Math.abs(roundDecimals(payments.reduce((acc, p) => acc + p.amount, 0), 2) - taxNoteValue?.mainValue) > 0.7) {
                                        setDisplayNotEqualErr(true);
                                    }
                                }}
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                }}>
                                <LoadingButton
                                    onClick={() =>
                                        createNewTaxNote()
                                    }
                                    loading={createCustomerDocument.isLoading}
                                    disabled={createCustomerDocument.isSuccess || (dtype === "tax_invoice" ? (taxNoteValue.mainValue == 0 || roundDecimals(payments.reduce((acc, p) => acc + p.amount, 0), 4) <= 0) || Math.abs(roundDecimals(payments.reduce((acc, p) => acc + p.amount, 0), 4) - taxNoteValue?.mainValue) > 0.7 : taxNoteValue.mainValue == 0)}
                                    variant='contained'
                                    type='submit'
                                    fullWidth
                                    sx={{m: 2}}
                                >
                                    הנפק
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
                fullWidth
                maxWidth='lg'
                sx={{'& .MuiDialog-paper': {p: 0}}}
            >
                {/******************* work here */}
                {/* <Box ref={dialogRef}>
                    <MainCard>
                        <MasterCalendar
                            events={deliveryNotes ?? []}
                            locale={config.i18n}
                            direction={config.rtlLayout ? 'rtl' : 'ltr'}
                            withMonthView
                            editable
                            eventMouseEnter={onMouseEnterHandler}
                            setRange={setRange}
                            EventForm={(event) => <DocumentPreview document={event as ExtendedDocument} />}
                        />
                    </MainCard>
                </Box>
                <HoverableEvent
                    hoverData={hoverData}
                    included={receipts.map((d) => d.id).includes(hoverData.document.id)}
                    onMouseLeaveHandler={onMouseLeaveHandler}
                    onAddDeliveryCertificate={onAddDeliveryCertificate}
                    onRemoveDeliveryCertificate={onRemoveDeliveryCertificate}
                /> */}
                {/******************* */}
                <CalendarContainer
                    setOpenCalendar={setOpenCalendar}
                    events={deliveryNotes ?? []}
                    receipts={receipts}
                    onAddDeliveryCertificate={onAddDeliveryCertificate}
                    onRemoveDeliveryCertificate={onRemoveDeliveryCertificate}
                    setPreviewOpen={setPreviewOpen}
                    setPreviewData={setPreviewData}
                />
                {/******************* */}
            </Dialog>
            <Dialog fullWidth open={previewOpen} onBackdropClick={onPreviewClose}
                    sx={{'& .MuiDialog-paper': {p: 0}}}>
                <DocumentPreview document={previewData} pool={pool}/>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={displayEmptyErr || displayNotEqualErr} autoHideDuration={3000} onClose={() => {
                setDisplayEmptyErr(false);
                setDisplayNotEqualErr(false);
            }}>
                <Alert onClose={() => {
                    setDisplayEmptyErr(false);
                    setDisplayNotEqualErr(false);
                }} severity="error"
                       sx={{width: '100%', backgroundColor: "#ff7373", color: "black", fontWeight: "bold"}}>
                    {displayEmptyErr ? 'חובה להכניס פריטים לחשבונית' : 'סכום התשלום חייב להיות שווה לסכום החשבונית'}
                </Alert>
            </Snackbar>
        </MainCard>
    );
};
export default memo(NewInvoice);

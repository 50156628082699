
import { Badge, Chip, CircularProgress } from "@mui/material";
import React, {memo} from "react";
import { Link } from "react-router-dom";
import StoreIcon from '@mui/icons-material/Store';

const InventoryBadge = () => {
  return (
    <Badge color='secondary'
      badgeContent={0}
      max={5000}>
      <Link to="/products/inventory">
        <Chip
          sx={{
            ml: 0.5, mr: 0.5, cursor: "pointer",
            '& .MuiChip-icon': {
              color: '#000080',
            },
          }}
          label={
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <StoreIcon style={{ display: 'flex', color: "#246927", marginLeft: "5px" }} />
              <span>מלאי</span>
            </div>
          }
          variant="outlined"
        />
      </Link>
    </Badge>
  )
};


export default memo(InventoryBadge);

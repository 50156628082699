import React from 'react';

// material ui
import { Autocomplete, Box, Chip, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

// locale
import { FormattedMessage } from 'react-intl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// types
import Customer from 'types/customer';

// assets
import { IconDotsCircleHorizontal } from '@tabler/icons';
import config from '../../../../config';
import { enUS, he } from 'date-fns/locale';
import MyDatePicker from "../../../../ui-component/MyDatePicker";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import { updateDriver } from "../../../../api/customer/documents.api";
import { dateObjectToString } from "../../../../fromKotlin/nkutils";
import { getSpecificCustomer } from "../../../../store/customers/customersSlice";
import { SNACKBAR_OPEN } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectDriversNames } from "../../../../store/agents/agentsSlice";
import Agent from "../../../../types/agent";

interface DocumentCustomerDetailsProps {
    customer: Customer;
    date: Date | null;
    setDate: (date: Date) => void;
    driver?: Agent
    setDriver: any
    drivers: Agent[]
    setDetails: any
    details?: any
}

const DocumentCustomerDetails = ({ customer, date, setDate, driver, setDriver, drivers ,setDetails,details}: DocumentCustomerDetailsProps) => {
    const [showBusinessDetails, setShowBusinessDetails] = React.useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const saveDriver = React.useCallback(
        async (d) => {
            try {

                await updateDriver(customer.id, d.name, false, dateObjectToString(date));
                console.log(d)
                setDriver(d)
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    message: `לקוח עודכן בהצלחה`,
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            } catch (e) {
                console.log(e);
                Swal.fire({
                    title: 'שגיאה בגישה לנתונים',
                    target: '#custom-target',
                    text: '',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#d63030'
                });
            }
        },
        [customer, date]
    );
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h4">
                    <FormattedMessage id="For" />
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField fullWidth id="name" name="name" label={<FormattedMessage id="Customer Name" />} value={customer.name} />
                {driver && drivers.length > 0 ?
                    <Autocomplete
                        id='driver'
                        value={driver}
                        onChange={(event, value) => {
                            if (value) Swal.fire({
                                title: `שינוי נהג מתאריך ${dateObjectToString(date)}`,
                                text: 'שינוי מתאריך זה עד השינוי הבא',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: theme.palette.primary.dark,
                                cancelButtonColor: theme.palette.error.light,
                                confirmButtonText: 'שנה',
                                cancelButtonText: 'בטל',
                                allowEnterKey: true,
                                allowEscapeKey: true,
                                keydownListenerCapture: true,
                                focusConfirm: true
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    saveDriver(value);

                                }
                            });
                        }}

                        options={drivers ?? []}
                        getOptionLabel={(o) => o.name}
                        fullWidth
                        autoHighlight

                        renderInput={(params) => (
                            <TextField
                                style={{ marginRight: 5 }}
                                {...params}
                                label='שם נהג'

                            />
                        )}
                    />
                    : undefined}
            </Grid>
            <Grid item xs={6} sm={6} >
                <MyDatePicker date={date ?? new Date()} setDate={setDate} />


            </Grid>

            <Grid item xs={12} sm={12}>
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="h4" sx={{ marginRight: 1 }}>
                        <FormattedMessage id="Business Details" />
                    </Typography>
                    <Tooltip onClick={() => setShowBusinessDetails((p) => !p)} title={<FormattedMessage id="Show Business Details" />}>
                        <Chip icon={<PendingOutlinedIcon />} sx={{
                                ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
                                    color: '#000080',
                                },
                            }} label="הראה פרטי עסק" variant="outlined" />
                        {/* <IconButton onClick={() => setShowBusinessDetails((p) => !p)} size="small">
                            <IconDotsCircleHorizontal />
                        </IconButton> */}
                    </Tooltip>
                </Box>
            </Grid>
            {showBusinessDetails && (
                <>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            id="businessName"
                            name="businessName"
                            label={<FormattedMessage id="Company Name" />}
                            value={details ? details?.companyName : customer.businessName}
                            onChange={(e) => setDetails(prev=> {
                            return {...prev, companyName: e.target.value }
                            }
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            id="businessId"
                            name="businessId"
                            label="ח.פ. \ ע.מ."
                            type="string"
                            value={details ? details?.companyId : customer.businessId}
                            onChange={(e) => setDetails(prev=>
                            {
                               return  {...prev, companyId: e.target.value }
                            }
                          )
                        }
                        />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <TextField fullWidth id="city" name="city" label={<FormattedMessage id="City" />} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField fullWidth id="street" name="street" label={<FormattedMessage id="Street" />} value={customer.street} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            fullWidth
                            id="streetNumber"
                            name="streetNumber"
                            label={<FormattedMessage id="Street Number" />}
                            type="number"
                            value={customer.streetNumber}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default React.memo(DocumentCustomerDetails);

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {DataSheetGridContext} from '../index';
import {
    Badge,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    PaperProps,
    Radio,
    RadioGroup,
    Tooltip
} from '@mui/material';
import {Person} from '@mui/icons-material';
import {orderKey, spreadAmountRoundRobin, spreadAmountStronger} from '../../../../types/OrderData';
import Draggable from 'react-draggable';
import NoNegative from '../../../../ui-component/textFieldNumber';
import {Percentage} from 'tabler-icons-react';
import {memo} from "react";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
function FormDialog({distributeDialogOn,setDistributeDialogOn,setData,updatedRowIds,setRefreshRowIds}) {
    const [open, setOpen] = React.useState(false);

    const [title, setTitle] = React.useState('');
    const [size, setSize] = React.useState(0);
    const [amountSpread, setAmountSpread] = React.useState(0);
    const [percentSpread, setPercentSpread] = React.useState(0);
    const [amountSum, setSum] = React.useState(0);
    const [sumLocked, setSumLocked] = React.useState(0);
    const [curSum, setCurSum] = React.useState(0);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setDistributeDialogOn(undefined)
    };
    React.useEffect(() => {
        if (distributeDialogOn) {
            const colName = distributeDialogOn.colName
            const data = distributeDialogOn.data

            // const name = columns[colMin]?.title?.props?.name;
            let sum = 0;
            let sum_locked = 0;
            data.forEach((d)=>{
                if (d) sum += (d[colName] ?? 0);
                if (d && (d.readonly || d.status == 'נעולה')) sum_locked += (d[colName] ?? 0);
            })
            setOpen(true);
            setSum(sum);
            setSumLocked(sum_locked);
            setCurSum(sum);
            // setTitle(name);
            setSize(data.length);
        }
    }, [distributeDialogOn]);
    const [value, setValue] = React.useState('sup');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    const onApprove =
        () => {
            const colName = distributeDialogOn.colName
            const data = distributeDialogOn.data

            let curData: any[] = [];
            data.forEach((d)=>{
                if (d &&   !(d.readonly || d.status =='נעולה')) curData.push(d);
            })

            if (value === 'regular') {
                curData = spreadAmountRoundRobin(curData, colName, amountSpread, false);
            } else if (value === 'sup') {
                curData = spreadAmountStronger(curData, colName, Math.max(amountSum - sumLocked, 0));
            } else if (value === 'regular_plus') {
                curData = spreadAmountRoundRobin(curData, colName, amountSpread, true);
            }


            const ids = curData.filter((dd) => (dd.value != undefined && dd.value >= 0)).reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});
            const x= new Set()
            setData((prev) =>
                prev.map((d, i) => {
                    if (d.id! in ids  &&  !(d.readonly || d.status =='נעולה') ) {
                        if (d[colName] == undefined && ids[d.id!].value == 0) {

                        } else {
                            updatedRowIds.add(orderKey(d));
                            x.add(orderKey(d))
                            return {
                                ...d,
                                [colName]: ids[d.id!].value,
                                hasBeenEdited:true
                            };
                        }

                    }
                    return d;
                }
                )
            );
            setRefreshRowIds(x)
            handleClose();
        };

    return (
        <div>
            <Button variant='outlined' id='auto-scatter-dialog-open' onClick={handleClickOpen}>
                פיזור אוטומטי
            </Button>
            <Dialog aria-labelledby="draggable-dialog-title" PaperComponent={PaperComponent} id='helper_dialog' open={open} onClose={handleClose}>
                <DialogTitle>פיזור אוטומטי לעמודה {title} <Badge color='secondary' max={2000} badgeContent={size}>
                    <IconButton size='medium'>
                        <Person />
                    </IconButton>
                </Badge> </DialogTitle>
                <DialogContent>

                    <FormControl>
                        <FormLabel id='demo-row-radio-buttons-group-label'> בחר שיטת פיזור</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={value}
                            onChange={handleChange}
                        >
                            <Tooltip
                                title='מפזר את המוצר ושומר על אותו יחס כמות שהיה לפני כן. דוגמא: אם לקוח 1 עם 20% מכמות המוצר אזי אותו היחס ישאר אחרי הפיזור. אם היה לקוח שלא קיבל מהמוצר אזי הוא נשאר על 0.'>
                                <FormControlLabel value='sup' control={<Radio />} label='מחזק' />
                            </Tooltip>

                            <Tooltip
                                title='מפזר לכל לקוח מסומן שווה בשווה רק ללקוחות שיש להם ערך קיים. דוגמא: יש 4 לקוחות, רק ל3 יש ערך גדול מ-0 וצריך לחלק 4 מוצרים, רק ה3 יקבלו 1 והאקסטרה 1 שנשאר ילך ללקוח הכי חזק במוצר הזה. '>


                                <FormControlLabel value='regular_plus' control={<Radio />} label='אחיד סלקטיבי ->' />
                            </Tooltip>


                            <Tooltip
                                title='מפזר לכל לקוח מסומן שווה בשווה. דוגמא: יש 3 לקוחות וצריך לחלק 4 מוצרים, כולם יקבלו 1 והאקסטרה 1 שנשאר ילך ללקוח הכי חזק במוצר הזה. '>


                                <FormControlLabel value='regular' control={<Radio />} label='אחיד ->' />
                            </Tooltip>


                            {/*<Tooltip*/}
                            {/*    title=' מפזר את המוצר כך שיהיה כמות זהה לכל הלקוחות. דוגמא: לקוח 1 עם כמות 2 מהמוצר ולקוח 2 עם כמות 1 מהמוצר, אנחנו רוצים לחלק מוצר 1 ולכן לקוח 2 יקבל אותו כדי שלכל הלקוחות יהיה אותה כמות. אם יש עודף יחולק לפי הלקוח החזק במוצר '>*/}
                            {/*    <FormControlLabel value='weak' control={<Radio />} label='משלים חוסרים' />*/}
                            {/*</Tooltip>*/}
                            {/*<Tooltip title='מפזר את המוצר לפי ממוצע הזמנות הקודמות, מתחשב בחזרות.'>*/}
                            {/*    <FormControlLabel*/}
                            {/*        value='statistics'*/}

                            {/*        control={<Radio />}*/}
                            {/*        label='ממוצע תעודות קודמות'*/}
                            {/*    />*/}
                            {/*</Tooltip>*/}
                        </RadioGroup>
                    </FormControl>
                    <Grid container>
                        <Grid item xs={2} sm={2}>
                            <TextField

                                value={curSum}
                                onFocus={(e) => {

                                    // disableKeyBoard(true)
                                    // current?.setSelection(null)
                                }}
                                onChange={(e) => {
                                }}
                                label='קיים'
                                type='number'
                                fullWidth
                                variant='standard'
                            />
                        </Grid>
                        <Grid item xs={1} sm={1}>+</Grid>
                        <Grid item xs={2} sm={2}>
                            <NoNegative
                                type='text'
                                onFocus={(e) => {
                                    // current?.setSelection(null)
                                }}


                                value={amountSpread}
                                inputProps={{ style: { textAlign: 'right', direction: 'ltr' } }}


                                changer={(v) => {

                                    if (v) {
                                        setAmountSpread(v);
                                        setSum(v + curSum);

                                    } else {
                                        setAmountSpread(0);
                                        setSum(curSum);
                                    }
                                }}
                                label='לחלק'
                                fullWidth
                                variant='standard'
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <TextField
                                type='text'

                                onFocus={(e) => {
                                    // disableKeyBoard(true)
                                    // current?.setSelection(null)
                                }}
                                value={percentSpread}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Percentage size={16} />
                                        </InputAdornment>
                                    )
                                }}

                                inputProps={{

                                    style: { textAlign: 'right', direction: 'ltr' }
                                }}
                                onChange={(e) => {
                                    let v = e.target.value
                                    if (Number(v)) {
                                        v = Number(v)
                                        const x = Math.max(Math.min(100, v), -100)
                                        setPercentSpread(x)
                                        let total = Math.round(curSum + curSum * (x / 100))
                                        let spread = total - curSum
                                        setAmountSpread(spread);
                                        setSum(total);
                                    } else if (v == '') {
                                        setAmountSpread(0);
                                        setPercentSpread(0)
                                        setSum(curSum);
                                    }
                                    else if (v == '-' || v == '0-' || v == '-0') {
                                        setPercentSpread('-');
                                    }
                                }}

                                label='או באחוזים'
                                fullWidth
                                variant='standard'
                            />
                        </Grid>
                        <Grid item xs={1} sm={1}>=</Grid>
                        <Grid item xs={3} sm={3}>
                            <NoNegative
                                autoFocus
                                label='סה"כ'
                                value={amountSum}
                                onFocus={(e) => {

                                    // disableKeyBoard(true)
                                    // current?.setSelection(null)
                                }}
                                changer={(v) => {
                                    if (v) {
                                        setAmountSpread(v - curSum);
                                        setSum(v);

                                    } else {
                                        setAmountSpread(-curSum);
                                        setSum(0);

                                    }
                                }}
                                fullWidth
                                variant='standard'
                            />

                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <NoNegative
                                label='נעול'
                                value={sumLocked}
                                onFocus={(e) => {

                                    // disableKeyBoard(true)
                                    // current?.setSelection(null)
                                }}
                                changer={(v) => {
                                }}
                                fullWidth
                                variant='standard'
                            />

                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'green', color: 'white' }}>בצע
                        פעולה</Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>ביטול</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default (FormDialog);

interface Agent {
    id: number;
    name: string
    isManager: boolean
    isDriver: boolean
    isCollector: boolean
    isAgent: boolean
    active: boolean,
    start_point?:string,
    end_point?:string,
    comment?:string,
    password?:string,
    nkObject: any;
}
export const getType = (a: Agent): number => {
    if (a.isAgent)
        return 0
    else if (a.isDriver)
        return 1
    else
        return 2
};
export const deserializeAgent = (a): Agent =>{

    return ({
        id: a.id,
        isCollector: a.is_collector >= 1,
        isDriver: a.is_driver >= 1,
        isAgent: a.is_agent === 1,
        isManager: a.manager >= 1,
        start_point:a.start_point,
        end_point:a.end_point,
        name: a.user_name,
        comment: a.comment,
        password: a.user_password,
        active: a.active>=1,
        nkObject: a
    })
} ;
export default Agent;

import type { IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community';
import './radio.css';
export class RadioNumberFilter implements IFilterComp {
    eGui!: HTMLDivElement;
    rbAllYears: any;
    rbAfter2004: any;
    filterActive!: boolean;
    filterChangedCallback: any;
    uniqueId: string;
    columnName: string;
    columnFunc: any;
    rbEqualsZero: any;
    constructor() {
        this.uniqueId = Math.random().toString(36).substr(2, 9);
    }

    init(params: IFilterParams) {
        this.columnName = params.column.getColDef().id;
        this.columnFunc = params.column.getColDef().valueGetter;
        this.filterChangedCallback = params.filterChangedCallback.bind(this);
        this.eGui = document.createElement('div');
        this.eGui.innerHTML = `<div class="year-filter" >
                <label>
                    <input type="radio" name="yearFilter-${this.uniqueId}" checked="checked" id="rbAllYears-${this.uniqueId}" /> הכל
                </label>
           
                <label>
                    <input type="radio" name="yearFilter-${this.uniqueId}" id="rbEqualsZero-${this.uniqueId}" /> =0
                </label>
                     <label>
                    <input type="radio" name="yearFilter-${this.uniqueId}" id="rbGreaterThanZero-${this.uniqueId}" /> >0
                </label>
            </div>`;

        this.rbAllYears = this.eGui.querySelector(`#rbAllYears-${this.uniqueId}`);
        this.rbAllYears.addEventListener('change', this.filterChangedCallback);
        this.rbAfter2004 = this.eGui.querySelector(`#rbGreaterThanZero-${this.uniqueId}`);
        this.rbAfter2004.addEventListener('change', this.filterChangedCallback);
        this.rbEqualsZero = this.eGui.querySelector(`#rbEqualsZero-${this.uniqueId}`);
        this.rbEqualsZero.addEventListener('change', this.filterChangedCallback);
        this.filterActive = false;
    }
    getGui() {
        return this.eGui;
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        const d = (this.columnFunc ? this.columnFunc(params) : params.data[this.columnName]) ?? 0;
        if (this.filteron() === 'greaterThanZero') {
            return d > 0;
        } else if (this.filteron() === 'equalsZero') {
            return d === 0;
        } else {
            return true;
        }
    }
    filteron() {
        if (this.rbAfter2004.checked) {
            return 'greaterThanZero';
        } else if (this.rbEqualsZero.checked) {
            return 'equalsZero';
        } else {
            return 'all';
        }
    }

    isFilterActive() {
        if (this.rbAfter2004.checked || this.rbEqualsZero.checked) {
            return true;
        } else {
            return false;
        }
    }

    getModel() {
        return this.filteron() !== 'all' ? this.filteron() : null;
    }

    onFloatingFilterChanged(value: string) {
        this.setModel(value);
        this.filterChangedCallback();
    }

    setModel(model: string) {
        if (model === 'greaterThanZero') {
            this.rbAllYears.checked = false;
            this.rbAfter2004.checked = true;
            this.rbEqualsZero.checked = false;
        } else if (model === 'equalsZero') {
            this.rbAllYears.checked = false;
            this.rbAfter2004.checked = false;
            this.rbEqualsZero.checked = true;
        } else {
            this.rbAllYears.checked = true;
            this.rbAfter2004.checked = false;
            this.rbEqualsZero.checked = false;
        }
    }
}

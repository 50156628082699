import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import JSDB from 'fromKotlin/nk';
import { AsyncStatus } from 'store/types/asyncState';


// THUNKS
export const getApi = createAsyncThunk('api/config', async () =>  JSDB().apiConfig);

const initialState: {
    info?:any;
    getUserAsyncState: AsyncStatus;
} = {
    info: undefined,
    getUserAsyncState: 'idle'
};

export const apiNoteSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        initGetUserAsyncState: (state) => {
            state.getUserAsyncState = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            /* GET_AGENTS STATE */
            .addCase(getApi.pending, (state) => {
                if (state.getUserAsyncState === 'idle') {
                    state.getUserAsyncState = 'loading';
                }
            })
            .addCase(getApi.fulfilled, (state, action) => {
                if (state.getUserAsyncState === 'loading') {
                    state.getUserAsyncState = 'succeeded';
                }
                state.info = (action.payload.first)
            })
            .addCase(getApi.rejected, (state, action) => {
                if (state.getUserAsyncState === 'loading') {
                    state.getUserAsyncState = 'failed';
                }
            });
    }
});

// actions
export const { initGetUserAsyncState } = apiNoteSlice.actions;

// Selectors
export const selectGetAgentsAsyncState = (state: RootState) => state.user.getUserAsyncState;
export const selectApi = (state: RootState) => state.apiNote.info!;

export default apiNoteSlice.reducer;

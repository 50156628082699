// material-ui
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Autocomplete, Box, Dialog, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import AppBreadcrumbsNavigation from 'ui-component/extended/AppBreadcrumbsNavigation';
import { selectCustomersidname } from '../../../store/customers/customersSlice';
import DialogAddAgent from '../../../ui-component/dialogs/addAgentDialog';
import DialogAddDoc from '../../../ui-component/dialogs/addDocDialog';
import DialogSearchDoc from '../../../ui-component/dialogs/searchDocDialog';
import { subStringMatch } from '../../../utils/sort';
import { Transition } from '../../../views/customer/CustomerList';
import CustomerPreview from '../../../views/customer/profile/documents/CustomerPreview';
import LogoSection from '../LogoSection';
import { AutoCompleteInput } from './AutoCompleteInput';
import { MenuToggleButton } from './MenuToggleButton';
import MobileSection from './MobileSection';
import { PopperMy } from './PopperMy';
import ProfileSection from './ProfileSection';
import DocsBadge from './ordersBadge/docsBadge';
import InventoryBadge from './ordersBadge/inventoryBadge';
import OrdersBadge from './ordersBadge/ordersBadge';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
    leftDrawerOpened: boolean;
    handleLeftDrawerToggle: () => void;
}

const Header = ({ leftDrawerOpened, handleLeftDrawerToggle }: HeaderProps) => {
    const theme = useTheme();
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [curCustomer, setCustomer] = React.useState<any>(undefined);
    const customers: any[] = useSelector(selectCustomersidname);
    const handleClose = () => {
        setPreviewOpen(false);
    };

    const onAutoCompleteChange = useCallback((event, value) => {
        if (value) {
            setPreviewOpen(true);
            setCustomer(value);
        }
    }, []);

    const externalIdSearch = parseInt(localStorage.getItem('externalIdOrders') ?? '0') === 1;

    return (
        <Grid container>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <MenuToggleButton leftDrawerOpened={leftDrawerOpened} handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </Box>
            <Box
                sx={{
                    ml: 3,
                    '& .rtl-1q9lxz1': {
                        padding: '6px 8px 6px 8px'
                    }
                }}
            >
                <AppBreadcrumbsNavigation />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box
                sx={{
                    marginRight: '70px'
                }}
            >
                <OrdersBadge />
                <DocsBadge />
                <InventoryBadge />
            </Box>

            <DialogAddAgent />
            <DialogAddDoc />
            <DialogSearchDoc />
            <Autocomplete
                onChange={onAutoCompleteChange}
                PopperComponent={PopperMy}
                options={customers}
                getOptionLabel={(o) => `${externalIdSearch ? o.externalId : o.id} - ${o.name}`}
                filterOptions={(options, { inputValue }) => {
                    return options
                        .filter((option) => {
                            return subStringMatch(inputValue, [option.name, externalIdSearch ? option.externalId : option.id]);
                        })
                        .sort((a, b) => {
                            if (inputValue == '') {
                                return a.position > b.position ? 1 : -1;
                            } else {
                                const n1 = externalIdSearch ? a.externalId : a.id;
                                const n2 = externalIdSearch ? b.externalId : b.id;

                                if (Number(n1) && Number(n2)) {
                                    return Number(n1) > Number(n2) ? 1 : -1;
                                } else if (Number(n1)) {
                                    return 1;
                                } else if (Number(n2)) {
                                    return -1;
                                } else return n1 > n2 ? 1 : -1;
                            }
                        });
                }}
                size="small"
                autoHighlight
                renderInput={(params) => AutoCompleteInput(params)}
            />

            {previewOpen && curCustomer ? (
                <Dialog
                    TransitionComponent={Transition}
                    fullScreen
                    fullWidth
                    open={previewOpen}
                    onBackdropClick={() => setPreviewOpen(false)}
                    sx={{ '& .MuiDialog-paper': { p: 0 } }}
                >
                    <AppBar sx={{ position: 'sticky' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                סגור חלון
                            </Typography>
                            <Typography autoFocus color="inherit" onClick={handleClose}>
                                {curCustomer.name}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <CustomerPreview id={curCustomer.id} />
                </Dialog>
            ) : undefined}
            <div style={{ width: '55px' }}></div>
            <ProfileSection />
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </Grid>
    );
};

export default Header;

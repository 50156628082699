import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from "@mui/material";
import { changeRangeValue, valueCheck } from "./ag-grid-functions";
import React, { useState } from "react";

const GrossValueChangeDialog = ({
  grossValueChangeModal,
  setGrossValueChangeModal,
  grossValue,
  tableRef,
  setEditMode,
  statusBarData,
  setStatusBarData,
  setGrossValue,
  tableControls,
  setValueAlert,
  setEditList,
  editList,
  menuiItems
}) => {

  const [actualValue, setActualValue] = useState<any>(null);

  const grossChange = () => {
    setGrossValueChangeModal(false);
    setEditMode(true);
    const clickedCellRef = tableRef?.current?.api?.getFocusedCell();
    const clickedCell = tableRef?.current?.api?.getDisplayedRowAtIndex(clickedCellRef?.rowIndex);
    const value = clickedCell.data[clickedCellRef?.column?.colDef?.id];
    const isRange = tableRef.current.api.getCellRanges()[0];
    const rangeLength = isRange.endRow.rowIndex > isRange.startRow.rowIndex ? isRange.endRow.rowIndex - isRange.startRow.rowIndex : isRange.startRow.rowIndex - isRange.endRow.rowIndex;
    if (valueCheck(setValueAlert, value, actualValue)) {
      tableRef?.current?.node?.setDataValue(tableRef.current.column.colId, value);
      return;
    }
    let numOfChanges = 0;
    if (rangeLength >= 1) {
      numOfChanges = changeRangeValue(tableRef.current, setValueAlert, actualValue, tableControls, editList, setEditList) ?? 0;
    }
    const selectedRowNodes = tableRef?.current?.api?.getSelectedNodes();
    let newEditList: any[] = [];
    selectedRowNodes.forEach((node) => {
      if (node.data[clickedCellRef.column.colDef.id] === actualValue) return;
      if (valueCheck(setValueAlert, value, actualValue)) {
        node.setDataValue(tableRef.current.column.colId, value);
        return;
      }
      const isInEditList = editList.find((originalNode: any) => originalNode?.id === node?.id);
      if (!isInEditList) {
        newEditList.push({
          ...node,
          data: {
            ...node.data,
            hasBeenEdited: true,
          }
        })
      }
      const valueToSet = typeof node.data[clickedCellRef.column.colDef.id] === "number" && actualValue.toString().match(/^[0-9]+$/) ? Number(actualValue) : actualValue;
      node.setDataValue(clickedCellRef.column.colDef.id, valueToSet);
      !node.data?.hasBeenEdited && numOfChanges++;
      node.data.hasBeenEdited = true;
    });
    tableRef?.current?.api.refreshCells({ force: true, rowNodes: selectedRowNodes });
    newEditList.length && setEditList([...editList, ...newEditList]);
    if (numOfChanges > 0) {
      setStatusBarData({
        ...statusBarData,
        editedRows: statusBarData?.editedRows + numOfChanges,
      });
      setEditMode(true);
    }
    tableRef?.current?.api?.deselectAll();
    setActualValue(null);
    setGrossValue("");
  }

  const focusedCell = tableRef?.current?.api?.getFocusedCell();
  const column = focusedCell?.column?.colDef?.id;

  return (
    <Dialog
      open={grossValueChangeModal}
      onClose={() => {
        setGrossValueChangeModal(false)
        setActualValue(null);
        setGrossValue("");
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>שינוי ערך גורף</DialogTitle>
      <DialogContent>
        {
          typeof grossValue === "object" ? (
            <Select
              sx={{
                width: "100%",
                marginTop: "10px",
              }}
              label="הכנס ערך"
              value={menuiItems?.[column].find((item: { label: string, value: any }) => item.value === actualValue)?.label || grossValue}
              onChange={(e) => {
                setActualValue(menuiItems?.[column].find((item: { label: string, value: any }) => item.label === e.target.value)?.value);
              }}
            >
              {
                grossValue?.map((item: any) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))
              }
            </Select>
          ) : (
            <TextField
              onKeyPress={(e) => e.key === "Enter" && grossChange()}
              sx={{
                width: "100%",
                marginTop: "10px",
              }} label="הכנס ערך" value={actualValue ? actualValue : grossValue} onChange={(e) => setActualValue(e.target.value)} />
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setGrossValueChangeModal(false)
            setActualValue(null);
            setGrossValue("");
          }} color="primary">
          ביטול
        </Button>
        <Button onClick={() => grossChange()
        } color="primary" autoFocus>
          אישור
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(GrossValueChangeDialog);

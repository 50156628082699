/* eslint-disable no-nested-ternary */
import React, {memo} from 'react';

// material-ui
import {Box} from '@mui/material';

// assets
// types
import {DocumentType} from 'types/DocumentsTypes/Document';
import 'sweetalert2/src/sweetalert2.scss';
import './style.css';
import NewReceipt from './receipt/NewReceipt';
import NewDeliveryCertificate from './deliveryCertificate/NewDeliveryCertificate';
import NewInvoice from './invoice/NewInvoice';
import NewVisit from './visit/NewVisit';
import IItem from 'types/product_delivery';
import NewSupNote from "../../../suppliers/documents/sup-notes/NewSupNote";

const DocumentNew = ({
                         customerId,
                         type,
                         productsFromAbove,
                            refId,
                         fromOrder,
    types
                     }: { customerId: string; type: DocumentType, ExtraOptions?: () => JSX.Element, productsFromAbove?: IItem[] ,refId?:string,fromOrder?:any,types?:any}) => {
    return (
        <Box id='custom-target'>
            {type === 'receipt' ? (
                <NewReceipt upid={customerId}  types={types} refId={refId}   narrow />
            ) : type === 'delivery_certificate' ? (
                <NewDeliveryCertificate upid={customerId} dtype={type} productsFromAbove={productsFromAbove}  refId={refId} fromOrder={fromOrder}  narrow />

            ) : type === 'order' ? (
                <NewDeliveryCertificate upid={customerId} dtype={type} productsFromAbove={productsFromAbove} refId={refId} fromOrder={fromOrder} narrow />
            ) : type === 'supplier_note' ? (
                    <NewSupNote upid={customerId}  narrow />
                )
                : type === 'refund' ? (
                    <NewInvoice upid={customerId} dtype={type} narrow />

                ) : type === 'invoice' ? (
                    <NewInvoice upid={customerId} dtype={type} productsFromAbove={productsFromAbove} refId={refId} narrow />
                ) : type === "visit_note" ? (
                    <NewVisit upid={customerId} />
                ) : (
                null
                )}
        </Box>
    );
};


export default memo(DocumentNew);

import {
    all_dvs_printed,
    buildQuerySum,
    dailyBuildQuerySum,
    getCofcLink,
    getMassDocumentPdf,
    getOrderDocumentPdf
} from 'api/customer/documents.api';
import {callbackNetworkResult, dataMimshak, getFile} from 'api/customer/files.api';
import Swal from "sweetalert2";
import {
    whatsapp2,
    userIcon,
    pdfIcon,
    duplicateIcon,
    deleteIcon,
    cancelIcon,
    addFilterIcon,
    addDocIcon,
    branchIcon,
    borderIcon,
    phoneIcon,
    pinIcon,
    columnIcon,
    rowIcon,
    email,
    boxIcon,
    antena
} from 'assets/images';
import JSDB, {NKTypeConvertor} from 'fromKotlin/nk';
import {dateObjectToString, dateObjectToStringIsrael} from 'fromKotlin/nkutils';
import xslx from "../../../assets/images";
import {parseJSON} from "date-fns";
import {distinctByField} from "../../../utils/sort";
import {orderKey} from "../../../types/OrderData";

const contextMenu2 = (params, setTempData, removeAllFilters, onApplyFilter, addCustomerFilter, activeState, dvs_flag, updatedRowIds, saveOrder, cancelDV, date, theme, deleteOrder, updatedDriverRowIds, setSingleDelete, setSelectedCustomersSend, setDistributeDialogOn, setReorderDialog, newClientOrder, setDocumentAction, setOpenSelectProductsToExport, setIdsForDuplicate, setExternalAnchor,setHistory) => {

    const ranges = params?.api?.getCellRanges();

    const selected = params?.api?.getSelectedRows()
    let nodesInRange: any = [];
    selected?.forEach((row) => {
        nodesInRange.push(row);
    })

    if (!selected?.length) {
        ranges?.forEach((range) => {
            const rowMin = range?.startRow?.rowIndex < range?.endRow?.rowIndex ? range?.startRow?.rowIndex : range?.endRow?.rowIndex;
            const rowMax = range?.startRow?.rowIndex > range?.endRow?.rowIndex ? range?.startRow?.rowIndex : range?.endRow?.rowIndex;
            for (let i = rowMin; i <= rowMax; i += 1) {
                const node = params.api?.getDisplayedRowAtIndex(i);
                if (node?.data) {
                    nodesInRange.push(node?.data)
                }
                if (node?.group) {
                    const children = node?.childrenAfterGroup;
                    children?.forEach((child) => {
                        nodesInRange.push(child?.data)
                    })
                }
            }
        })
    }
    nodesInRange = distinctByField(nodesInRange, orderKey)
    let notes = true;
    nodesInRange.forEach((data) => {
        notes = notes && (data?.readonly);
    })

    const range = ranges?.[0];
    const rowIndex = range?.startRow.rowIndex;
    const rowData = nodesInRange[0]

    let withBox = false;
    let total = 0;
    let boxes = 0;
    if (range?.columns?.length === 1)
        nodesInRange.forEach((data) => {
            if (range?.startColumn?.colDef?.field?.startsWith('p_')) {
                const id = Number(range?.startColumn?.colDef?.field?.replace('p_', ''));
                const product = JSDB().getClientProduct(id).first;
                const value = Number(data?.[range?.startColumn?.colDef?.field]);
                if (!isNaN(value)) {
                    if (data?.[range?.startColumn?.colDef?.field] && data?.[range?.startColumn?.colDef?.field.replace('p_', 'u_')]) {
                        withBox = true;
                        total += data?.[range?.startColumn?.colDef?.field] * product?.getOrderProduct()?.conversion_rate ?? 0 // add convertion rate
                        boxes += data?.[range?.startColumn?.colDef?.field]
                    } else {
                        total += data?.[range?.startColumn?.colDef?.field]
                    }
                }
            }
        })
    const withBoxText = withBox ? total > 0 ? `${range?.startColumn?.colDef?.headerName}:  ${total} = ${boxes}` : `${boxes}` : `${range?.startColumn?.colDef?.headerName}: ${total}`;

    const selectedCustomers = nodesInRange;

    const isProduct = range?.startColumn?.colDef?.field?.startsWith('p_');
    const tableDividers = JSON.parse(localStorage.getItem('orders-table-dividers') || '[]');
    const colId = range?.startColumn?.colDef?.field;

    const whatsappCallBack = (result) => {
        const client = JSDB().getClient(Number(rowData.id)).first
        let text = JSDB().userCofcConf?.text && JSDB().userCofcConf?.text != "None" ? JSDB().userCofcConf?.text : `שלום ${"#name"} ${"#id"}אנא בצע הזמנה עבור ${JSDB().getUser().first.user_company_print_name}`
        text = text.replace("#name", client.getName())
        text = text.replace("#id", client.id)
        text = text.replace("#external_id", client.getExternalId())
        text = text.replace("#date", dateObjectToStringIsrael(date))
        text = text.replace("#day", JSDB().getDatesManipulator().getDayOfWeekStr(dateObjectToString(date), true))

        const url = `${text.replaceAll('\\\\n', '%0a')} %0a %0a https://nihulkav.shop?pass=${result}&date=${dateObjectToString(date)} %0a`;
        // console.log(encodeURIComponent("🙏🏽"))
        const phone = client.getPhone();
        window?.open(`https://api.whatsapp.com/send?text=${url}&phone=${JSDB().getValidPhone(phone)}`, '_blank')?.focus();
    };

    const contextMenu = [
        {
            name: `לקוחות מסומנים: <b>${selectedCustomers.length || nodesInRange.size || 0}</b>`,
            icon: `<img src="${userIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
        },
        "separator",
        {
            name: !notes && activeState.day == -1 ? "הנפק תעודת משלוח" : notes && activeState.day == -1 ? "הסר תעודת משלוח" : "הנפק תעודת משלוח",
            icon: `<img src="${addDocIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            cssClasses: ["context-menu-red-item"],
            action() {
                if (!notes && activeState.day == -1) {
                    const orders: any = [];
                    selectedCustomers.forEach((data) => {
                        if (!(data?.dvs && data?.dvs.length > 0) && (dvs_flag || !updatedRowIds.has(orderKey(data)))) orders.push(data);

                    })

                    const dvs = orders.map((p) => null);
                    const text = dvs?.length === 1 ? `האם תרצה להנפיק תעודת משלוח ללקוח ${orders[0]?.customer}?` : `האם תרצה להנפיק ${dvs.length} תעודות משלוח?`;
                    if (dvs.length > 0)
                        Swal.fire({
                            title: text,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: theme.palette.primary.main,
                            cancelButtonColor: theme.palette.error.main,
                            confirmButtonText: 'הנפק',
                            cancelButtonText: 'בטל'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                /* Perform insert, update, and delete to the database here */
                                saveOrder(orders.map((p) => orderKey(p)), dvs);
                            }
                        });
                    close();
                }
                if (notes && activeState.day == -1) {
                    const dvs: any = [];
                    selectedCustomers.forEach((data) => {

                        if (data?.dvs && data?.dvs.length > 0)
                            data?.dvs?.forEach((dd) => (dd?.status !== 'closed' && dd?.type == 'delivery_certificate') ? dvs.push(dd?.id) : undefined);

                    })
                    const text = dvs?.length === 1 ? `האם תרצה לבטל תעודת משלוח ללקוח ${selectedCustomers[0]?.customer}?` : `האם תרצה לבטל ${dvs.length} תעודות משלוח?`;
                    if (dvs.length > 0)
                        Swal.fire({
                            title: text,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: theme.palette.primary.main,
                            cancelButtonColor: theme.palette.error.main,
                            confirmButtonText: 'ביטול תעודות',
                            cancelButtonText: 'חזור'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                /* Perform insert, update, and delete to the database here */
                                cancelDV(dvs);
                            }
                        });
                    close();
                }
            },
        },
        "separator",
        {
            name: "פעולות ייצוא",
            icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            subMenu: [
                {
                    name: "ייצא הזמנות",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const update_printed = new Set();

                        selectedCustomers.forEach((data) => {
                            if (data?.orderId) ids.push(data?.orderId)
                            if (data?.orderId && !data?.readonly) update_printed.add(data?.orderId)

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0) {
                            getFile(false, () => getOrderDocumentPdf(undefined, curPdf), 'הזמנות');
                            setTempData((prev) =>
                                prev.map((d, i) => {
                                        if (update_printed.has(d.orderId)) {
                                            updatedRowIds.add(orderKey(d));
                                            return {...d, status: 'הודפס', hasBeenEdited: true};
                                        } else {
                                            return d;
                                        }

                                    }
                                )
                            );
                            setTimeout(() => {
                                params.api.refreshCells({force: true});
                            }, 50)

                        }

                        close();
                    },
                },

                {
                    name: "שלח הזמנות למייל",
                    icon: `<img src="${email}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const clients: any = [];
                        const update_printed = new Set();

                        selectedCustomers.forEach((data) => {
                            if (data?.orderId) ids.push(data?.orderId)
                            if (data?.orderId) clients.push(data?.id)
                            if (data?.orderId && !data?.readonly) update_printed.add(data?.orderId)

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0) {
                            setSelectedCustomersSend({
                                selected: clients.map((u) => Number(u)),
                                defaultPlatform: 'email',
                                files: {
                                    ids: ids.map((u) => Number(u)),
                                    types: ids.map((p) => 10),
                                },
                                msg: 'הזמנה',
                                subject: 'שליחת מסמכים',


                            })
                        }

                        close();
                    },
                },
                {
                    name: "שלח הזמנות לווטסאפ",
                    icon: `<img src="${whatsapp2}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const clients: any = [];
                        const update_printed = new Set();

                        selectedCustomers.forEach((data) => {
                            if (data?.orderId) ids.push(data?.orderId)
                            if (data?.orderId) clients.push(data?.id)
                            if (data?.orderId && !data?.readonly) update_printed.add(data?.orderId)

                        })

                        const curPdf = ids.map((u) => Number(u));

                        if (curPdf.length > 0) {
                            setSelectedCustomersSend({
                                selected: clients.map((u) => Number(u)),
                                defaultPlatform: 'whatsapp',
                                files: {
                                    ids: ids.map((u) => Number(u)),
                                    types: ids.map((p) => 10),
                                },
                                msg: 'הזמנה',
                                subject: 'שליחת מסמכים',


                            })
                        }

                        close();
                    },
                },
                "separator",
                {
                    name: "ייצא תעודות",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        selectedCustomers.forEach((data) => {
                            if (data?.dvs && data?.dvs.length > 0)
                                ids.push(...data?.dvs);

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            getFile(false, () => getMassDocumentPdf(ids, undefined, undefined), 'תעודות', undefined, async () => {
                                try {
                                    const x = await all_dvs_printed(ids);
                                    const set = new Set(x.map((dv) => dv.entId));
                                    setTempData((prev) =>
                                        prev.map((d, i) => {
                                                if (set.has((d.id))) {
                                                    return {...d, all_dvs_printed: true};
                                                } else {
                                                    return d;
                                                }

                                            }
                                        )
                                    );
                                } catch (e) {
                                }

                                setTimeout(() => {
                                    params.api.refreshCells({force: true});
                                }, 50)
                            });
                        close();
                    }
                },
                {
                    name: "שלח תעודות לאימייל",
                    icon: `<img src="${email}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        selectedCustomers.forEach((data) => {
                            if (data?.dvs && data?.dvs.length > 0)
                                ids.push(...data?.dvs);

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            setSelectedCustomersSend({
                                selected: ids.map((u) => Number(u.entId)),
                                defaultPlatform: 'email',
                                files: {
                                    ids: ids.map((u) => Number(u.id)),
                                    types: ids.map((p) => p.nkObject.getConnectedDocType()),
                                },
                                msg: 'שליחת תעודות',
                                subject: 'מסמך',


                            })
                        close();
                    }
                },
                {
                    name: "שלח תעודות לווטסאפ",
                    icon: `<img src="${whatsapp2}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        selectedCustomers.forEach((data) => {
                            if (data?.dvs && data?.dvs.length > 0)
                                ids.push(...data?.dvs);

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0) {

                            setSelectedCustomersSend({
                                selected: ids.map((u) => Number(u.entId)),
                                defaultPlatform: 'whatsapp',
                                files: {
                                    ids: ids.map((u) => Number(u.id)),
                                    types: ids.map((p) => p.nkObject.getConnectedDocType()),
                                },
                                msg: 'שליחת תעודות',
                                subject: 'מסמך',


                            })
                        }


                        close();
                    }
                },
                {
                    name: "ייצא תעודות מקור+העתק",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        selectedCustomers.forEach((data) => {
                            if (data?.dvs && data?.dvs.length > 0)
                                ids.push(...data?.dvs);

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            getFile(false, () => getMassDocumentPdf(ids, undefined, undefined, undefined, 3), 'תעודות', undefined, async () => {
                                try {
                                    const x = await all_dvs_printed(ids);
                                    const set = new Set(x.map((dv) => dv.entId));
                                    setTempData((prev) =>
                                        prev.map((d, i) => {
                                                if (set.has((d.id))) {
                                                    return {...d, all_dvs_printed: true};
                                                } else {
                                                    return d;
                                                }

                                            }
                                        )
                                    );
                                } catch (e) {
                                }

                                setTimeout(() => {
                                    params.api.refreshCells({force: true});
                                }, 50)
                            });
                        close();
                    }
                },
            ]
        },
        {
            name: "סיכומים",
            icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            subMenu: [
                {
                    name: "ייצא השוואת כמויות",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const title_holder = new Set();
                        selectedCustomers.forEach((data) => {
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.id);
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                        })

                        const curPdf = ids.map((u) => Number(u));
                        let title = Array.from(title_holder).join(',');
                        if (curPdf.length > 0)
                            getFile(false, () => buildQuerySum(curPdf, date, date, title, true, true), 'השוואת כמויות');
                        close();
                    }
                },
                {
                    name: "ייצא סיכום הזמנות",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const title_holder = new Set();
                        selectedCustomers.forEach((data) => {
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.id);
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                        })
                        const curPdf = ids.map((u) => Number(u));
                        let title = Array.from(title_holder).join(',');
                        if (curPdf.length > 0)
                            getFile(false, () => buildQuerySum(curPdf, date, date, title, false, true), 'סיכום הזמנות');
                        close();
                    }
                },
                {
                    name: "ייצא סיכום נהגים",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const title_holder = new Set();
                        selectedCustomers.forEach((data) => {
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.id);
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            getFile(false, () => dailyBuildQuerySum(curPdf, date, 1, undefined, 1), 'סיכום נהגים');
                        close();
                    }
                },
                {
                    name: "ייצא סיכום נהגים מפורט",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const title_holder = new Set();
                        selectedCustomers.forEach((data) => {
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.id);
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                        })
                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            getFile(false, () => dailyBuildQuerySum(curPdf, date, 1, undefined, 2), 'סיכום נהגים מפורט');
                        close();
                    }
                },
                {
                    name: "ייצא סיכום הזמנות מפורט כללי",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const title_holder = new Set();
                        selectedCustomers.forEach((data) => {
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.orderId);
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                        })
                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            getFile(false, () => dailyBuildQuerySum(curPdf, date, 4, undefined, 2,undefined,'',undefined,1), 'סיכום נהגים מפורט');
                        close();
                    }
                },
                {
                    name: "ייצא סיכום נהגים בטבלה",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const title_holder = new Set();
                        selectedCustomers.forEach((data) => {
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.id);
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            getFile(false, () => dailyBuildQuerySum(curPdf, date, 1, undefined, 1,undefined,undefined,10,undefined), 'סיכום נהגים');
                        close();
                    }
                },
                {
                    name: "ייצא סיכום נהגים לפי מוצר",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const title_holder = new Set();
                        selectedCustomers.forEach((data) => {
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) ids.push(data?.id);
                            if (((data?.dvs && data?.dvs.length > 0) || (data.orderId != ''))) title_holder.add((data?.driverName != '') ? data?.driverName : data?.agent);

                        })
                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            getFile(false, () => dailyBuildQuerySum(curPdf, date, 1, undefined, 2,undefined,'',3), `סיכום מפורט ${name}`, "application/pdf");
                            // getFile(false, () => dailyBuildQuerySum(curPdf, date, 1, undefined, 2), 'סיכום נהגים מפורט');
                        close();
                    }
                },
                {
                    name: "ייצא סיכום הזמנות ספציפי",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        setOpenSelectProductsToExport({selectedCustomers: selectedCustomers});
                    }
                },
                {
                    name: "ייצא סיכום הזמנות ספציפי מפורט ספציפי",
                    icon: `<img src="${pdfIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        setOpenSelectProductsToExport({selectedCustomers: selectedCustomers, detailed: true});
                    }
                },
            ]
        },
        {
            name: "ממשקים",
            subMenu: [
                {
                    name: "ייצא הזמנות לממשק אקסל",
                    icon: `<img src="${xslx}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const ids: any = [];
                        const update_printed = new Set();

                        selectedCustomers.forEach((data) => {
                            if (data?.orderId) ids.push(data?.orderId)
                            if (data?.orderId && !data?.readonly) update_printed.add(data?.orderId)

                        })

                        const curPdf = ids.map((u) => Number(u));

                        if (curPdf.length > 0) {
                            const from = parseJSON(date);
                            const to = parseJSON(date);
                            getFile(false, () => dataMimshak(from, to, 'nissan', undefined, curPdf,), `נתונים לממשק ${from}_${to}.zip`, 'application/octet-stream', undefined, undefined);

                            setTempData((prev) =>
                                prev.map((d, i) => {
                                        if (update_printed.has(d.orderId)) {
                                            updatedRowIds.add(orderKey(d));

                                            return {...d, status: 'הודפס', hasBeenEdited: true};
                                        } else {
                                            return d;
                                        }

                                    }
                                )
                            );
                            setTimeout(() => {
                                params.api.refreshCells({force: true});
                            }, 50)


                        }

                        close();
                    },
                },]
        },
        {
            name: "מסלולים",
            icon: `<img src="${boxIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
            subMenu: [
                {
                    name: "סדר לקוחות בצורה אופטימלית",
                    // icon: <GradingIcon />,
                    action: () => {

                        // const ag = JSDB().createDailyData(date,Number(rowData?.id),rowData?.orderDriver ?? rowData?.driverName,rowData?.collectorName,rowData?.position,rowData?.collector_position,rowData?.mName,-1,rowData?.car)
                        console.log(rowData?.orderDriver ?? rowData?.driverName)
                        const ag = JSDB().getAgentBy(null, rowData?.orderDriver ?? rowData?.driverName, false, true, false, false)?.first

                        const startPoint = rowData?.start_point ? rowData?.start_point :
                            JSON.parse(localStorage.getItem(`driver_${rowData?.id}_position`) || '{}')?.start_position;
                        const endPoint = rowData?.end_point ? rowData?.end_point :
                            JSON.parse(localStorage.getItem(`driver_${rowData?.id}_position`) || '{}')?.end_position;
                        const start = ag?.start_point
                        const end = ag?.end_point
                        setReorderDialog({
                            data: nodesInRange,
                            start: start,
                            end: end,
                            ag: ag,
                            api: params.api,
                        });


                    },
                },
                {
                    name: "הצג מסלול בגוגל מפות",
                    action: () => {

                        const data = nodesInRange.map((d) => {
                            return d?.id ? Number(d?.id) : null;
                        })
                        if (!data.length) return;
                        const locationsArray = JSDB().getGeoLocations(NKTypeConvertor().toKotlinList(data))?.first?.toArray();
                        window && locationsArray.length && window.open(`https://www.google.com/maps/dir/${locationsArray?.join("/")}`, '_blank')?.focus();
                    },
                    // icon: <MapPin size={22} strokeWidth={1} color="#000000" />
                },

                {
                    name: "הצג נקודות במפה חיצונית ",
                    // icon: <Map2 size={22} strokeWidth={1} color="#000000" />,
                    action: () => {
                        const data = nodesInRange.map((d) => {
                            return d?.id ? Number(d?.id) : null;
                        })
                        if (!data.length) return;
                        const locationsArray = JSDB().getGeoLocations(NKTypeConvertor().toKotlinList(data))?.first?.toArray();

                        let pointsString = "";
                        locationsArray?.forEach((item, i) => {
                            const c = nodesInRange[i]?.customer?.replace(" ", "+")
                            if (i !== locationsArray.length - 1) {

                                pointsString += `point=${item?.split(",")[0]}%2C${item?.split(",")[1]}_${c}&`;
                            } else {
                                pointsString += `point=${item?.split(",")[0]}%2C${item?.split(",")[1]}_${c}`;
                            }
                        });
                        const url = `https://graphhopper.com/maps/?${pointsString}&profile=truck&layer=Omniscale`;
                        window.open(url, '_blank')?.focus();
                    }
                }
            ]
        },
        {
            name: "פעולות מיוחדות",
            subMenu: [
                {
                    name: "הוסף לקוחות לפילטור",
                    cssClasses: ["context-menu-blue-item"],
                    icon: `<img src="${addFilterIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const names: any = [];
                        for (let i = 0, len = selectedCustomers.length; i < len; i += 1) {
                            if (selectedCustomers[i]?.customer) names.push(selectedCustomers[i]?.customer);
                        }
                        removeAllFilters();
                        onApplyFilter({property: 'customer', contains: '', values: Array.from(names)});
                        addCustomerFilter(Array.from(names));
                        // if (current.selection)
                        //     current?.setSelection({
                        //         min: { col: current.selection.min.col, row: 0 },
                        //         max: { col: current.selection.max.col, row: names.length - 1 }
                        //     });

                    },
                },
                {
                    name: "פיזור אוטומטי",
                    icon: `<img src="${branchIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    disabled: !isProduct,
                    action() {
                        setDistributeDialogOn({
                            data: nodesInRange,
                            colName: colId,
                        })
                    }
                },
                {
                    name: "סמן עמודה",
                    icon: `<img src="${columnIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action: () => {
                        params.api.clearRangeSelection();
                        params.api.addCellRange({
                            rowStartIndex: 0,
                            rowEndIndex: params.api.getDisplayedRowCount() - 1,
                            columns: [params.api.getFocusedCell().column]
                        });
                    }
                },
                {
                    name: "סמן שורות",
                    icon: `<img src="${rowIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action: () => {
                        const startRow = range.startRow.rowIndex;
                        const endRow = range.endRow.rowIndex;
                        for (let i = startRow; i <= endRow; i++) {
                            params.api.getDisplayedRowAtIndex(i).setSelected(true);
                        }
                    }
                },

                "separator",
                {
                    name: "שלח לינק",
                    icon:
                        `<img style="width: 18px;height: 18px" src=` + whatsapp2 + ` />`,
                    action() {
                        const ids = new Set();
                        const update_printed = new Set();

                        selectedCustomers.forEach((data) => {
                            ids.add((data?.id))
                            if (!data?.readonly) update_printed.add(orderKey(data))
                        })


                        const curPdf = Array.from(ids).map((u) => Number(u));
                        if (curPdf.length > 0) {
                            setSelectedCustomersSend({
                                selected: curPdf,
                                defaultPlatform: 'whatsapp',
                                msg: '#link',
                                subject: 'לינק  להזמנה',
                                onSend: (res) => {
                                    const cd = []
                                    setTempData((prev) =>
                                        prev.map((d, i) => {
                                                if (update_printed.has(orderKey(d))) {
                                                    cd.push({...d, status: 'ממתין ללקוח', hasBeenEdited: true})
                                                    updatedRowIds.add(orderKey(d));
                                                    return {...d, status: 'ממתין ללקוח', hasBeenEdited: true};
                                                } else {
                                                    return d;
                                                }

                                            }
                                        )
                                    );

                                    // refuctor this
                                    if (JSDB().getCompany() != 'masheou' && JSDB().getCompany() != 'sendimasheou')
                                        setTimeout(() => {
                                            saveOrder(Array.from(update_printed), undefined, cd)
                                            // params.api.refreshCells({force: true});

                                        }, 500)
                                }
                            })


                        }


                        close();
                    }
                },
                {
                    name: "שלח תזכורת",
                    icon:
                        `<img style="width: 18px;height: 18px" src=` + whatsapp2 + ` />`,
                    action() {
                        const ids: any = [];
                        selectedCustomers.forEach((data) => {
                            ids.push((data?.id))

                        })

                        const curPdf = ids.map((u) => Number(u));
                        if (curPdf.length > 0)
                            setSelectedCustomersSend({
                                selected: curPdf,
                                defaultPlatform: 'whatsapp',
                                msg: 'היי לא קיבלנו הזמנה לתאריך #date',
                                subject: 'תזכורת',
                            })
                        close();
                    }
                },
                "separator",
                {
                    name: tableDividers?.includes(colId) ? "הסר הפרדה" : "הפרד עמודה לימין",
                    icon: `<img src="${borderIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const colDef = range?.startColumn.colDef;
                        if (tableDividers?.includes(colId)) {
                            const index = tableDividers.indexOf(colId);
                            tableDividers.splice(index, 1);
                            colDef.cellStyle = (params) => {
                                return {
                                    borderLeft: `1px solid ${theme.palette.divider}`,
                                    borderRight: "none",
                                    backgroundColor: params?.data?.readonly ? "#9affdd" : params?.data?.hasBeenEdited ? "#ff020240" : "inherit",
                                    fontWeight: (params?.data?.hasBeenEdited || params?.node?.rowPinned === "top") ? "bold" : "normal"
                                }
                            };
                        } else {
                            tableDividers.push(colId);
                            colDef.cellStyle = (params) => {
                                return {
                                    borderRight: "1.5px solid black",
                                    borderLeft: `1px solid ${theme.palette.divider}`,
                                    backgroundColor: params?.data?.readonly ? "#9affdd" : params?.data?.hasBeenEdited ? "#ff020240" : "inherit",
                                    fontWeight: (params?.data?.hasBeenEdited || params?.node?.rowPinned === "top") ? "bold" : "normal"
                                }
                            }
                        }
                        localStorage.setItem('orders-table-dividers', JSON.stringify(tableDividers));
                        const col = params.columnApi.getColumn(range?.startColumn.colId);
                        params?.api?.refreshCells({columns: [col], force: true});
                    }
                },
                {
                    name: "הצג מיקום לקוח",
                    icon: `<img src="${pinIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const userLocation = JSDB().getClient(Number(rowData?.id))?.first?.location;
                        if (userLocation) {
                            window.open(`https://www.google.com/maps/search/?api=1&query=${userLocation}`, '_blank')?.focus();
                        }
                    }
                },
                {
                    name: "התקשר ללקוח",
                    icon: `<img src="${phoneIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>`,
                    action() {
                        const focusedCell = params?.api?.getFocusedCell();
                        const node = params?.api?.getDisplayedRowAtIndex(focusedCell?.rowIndex);
                        const client = JSDB().getClient(Number(node?.data?.id)).first;
                        const openPhone = (result) => {
                            window.open(`tel:${result}`, '_blank')?.focus();
                        };
                        if (client?.phone) {
                            const hasTwo = client?.phone?.includes(',');
                            if (hasTwo[1]) {
                                const phones = client?.phone?.split(',');
                                Swal.fire({
                                    title: 'בחר מספר',
                                    input: 'select',
                                    inputOptions: {
                                        '0': phones[0],
                                        '1': phones[1],
                                    },
                                    inputPlaceholder: 'בחר מספר',
                                    showCancelButton: true,
                                    inputValidator: (value) => {
                                        if (!value) {
                                            return 'עליך לבחור מספר';
                                        }
                                    }
                                } as any).then((result) => {
                                    if (result.isConfirmed) {
                                        openPhone(phones[result.value]);
                                    }
                                });
                            } else {
                                openPhone(client?.phone);
                            }
                        }
                    }
                },
            ]
        },
        "separator",
        {
            name: "פעולות להזמנות",
            icon: `<img style="width: 18px;height: 18px" src=` + duplicateIcon + ` />`,
            subMenu: [
                {

                    name: `צור הזמנה נוספת`,
                    disabled: selectedCustomers.length > 1,
                    icon: `<img style="width: 18px;height: 18px" src=` + duplicateIcon + ` />`,
                    cssClasses: ["context-menu-blue-item"],
                    action() {
                        newClientOrder(selectedCustomers);
                        close();
                    },
                },
                "separator",

                {

                    name: `שכפל לפה  ${selectedCustomers.length} לקוחות`,
                    icon: `<img style="width: 18px;height: 18px" src=` + duplicateIcon + ` />`,

                    action() {
                        const clickRect = document.querySelector('.ag-menu-list')?.getBoundingClientRect();
                        if (!clickRect?.right && !clickRect?.top) return;
                        const closestCell = document.elementFromPoint(clickRect.right + 10, clickRect.top + 10) as HTMLElement;
                        if (!closestCell) return;
                        setExternalAnchor(closestCell);
                        setIdsForDuplicate(selectedCustomers);
                    },
                },
                // {
                //     name: `שכפל לפה רק את התאים שנבחרו`,
                //     icon: `<img style="width: 18px;height: 18px" src=` + duplicateIcon + ` />`,
                //     action() {
                //
                //         close();
                //     },
                // },
                "separator",
                {
                    name: "העבר הזמנות לתאריך אחר",
                    action() {
                        const goodNodes = nodesInRange
                        if (goodNodes.length > 0)
                            setDocumentAction({
                                docs: goodNodes,
                                action: "move",
                                doc_type: "order",
                                title: "העבר הזמנות לתאריך אחר",
                            })
                        close();
                    },
                    disabled: false
                },

                {
                    name: "פריז הזמנה",
                    disabled: rowData?.readonly || rowData?.orderId == undefined || rowData?.orderId == '',
                    icon: `<img style="width: 18px;height: 18px" src=` + pinIcon + ` />`,
                    action() {
                        const orders: any = [];
                        selectedCustomers.forEach((data) => {
                            if (!(data?.dvs && data?.dvs.length > 0) && (dvs_flag || !updatedRowIds.has(orderKey(data)))) orders.push(data);

                        })
                        const text = `האם תראה להקפיא מצב הסטוריה`
                        Swal.fire({
                            title: text,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: theme.palette.primary.main,
                            cancelButtonColor: theme.palette.error.main,
                            confirmButtonText: 'פריז',
                            cancelButtonText: 'ביטול פעולה'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                /* Perform insert, update, and delete to the database here */

                                orders.forEach((d) => {
                                    updatedRowIds.add(orderKey(d));
                                })
                                setTempData((prev) =>
                                    prev.map((d, i) => {
                                            if (updatedRowIds.has(orderKey(d))) {


                                                return {...d, hasBeenEdited: true};
                                            } else {
                                                return d;
                                            }

                                        }
                                    )
                                );
                                setTimeout(() => {
                                    saveOrder(orders.map((p) => orderKey(p)), undefined,undefined,true);
                                    // params.api.refreshCells({force: true});

                                }, 500)

                            }
                        });
                    }
                },
            ]
        },
        {
            name: "מחק הזמנה",
            disabled: rowData?.readonly || rowData?.orderId == undefined || rowData?.orderId == '',
            icon: `<img style="width: 18px;height: 18px" src=` + deleteIcon + ` />`,
            action() {
                const text = selectedCustomers?.length === 1 ? `האם תרצה למחוק הזמנה ללקוח ${rowData?.customer}?` : `האם תרצה למחוק ${selectedCustomers?.length} הזמנות?`;
                Swal.fire({
                    title: text,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: theme.palette.primary.main,
                    cancelButtonColor: theme.palette.error.main,
                    confirmButtonText: 'מחק',
                    cancelButtonText: 'ביטול פעולה'
                }).then((result) => {
                    if (result.isConfirmed) {
                        /* Perform insert, update, and delete to the database here */
                        const orders: any = [];
                        selectedCustomers.forEach((data) => {
                            if (!data?.readonly && data?.orderId != undefined && data.orderId != '' && data.status != 'בוצעה') orders.push(data.orderId);

                        })
                        deleteOrder(orders);
                    }
                });
            }
        },
        {
            name: "בטל שינויים בהזמנה",
            icon: `<img style="width: 18px;height: 18px" src=` + cancelIcon + ` />`,
            action() {
                const orders: any = [];
                selectedCustomers.forEach((data) => {
                    if (!data?.readonly) orders.push(orderKey(data));

                })
                setSingleDelete(orders);
            }
        },
        {
            icon:
                '<img style="width: 18px;height: 18px" src="https://cdn1.iconfinder.com/data/icons/ios-11-glyphs/30/save-512.png" />',
            disabled: dvs_flag || rowData?.readonly || !rowData || (!updatedRowIds.has(orderKey(rowData)) && !updatedDriverRowIds.has(rowData.id)),
            name: "שמור",
            action() {
                const orders: any = [];
                selectedCustomers.forEach((data) => {
                    if (!data?.readonly) orders.push(orderKey(data));

                })
                saveOrder(orders);
            }
        },
    ]

    if (range?.columns?.length === 1 && (total > 0 || boxes > 0) && isProduct) {
        contextMenu.splice(1, 0, {
            name: withBoxText,
            icon: withBox ? `<img src="${boxIcon}" style="width: 20px; height: 20px; margin-right: 5px;"/>` : ``
        })
    }
    return contextMenu
}

export default contextMenu2;

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ResetTableDialogProps {
    open: boolean;
    openDialog: (boolean) => void;
    resetTable: () => void;
}

export const ResetTableDialog: React.FC<ResetTableDialogProps> = ({ open, openDialog, resetTable }) => {
    return (
        <Dialog
            open={open}
            onClose={() => openDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'האם אתה בטוח שברצונך לאפס את הטבלה למצב ראשוני?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">כל השינויים שלא נשמרו יאבדו</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => openDialog(false)} color="primary">
                    ביטול
                </Button>
                <Button
                    onClick={() => {
                        resetTable();
                        openDialog(false);
                    }}
                    color="primary"
                    autoFocus
                >
                    אישור
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import { Chip,Autocomplete, Box, Grid, IconButton, InputAdornment, Tooltip } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from '../../store/actions';
import { useDocumentEventListener } from '../../datasheetreact/src/hooks/useDocumentEventListener';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getAgents, initGetAgentsAsyncState } from '../../store/agents/agentsSlice';
import { addNewAgentForce } from '../../api/users/users.api';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {memo} from "react";


function DialogAddAgent() {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [newValue, setNewValue] = React.useState<string>('');
    const [type, setType] = React.useState<any>();
    // const [data, setData] = React.useState<any[]>([]);
    const [options, setOptions] = React.useState<any[]>(['סוכן', 'נהג', 'מלקט'].map((i, index) => {
            return { name: i, type: index };
        }
    ));
    const onKeyDown = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.keyCode === 13 && open) {
                e.preventDefault();
                handleOk();
            }
        }, [newValue, open, type]);
    useDocumentEventListener('keydown', onKeyDown);
    const handleOk = async () => {
        try {
            if (type && newValue != '') {

                const d = await addNewAgentForce(newValue, type.type);
                dispatch(initGetAgentsAsyncState());
                dispatch(getAgents());
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    message: ` נוסף בהצלחה${type.name} ${newValue}`,
                    variant: 'alert',
                    alertSeverity: 'success'
                });

            } else {
                return;
            }
        } catch (e) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                message: `לא הצליח להוסיף ${type.name} ${newValue}`,
                variant: 'alert',
                alertSeverity: 'error'
            });
        }

        handleClose();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    // React.useEffect(() => {
    //     console.log(updateCustomerAsyncState);
    //     if (updateCustomerAsyncState === 'succeeded') {
    //         dispatch({
    //             type: SNACKBAR_OPEN,
    //             open: true,
    //             message: 'המוצרים עודכנו בהצלחה!',
    //             variant: 'alert',
    //             alertSeverity: 'success'
    //         });
    //
    //         dispatch(initMassCustomersProductAsyncState());
    //
    //     } else if (updateCustomerAsyncState === 'failed') {
    //         Swal.fire({
    //             title: 'שגיאה בעדכון מוצרים',
    //             target: '#custom-target',
    //             text: '',
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'המשך'
    //         });
    //         dispatch(initMassCustomersProductAsyncState());
    //     }
    // }, [updateCustomerAsyncState, dispatch]);

    const handleClose = () => {
        setOpen(false);
        setNewValue('');
        setType(undefined);
    };

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue((event.target as HTMLInputElement).value);
    // };

    const onApprove =
        () => {
            handleOk();
        };

    return (
        <div>
            <Chip onClick={handleClickOpen} icon={<PersonAddIcon fontSize={'medium'} />} sx={{ml: 0.5, mr: 0.5, '& .MuiChip-icon': {
      color: '#008000',
    }, }} label="הוסף סוכן" variant="outlined" />
            {/* <Tooltip title={'הוסף סוכן/נהג/מלקט חדש'}>
                <IconButton size='large' onClick={handleClickOpen} style={{'color':'#008000'}}>
                    <PersonAddIcon fontSize={'medium'} />
                </IconButton>
            </Tooltip> */}
            {open ? <Dialog id='doc_search_dialog'  maxWidth={'sm'} fullWidth={true}
                            open={open} onClose={handleClose}>
                <DialogTitle> {'הוסף סוכן/נהג/מלקט חדש'}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={5} sm={5}>

                            <Autocomplete
                                id='type'

                                onChange={(e, value) => {
                                    if (value)
                                        setType(value);
                                    else
                                        setType(undefined);
                                }}

                                getOptionLabel={(option) => option.name}
                                options={options ?? []}
                                fullWidth
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='standard'
                                        label='בחר סוג.. '
                                        autoFocus

                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <AccountCircleIcon size={16} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box />
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <TextField


                                label={'שם'}

                                value={newValue}
                                sx={{ minWidth: 10 }}
                                onChange={(v) => {
                                    setNewValue(v.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant='standard'
                            />
                        </Grid>
                    </Grid>
                    {/*<MasterTable*/}
                    {/*    headCells={columns}*/}
                    {/*    data={data ?? []}*/}
                    {/*    keyExtractor={(p) => p.id.toString()}*/}
                    {/*    withPagination*/}
                    {/*    paginationOptions={{*/}
                    {/*        rowsPerPage: 1000*/}
                    {/*    }}*/}
                    {/*    withSort*/}
                    {/*    sortOptions={{*/}
                    {/*        sortBy: {*/}
                    {/*            order: 'desc',*/}
                    {/*            orderBy: 'position'*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    withFilter*/}
                    {/*    TableItem={(row) => <ChangeItem row={row} />}*/}
                    {/*/>*/}

                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        onApprove();
                    }} style={{ backgroundColor: 'green', color: 'white' }}>המשך
                    </Button>
                    <Button onClick={handleClose} style={{ backgroundColor: 'red', color: 'white' }}>ביטול</Button>
                </DialogActions>
            </Dialog> : undefined}

        </div>
    );
}
export default memo(DialogAddAgent);

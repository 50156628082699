import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DefaultRootStateProps, HeadCell } from 'types';
import 'dayjs/locale/he';
import './ag-table.scss';
import { costumFunction, rightClickItems, getSavedColumnState, setColumns, mapFilterState } from './ag-table-utils';
import AgTableActionsBar from './ag-table-actions-bar';
import {
    createPinnedRowDataNew,
    onCellKeyDown,
    onColumnGroupOpened,
    onColumnMoved,
    onColumnResized,
    onColumnVisible,
    onFilterChanged,
    onManyCellEditingStopped,
    openColumnMenuOnRightClick,
    resetTable,
    saveColumnState
} from './ag-grid-functions';
import { AG_GRID_LOCALE_HE } from './ag-table-localization';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import GrossValueChangeDialog from './gross-value-change';
import AgChips, { IAgChip } from './components/Ag-Chip';
import CreateStateDialog from './CreateStateDialog';
import type { ColDef } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { ITableControls } from './interfaces';

export function removeDuplicatesById(data) {
    const uniqueDataMap = new Map();

    data.forEach((item) => {
        if (!uniqueDataMap.has(item.id)) {
            uniqueDataMap.set(item.id, item);
        }
    });

    return Array.from(uniqueDataMap.values());
}

const AgTableMobile = ({
    columns,
    data,
    tableControls,
    costumeControls,
    cellRenderer,
    refreshCells,
    costumFunc,
    extentedContextMenu,
    clearEditFromAbove,
    outSideTableRef,
    chipDetails,
    saveDataCallback,
    menuiItems,
    addDataAction,
    rightClickActions,
    customeCancelEdit,
    editCallback,
    editCallbackMany,
    selectionControls,
    specialDialogs,
    customHeaderComponents,
    onLoad,
    pinnedControl,
    onCellClick
}: {
    columns: HeadCell[];
    data: any;
    tableControls: ITableControls;
    costumeControls?: any;
    cellRenderer?: any;
    costumFunc?: any;
    refreshCells?: any;
    clearEditFromAbove?: any;
    outSideTableRef?: MutableRefObject<any>;
    chipDetails?: IAgChip[];
    extentedContextMenu?: (
        tableApi: any
    ) => (
        | {
              name: string;
              action: () => any;
              disabled?: boolean;
              icon?: any;
              cssClasses?: string;
              subMenu?: {
                  name: string;
                  action: () => any;
                  disabled?: boolean;
                  icon?: any;
                  cssClasses?: string;
              }[];
          }
        | 'separator'
    )[];
    saveDataCallback?: (data: any) => any;
    menuiItems?: {
        [key: string]: {
            label: string;
            value: any;
        }[];
    };
    addDataAction?: {
        tooltip?: string;
        label?: string;
        action?: (data?: any) => any;
        component?: (data?: any) => any;
    };
    rightClickActions?: {
        [key: string]: {
            name: string;
            disabled?: (params?: any) => boolean;
            action: (params?: any) => any;
            params?: (params: any, clickedNode: any) => any;
        };
    };
    customeCancelEdit?: (params: any) => any;
    editCallback?: (params: any) => any;
    editCallbackMany?: (params: any) => any;
    selectionControls?: (selectedItems) => any;
    specialDialogs?: ({ ...params }) => React.ReactNode;
    customHeaderComponents?: {
        search?: (params: any) => any;
    } | null;
    onLoad?: (params: any) => any;
    costumeFunctions?: {
        [key: string]: any;
    };
    pinnedControl?: any;
    onCellClick?: (params: any) => any;
}) => {
    const [columnDefs, setColumnDefs] = useState<[]>([]);
    const [nestedColumnsDefs, setNestedColumnsDefs] = useState<HeadCell[]>([]);
    const [rowData, setRowData] = useState<any[]>([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [reOrderMode, setReOrderMode] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [gridReady, setGridReady] = useState<boolean>(false);
    const [statusBarData, setStatusBarData] = useState<{
        numberOfItems: number;
        selectedRows: number;
        filteredRows: number;
        editedRows: number;
    }>({
        numberOfItems: 0,
        selectedRows: 0,
        filteredRows: 0,
        editedRows: 0
    });
    const [editList, setEditList] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [pinnedTopRowDataConfiguration, setPinnedTopRowDataConfiguration] = useState<any[]>(
        (localStorage.getItem(`${tableControls?.tableName}_pinnedRowTopDefault`)
            ? JSON.parse(localStorage.getItem(`${tableControls?.tableName}_pinnedRowTopDefault`)!)
            : pinnedControl?.pinnedRowTopDefault
        )?.map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c)) || []
    );
    const [pinnedBottomRowDataConfiguration, setPinnedBottomRowDataConfiguration] = useState<any[]>(
        (localStorage.getItem(`${tableControls?.tableName}_pinnedRowBotDefault`)
            ? JSON.parse(localStorage.getItem(`${tableControls?.tableName}_pinnedRowBotDefault`)!)
            : pinnedControl?.pinnedRowBotDefault
        )?.map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c)) || []
    );

    useEffect(() => {
        if (!gridReady) return;
        const newDefaultTop = pinnedTopRowDataConfiguration?.map((c) => c?.id);
        const newDefaultBot = pinnedBottomRowDataConfiguration?.map((c) => c?.id);
        if (newDefaultTop?.length) {
            localStorage.setItem(`${tableControls?.tableName}_pinnedRowTopDefault`, JSON.stringify(newDefaultTop));
        } else {
            localStorage.removeItem(`${tableControls?.tableName}_pinnedRowTopDefault`);
        }
        if (newDefaultBot?.length) {
            localStorage.setItem(`${tableControls?.tableName}_pinnedRowBotDefault`, JSON.stringify(newDefaultBot));
        } else {
            localStorage.removeItem(`${tableControls?.tableName}_pinnedRowBotDefault`);
        }
    }, [pinnedTopRowDataConfiguration, pinnedBottomRowDataConfiguration]);

    const [pinnedTopRowData, setPinnedTopRowData] = useState<any[]>([]);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState<any[]>([]);
    const [filterChange, setFilterChange] = useState<boolean>(false);
    const [sortChange, setSortChange] = useState<boolean>(false);
    const [tableActionsOpen, setTableActionsOpen] = useState<boolean>(false);
    const [resetTableDialog, setResetTableDialog] = useState<boolean>(false);
    const [grossValueChangeModal, setGrossValueChangeModal] = useState<boolean>(false);
    const [grossValue, setGrossValue] = useState<any>('');
    const [valueAlert, setValueAlert] = useState<boolean>(false);
    const [defaultGroupAlert, setDefaultGroupAlert] = useState<boolean>(false);
    const [newRowDataDD, setNewRowDataDD] = useState<any>(null);
    const [newRowDataDD2, setNewRowDataDD2] = useState<any>(null);
    const [customeUserState, setCustomeUserState] = useState<any>(0);
    const [customeUserButtons, setCustomeUserButtons] = useState<any[]>([]);
    const [openCreateStateDialog, setOpenCreateStateDialog] = useState<boolean>(false);
    const editQueueRef: MutableRefObject<any[]> = useRef([]);
    const debounceTimeoutRef: MutableRefObject<NodeJS.Timeout | null | undefined> = useRef();
    const onCellEditingStoppedQ = useCallback(
        (params) => {
            editQueueRef.current = [...editQueueRef.current, params];
            // If a timeout is already pending, clear it
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
            // Set a new timeout
            debounceTimeoutRef.current = setTimeout(() => {
                // Process the queue here
                const many = editQueueRef.current.map((edit) => edit);
                editQueueRef.current = [];
                debounceTimeoutRef.current = null;
                onManyCellEditingStopped(
                    many,
                    setEditMode,
                    setStatusBarData,
                    setValueAlert,
                    setEditList,
                    editList,
                    editCallback,
                    editCallbackMany
                );
            }, 500);
        },
        [editList, editCallback, editCallbackMany]
    );
    useEffect(() => {
        if (!resetTableDialog) return;
        setPinnedTopRowDataConfiguration(
            pinnedControl?.pinnedRowTopDefault?.map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c)) || []
        );
        setPinnedBottomRowDataConfiguration(
            pinnedControl?.pinnedRowBotDefault?.map((c) => pinnedControl?.pinnedRowControlsNew?.find((x) => x.id == c)) || []
        );
    }, [resetTableDialog]);
    const handleRowDragLeave = useCallback(
        (event) => {
            const draggedRowNodes = event.nodes;
            if (!event.node) {
                return;
            }
            if (event.node.group) {
                const newRowData = newRowDataDD2;
                const fromGroupKey = draggedRowNodes[0].key;
                const groupingField = draggedRowNodes[0]?.field;
                const draggedGroupRows = newRowData.filter((row) => row && row[groupingField] === fromGroupKey);
                const newRowDataWithoutDraggedGroup = newRowData.filter((row) => row && row[groupingField] !== fromGroupKey);
                newRowDataWithoutDraggedGroup.splice(0, 0, ...draggedGroupRows);
                tableRef.current.api.applyTransaction({ remove: newRowDataWithoutDraggedGroup });
                tableRef.current.api.applyTransaction({ add: newRowDataWithoutDraggedGroup });
            }
        },
        [newRowDataDD2]
    );
    const handleRowDragEnd = useCallback(
        (event) => {
            const newRowData = newRowDataDD2;
            const draggedRowNodes = event.nodes;
            if (!event.node) {
                return;
            }
            const fromIndex = draggedRowNodes[0].rowIndex;
            const isDragGroup = draggedRowNodes[0].group;
            if (isDragGroup) {
                const fromGroupKey = draggedRowNodes[0].key;
                const toGroupKey = event?.overNode?.key;
                const groupingField = draggedRowNodes[0]?.field;
                if (fromGroupKey !== toGroupKey) {
                    const draggedGroupRows = newRowData.filter((row) => row && row[groupingField] === fromGroupKey);
                    const newRowDataWithoutDraggedGroup = newRowData.filter((row) => row && row[groupingField] !== fromGroupKey);
                    const targetGroupLastRowIndex = newRowDataWithoutDraggedGroup.findIndex((row, index, arr) => {
                        if (row[groupingField] === toGroupKey) {
                            if (index === arr.length - 1 || arr[index + 1][groupingField] !== toGroupKey) {
                                return true;
                            }
                        }
                        return false;
                    });
                    newRowDataWithoutDraggedGroup.splice(targetGroupLastRowIndex + 1, 0, ...draggedGroupRows);
                    tableRef.current.api.applyTransaction({ remove: newRowDataWithoutDraggedGroup });
                    tableRef.current.api.applyTransaction({ add: newRowDataWithoutDraggedGroup });
                }
                return;
            }

            handleRowDragMove(event, true);
            tableControls?.drag?.customeDragEndAction?.(event);
        },
        [newRowDataDD2]
    );
    const onStartDrag = useCallback((event) => {
        const allRowObjects: any = [];
        tableRef.current.api.forEachNode(function (rowNode) {
            allRowObjects.push(rowNode.data);
        });
        const allRowObjects2: any = [];
        tableRef.current.api.forEachNodeAfterFilterAndSort(function (rowNode) {
            allRowObjects2.push(rowNode.data);
        });
        setNewRowDataDD(allRowObjects);
        setNewRowDataDD2(allRowObjects2);
    }, []);
    const handleRowDragMove = useCallback(
        (event) => {
            const newRowData = newRowDataDD2;
            const draggedRowNodes = event.nodes;

            if (!event.node || !event.overNode) {
                return;
            }
            const fromIndex = draggedRowNodes[0].rowIndex;
            const lastIndex = draggedRowNodes[draggedRowNodes.length - 1].rowIndex;
            const toIndex = event.overNode.rowIndex;

            const isDragGroup = draggedRowNodes[0].group;
            const isToDragGroup = event.overNode.group;

            if (isDragGroup && isToDragGroup) {
                const fromGroupKey = draggedRowNodes[0].key;
                const toGroupKey = event.overNode.key;
                const groupingField = event.overNode.field;
                if (fromGroupKey !== toGroupKey) {
                    const draggedGroupRows = newRowData.filter((row) => row && row[groupingField] === fromGroupKey);
                    const newRowDataWithoutDraggedGroup = newRowData.filter((row) => row && row[groupingField] !== fromGroupKey);
                    const targetGroupLastRowIndex = newRowDataWithoutDraggedGroup.findIndex((row, index, arr) => {
                        if (row[groupingField] === toGroupKey) {
                            return true;
                        }
                        if (index === arr.length - 1 || arr[index + 1][groupingField] !== toGroupKey) {
                            return true;
                        }
                        return false;
                    });
                    newRowDataWithoutDraggedGroup.splice(targetGroupLastRowIndex + 1, 0, ...draggedGroupRows);
                    tableRef.current.api.applyTransaction({ remove: draggedGroupRows });
                }
                return;
            } else if (isDragGroup) {
                return;
            } else if (isToDragGroup) {
                tableRef.current.api.setRowNodeExpanded(event.overNode, true);
                return;
            }
            if (event.overNode.parent) {
                const groupingField = event.overNode.parent.field;
                const fromGroupKey = draggedRowNodes[0].data[groupingField];
                const toGroupKey = event.overNode.data[groupingField];

                if (fromGroupKey !== toGroupKey) {
                    // The groups are different
                    // Remove dragged rows from source group
                    draggedRowNodes.forEach((m, index) => {
                        newRowData.splice(m.rowIndex - index, 1);
                    });
                    // Update the group key for the dragged rows
                    const updatedDraggedRows = draggedRowNodes.map((m) => {
                        return {
                            ...m.data,
                            category: toGroupKey
                        };
                    });
                    // Add dragged rows to the target group
                    newRowData.splice(toIndex, 0, ...updatedDraggedRows);
                    tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
                    return;
                }
            }
            if (lastIndex - fromIndex + 1 !== draggedRowNodes.length) {
                draggedRowNodes.forEach((m, index) => {
                    newRowData.splice(m.rowIndex - index, 1);
                });
                newRowData.splice(fromIndex, 0, ...draggedRowNodes.map((m) => m.data));
                tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
            } else if (fromIndex - toIndex >= 1) {
                newRowData.splice(fromIndex, draggedRowNodes.length);
                newRowData.splice(toIndex, 0, ...draggedRowNodes.map((m) => m.data));
                tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
            } else if (toIndex - fromIndex >= draggedRowNodes.length) {
                newRowData.splice(fromIndex, draggedRowNodes.length);
                newRowData.splice(toIndex - draggedRowNodes.length, 0, ...draggedRowNodes.map((m) => m.data));
                tableRef.current.api.setRowData(removeDuplicatesById(newRowData.filter((m) => m)));
            }
        },
        [newRowDataDD2]
    );

    const tableRef = outSideTableRef ? outSideTableRef : useRef<any>(null);

    const pushNewRow = useCallback((c, top) => {
        if (top) setPinnedTopRowDataConfiguration((prev) => [...prev, c]);
        else setPinnedBottomRowDataConfiguration((prev) => [...prev, c]);
    }, []);
    const removeRow = useCallback((index, top) => {
        if (top) {
            setPinnedTopRowDataConfiguration((prev) => {
                const newFormat = [...prev];
                newFormat.splice(index, 1);
                return newFormat;
            });
        } else {
            setPinnedBottomRowDataConfiguration((prev) => {
                const newFormat = [...prev];
                newFormat.splice(index, 1);
                return newFormat;
            });
        }
    }, []);

    const [pinnedRowsSideBarControl, setPinnedRowsSideBarControl] = useState({
        topCollapse: false,
        bottomCollapse: false
    });

    const setColumnsCallback = useCallback(() => {
        const filters = tableRef?.current?.api?.getFilterModel();
        const columnsForDef = setColumns(columns, tableControls, menuiItems, undefined, cellRenderer, true);
        setColumnDefs(columnsForDef);
        setTimeout(() => {
            // console.log(columnsForDef.filter((c) => c.aggFunc).map((c) => c.id))
            // console.log(tableRef?.current?.columnApi?.getColumns())
            // console.log(tableRef?.current?.columnApi?.getValueColumns())

            tableRef?.current?.columnApi?.addValueColumns(columnsForDef.filter((c) => c.aggFunc).map((c) => c.id));
            // console.log(tableRef?.current?.api?.getColumnDefs())
            // console.log(tableRef?.current?.columnApi?.getValueColumns())
            setIsLoaded(true);
            setColumnState();
            // if (filters)
            //     setFilterMod(filters)
        }, 50);
        if (tableControls?.nestedColumns?.enable) {
            const nested = true;
            const nestedColumnsForDef = setColumns(
                tableControls?.nestedColumns?.columns,
                tableControls,
                menuiItems,
                nested,
                cellRenderer,
                true
            );
            setNestedColumnsDefs(nestedColumnsForDef);
        }
    }, [columns, tableControls, tableRef, tableControls, cellRenderer, menuiItems]);
    const setCellRenderrerColumnsCallback = useCallback(() => {
        if (isLoaded) {
            const defs = tableRef?.current?.api?.getColumnDefs()?.slice();
            const filters = tableRef?.current?.api?.getFilterModel();
            const newDefs = defs?.map((d: any) => {
                const column = columns.find((c) => c.id === d.id);
                const cd = {
                    ...d,
                    cellRenderer:
                        column && (tableControls?.cellRenderer ?? cellRenderer)
                            ? (params) => (tableControls?.cellRenderer ?? cellRenderer)(params, column)
                            : null
                };
                delete cd.colId;
                return cd;
            });
            tableRef?.current?.api?.setColumnDefs(newDefs);
            setFilterMod(filters);
            setTimeout(() => {
                setColumnState();
            }, 50);
        }
    }, [columns, tableControls, tableRef, cellRenderer, isLoaded]);
    useEffect(() => {
        if (cellRenderer) setCellRenderrerColumnsCallback();
    }, [cellRenderer]);

    useEffect(() => {
        if (clearEditFromAbove && clearEditFromAbove.size > 0) {
            const keep: any = [];
            editList.forEach((e) => {
                if (!clearEditFromAbove.has(e.id)) {
                    keep.push(e);
                }
            });
            setEditList(keep);
            clearEditFromAbove.clear();
        }
    }, [clearEditFromAbove]);

    const setColumnsCallbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        return () => {
            if (setColumnsCallbackTimeoutRef.current) {
                clearTimeout(setColumnsCallbackTimeoutRef.current);
            }
        };
    }, []);
    useEffect(() => {
        if (setColumnsCallbackTimeoutRef.current) {
            clearTimeout(setColumnsCallbackTimeoutRef.current);
        }
        setColumnsCallbackTimeoutRef.current = setTimeout(() => {
            setColumnsCallback();
        }, 400); // 1 second delay
    }, [columns, menuiItems]);
    //--- set data
    useEffect(() => {
        if (!rowData.length || rowData !== data) {
            setRowData(data?.map((item: any) => ({ ...item })));
            tableRef?.current?.api?.refreshHeader();
            setStatusBarData({
                numberOfItems: data?.length,
                selectedRows: 0,
                filteredRows: 0,
                editedRows: 0
            });
        }
        if (tableControls.additionalData) {
            setRowData(data?.map((item: any) => ({ ...item })));
            tableRef?.current?.api?.refreshHeader();
            setStatusBarData({
                numberOfItems: data?.length,
                selectedRows: 0,
                filteredRows: 0,
                editedRows: 0
            });
        }
    }, [data, columns]);
    //--- set columns state
    const colStateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const setColumnState = useCallback(() => {
        if (colStateTimeoutRef.current) {
            clearTimeout(colStateTimeoutRef.current);
        }
        colStateTimeoutRef.current = setTimeout(() => {
            getSavedColumnState(tableRef, tableControls, gridReady);
            tableRef?.current?.api?.onFilterChanged();
        }, 500); // 1 second delay
    }, [tableRef, tableControls]);
    const setFilterMod = useCallback(
        (fm) => {
            const cs2 = tableRef?.current?.columnApi?.getColumnState();
            const fs = mapFilterState(fm, cs2);
            tableRef?.current?.api?.setFilterModel(fs);
        },
        [tableRef, tableControls]
    );
    // - check for custome user buttons
    useEffect(() => {
        const customeButtonsStorage = localStorage.getItem(tableControls?.tableName + '-customeButtons');
        const customeButtons = customeButtonsStorage ? JSON.parse(customeButtonsStorage) : [];
        setCustomeUserButtons(customeButtons);
    }, []);
    useEffect(() => {
        if (refreshCells && refreshCells.size > 0) {
            setTimeout(() => {
                const nodes: any = [];
                refreshCells.forEach((cell) => {
                    const node = tableRef?.current?.api?.getRowNode(cell);
                    if (node) {
                        nodes.push(node);
                    }
                });

                refreshCells.clear();
                tableRef?.current?.api?.refreshCells({ rowNodes: nodes, force: true });
                setPinnedRowDataCallback();
            }, 650);
        }
    }, [refreshCells]);
    // costume functions
    const costumAggFunctions = useMemo(() => {
        const x = costumFunction();
        if (costumFunc) {
            Object.keys(costumFunc).forEach((key) => {
                x[key] = costumFunc[key];
            });
        }
        return x;
    }, []);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const setPinnedRowDataCallback = useCallback(() => {
        // Clear the previous timeout if there is one
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        // Set a new timeout
        timeoutRef.current = setTimeout(() => {
            const filterdNodeData: any = [];
            tableRef?.current?.api?.forEachNodeAfterFilterAndSort((node) => {
                node.data && filterdNodeData.push(node.data);
            });
            // build top
            let top = [];
            let bot = [];

            pinnedTopRowDataConfiguration.forEach((c) => {
                let r = {};
                console.log(c);
                if (c?.editable) {
                    r = c.editableData;
                    r.editable = c.editable;
                } else {
                    if (c?.sumByConfigurations && pinnedControl?.pinnedRowControlsNew) {
                        const conf1 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[0]);
                        const conf2 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[1]);
                        let r1;
                        let r2;
                        if (conf1!.editableData) {
                            r1 = conf1!.editableData;
                        } else {
                            r1 = createPinnedRowDataNew(columns, filterdNodeData, conf1, true);
                        }
                        if (conf2!.editableData) {
                            r2 = conf2!.editableData;
                        } else {
                            r2 = createPinnedRowDataNew(columns, filterdNodeData, conf2, true);
                        }
                        Object.keys(r1).forEach((k) => {
                            switch (c.sumByConfigurations.func) {
                                case 'sum':
                                    if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k]))) r[k] = (Number(r1[k]) ?? 0) + (Number(r2[k]) ?? 0);
                                    else r[k] = '';
                                    break;
                                case 'minus':
                                    if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k]))) r[k] = (Number(r1[k]) ?? 0) - (Number(r2[k]) ?? 0);
                                    else r[k] = '';
                                    break;
                            }
                        });
                        r[c.sumByConfigurations.name] = c.label;
                    } else {
                        if (c?.editableData) {
                            r = c.editableData;
                        } else r = createPinnedRowDataNew(columns, filterdNodeData, c);
                    }
                }
                top.push(r);
            });
            setPinnedTopRowData(top);
            pinnedBottomRowDataConfiguration.forEach((c) => {
                let r = {};
                if (c?.editable) {
                    r = c.editableData;
                    r.editable = c.editable;
                } else {
                    if (c?.sumByConfigurations && pinnedControl?.pinnedRowControlsNew) {
                        const conf1 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[0]);
                        const conf2 = pinnedControl?.pinnedRowControlsNew.find((c1) => c1.id === c.sumByConfigurations.ids[1]);
                        let r1;
                        let r2;
                        if (conf1!.editableData) {
                            r1 = conf1!.editableData;
                        } else {
                            r1 = createPinnedRowDataNew(columns, filterdNodeData, conf1);
                        }
                        if (conf2!.editableData) {
                            r2 = conf2!.editableData;
                        } else {
                            r2 = createPinnedRowDataNew(columns, filterdNodeData, conf2);
                        }
                        Object.keys(r1).forEach((k) => {
                            switch (c.sumByConfigurations.func) {
                                case 'sum':
                                    if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k]))) r[k] = (Number(r1[k]) ?? 0) + (Number(r2[k]) ?? 0);
                                    else r[k] = '';
                                    break;
                                case 'minus':
                                    if (!isNaN(Number(r1[k])) && !isNaN(Number(r2[k]))) r[k] = (Number(r1[k]) ?? 0) - (Number(r2[k]) ?? 0);
                                    else r[k] = '';
                                    break;
                            }
                        });
                        r[c.sumByConfigurations.name] = c.label;
                    } else {
                        if (c?.editableData) {
                            r = c.editableData;
                        } else r = createPinnedRowDataNew(columns, filterdNodeData, c);
                    }
                }
                bot.push(r);
            });
            setPinnedBottomRowData(bot);
        }, 1000); // 1 second delay
    }, [
        columns,
        pinnedBottomRowDataConfiguration,
        pinnedTopRowDataConfiguration,
        pinnedTopRowData,
        pinnedBottomRowData,
        pinnedControl,
        tableControls
    ]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (colStateTimeoutRef.current) {
                clearTimeout(colStateTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        statusBarChange(tableRef.current);
        setPinnedRowDataCallback();
    }, [filterChange, rowData, pinnedBottomRowDataConfiguration, pinnedTopRowDataConfiguration]);

    // change status bar data
    const statusBarChange = useCallback((params: any) => {
        setStatusBarData((prev) => {
            return {
                numberOfItems: prev?.numberOfItems,
                selectedRows: params?.api?.getSelectedRows()?.length,
                filteredRows: params?.api?.isAnyFilterPresent() ? params?.api?.getDisplayedRowCount() : 0,
                editedRows: prev?.editedRows
            };
        });
    }, []);

    //  default column settings
    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 200,
            filter: true, //"agMultiColumnFilter",
            floatingFilter: tableControls.floatingFilters ?? true,
            flex: 1,
            enableRowGroup: tableControls?.groupBy?.rowGroup,
            enablePivot: tableControls?.groupBy?.pivot,
            enableValue: tableControls?.groupBy?.value,
            suppressMovable: true
            //floatingFilterComponent: (params) => <AgTableHeaderSearch params={params} tableRef={tableRef} />,
        };
    }, []);

    const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);

    const [tableHeightNum, setTableHeight] = useState(0);

    useEffect(() => {
        const table = document.getElementById(tableControls?.customeId ? tableControls?.customeId : 'actual-ag-table');
        if (table) {
            setTimeout(() => {
                const rect = table.getBoundingClientRect();
                setTableHeight(window.outerHeight - rect.top - 20);
            }, 500);
        }
    }, [data, rowData, leftDrawerOpened]);

    const tableHeight = useMemo(() => {
        const table = document.getElementById(tableControls?.customeId ? tableControls?.customeId : 'actual-ag-table');
        if (table) {
            const rect = table.getBoundingClientRect();
            return window.outerHeight - rect.top - 20;
        }
    }, [data, rowData, leftDrawerOpened, tableHeightNum]);

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        if (tableControls?.groupBy?.headerName)
            return {
                headerName: tableControls?.groupBy?.headerName,
                width: 200,
                lockPosition: true,
                pinned: 'right',
                filter: 'agGroupColumnFilter',
                lockPinned: true,
                aggFunc: 'count'
            };
        else
            return {
                width: 200,
                lockPosition: true,
                pinned: 'right',
                filter: 'agGroupColumnFilter',
                lockPinned: true
                // cellRendererParams: {
            };
    }, [tableControls?.groupBy?.headerName]);

    useEffect(() => {
        setTimeout(() => {
            tableRef?.current?.columnApi?.autoSizeAllColumns();
        }, 1000);
    }, [gridReady, setColumnState]);

    return (
        <div id="ag-table-main-div">
            {
                <div
                    style={{
                        marginBottom: 10,
                        position: 'relative',
                        display: costumeControls || selectionControls || chipDetails || tableControls?.allowReorder ? 'flex' : 'none',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '50%'
                        }}
                    >
                        {chipDetails && (selectedItems.length == 0 || tableControls?.allowReorder) && (
                            <AgChips
                                gridReady={gridReady}
                                chipList={chipDetails}
                                chipDisplayAmount={tableControls?.chipDisplayAmount}
                                reOrderMode={reOrderMode}
                                setReOrderMode={setReOrderMode}
                                params={tableRef?.current}
                                tableControls={tableControls}
                                rowData={rowData}
                                setRowData={setRowData}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: '50%'
                        }}
                    >
                        {selectionControls && selectedItems.length ? selectionControls(selectedItems) : null}
                        {costumeControls ? costumeControls() : null}
                    </div>
                </div>
            }
            <div
                style={
                    tableControls?.groupBy?.enable
                        ? {}
                        : {
                              paddingBottom: 50,
                              backgroundColor: '#f8f8f8',
                              borderTopRightRadius: 10,
                              borderTopLeftRadius: 10,
                              border: 'solid #babfc7 1px',
                              paddingTop: 10
                          }
                }
            >
                <IconButton
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: -15,
                        zIndex: 1,
                        padding: 0
                    }}
                    onClick={() => setTableActionsOpen(true)}
                >
                    <MoreVert
                        style={{
                            fontWeight: 'bold',
                            fontSize: '40px',
                            color: 'black'
                        }}
                    />
                </IconButton>
                <Dialog
                    open={tableActionsOpen}
                    onClose={() => setTableActionsOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    id="ag-table-actions-dialog"
                >
                    <AgTableActionsBar
                        rowData={rowData}
                        setRowData={setRowData}
                        statusBarData={statusBarData}
                        setStatusBarData={setStatusBarData}
                        tableRef={tableRef}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        tableControls={tableControls}
                        setResetTableDialog={setResetTableDialog}
                        setEditList={setEditList}
                        editList={editList}
                        allowGraph={tableControls?.allowGraph}
                        columns={columns}
                        setPinnedRowDataCallback={setPinnedRowDataCallback}
                        filterChange={filterChange}
                        sortChange={sortChange}
                        customHeaderComponents={customHeaderComponents}
                        saveDataCallback={saveDataCallback}
                        addDataAction={addDataAction}
                        customeCancelEdit={customeCancelEdit}
                        gridReady={gridReady}
                    />
                </Dialog>
            </div>
            <div
                id={tableControls?.customeId ? tableControls?.customeId : 'actual-ag-table'}
                className="ag-theme-alpine"
                style={{ height: tableHeight, minWidth: '100%' }}
                onContextMenu={(e) => {
                    openColumnMenuOnRightClick(e, tableRef);
                }}
            >
                <AgGridReact
                    //---- basic settings ----//
                    ref={tableRef}
                    // column def (column settings)
                    columnDefs={columnDefs}
                    // default column settings
                    defaultColDef={defaultColDef}
                    // row data
                    rowData={rowData}
                    // enable nested table
                    masterDetail={tableControls?.nestedColumns?.enable}
                    //---- general settings ----//
                    autoGroupColumnDef={autoGroupColumnDef}
                    // pinned top row data
                    pinnedTopRowData={pinnedTopRowData?.length ? pinnedTopRowData : undefined}
                    // costume agg functions
                    aggFuncs={costumAggFunctions}
                    maintainColumnOrder={true}
                    // costume right click menu
                    getContextMenuItems={() =>
                        rightClickItems(
                            tableRef,
                            statusBarData,
                            tableControls,
                            setGrossValueChangeModal,
                            setGrossValue,
                            extentedContextMenu,
                            rightClickActions,
                            menuiItems
                        )
                    }
                    // enable to change data on drag
                    enableFillHandle={true}
                    // enable right to left
                    enableRtl={true}
                    getRowId={(row) => {
                        return tableControls.extractId ? tableControls.extractId(row) : Number(row?.data?.id);
                    }}
                    // locale text
                    localeText={AG_GRID_LOCALE_HE}
                    detailCellRenderer={
                        tableControls?.nestedColumns?.isDataDisplay
                            ? (params) => {
                                  if (typeof tableControls?.nestedColumns?.datas === 'string') {
                                      return (
                                          <div style={{ height: 50, fontSize: '1rem', padding: 10 }}>
                                              {params?.data[tableControls?.nestedColumns?.datas]}
                                          </div>
                                      );
                                  } else {
                                      const findKey = tableControls?.nestedColumns?.datas.find((data) => params?.data?.[data]);
                                      const findData = params?.data?.[findKey || ''];
                                      return <div style={{ height: 50, fontSize: '1rem', padding: 10 }}>{findData ? findData : ''}</div>;
                                  }
                              }
                            : undefined
                    }
                    detailRowHeight={70}
                    //---- group settings ----//

                    // group checkbox select all children
                    groupSelectsChildren={true}
                    // sticky group rows

                    groupDisplayType={'multipleColumns'}
                    multiSortKey={'ctrl'}
                    suppressScrollOnNewData={true}
                    groupRemoveSingleChildren={tableControls?.groupBy?.removeSingleChildren}
                    suppressAggFuncInHeader={true}
                    //---- drag and drop settings ----//

                    // enable row drag
                    rowDragManaged={!tableControls?.drag?.complex}
                    onDragStarted={tableControls?.drag?.complex ? onStartDrag : undefined}
                    onRowDragEnd={tableControls?.drag?.complex ? handleRowDragEnd : undefined}
                    onRowDragMove={tableControls?.drag?.complex ? handleRowDragMove : undefined}
                    onRowDragLeave={tableControls?.drag?.complex ? handleRowDragLeave : undefined}
                    // enable row animation
                    animateRows={true}
                    // enable multi row drag
                    rowDragMultiRow={tableControls?.drag?.multiRow}
                    //---- selection settings ----//

                    // allow multiple / single selection
                    rowSelection={tableControls?.selection?.mode}
                    // enable range selection
                    enableRangeSelection={tableControls?.selection?.enable}
                    // disable row click selection (only checkbox selection)
                    suppressRowClickSelection={tableControls?.selection?.enable}
                    //---- events ----//

                    // listen for grid ready
                    onGridReady={(params) => {
                        params.api.setHeaderHeight(60);
                        setTimeout(() => {
                            onLoad ? onLoad(params) : undefined;
                        }, 1000);
                        setTimeout(() => {
                            setGridReady(true);
                        }, 500);
                    }}
                    // listen for all changes
                    // onColumnEverythingChanged={(params) => {
                    //     statusBarChange(params)
                    // }}

                    undoRedoCellEditing={true}
                    undoRedoCellEditingLimit={6}
                    // listen for column group changes
                    onColumnRowGroupChanged={(params: any) => {
                        const groupCol = params?.columnApi?.getRowGroupColumns();
                        // params?.columnApi?.autoSizeColumns(groupCol)

                        if (tableControls?.defaultGroupBy) {
                            if (!groupCol.length || !groupCol.some((col: any) => col.colId === tableControls?.defaultGroupBy)) {
                                params.columnApi.addRowGroupColumn(tableControls?.defaultGroupBy);
                                setDefaultGroupAlert(true);
                            }
                        }
                        // setTimeout(() => {
                        //     params.api.sizeColumnsToFit();
                        // }, 500);
                        // saveColumnState(params, tableControls);
                        saveColumnState(params, tableControls, gridReady, params?.columns);
                    }}
                    onCellClicked={(params) => {
                        if (params?.rowPinned === 'top') {
                            const numOfRows = params?.api?.getDisplayedRowCount();
                            params?.api?.addCellRange({
                                rowStartIndex: 0,
                                rowEndIndex: numOfRows,
                                columns: [params?.column]
                            });
                        }
                        if (onCellClick) {
                            onCellClick(params);
                        }
                    }}
                    // listen for selection changes
                    onSelectionChanged={(params) => {
                        const selectedRows = params.api.getSelectedRows();
                        setSelectedItems(selectedRows);
                        statusBarChange(params);
                    }}
                    // listen for filter changes
                    onFilterModified={() => {
                        setFilterChange(!filterChange);
                    }}
                    // listen for sort changes
                    onSortChanged={(params) => {
                        setSortChange(!sortChange);

                        saveColumnState(params, tableControls, gridReady);
                    }}
                    // listen for row group open
                    onRowGroupOpened={(params) => statusBarChange(params)}
                    // listen if column becomes visible
                    onColumnVisible={(params) => onColumnVisible(params, tableControls, gridReady)}
                    // listen for filter changes
                    onFilterChanged={(params: any) => {
                        statusBarChange(params);
                        onFilterChanged(params);
                    }}
                    // for editing and navigation
                    onCellKeyDown={(params: any) => !tableControls.strictEdit && onCellKeyDown(params, tableRef, menuiItems)}
                    // list to mouse down
                    onCellMouseDown={(params: any) => {
                        if (params.event.detail === 2 && params?.column?.colDef?.editable) {
                            params.api.startEditingCell({
                                rowIndex: params.rowIndex,
                                colKey: params.column.colId
                            });
                        }
                    }}
                    onCellValueChanged={(params) => {
                        if (params?.source === 'undo') {
                            const isInEditList = editList?.find((node) => node?.id === params?.node?.id);
                            if (isInEditList) {
                                let noChanges = true;
                                Object.keys(params?.node?.data).forEach((key) => {
                                    if (params?.node?.data[key] !== isInEditList.data[key]) {
                                        noChanges = false;
                                    }
                                });
                                if (noChanges) {
                                    setStatusBarData({ ...statusBarData, editedRows: statusBarData.editedRows - 1 });
                                    if (statusBarData.editedRows === 1) {
                                        setEditMode(false);
                                    }
                                    setEditList((prevState) => prevState.filter((node) => node?.id !== params?.node?.id));
                                    if (params?.data?.hasBeenEdited) {
                                        delete params?.data?.hasBeenEdited;
                                    }
                                    params.api.refreshCells({ force: true });
                                }
                            }
                        }

                        if (params?.source === 'paste') {
                            const colId = (params?.colDef as any)?.id;
                            if (menuiItems?.[colId]) {
                                const isValueInList = menuiItems?.[colId]?.find((item) => {
                                    if (typeof item.value === 'boolean' && (params?.newValue === 'true' || params?.newValue === 'false')) {
                                        return true;
                                    }
                                    return item.value == params?.newValue;
                                });
                                if (!isValueInList) {
                                    params.node.setDataValue(params.column, params.oldValue);
                                    setValueAlert(true);
                                    return;
                                }
                            }

                            onCellEditingStoppedQ(params);
                        }

                        if (params?.source === 'rangeService') {
                            onCellEditingStoppedQ(params);
                        }
                    }}
                    onCellEditingStarted={(params) => {
                        if (params?.rowPinned && !params?.data?.editable) {
                            params.api.stopEditing();
                            return;
                        }
                        if (!params?.data?.hasBeenEdited) {
                            // setEditMode(true);
                            // params.data.hasBeenEditedFromStart = true;
                            // params.data.hasBeenEdited = true;
                            // params.api.refreshCells({ force: true, rowNodes: [params.node] });
                        }
                    }}
                    onCellEditingStopped={(params) => {
                        if (params?.rowPinned) {
                            if (params?.data?.editable) {
                                params?.data?.editable(params);
                                setTimeout(() => {
                                    setPinnedRowDataCallback();
                                }, 200);
                            } else {
                                params.api.stopEditing();
                            }

                            return;
                        }
                        if (params?.newValue == params?.oldValue && editMode) {
                            statusBarData.editedRows == 0 && setEditMode(false);
                            const isInEditList = editList?.find((node) => node?.id === params?.node?.id);
                            !isInEditList && params.data.hasBeenEditedFromStart ? delete params.data.hasBeenEditedFromStart : null;
                            !isInEditList && params.data.hasBeenEdited ? delete params.data.hasBeenEdited : null;
                            params.api.refreshCells({ force: true, rowNodes: [params.node] });
                        }
                        if (tableControls.strictEdit) {
                            onCellEditingStoppedQ(params);
                            params.colDef.editable = false;
                        } else {
                            onCellEditingStoppedQ(params);
                        }
                    }}
                    // column moved

                    onColumnMoved={(params: any) => onColumnMoved(params, tableControls, editMode)}
                    onColumnGroupOpened={(params: any) => onColumnGroupOpened(params, tableControls, gridReady)}
                    // column resized
                    onColumnResized={(params: any) => onColumnResized(params, tableControls, gridReady)}
                    stopEditingWhenCellsLoseFocus={true}
                    //---- nested table ----//
                    detailCellRendererParams={{
                        detailGridOptions: {
                            columnDefs: nestedColumnsDefs,
                            defaultColDef: defaultColDef,
                            enableRtl: true,
                            enableRangeSelection: true,
                            enableCharts: true,
                            cellStyle: { direction: 'rtl' }
                        },
                        getDetailRowData: (params) => {
                            const nestedData = params.data?.[tableControls?.nestedColumns?.subData as keyof typeof params.data] || [];
                            params.successCallback(nestedData);
                        }
                    }}
                />
            </div>
            {resetTableDialog ? (
                <Dialog
                    open={resetTableDialog}
                    onClose={() => setResetTableDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'האם אתה בטוח שברצונך לאפס את הטבלה למצב ראשוני?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">כל השינויים שלא נשמרו יאבדו</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setResetTableDialog(false)} color="primary">
                            ביטול
                        </Button>
                        <Button
                            onClick={() => {
                                resetTable(tableRef, tableControls, columnDefs, setCustomeUserState, gridReady);
                                setResetTableDialog(false);
                            }}
                            color="primary"
                            autoFocus
                        >
                            אישור
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : undefined}
            {grossValueChangeModal ? (
                <GrossValueChangeDialog
                    grossValueChangeModal={grossValueChangeModal}
                    setGrossValueChangeModal={setGrossValueChangeModal}
                    grossValue={grossValue}
                    setGrossValue={setGrossValue}
                    tableRef={tableRef}
                    setEditMode={setEditMode}
                    statusBarData={statusBarData}
                    setStatusBarData={setStatusBarData}
                    tableControls={tableControls}
                    setValueAlert={setValueAlert}
                    setEditList={setEditList}
                    editList={editList}
                    menuiItems={menuiItems}
                />
            ) : undefined}

            {valueAlert ? (
                <Snackbar
                    open={valueAlert}
                    autoHideDuration={6000}
                    onClose={() => setValueAlert(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        style={{ color: 'black' }}
                        variant="filled"
                        onClose={() => setValueAlert(false)}
                        severity="warning"
                        sx={{ width: '100%' }}
                    >
                        הערך שהכנסת אינו מתאים לסוג העמודה
                    </Alert>
                </Snackbar>
            ) : undefined}
            <CreateStateDialog
                openCreateStateDialog={openCreateStateDialog}
                setOpenCreateStateDialog={setOpenCreateStateDialog}
                tableRef={tableRef}
                customeUserButtons={customeUserButtons}
                setCustomeUserButtons={setCustomeUserButtons}
                tableControls={tableControls}
                setCustomeUserState={setCustomeUserState}
            />
            {specialDialogs && specialDialogs(tableRef?.current)}
            {defaultGroupAlert ? (
                <Snackbar
                    open={defaultGroupAlert}
                    autoHideDuration={3000}
                    onClose={() => setDefaultGroupAlert(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        style={{ color: 'black', fontWeight: 'bold' }}
                        variant="filled"
                        onClose={() => setDefaultGroupAlert(false)}
                        severity="error"
                        sx={{ width: '100%' }}
                    >
                        קבוצה זו אינה ניתנת להסרה
                    </Alert>
                </Snackbar>
            ) : undefined}
        </div>
    );
};

export default React.memo(AgTableMobile);

import { useTheme } from '@mui/material/styles';
import { HeaderProps } from '.';
import ButtonBase from '@mui/material/ButtonBase';
import Avatar from '@mui/material/Avatar';
import { IconMenu2 } from '@tabler/icons';

export const MenuToggleButton = ({ leftDrawerOpened, handleLeftDrawerToggle }: HeaderProps) => {
    const theme = useTheme();

    return (
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
            <Avatar
                variant="rounded"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                    '&:hover': {
                        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                    }
                }}
                onClick={handleLeftDrawerToggle}
                color="inherit"
            >
                {!leftDrawerOpened ? <IconMenu2 stroke={1.5} size="1.3rem" /> : <IconMenu2 stroke={1.5} size="1.3rem" />}
            </Avatar>
        </ButtonBase>
    );
};

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ThunkAction, Action } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { apiSlice } from './api/apiSlice';
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware, apiSlice.middleware));
const store = createStore(reducer, composedEnhancer);
const persister = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export { store, persister };

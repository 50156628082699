import { TextField, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from "react";
import "./PreviewComments.scss";
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import CancelIcon from '@mui/icons-material/Cancel';
import { ExtendedDocument } from "../../../../types/DocumentsTypes/Document";
import { useActivateCustomerDocument, useEditComment } from "../../../../hooks/customer/documents.hooks";

const PreviewDocumentComments = (
  { comment, doc, outerDoc,pool }: { comment: string, doc: ExtendedDocument, outerDoc?: any,pool?:string  }
) => {

  const [text, setText] = useState(comment);
  const [editMode, setEditMode] = useState(false);
  const createCustomerDocument = useEditComment(pool);
  const onSave = () => {
    setEditMode(false);
    createCustomerDocument.mutate({ doc: { ...doc, comment: text } });

    // CALLBACK
  };

  const onCancel = () => {
    setEditMode(false);
    setText(comment);
  };

  useEffect(() => {
    setText(comment);
  }, [comment]);

  return (
    <>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        {!outerDoc && !editMode ?
          <Typography variant='subtitle1'>
            הערות: {text}
          </Typography>
          : null}
        {!outerDoc ?
            <>{!editMode ?
            <EditIcon className="edit-icon" style={{
              cursor: 'pointer',
            }} onClick={() => {
              setEditMode(true);
            }} />
            :
            <>
              <SaveSharpIcon className="edit-icon" style={{
                cursor: 'pointer',
              }} onClick={onSave} />
              <CancelIcon className="edit-icon" style={{
                cursor: 'pointer',
              }} onClick={onCancel} />
            </>

        }</>:
        undefined
        }

      </div>
      {
        editMode || outerDoc ?
          <TextField
            id="outlined-multiline-static"
            label="היערות"
            multiline
            sx={{ width: '100%' }}
            rows={2}
            defaultValue={text}
            variant="outlined"
            onChange={(e) => {
              setText(e.target.value);

              outerDoc ?   outerDoc(prev=> {
                return {...prev,notes:e.target.value}
              }) : null

            }}
          /> : null
      }
    </>
  )
};

export default PreviewDocumentComments;

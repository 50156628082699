
import Agent, { getType } from '../../types/agent';
import {ApiCall, NKTypeConvertor} from '../../fromKotlin/nk';

export const addNewAgent = async (a: Agent) => {
    const req = new ApiCall('NEW_AGENT');
    req.putParam('user_name', a.name);
    req.putParam('type', getType(a));
    return req.execute();
};

export const addNewAgentForce = async (user_name:string,type:number) => {
    const req = new ApiCall('NEW_AGENT');
    req.putParam('user_name', user_name);
    req.putParam('type', type);
    return req.execute();
};

export const updateAgents = async (data) => {
    const req = new ApiCall('UPDATE_AGENT');

    const ids = data.map((a) => a.id);
    const user_name = data.map((a) => a.name);
    const comment = data.map((a) => a.comment??'');
    const password = data.map((a) => a.password??'');
    req.putParam('ids',NKTypeConvertor().toKotlinList(ids) );
    req.putParam('user_name',NKTypeConvertor().toKotlinList(user_name) );
    req.putParam('password',NKTypeConvertor().toKotlinList(password) );
    req.putParam('comment',NKTypeConvertor().toKotlinList(comment) );
    return req.execute();
};

// pass (display) = (costumeControls || selectionControls || chipDetails || tableControls?.allowReorder)
export const AgTableMain = (displayFlex: boolean) => ({
    marginBottom: 10,
    position: 'relative',
    display: displayFlex ? 'flex' : 'none',
    alignItems: 'center',
    width: '100%'
});

export const AgTableMainInnerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '50%'
};

export const AgTableActionsBarStyle = {
    paddingBottom: 50,
    backgroundColor: '#f8f8f8',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    border: 'solid #babfc7 1px',
    paddingTop: 10
};

import React from 'react';

import {Box, Grid, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/lab';

import {FormattedMessage} from 'react-intl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import {IconDotsCircleHorizontal} from '@tabler/icons';

import {enUS, he} from 'date-fns/locale';
import config from '../../../config';

interface DocumentSupplierDetailsProps {
    supplier: any;
    date: Date | null;
    setDate: (date: Date) => void;
}
const DocumentSupplierDetails = ({ supplier, date, setDate }: DocumentSupplierDetailsProps) => {
    const [showBusinessDetails, setShowBusinessDetails] = React.useState(false);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h4">
                    <FormattedMessage id="For" />
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
                <TextField fullWidth id="name" name="name" label={'שם ספק'} value={supplier.name} />
            </Grid>
            <Grid item xs={6} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.i18n === 'he' ? he : enUS}>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} type={'date'} />}
                        label={<FormattedMessage id="Date" />}
                        value={date ?? new Date()}
                        onChange={(newValue) => {
                            if (newValue && !isNaN(newValue.getDate())) setDate(newValue);
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="h4" sx={{ marginRight: 1 }}>
                        <FormattedMessage id="Business Details" />
                    </Typography>
                    <Tooltip title={<FormattedMessage id="Show Business Details" />}>
                        <IconButton onClick={() => setShowBusinessDetails((p) => !p)} size="small">
                            <IconDotsCircleHorizontal />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Grid>
            {showBusinessDetails && (
                <>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            id="businessName"
                            name="businessName"
                            label={<FormattedMessage id="Company Name" />}
                            value={supplier.businessName}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            id="businessId"
                            name="businessId"
                            label="ח.פ. \ ע.מ."
                            type="string"
                            value={supplier.businessId}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <TextField fullWidth id="city" name="city" label={<FormattedMessage id="City" />} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField fullWidth id="street" name="street" label={<FormattedMessage id="Street" />} value={supplier.street} />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            fullWidth
                            id="streetNumber"
                            name="streetNumber"
                            label={<FormattedMessage id="Street Number" />}
                            type="number"
                            value={supplier.streetNumber}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default DocumentSupplierDetails;

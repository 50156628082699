const basicPinnedControl = (control = false) => ({
  pinnedRowControlsNew: [{
    id: 1,
    label: "שורת סיכום",
  },
  ],
  pinnedRowTopDefault: [1],
  control: control,
});

export default basicPinnedControl;
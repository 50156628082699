import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Select, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RouteIcon from '@mui/icons-material/Route';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import InventoryIcon from '@mui/icons-material/Inventory';
const TimelineAccordion = ({ metaData, isOpen, width, height, children, style, options }: {
  metaData: {
    [key: string]: any
  },
  isOpen: boolean,
  children: any,
  width?: number | string,
  height?: number | string,
  style?: any
  options?: {
    id: string;
    label: string;
    action: (data?: any) => void;
  }[];
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  return (
    <Accordion
      defaultExpanded={isOpen}
      style={{
        width: width ? `calc(${width} - 3px)` : "calc(33% - 3px)",
        height: height ? height : "100%",
        maxHeight: "600px",
        minHeight: "70px",
        margin: "5px 0px",
        border: "solid 1px #e0e0e0",
        borderRadius: "10px",
        padding: "0px",
        ...style
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container spacing={2} style={{ marginTop: -25, marginBottom: -10, display: "flex", justifyContent: "center", alignItems: "center" }}>
          {options ?
            <Grid item xs={1} style={{
              padding: "10px 0px",
            }}>
              <div style={{
                display: "flex",
                position: "relative",
                flexDirection: "column",
              }}>
                <span className='more-options' onClick={(e) => {
                  e.stopPropagation();
                  setOpenOptions(!openOptions)
                }}>
                  <MoreVertIcon />
                </span>
                <Select
                  open={openOptions}
                  onClose={() => setOpenOptions(false)}
                  sx={{
                    display: "flex",
                    opacity: 0,
                    p: 0,
                    m: 0,
                    width: "100%",
                  }}
                  MenuProps={{
                    onClose: () => setOpenOptions(false),
                  }}
                >
                  <MenuItem onAbort={() => setOpenOptions(false)} onClick={(e) => {
                    e.stopPropagation();
                    setOpenOptions(false)
                  }}>
                    סגור
                  </MenuItem>
                  {options?.map((option) => (
                    <MenuItem key={option.id} value={option.id} onClick={(e) => {
                      e.stopPropagation();
                      option.action()
                    }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            : null
          }
          {
            metaData?.title ?
              <Grid item xs={2}>
                <Tooltip
                  title="נהג"
                  style={{
                    margin: "0 5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center"
                  }}>
                  <div>
                    <LocalShippingIcon style={{
                      color: metaData?.title === "ללא driver" || !metaData?.title ? "red" : "green"
                    }} />
                      <b>{metaData.title}</b>
                  </div>
                </Tooltip>
              </Grid> : null
          }
          {
            metaData?.amount ?
              <Grid item xs={2}>
                <Tooltip
                  title="לקוחות שבוקרו"
                  style={{
                    margin: "0 5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <div>
                    <RouteIcon style={{
                      color: "blue"
                    }} />
                      <b>{metaData.amount}</b>
                  </div>
                </Tooltip>
              </Grid> : null
          }
          {1 ?
            <Grid item xs={2}>
              <Tooltip
                title="ביצוע מתוך תכנון"
                style={{
                  margin: "0 5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                <div>
                  <AssignmentTurnedInIcon style={{
                    color: "green"
                  }} />
                 <b> {metaData.plannedTotal} / {metaData.planned}</b>
                </div>
              </Tooltip>
            </Grid> : null
          }
          {
            1 ?
              <Grid item xs={2}>
                <Tooltip
                  title="לא בוצעו מהתכנון"
                  style={{
                    margin: "0 5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                  <div>
                    <EventBusyIcon style={{
                      color: "red"
                    }} />
                      <b>{metaData.notDone}</b>
                  </div>
                </Tooltip>
              </Grid> : null
          }
          {
            metaData.time ?
              <Grid item xs={2}>
                <Tooltip
                  title="זמן"
                  style={{
                    margin: "0 5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                  <div>
                    <AccessTimeIcon style={{
                      color: "#df8600"
                    }} />
                      <b>{metaData.time}</b>
                  </div>
                </Tooltip>
              </Grid> : null
          }          {
            metaData.value ?
              <Grid item xs={2}>
                <Tooltip
                  title="כמות"
                  style={{
                    margin: "0 5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}>
                  <div>
                    <InventoryIcon style={{
                      color: "#483e3e"
                    }} />
                    <b>{metaData.value}</b>
                  </div>
                </Tooltip>
              </Grid> : null
          }
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{
        padding: "0px",
      }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default TimelineAccordion;

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import RouteIcon from '@mui/icons-material/Route';
import {Box, Grid, Tooltip, Typography} from '@mui/material';
import InventoryIcon from "@mui/icons-material/Inventory";
const RouteFilterBar = ({
  summaryData,
  summaryFilter,
  setSummaryFilter,setDocuments, setPreviewData,setPreviewOpen,oManagment
}) => {

  return (
    <Box style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      height: 55,
      border: '1px solid #e0e0e0',
      padding: "5px 10px",
      marginBottom: 5,
      borderRadius: 10,
    }}>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", backgroundColor: summaryFilter === "noDriver" ? "#e0e0e0" : "unset", borderRadius: 10, padding: 5 }}>
        <Typography variant="h5" style={{ marginRight: 10, marginBottom: 3 }}>סה"כ ללא נהגים</Typography>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }} onClick={() => {
          if (summaryFilter === "noDriver") {
            setSummaryFilter(null)
          } else {
            setSummaryFilter("noDriver")
          }
        }}>
          <LocalShippingIcon style={{
            color: "red"
          }} />
          <Typography variant="h5" style={{
            marginRight: 10,
          }}>{summaryData?.numOfWithNoDrivers}</Typography>
        </div>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", backgroundColor: summaryFilter === "withDriver" ? "#e0e0e0" : "unset", borderRadius: 10, padding: 5 }}>
        <Typography variant="h5" style={{ marginRight: 10, marginBottom: 3 }}>סה"כ נהגים</Typography>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }} onClick={() => {
          if (summaryFilter === "withDriver") {
            setSummaryFilter(null)
          } else {
            setSummaryFilter("withDriver")
          }
        }}>
          <LocalShippingIcon style={{
            color: "green"
          }} />
          <Typography variant="h5" style={{
            marginRight: 10,
          }}>{summaryData?.numOfDrivers}</Typography>
        </div>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}>
        <Typography variant="h5" style={{ marginRight: 10, marginBottom: 3 }}>סה"כ לקוחות</Typography>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <RouteIcon style={{
            color: "blue"
          }} />
          <Typography variant="h5" style={{
            marginRight: 10,
          }}>{summaryData?.numOfRoutes}</Typography>
        </div>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}>
        <Typography variant="h5" style={{ marginRight: 10, marginBottom: 3 }}>סה"כ כמויות</Typography>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <InventoryIcon style={{
            color: "#483e3e"
          }} />
          <Typography variant="h5" style={{
            marginRight: 10,
          }}>{summaryData?.totalValue}</Typography>
        </div>
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" ,cursor:"pointer" }}  onClick={(e)=>{
        const docs = []
        oManagment.forEach((driver)=>{
          const x = driver.route.filter(r => r.cname === r.planName).map((r) => r.docs).flat()
          docs.push(...x);
        })
        setDocuments(docs)
      }}>
        <Typography variant="h5" style={{ marginRight: 10, marginBottom: 3 }}>סה"כ בוצעו</Typography>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <AssignmentTurnedInIcon style={{
            color: "green"
          }} />
          <Typography variant="h5" style={{
            marginRight: 10,
          }}>{summaryData?.numOfPlanned}</Typography>
        </div>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" ,cursor:"pointer"}} onClick={(e)=>{
        const docs = []
        oManagment.forEach((driver)=>{
          const x = driver.route.filter(r => r.cname === "" || !r.cname).map((r) => r.plannedDoc)
          docs.push(...x);
        })
        setDocuments(docs)
      }}>
        <Typography variant="h5" style={{ marginRight: 10, marginBottom: 3 }}>סה"כ לא בוצעו</Typography>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }} >
          <EventBusyIcon style={{
            color: "red"
          }} />
          <Typography variant="h5" style={{
            marginRight: 10,
          }}>{summaryData?.numOfNotDone}</Typography>
        </div>
      </Box>
    </Box>
  )
}

export default RouteFilterBar

import React from 'react';
import { Box, Typography } from '@mui/material';
import styledTag from '../styles';
import { TagProps } from '../types';
import { CircleX } from 'tabler-icons-react';
function BooleanTag(props: TagProps) {
    const { filter, onApplyFilter, onRemoveFilter, onDelete, ...other } = props;
    React.useEffect(() => {
        if (onApplyFilter)
            onApplyFilter({ property: filter.id, contains: '', custom: filter.custom ?? (() => false) })
    }, [filter, onApplyFilter])
    return (
        <Box {...other} sx={{ cursor: 'pointer' }}>
            <Typography variant="h5" fontWeight="bold">
                {filter.label ?? filter.id}
            </Typography>
            <CircleX fontSize={'medium'} onClick={() => {
                onDelete();
                if (onRemoveFilter)
                    onRemoveFilter({ property: filter.id, contains: '' })
            }} />
        </Box>
    );
}

const StyledBooleanTag = styledTag(BooleanTag);
 
export default StyledBooleanTag;

// import other from './other';
import application from './application';
import dashboard from './dashboard';
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [application]
};

export default menuItems;

import {Box, Typography} from '@mui/material';
import Customer from 'types/customer';
import {MapPin} from 'tabler-icons-react';

// import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';

function CustomerAddress({ customer, withIcon = true }: { customer: Customer; withIcon?: boolean }) {
    // eslint-disable-next-line no-nested-ternary
    const address = customer.street && customer.streetNumber ? `${customer.street}, ${customer.streetNumber}` : customer.street;
    const full_address = customer.city && address ? `${address} ${customer.city}` : customer.city;

    if (!customer.city && !address && customer.location=='') return <Box />;
    const openGmaps = (result) => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${result}`, '_blank').focus();
    };
    return (
        <Box sx={{ cursor: 'pointer' }} onClick={(e)=>{
            openGmaps(customer.location!="" ? customer.location : full_address )

        }}>
            {withIcon ? (
                <Typography variant="subtitle1">
                    {' '}
                    <MapPin size={14} strokeWidth={1} color="#000000" /> {customer.city}
                </Typography>
            ) : (
                <Typography variant="subtitle1">{customer.city}</Typography>
            )}
            <Typography variant="caption">{address}</Typography>

            {customer.location!="" ? <div><Typography variant="caption">{customer.location}</Typography></div>:undefined}

        </Box>
    );
}

export default CustomerAddress;
